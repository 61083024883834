import { isMobile } from '../utils/index'
export default {
  address: '接待中心：桃園市蘆竹區大福路145號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14461.58242481766!2d121.24736666770482!3d25.020645183978576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34682102351fe553%3A0x80d36070ed716d14!2z5qSN5pil5qi5!5e0!3m2!1szh-TW!2stw!4v1617774811235!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/aMWGVKfV4YeWXcgM7',
  phone: '03-3259055',
  fbLink: 'https://www.facebook.com/植春樹-103761678229425',
  fbMessage: 'https://m.me/103761678229425',
  caseName: '植春樹',
  indigatorLength: 10,

  houseInfos: [
    ['｜投資興建｜', '玖登開發投資有限公司'],
    ['｜建築設計｜', '張建鴻建築師事務所'],
    ['｜營建施工｜', '振昇營造股份有限公司'],
    ['｜樓層規劃｜', '地上15層，地下3層'],
    ['｜格局規劃｜', '二房(23~29坪) 、 三房(36坪) 、 四房(43坪)'],
    ['｜公設比例｜', '32.9%'],
    ['｜企劃銷售｜', '鴻智行銷'],
    ['｜創意整合｜', '天彤企劃'],
    ['｜建造執照｜', '109桃市都建執照字第01197-02號'],
  ],

  gtmCode: ['NXPZLM6','NVH86BK'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '｜預約賞屋｜' : '｜預約賞屋｜',
    subTitle: ''
  }
}
