export default [
  					
  // { name: '首頁 ', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '大竹崛起', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: -40 },
  { name: '六大價值', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: -70 },
  { name: '職人品牌', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: -70 },
  { name: '質感生活', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: -70 },
  { name: '精心規劃', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: 5 },
  { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 0 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]
