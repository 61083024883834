<template>
  <div class="section1">
    <img src="./s1/bg.png" :alt="`${info.caseName}_img`" class="grass" data-aos="fade-top" data-aos-offset="-500" dataaos--delay="800">
    <div>
<svg version="1.1" class="logo" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 437 744" style="enable-background:new 0 0 437 744;" xml:space="preserve">
<g id="樹">
	<path class="st0" d="M194.7,742.2c-0.2,0.5-0.7,0.2-1.1,0.2c-8.8,0-17.7-0.2-26.5-0.1c-4,0-8,0.6-12,0.9c-0.8,0.1-1.5,0-2.3,0
		c-0.8,0-1.3-0.5-1.5-1.1c-0.2-0.6,0.5-0.9,0.9-1.2c1.1-0.7,2.4-0.7,3.5-1.3c3.7-1.8,5.8-4.9,6.9-8.8c1.8-6.3,0.9-12.7,0.2-19
		c-0.5-4.2-0.8-8.5-1.2-12.7c-0.3-3-1.1-6-1.5-9c-0.5-3.1-1.1-6.2-1.3-9.3c-0.2-3.6-1.5-6.9-3-10.1c-0.7-1.5-1.3-3.1-2-4.7
		c-0.4-0.9-0.2-1.7,0.7-2.2c0.7-0.4,1.4-0.8,2.2-1.1c1-0.4,1.5,0.1,1.8,1c1.1,3,2.1,6.1,3.1,9.1c1.3,3.8,2.5,7.6,3.7,11.3
		c0.1,0.2,0,0.4,0.3,0.4c0.3,0,0.4-0.2,0.6-0.5c2.1-3.4,3.1-7.2,3.6-11.1c0.5-4.1,0.9-8.2,1.4-12.2c0.2-1.3,0.7-1.5,1.7-0.6
		c0.7,0.6,1.4,1.3,2.1,2c0.6,0.6,1,0.5,1.2-0.4c0.4-1.6,0.8-3.2,1.5-4.8c0.4-0.9-0.5-1.1-0.9-1.5c-2.4-2.1-4.3-4.6-5.4-7.6
		c-0.1-0.3-0.2-0.6-0.3-0.8c-0.4-0.8-0.4-1.7,0.3-2c0.6-0.3,1.1,0.6,1.5,1.2c0.5,0.9,1,1.8,1.4,2.8c0.9,2.1,2.5,3.3,4.6,4
		c0.3,0.1,0.7,0.4,0.9,0c0.2-0.3,0.1-0.7-0.1-1c-1-2.1-2.1-4.1-3-6.2c-1-2.2-2.4-4.3-3.2-6.6c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.9-0.8-1.2-2-1.7-3.1c-0.3-0.6,0-1.4,0.5-1.8c0.6-0.4,1,0.3,1.4,0.7c0.8,1.1,1.6,2.1,2,3.4c0.8,0.6,1.1,1.5,1.7,2.3
		c0.3,0.4,0.5,1.1,1.2,0.6c0.1-1.8-0.3-3.6,0.1-5.4c0.2-0.9,0.8-1.1,1.6-0.6c1,0.6,1.5,1.5,1.5,2.6c0,3.5,0.3,7,0.9,10.4
		c0.3,1.6,1,3.2,1.2,4.8c0.8,6.1,2.5,12.2,1.2,18.4c-1.4,6.4-1.5,12.9-1.4,19.3c0,2.4,0,4.9,0,7.3c0,0.9,0.2,1.7,1.3,1.8
		c1.1,0.2,1.4-0.5,1.7-1.3c0.4-1.3,0.7-2.6,1.4-3.8c0.8-1.5,1.7-2.1,3.2-1.7c2.1,0.5,3.1,1.7,2.9,3.4c-0.3,2.2-0.9,4.3-1.7,6.4
		c-1.8,4.8-3.7,9.7-5.5,14.5c-2,5.6-1.2,10.9,1.6,16c1.5,2.7,3.6,5.1,5.4,7.5C194.4,741.7,194.8,741.9,194.7,742.2z M176.4,576.8
		c0.7-2.5,2.5-4.1,4.7-5.4c2.6-1.5,5.2-2.9,7.8-4.5c0.6-0.4,1.6-0.8,1.2-1.6c-0.3-0.5-1.3-0.1-1.9,0.3c-1.3,0.8-2.6,1.6-3.9,2.2
		c-1.5,0.7-2.9,1.8-4.7,2.3c0.2-0.6,0.6-1,0.9-1.3c2.3-2.7,4.5-5.3,6.8-8c0.4-0.5,1-1.1,1.1-1.8c0.1-0.3,0.3-0.8-0.1-1
		c-0.4-0.2-0.7,0-1,0.4c-1.1,1.6-2.3,3.1-3.5,4.7c-1.5,1.9-2.9,3.9-5.3,4.9c-0.2-0.4-0.2-0.8,0-1.1c1.5-2.6,1.8-5.4,1.2-8.4
		c-0.2-1.2,0.3-2,1.5-2.5c1.4-0.6,2.8-1.3,4.2-2.1c0.6-0.3,1.6-0.8,1.1-1.5c-0.4-0.7-1.3-0.2-1.9,0.3c-0.5,0.5-1.2,0.7-2,0.9
		c1.2-2.3,2.4-4.5,3-6.8c0.2-0.7,0.3-1.4,0.6-2.2c0.9-2,2.7-2.9,4.3-4.1c0.3-0.2,0.9-0.1,0.7-0.7c-0.2-0.6-0.8-0.4-1.2-0.3
		c-2.3,0.8-4.4,2.2-5,4.5c-1,3.6-2.6,6.7-4.5,9.8c-0.1,0.2-0.1,0.7-0.5,0.7c-0.4,0-0.6-0.3-0.7-0.7c-0.6-3-2.2-5.5-3.9-7.9
		c-2.1-2.8-2.5-5.9-1.8-9.2c0.3-2.4,0.7-4.7,2.2-6.8c0.7-1,1.2-2.1,1.8-3.1c1.4-2.2,2.3-4.8,4.4-6.5c4.2-3.5,8.8-6.4,14-8.2
		c0.6-0.2,1.1-0.6,0.9-1.3c-0.2-0.9-0.8-0.8-1.4-0.6c-0.3,0.1-0.6,0.2-1,0.4c-3,1.4-6,2.8-8.9,4.3c-4,2-7.2,4.8-9.2,8.9
		c-0.6,1.2-0.6,2.7-1.8,3.8c-0.7-3.8-0.5-7,1-10.3c1.5-3.4,1.5-6.6-0.5-9.8c-0.2-0.2-0.2-0.6-0.3-0.8c-1.1-2.3-0.7-4.9-1.3-7.3
		c-0.2-0.8-0.4-1.5-1.4-1.7c-0.1,0.5-0.1,0.9-0.2,1.3c0,1,0,2,0,3c0,2.1,0,2.1-1.9,1.3c-1.4-0.6-2.8-0.7-4.6-0.3
		c1.9,1.2,3.5,2.4,5.3,3.5c4.1,2.6,4.7,7.8,2.2,11.7c-0.4,0.6-0.7,0.7-1.2,0c-0.8-1.1-1.6-2.1-2.5-3.1c-0.9-0.9-1.6-1.9-2.7-2.5
		c-0.7-0.4-1.3-0.1-1.6,0.4c-0.4,0.5,0.3,0.7,0.6,1c0.8,0.8,1.7,1.7,2.5,2.6c3.4,3.8,4.8,8.2,3.9,13.2c-0.8,4.1-2.1,8.2-0.8,12.4
		c0.1,0.4,0.1,0.8-0.5,0.8c-1.1,0.1-3.5-1.5-3.8-2.6c-1.2-4.4-4.5-7.4-6.8-11c-0.5-0.7-1.5-1.1-2.2-0.3c-0.8,0.8-0.2,1.4,0.5,2.1
		c2.5,2.4,4.9,4.8,6.3,8c0.1,0.3,0.4,0.6,0.2,0.8c-0.2,0.3-0.5,0.1-0.8,0c-2.3-0.4-4.7-0.9-7-1.2c-4.4-0.6-7.6-3.1-10.2-6.5
		c-3.1-4-3.5-8.6-2.9-13.4c0.1-0.6,0.5-1.5-0.7-1.6c-1.1-0.1-1.5,0.5-1.5,1.4c0,1.6,0,3.2-0.1,4.9c0,2.2,0.2,4.4,1,6.6
		c1.1,3,3.1,5.2,5.7,7c0.3,0.2,1,0.4,0.8,1c-0.2,0.5-0.7,0.2-1.1,0.2c-3.7-0.1-7.3-0.6-10.9-1.6c-1.6-0.5-1.5-1-1-2.3
		c0.6-1.3,1.2-2.5,0.9-4c-0.5-2-0.5-4,0.4-5.9c0.4-0.9,0.8-1.9,0.1-3c-1,0.6-1.4,1.5-1.7,2.5c-0.2,0.4-0.3,1.3-1.1,0.5
		c-1-1-2-2-2.6-3.3c-0.7-1.5-0.9-3.1-0.9-4.7c0-0.9-0.4-1.8-1.2-1.8c-0.7,0-0.6,1-0.7,1.6c-0.1,1.7,0.1,3.3,1,4.7
		c1,1.6,2.1,3,3.2,4.5c1.9,2.7,2,5.8,0,8.3c-0.6,0.8-0.8,0.9-1.6,0.1c-1-1-1.9-2.1-2.6-3.4c-0.7-1.2-1-2.8-2.6-3.5
		c-0.1,1.5,0.4,3,2,5.3c3.3,4.7,8.2,6.8,13.8,8.1c-1.7,2-4.7,1.4-6.4,3.5c1.4-0.1,2.5,0,3.7-0.4c3.1-1.2,6.3-1.7,9.6-2
		c1.4-0.1,2.8-0.3,4.3-0.2c0.8,0.1,1,0.2,0.5,1c-0.8,1.1-1.6,2-2.8,2.5c-3.8,1.6-7.9,2.4-11.8,3.6c-2.9,0.8-6.1,1.3-7.7,4.5
		c-0.3,0.7-0.8,1.4,0,1.9c0.7,0.4,1.3,0,1.8-0.6c1.7-2.5,4.3-3.7,7.2-4.2c0.5-0.1,1.2-0.5,1.5,0.1c0.3,0.5-0.2,1-0.5,1.5
		c-1.8,3-1.8,3.9,0.6,6c1.1,1,1.9,2,1.8,3.6c1.4-0.5,1.5-1.6,1.2-2.7c-0.3-1-1-1.8-1.6-2.7c-1.1-1.5-0.9-3.3,0.5-4.6
		c0.9-0.8,2-1.2,3.1-1.8c3.3-1.7,6.8-3.1,8.7-6.7c0.4-0.8,1.3-1.4,2.3-1.3c2.8,0.1,5.4,0.8,7.9,2.4c-7.9,1.9-10,2.9-11.3,5.1
		c1.7-0.7,3.3-1.6,5-2.1c1.7-0.6,3.5-0.9,5.3-1.3c1.6-0.4,3.2-0.6,4.9,0.1c2.3,0.9,3.8,2.6,5,4.6c1.1,1.8,2,3.6,2.4,6
		c-2.4-1.8-4.6-3.7-7.6-3.3c-2.7,0.3-5.6-0.3-8.4,1.1c1.2,0.9,1.2,0.9,1.8,0.8c3.7-0.7,7.2-0.4,10.7,1c3.1,1.3,4.1,3.1,3.3,6.4
		c-0.6,2.6-1.9,5-2.6,7.5c-0.6,1.9-1.3,3.9-1.3,5.9c0,1.6,1.5,1.4,2.4,1.8C175.9,577.9,176.3,577.3,176.4,576.8z M231.3,643.1
		c-1,1.6-1.8,3.3-2.8,5c1,0.2,1.4-0.6,1.9-1.1c1.8-1.9,3.6-3.8,5.3-5.8c3.5-4.1,8-5.5,13.2-5.3c0.2,0,0.4,0,0.6,0.1
		c0.3,0.1,0.5,0.3,0.9,0.5c-1.5,1.1-3,1.7-4.9,1.8c-3.4,0.1-6.1,1.5-7.8,4.5c-0.7,1.3-1.6,2.4-2.6,3.5c-3.2,3.7-7.1,6.7-10.6,10.2
		c-1.9,1.8-3.5,3.8-5.3,5.8c-0.4,0.5-0.6,1.2-0.9,1.4c0.2-0.2,0.8-0.2,1.1-0.6c0.8-0.6,1.6-1.1,2.5-1.7c0.4-0.3,0.8-0.5,1.2-0.2
		c0.5,0.4,0.3,0.9,0.1,1.4c-0.9,2-2.7,2.9-4.3,4c-4,2.8-8.1,5.4-12,8.2c-3.3,2.4-5.4,5.6-6.9,9.4c-0.7,1.9-1.5,3.7-3.5,4.6
		c-0.3,0.1-0.6,0.3-0.8,0.1c-0.1,0-0.1-0.3,0-0.4c1.4-3.4,2.3-7.1,4.7-10c3.8-4.5,7.7-8.9,11.9-13c5.5-5.4,9.5-11.9,12.5-19
		c2.6-6,6.2-11.4,12.6-14.3c2.1-0.9,4.3-1.6,6.4-2.1c4.2-1,7.3-3.4,8.9-7.4c1.7-4.1,4.3-7.3,7.9-9.9c5.2-3.9,8.5-9.2,10.6-15.3
		c0.2-0.5,0.3-1.2,0.8-1.2c0.6,0.1,1.1,0.6,1.1,1.4c0,1,0.1,2,0.1,3c0,0.8,0.1,1.2,1,0.7c1.3-0.7,2.6-1.3,3.9-1.9
		c0.5-0.2,1.1-0.5,1.5,0.1c0.4,0.7-0.1,1.1-0.7,1.4c-1.2,0.9-2.6,1.5-3.9,2.1c-1.4,0.6-2.6,1.5-3.2,3c-1.5,3.5-4.5,5.5-7.5,7.4
		c-2.7,1.7-5.6,3.1-7.7,5.7c-2.2,2.8-3.3,6-4.9,9.3c3.4,0.1,6-2.8,9.5-2.3c-1.2,2.5-3.8,3.1-5.5,4.9c2.9,0.2,5.7,0.3,8.4-0.4
		c0.7-0.2,0.9-0.8,0.6-1.6c-1.3-3.6-1.3-7.2,0.2-10.8c0.5-1.2,1.2-2.2,2.8-2.5c-0.5,2.3-0.9,4.5-1.6,6.6c-0.7,2.1-0.3,3.8,0.7,5.6
		c0.2,0.4,0.5,0.5,1,0.2c2.5-1.3,3.5-3.7,4.3-6.1c1.3-3.8,2.5-7.6,3.7-11.5c0.1-0.5,0.5-0.8,0.7-1.1c0.4-0.6,0.9-0.9,1.5-0.6
		c0.5,0.3,0.5,0.9,0.3,1.5c-0.9,3.1-1.8,6.3-2.7,9.4c-0.4,1.6-0.3,1.7,1.3,1.5c1-0.2,2.1-0.4,3.1-0.6c0,0,0,0,0,0
		c0.9-0.9,2.1-0.6,3.1-0.8l0,0c0,0,0,0,0,0c1.9,0,3.5-1,5.4-1h0l0,0c1-0.9,2.2-0.4,3.2-0.5c0.6,0,0.4,0.5,0.2,0.8
		c-0.8,1.2-1.6,2.5-3.3,2.5c0,0,0,0,0,0c-0.2,0.6-0.8,0.8-1.2,0.8c-2.2,0-4.3,0.8-6.5,1c-0.2,0-0.4,0.1-0.6,0
		c-3.8,0.6-6.8,2.7-9.7,5c-2,1.6-3.9,3.2-6.3,4.2c-2.8,1.2-5.7,1.1-8.5,0.3c-2.4-0.7-4.6-0.7-7-0.1c-2.3,0.6-4.7,0.7-7,1.4
		C237.2,635.8,233.8,638.9,231.3,643.1z M112.3,618.6c-1.1-2.4-2.3-4.8-3.3-7.3c-1.2-2.7-2.7-5.3-4.7-7.5c-2.9-3.2-3.3-7.2-3.7-11.2
		c-0.3-3.4-0.9-6.7-2-9.9c-0.7-2.2-0.6-3.4,1.1-4.9c0.7-0.7,1.6-1.3,2.4-1.8c4-2.3,7.4-5.2,10.4-8.6c0.6-0.7,1.3-1.2,1.5-2.2
		c0.1-0.3,0.4-0.7,0-1c-0.3-0.2-0.6,0-0.9,0.2c-0.6,0.3-1.1,0.8-1.6,1.3c-3.1,3.5-6.8,6.2-10.3,9.1c-0.3,0.3-0.8,0.8-1.3,0.4
		c-0.4-0.4,0.2-0.8,0.4-1.1c1.2-3,3.3-5.3,5.3-7.8c0.3-0.4,0.8-0.8,0.4-1.3c-0.4-0.5-1-0.3-1.5,0.1c-1.1,0.8-2.2,1.7-2.9,2.9
		c-0.8,1.3-1.2,0.9-1.9-0.1c-2.2-3.3-1.2-7.3,2.3-9.1c5.5-2.9,4.6-4.2,4.6-9.3c0-0.5-0.2-0.9-0.7-1c-0.3,0.5-0.2,1.1-0.1,1.6
		c0.3,2-0.4,3.5-1.9,4.8c-0.9,0.8-1.7,1-2.3-0.4c-0.2-0.5-0.6-0.9-1.2-1.1c0,0.2-0.1,0.3-0.1,0.4c0,1.3-0.7,4-1.5,5
		c-1.1,1.4-1.1,3.2-1.5,4.9c-4.4-4.4-2.5-8.8-0.2-13.2c-3.2,1.9-4.1,4.8-3.9,8.2c0.2,3.4,1.7,6.4,3.5,9c2.6,3.7,1,6.8-0.3,10.2
		c-0.4-0.4-0.7-0.7-1.1-0.9c-0.6-0.3-1.4-0.9-1.9-0.3c-0.4,0.6,0.1,1.3,0.6,1.9c0.7,0.8,1.4,1.6,2.1,2.4c1.3,1.4,0.8,3.3,1.2,4.9
		c0.4,1.4,0.8,2.8,0.5,4.3c-1.3-0.3-2.5-1.1-3.4-1.9c-5.2-4.7-6.7-10.8-5.9-17.6c0.3-2.5,1.2-4.9,2.3-7.1c0.4-0.8,0.2-1.7-0.4-2
		c-0.8-0.4-1.4,0.4-1.7,1.1c-0.4,1.1-0.8,2.2-1,3.3c-0.2,1.4-0.9,2.7-1.9,3.9c-0.3-0.6-0.5-1.1-0.8-1.6c-1.3-2.8-3.1-5.3-5.2-7.7
		c-0.4-0.4-0.8-1-1.4-0.5c-0.5,0.4,0,0.9,0.2,1.4c0.1,0.2,0.2,0.4,0.3,0.5c3.9,4.6,5.2,10.1,5.5,15.9c0,0.7-0.1,0.9-0.8,0.9
		c-0.6,0-1-0.1-1.4-0.7c-0.8-1.1-1.7-2.2-2.5-3.3c-0.5-0.6-1.1-1.4-1.7-1c-0.6,0.4-0.1,1.4,0.3,2.1c0.4,0.6,0.8,1.2,1.2,1.9
		c-3.3,0.3-5.7-1.6-6.7-5.2c-0.2-0.6-0.3-1.2-0.7-1.6c-0.3-0.3-0.6-0.6-1.1-0.3c-0.4,0.3-0.2,0.7,0,1.1c1,1.8,1.9,3.6,2.9,5.3
		c0.6,1.1,0.8,1.9-0.2,2.9c-1,1-1.7,2.2-2.5,3.4c-0.7,1-0.6,2.1,0.5,2.6c1.1,0.5,1.2-0.6,1.6-1.3c1.1-1.7,2.2-3.6,4.5-3.6
		c4.1,0.1,7.8,1.4,10.2,4.9c2.2,3.1,4.9,5.5,8.7,6.6c2.5,0.7,3.6,3.1,2.8,5.1c-0.8,1.8-0.9,1.8-2.4,0.5c-3.2-2.7-6.9-4.5-11.1-4.8
		c-0.9-0.1-2.3-0.4-2.4,0.8c-0.1,0.8,1.4,0.9,2.3,1c3.2,0.2,6,1.5,8.6,3.3c2.5,1.6,4.4,3.9,6.3,6.2c0.4,1,1,1.8,1.8,2.5
		c4,6,7.6,12.3,12,18.1c3.7,4.8,8.9,7.7,14.1,10.4c1.1,0.6,2.1,0.4,2.6-0.8c0.6-1.4,0.2-2.6-1.2-3.1c-1.7-0.6-3.5-1.1-5.1-1.7
		c-3.8-1.5-6.8-3.8-8.3-7.8c-0.7-1.8-0.4-3.7-0.9-5.5c-0.3-1.2-0.6-2.5-0.6-3.7c0-0.6-0.2-1.3-0.9-1.2c-0.7,0.1-0.4,0.8-0.4,1.2
		c0,0.5,0.2,1.1,0.3,1.6c0.1,1.9,0.7,3.9-0.5,5.9C113,619.9,112.6,619.3,112.3,618.6z M159.8,626.9c0.5-1.3,0.8-2.7,1.1-4.1
		c0.7-3.9,0.7-7.7,0.1-11.6c-1-5.8-2.8-11.1-7.1-15.3c-2.7-2.6-5.1-5.3-6.4-8.9c-0.7-1.9-1.5-3.8-2.3-5.7c-0.3-0.8-0.6-1.8-1.9-1.4
		c-1.3,0.5-1.4,1.3-0.9,2.4c0.3,0.7,0.5,1.4,0.7,2.1c0.1,0.4,0.6,0.7,0.3,1.1c-0.4,0.4-0.8,0-1.2-0.2c-0.5-0.2-0.9-0.4-1.4-0.7
		c-2.2-1.1-4.5-2-6.9-2.2c-1.9-0.1-3.4-0.7-4.7-2c-3.2-3.3-4.4-7.1-1.6-11.5c0.8-1.3,1.4-2.7,2-4c0.2-0.5,0.6-1.4-0.2-1.7
		c-0.9-0.4-1.1,0.6-1.2,1.2c-0.4,1.9-1.2,3.7-2.1,5.5c-0.2,0.3-0.2,0.7-0.7,0.6c-0.4-0.1-0.2-0.5-0.3-0.8c-0.9-4-1.9-8-3.5-11.9
		c-1-2.5-2.4-4.9-2-7.8c0.1-1.1,0.5-2,0.8-3c0.2-0.5,0.4-1.2-0.2-1.4c-0.6-0.2-1,0.4-1.2,0.9c-0.1,0.3-0.2,0.6-0.3,0.9
		c-0.5,1.3-0.8,2.6-1.6,3.8c-2-3.1-2.8-6.3-1.1-9.8c2-4.1,0.6-7.7-1.4-11.3c-0.3-0.5-0.6-1.1-1.3-0.8c-0.7,0.3-0.6,0.9-0.5,1.6
		c0.1,0.3,0.2,0.6,0.2,0.9c0.4,2,0.5,3.9-0.2,5.8c-1.7,4.4-1.7,8.8,0.4,13.1c0.7,1.4,1.3,2.8,1.9,4.4c-1.5-0.2-2.6,0.1-3.7,0.8
		c-0.2,0.2-0.6,0.2-0.5,0.6c0.1,0.6,0.5,0.4,0.8,0.4c1.1,0,2.1,0,3.2-0.1c1.1-0.1,2,0.3,2.7,1.1c1.9,2.4,3.2,5.1,3.8,8.1
		c0.3,1.9,0.1,2.1-1.7,1.5c-0.7-0.2-1.6-0.9-2.3,0c0.1,0.4,0.4,0.3,0.6,0.4c2.7,1,5.1,2.6,5.3,5.7c0.3,3.7,1.3,7,2.9,10.2
		c0.2,0.5,0.7,0.6,1.1,0.6c1.5,0.1,2.9,0.2,4.4,0.3c5.9,0.3,10.9,2.7,15.2,6.5c5.9,5.3,9.3,11.9,10.6,19.7c0.3,2,1,4.1-0.1,6.5
		c-1-2.3-2-4.3-2.9-6.4c-2.7-6.3-5.7-12.2-10.4-17.3c-0.7-0.8-1.3-1.7-1.3-2.8c0-0.6,0-1.1-0.7-1.3c-0.8-0.1-1,0.5-1.1,1
		c-0.2,1.1-0.6,0.9-1.4,0.7c-1.9-0.6-3.9-1.1-5.8-1.6c-0.8-0.2-1.2,0.2-1.4,1c-0.2,0.8,0.1,1.3,0.8,1.5c0.2,0.1,0.5,0.2,0.7,0.3
		c6.9,2,11.7,6.4,15.2,12.6c2.8,5.1,4.1,10.7,5.5,16.2c0.5,2.1,1.2,4.2,2.6,5.9C158.2,628.5,159.3,628.5,159.8,626.9z M272.8,573.2
		c-0.2-0.8-0.4-0.9-1.1-0.4c-1,0.8-1.8,1.8-2.1,3.1c-0.1,0.3-0.1,0.5-0.4,0.5c-0.3,0-0.4-0.2-0.5-0.4c-0.9-1.4-1.8-2.9-2.8-4.2
		c-0.6-0.8-0.6-1.5,0-2.3c1.7-2.6,2.3-5.5,1.9-8.5c-0.2-2.2-1.1-4-3.3-5.1c-1.2-0.6-2.3-1.2-3.5-1.8c-1-0.5-1.6-1.3-1.4-2.5
		c0.1-0.4,0.7-1-0.2-1.2c-0.7-0.1-1.1,0.2-1.3,1c-0.3,1.5,0.4,2.9,1.7,3.7c0.9,0.6,2.1,0.8,2.8,1.8c0.6,0.7,1,1.5,1.6,2.3
		c-0.9-0.9-2.1-0.9-3.1-1.4c-1.8,0-3.6-0.6-5.2,0.8c-1,0.9-2.2,1.6-3.7,1c-0.2-0.1-0.6-0.1-0.7,0.4c0,0.3,0.2,0.4,0.4,0.5
		c2.1,1.3,3.1,1.1,5.1-0.5c1-0.8,2.1-1.7,3.5-1.3c3.6,1.2,6.4,4.9,4,9c-0.6,1.1-1.3,1.3-2.4,0.8c-0.9-0.4-1.9-0.8-2.9-0.7
		c-0.5,0-0.9,0.3-1.1,0.6c-0.2,0.4,0.2,0.8,0.5,1c0.8,0.7,1.7,1.3,2.6,2c2.4,1.9,4.7,4,5.7,7c2,5.8,0.7,11.1-2.2,16.2
		c-0.2,0.3-0.4,0.7-0.8,0.6c-0.3,0-0.3-0.5-0.4-0.7c-0.7-2.1-1.4-4.2-2.1-6.3c-0.2-0.8-0.7-0.9-1.4-0.8c-0.7,0.2-1.3,0.5-1.1,1.3
		c0.5,2.1,0.6,4.3,1.2,6.4c0.4,1.5,1.6,2.8,0.9,4.5c-1.2,3.2-5.1,6.4-9.3,5.6c-1.3-0.2-1.6-0.9-1.2-2c0.3-1,0.7-2,1.1-3
		c2-5.8,3.3-11.7,2.4-17.8c-0.3-2.4-0.8-3.5-2.5-4.7c0.6,3.5,0.8,6.8,0.1,10.1c-0.7,3.3-1.9,6.3-4,9c0-3.1-0.3-6.3,0.6-9.3
		c0.2-0.7,0.3-1.5,0.4-2.2c0-0.4,0.2-1-0.4-1.1c-0.5-0.1-1,0-1.4,0.5c-0.7,1-1.4,2-1.6,3.2c-0.9,4.9-0.5,9.8,0.8,14.6
		c0.9,3.5,1.4,6.9-0.3,10.3c-0.8,1.6-1.5,3.4-2.3,5.1c0.9,0.2,1.5,0,2.2-0.2c2.4-0.9,3.7-2.8,4.6-5c0.8-1.9,2.3-2.9,4.3-2.9
		c4.7,0,7.9-2.4,10.4-6.1c1.9-2.9,3.1-6.2,4.5-9.3c1-2.2,2.1-4.4,2-7c-0.2-3.5,0-7.1,0.8-10.6C272.5,575.7,273.2,574.5,272.8,573.2z
		 M228.5,574.5c1.3,1.8,2.7,3.5,4.5,4.7l0,0l0,0c0.9,0.1,1.1,1.2,2,1.3c0.6-0.7-0.3-1.9,0.8-2.5c0,0,0,0,0,0c0,0,0,0,0,0
		c0-0.1,0-0.3,0-0.4c0.1-0.7,0.6-1.3,1.2-1.3c0.8,0.1,0.7,0.9,0.7,1.6c0,0.2-0.1,0.5-0.1,0.7c-0.5,1,0.2,2.1,0,3.1
		c0.1,1,1,1.6,1.6,2.3c1.8,2.7,3,5.6,3,8.9c0.1,3.6,0.4,7.3-0.9,10.8c-0.6,1.6-1.5,3.1-2.8,4.2c-0.6,0.6-1.3,0.6-1.8-0.4
		c-1.1-2-1.7-4.2-1.8-6.5c-0.2-3.2-0.4-6.4-1.7-9.3c-0.5-1.1-1.2-2.1-2.1-2.9c0,0,0,0,0,0c0,0,0,0,0,0c-1.1-0.6-2.5-0.9-3.5-1.7l0,0
		c-1.3,0-2.6,0-3.9,0c-0.6,0-1.2,0.1-1.6-0.6c-0.2-0.8,0.3-1.1,0.9-1.3c0.5-0.9,1.5-0.8,2.1-0.7c1.3,0.2,2.9-0.8,4,0.7
		c2.8,0.7,4.3,2.9,5.8,5c2.1,3,2.2,6.6,2.7,10.1c0,0.2,0.1,0.5,0.1,0.8c0.2-0.2,0.3-0.3,0.3-0.4c0-3.6,0.5-7.2-0.4-10.7
		c-0.5-1.9-1.5-3.5-2.8-5c0,0,0,0,0,0l0,0c-1.9-1.5-3.8-3.2-5.1-5.2l0,0l0,0c-1.4-1.5-3-2.8-4.2-4.5c-2.3-3.4-3.1-7.1-2.7-11.2
		c0.3-2.8,1.1-5.4,1.5-8.2c0.1-0.4-0.1-1.1,0.6-1c0.6,0.1,0.9,0.5,0.9,1.2c0,2.5,0,5.1,0,7.6c0,0.9,0,1.8,0,2.7
		c0.6,0.6-0.2,1.7,0.8,2.1c1.9-2.3,3.8-4.7,5.7-6.9c0.6-0.7,1-1.5,1.5-2.3c0.5-0.9,0.5-1.9-0.5-2.7c-1.7-1.5-3.6-2.8-5.1-4.6
		c-2-2.6-2.9-5.5-2.3-8.8c0.2-1.4,0-2.2-1.4-2.9c-2.2-1.1-4.5-2.2-6.2-4c-1-1.1-1.5-2.4-1.7-3.8c-0.1-1.1-0.6-1.6-1.7-2
		c-2.8-1-5.3-2.7-7.2-5c-0.5-0.6-1.1-1.2-1.1-2.2c1.6,0.2,2.5,1.4,3.6,2.2c1.4,0.9,2.5,2.2,4.1,2.7c0.4,0.1,0.8,0.5,1.1,0.1
		c0.4-0.4,0-0.7-0.2-1.1c-0.2-0.4-0.5-0.8-0.6-1.2c-0.1-0.3-0.7-0.7-0.1-1c0.4-0.2,0.9,0,1.2,0.4c0.7,0.8,1.2,1.8,1.7,2.8
		c0.5,1.1,1,2.2,1.5,3.5c1.8-2.6,2.6-5.3,2.2-8.3c0-0.3-0.1-0.7-0.2-1c-0.1-0.3-0.6-0.7,0-0.9c0.5-0.2,0.7,0.3,0.8,0.7
		c0.8,2.5,1.2,5.1,0,7.7c-0.6,1.3-1,2.6-1.4,4c-0.3,1,0.5,1.6,1.1,2.2c1.1,0.9,2.3,1.7,3.5,2.5c1.1,0.7,1.9,0.5,2.4-0.7
		c0.7-1.7,1.3-3.5,2-5.2c0.3-0.7,0.5-1.4,1.5-1c1,0.4,0.5,1.2,0.2,1.7c-2,3.5-2.9,7.3-3.2,11.2c-0.1,1,0.1,2,0.5,3.3
		c1.5-3.3,4.5-4.9,6.1-7.8c0.4-0.7,0.6-1.5,1-2.2c0.2-0.3,0.3-0.9,0.8-0.6c0.4,0.2,0.4,0.7,0.3,1.2c-0.5,1.6-1.5,3-2.7,4.1
		c-1.7,1.6-2.8,3.6-3.8,5.7c-0.6,1.4-0.2,2.6,1.1,3.4c2.3,1.4,2.3,1.4,2.5-1.2c0.3-3.2,1.8-5.6,4.2-7.5c0.4-0.3,1.1-1,1.6-0.4
		c0.5,0.5-0.2,0.9-0.5,1.3c-0.9,1.2-1.8,2.5-2.7,3.7c-0.3,0.4-0.4,0.9-0.2,1.3c0.3,0.5,0.8,0.1,1.2,0c3.4-1.3,5.4-4.5,5.3-8.2
		c-0.1-3.2-1.6-5.7-3.3-8.3c-0.5-0.7-1.7-1.6-0.8-2.3c0.8-0.6,1.3,0.9,1.9,1.5c1.2,1.2,2.2,2.6,3,4.1c1.2,2.4,1.2,4.9,0.7,7.4
		c0,0.2-0.1,0.5-0.1,0.7c0,0,0.1,0.1,0.2,0.2c1.7-1.7,3.1-3.7,4.5-5.6c0.3-0.4,0-0.9-0.3-1.2c-0.6-0.7-1.2-1.5-1.8-2.2
		c-0.3-0.4-0.8-0.8-0.3-1.3c0.4-0.4,0.9-0.1,1.4,0.2c0.3,0.2,0.6,0.4,0.9,0.6c1.6,1.1,1.6,1.1,2.8-0.6c1-1.4,2.1-2.8,3.1-4.2
		c0.8-1.1,1.9-1.7,3.2-2.2c0.6-0.2,1,0,1.3,0.5c0.2,0.4,0,0.7-0.4,1c-4.2,2.6-6,6.9-7.7,11.2c-0.3,0.9-1.5,1.9-0.8,2.7
		c0.6,0.6,1.9-0.1,2.9-0.3c3.2-0.4,6.3-0.1,9.4,0.9c0.5,0.2,1.2,0.4,1.1,0.8c-0.1,0.4-0.7,0.8-1.3,0.8c-0.9-0.1-1.8,0-2.7-0.2
		c-3.6-0.8-7.1-0.4-10.5,0.6c-1.2,0.3-2.3,1.2-3.4,1.8c-2.4,1.4-4.8,2.9-7.2,4.2c-1,0.5-1.3,1.3-1.4,2.3c-0.1,2.3-0.1,4.6-0.2,6.8
		c-0.1,1.2,0.5,1.3,1.3,1.1c3.3-0.7,5.8-2.6,7.4-5.6c0.5-1,0.9-2.1,1.6-3.1c1.1,2.1,0.8,4.3-1.1,6.2c-3.1,3.2-6.7,5.4-11.2,5.6
		C230.2,564.8,226.3,571.6,228.5,574.5z M195.5,574.3c5.3-1.9,10.9-3.2,16.1-5.4c6.7-2.9,9.6-9.5,9.3-16c-0.1-1-0.5-0.8-1-0.4
		c-0.9,0.8-1.4,1.7-1.5,3c-0.3,4.5-2,8.3-6.2,10.4c-3,1.5-6.2,2.6-9.3,3.7c-5.1,1.8-10.3,3.4-15,6.2c-1.3,0.8-2.6,1.6-4.1,1.8
		c-0.2,0-0.6,0.3-0.7-0.2c-0.1-0.3,0.2-0.4,0.4-0.5c2.5-1.9,5-3.9,7.7-5.4c5.1-2.8,10.8-4.3,16.2-6.4c4-1.5,7.1-3.9,8.6-8.2
		c1.3-3.8,1.5-7.5,0.6-11.3c-0.4-1.8-1.1-3.7-2.2-5.3c-0.3-0.5-0.7-1.1-1.5-0.5c-0.6,0.4-1,0.9-0.7,1.6c0.1,0.3,0.2,0.7,0.4,1
		c1.1,2.8,1.6,5.7,1.3,8.8c-0.4,3.9-1.8,7.2-4.9,9.7c-2.2,1.7-4.6,3-7.1,4.1c-1.6,0.7-3.3,1.3-4.9,1.8c-0.4,0.1-0.9,0.2-1.2,0
		c-0.4-0.3,0.1-0.7,0.2-1c0.9-2.7,2.9-4.7,5.2-6.2c2.7-1.8,5.9-3,8.4-5.1c0.9-0.7,0.9-0.9,0.3-2.1c-1.9,3.3-5.3,3.5-8.4,4.5
		c-0.7,0.2-0.9-0.1-0.8-0.8c0.2-1.1,0.4-2.2,0.5-3.3c-0.2-0.1-0.3-0.2-0.5-0.2c-0.5,3.4-1.7,6.4-4.7,8.2c0,0-0.1-0.1-0.2-0.2
		c-0.1-0.2-0.2-0.4-0.2-0.6c-1-3.2-0.8-6.3,1-9.3c1.1-1.8,2-3.5,3.7-4.8c2.8-2,5.4-4.2,8-6.3c0.3-0.3,0.9-0.6,0.6-1
		c-0.4-0.6-0.9,0.1-1.3,0.3c-3.1,2.2-6.2,4.4-9.2,6.6c-0.8,0.6-1,0.4-1.1-0.4c-0.2-1-0.5-2-1.2-3c-0.5,0.6-0.1,1.1,0,1.5
		c0.4,1.6,0.5,3.2-0.1,4.8c-1,2.3-2.4,4.5-2.5,7.2c-0.9-0.3-1.3-0.9-1.7-1.5c-1.5-2-1.3-4.5-1.3-7.3c-0.8,0.9-1.3,1.5-1.2,2.3
		c0.3,2.3,0.6,4.6,2,6.6c1.4,1.9,2.4,4,2.9,6.4c0.6,2.9-0.4,5.2-3,6.7c-2.6,1.5-5.5,2.6-8,4.5c-4.5,3.3-6,7.8-5.5,13.2
		c0.1,1,0.6,1.5,1.6,0.9c1.8-0.9,3.7-1.7,5.5-2.8c4.1-2.4,8.3-4.5,12.7-6c4.2-1.4,8.4-2.6,12.5-4.3c0.7-0.3,1.4-0.6,1.2-1.2
		c-0.2-0.6-1.1-0.6-1.7-0.4c-0.2,0.1-0.5,0.2-0.7,0.3c-2.3,0.7-4.5,1.5-6.8,2c-5.5,1.2-10.9,2.6-15.6,5.9c-0.6,0.4-1.2,0.7-2.1,0.3
		C187.6,577.9,191.3,575.8,195.5,574.3z M212,688.9c0.1,0.5,0.5,0.4,0.8,0.4c2.1,0.2,4.2,0.7,6,1.8c0.6,0.4,1.1,0.8,1.6,1.3
		c3.6,3.4,7.8,4.4,12.6,3.5c1.8-0.3,3.5-0.9,5.2-1.4c0.6-0.2,1.5-0.5,1.8,0.1c0.3,0.7-0.4,1.3-1.1,1.6c-3.4,1.7-7,2.3-10.8,2.4
		c-4.8,0.2-8.1-2.5-11.5-5.1c-2.5-1.9-5.4-2.9-8.3-3.7c-1.2-0.4-2.4,0.3-3.5,0.6c-2.7,0.9-5.4,2-7.4,4.1c-0.3,0.3-0.6,0.7-1,0.4
		c-0.5-0.3-0.5-0.8-0.4-1.4c0.1-0.5,0.5-0.8,1-1.1c3.1-1.8,6-3.9,9.4-5.2c2.2-0.8,4.5-1.2,6.8-1.5c1.8-0.2,2.7-1.1,3.3-2.6
		c0.4-1.2,1-2.4,1.9-3.4c0.3-0.3,0.7-0.7,1.2-0.4c0.5,0.3,0.1,0.7,0,1.1c-0.1,0.7-0.3,1.5-0.5,2.2c-0.4,1.8-0.4,1.8,1.4,1.7
		c1.4-0.1,2.8-0.3,4.1-0.9c0.3-0.1,0.5,0,0.7,0.1c1.9,0.4,2.5,0.1,3-1.9c3.4-3.7,5.9-8.1,9-12c0.2-0.3,0.4-0.5,0.7-0.8
		c0.3-0.4,0.8-0.5,1.2-0.1c0.4,0.4,0.2,0.9-0.2,1.2c-1.3,1.1-2.2,2.7-3.3,4c-1.2,1.6-2,3.5-2.8,5.3c-0.1,0.3-0.3,0.9,0,1.1
		c0.4,0.3,0.7-0.1,1-0.3c2.5-1.6,5-3.1,7.4-4.8c0.9-0.6,1.1-1.5,1.1-2.5c-0.1-3.3,0.2-6.5,0.9-9.6c0.1-0.4,0.3-0.9,0.5-1.3l0,0h0
		c0.2-1-0.1-2.1,0.8-2.8c0-0.8,0.5-1.3,0.9-1.8c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.9,0.1-1.8,0.9-2.4c0.3-0.8,0.6-1.6,0.9-2.5v0
		c0.1-1.4,0.4-2.7,0.3-4.1c0-0.4,0-1,0.5-1c0.6-0.1,0.6,0.6,0.7,1c0.1,0.2,0,0.4,0.1,0.6c0.1,0.5-0.4,1.3,0.3,1.5
		c0.6,0.1,1.1-0.6,1.4-1.1c1.3-2.2,2.7-4.3,4-6.5c0.8-1.5,0.1-3.1-0.2-4.6c-0.2-0.9-0.2-1.6,0.8-2.1c1.2-0.5,1.6-0.1,1.7,1
		c0,0.2,0,0.3,0,0.5c0.2,1.9,0.3,1.9,1.9,0.9c0.4-0.3,0.8-0.5,1.3-0.8c0,0,0,0,0,0l0,0c0.4-0.4,0.8-0.4,1.3-0.5
		c1.5-0.7,3.1-0.9,4.6-1.4c0.7-0.2,1.3-0.1,1.5,0.5c0.2,0.6-0.6,0.6-0.9,0.9c-0.6,0.5-1.4,0.8-2.2,0.8l0,0c0,0,0,0,0,0
		c-1,1.1-2.6,0.5-3.7,1.5v0c0,0,0,0,0,0c-0.6,0.4-1.2,0.8-1.8,1.2c0,0,0,0,0,0c-0.6,0.6-1.2,1.2-1.8,1.8c-1.5,2.4-3,4.8-4.5,7.1
		c-0.3,0.5-0.6,1.1-1.3,1.4c0,0,0,0,0,0c-0.1,0.2-0.2,0.5-0.4,0.6c-1.8,1.1-2.8,2.7-2.9,4.8c0.9,0.4,1.6-0.1,2.4-0.3
		c0.8-0.4,1.6-0.8,2.4-1.2c0.7-0.5,1.4-1,2.1-1.6l0,0c0,0,0,0,0,0c0.8-0.8,1.6-1.6,2.4-2.5c0.3-0.4,0.6-0.8,0.9-1.2c0,0,0,0,0,0
		c0.4-1,1.2-1.6,1.8-2.5l0,0c0.3-0.4,0.4-1,0.9-1.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.7,0.6-1,0.9-1.5c0.1-0.9,0.8-1.4,1.5-1.2
		c0.8,0.3,0.3,1.2,0.3,1.8c-0.5,1.2-1,2.3-1.8,3.4c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.2-0.2,0.4-0.4,0.5c-0.5,0.8-0.6,1.7-1.2,2.4
		c-0.1,0.2-0.2,0.4-0.3,0.6c0.5,0.7,1.3,0.1,1.9,0.3c2.6-1.2,4.4-3.1,5.5-5.8c0,0,0,0,0,0c0,0,0,0,0,0c1-1.4,2-2.7,3-4
		c0.3-0.3,0.9-0.8,1.3-0.3c0.5,0.5,0,1-0.5,1.3c-1,0.7-1.3,1.9-2,2.8c-0.2,0.9-0.5,1.7-0.8,2.7c2.6-0.8,4.9-2.1,6.5-4.3
		c1.9-2.5,0.6-5.1,0.2-7.9c1.9,1,2.3,2.6,2.6,4.3c0.7,3.6-1.2,7.1-4.5,8.5c-3.1,1.3-6.2,2.4-9.3,3.6c-0.8,0.3-1.9,0.8-1.9,1.5
		c0.1,0.7,1.3,0.2,1.9,0.3c0.3,0,0.6-0.1,0.9,0.2c-0.8,1.3-2.2,0.5-3.3,0.8h0c-1.3,0.6-2.7,0.5-4.1,0.5c-1,0-2,0-2.9,0.4
		c-0.7,0.3-1.4,0.6-2.1,0.9c-0.6,0.9-1.8,0.6-2.4,1.4c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-1.4,0.4-2.5,1.4-3.7,2.2v0
		c0,0,0,0,0,0c-2,2.6-1.2,5.6-1.2,8.5c0,0.2,0.1,0.4,0.2,0.5c0.3,0.2,0.4-0.1,0.6-0.3c0.9-1.3,1.8-2.7,2.8-3.9
		c0.3-0.4,0.6-1.2,1.3-0.8c0.5,0.3,0.4,0.9,0.2,1.6c-0.9,2.4-2,4.7-3.4,6.9c2.1,0.8,3.5,0.5,4.9-1.1c2.8-3.2,3.6-6.8,2.6-10.8
		c-0.1-0.4-0.3-0.9,0.2-1.1c0.5-0.2,0.8,0.2,1,0.6c1.3,2,0.9,4.2,0.7,6.3c0,0.4-0.4,0.8-0.1,1.4c1.4-1.2,1.8-2.9,2-4.5
		c0.4-2.7,2-4.5,4.6-5.3c1.9-0.5,4-0.9,4.6-3.3c0.1-0.4,0.5-0.6,1-0.6c0.2,1-0.7,2.2,0,2.8c0.6,0.5,1.8,0.3,2.8,0.5
		c0.3,0,0.6,0.1,0.8,0.5c-0.6,0.7-1.4,1-2.2,1.1c-2.1,0.1-4.2,0.5-6.3,0.9c-2.9,0.5-4.7,2.8-4.3,5.9c0.1,1,0.7,0.6,1,0.4
		c0.8-0.5,1.6-0.9,2.4-1.1c0.3-0.1,0.9-0.2,1.1,0.3c0.2,0.4-0.2,0.8-0.5,1c-1.3,0.7-2.6,1.4-3.9,2.2c-0.9,0.6-1.6,1.4-2.3,2.3
		c-1.8,2.3-4.4,3.1-7.2,3.2c-2.9,0-5.2,1.1-7.4,3c-4.2,3.7-9.4,5.7-14.9,6.7c-5.1,0.9-10.1,1.8-15.2,2.7
		C212.3,688.5,212,688.4,212,688.9z M60,425.4c-2.3,1.9-4.3,3.9-4.1,7.3c0.2,3.5,1.4,6.5,3.7,9.2c0,0.8-0.1,0.8-1.3,0.4
		c-0.8-0.1-1.4-0.6-1.8-1.3c-0.7-0.6-1.4-0.6-2.1,0c-0.5,0.8-0.3,1.3,0.5,1.9c0.9,0.7,2,1.1,3,1.6c4,1.9,6.7,1,9.1-2.7
		c1.1-1.7,2.2-3.5,3.5-5c3.3-3.6,8.7-5.5,13.3-2.2c2.3,1.6,3.5,4,4.9,6.3c1.2,2,2.3,3.9,3.5,5.9c0.2,0.4,0.5,0.7,0.9,0.5
		c0.3-0.2,0.2-0.7,0.1-1c-0.2-0.6-0.4-1.2-0.7-1.8c-1.7-4.3-1.3-6,2.1-9.2c1.8-1.7,3.6-3.4,5.5-5c0.6-0.5,0.6-1.1,0.3-1.6
		c-0.3-0.5-1-0.5-1.5-0.3c-0.4,0.1-0.7,0.3-1.1,0.6c-1.5,1.1-3,2.3-4.5,3.5c-0.3,0.2-0.5,0.6-0.9,0.4c-0.2-0.1-0.1-0.5-0.1-0.8
		c0-0.3,0-0.6,0.1-0.9c1-3.2,2.8-6,5.4-8.2c1.3-1.1,2.7-2.1,4.2-2.9c0.4-0.2,1.2-0.7,1-1.2c-0.2-0.6-1-0.6-1.6-0.6
		c-0.2,0-0.3,0-0.4,0.1c-1.4,0.7-2.9,1.2-4,2.4c-1.6,1.7-3.1,3.4-4.6,5.2c-2.9,3.5-4.3,7.3-2.9,11.9c0.1,0.4,0,0.9,0,1.6
		c-0.3-0.7-0.6-1.2-0.8-1.7c-2.2-6.4-6.6-7.2-12.5-6.8c-0.8,0-1.7,0.2-2.5,0.7c-1.2,0.9-2.6,1.9-3.4-0.4c-0.1-0.3-0.5-0.7-1-0.4
		c-0.4,0.3-0.4,0.7-0.2,1.1c0.1,0.3,0.3,0.6,0.5,0.9c1,1.6,1,2.2-0.5,3.2c-1.8,1.3-3.2,2.8-4.3,4.7c-1,1.7-1.8,1.8-3.1,0.3
		c-4.2-4.6-4.4-9.8,0.7-14c1.5-1.2,3.2-2.3,4.8-3.4c3.1-2.1,5.7-4.6,6.5-8.5c1.1-5.1-1.5-9.2-3.4-13.5c-0.3-0.7-1.2-0.8-1.7-0.3
		c-0.5,0.5,0.2,0.9,0.5,1.3c1.8,2.1,2.8,4.4,2.2,7.4c-0.9-0.9-1.6-1.7-2.4-2.5c-0.3-0.3-0.7-0.5-1-0.2c-0.3,0.3,0,0.6,0.2,0.9
		c0.9,1.1,1.7,2.3,2.7,3.3c0.8,0.8,0.7,1.7,0.5,2.7c-0.2,0.6-0.4,1.2-0.8,1.8c-0.9,1.6-1.9,3.2-3.5,4.3c0.9-3.5,0.1-6.8-1-10
		c-0.5-1.4-0.7-2.8-1.6-4c-0.4-0.5-0.9-0.7-1.4-0.5c-0.7,0.4-0.2,0.9,0,1.3c0.5,1.4,1.2,2.6,1.8,4c1.2,3.1,1.4,6.3-0.2,9.4
		C63.3,422.4,61.7,423.9,60,425.4z M154,683.2c-2.5-4-5.2-7.8-7.7-11.8c-3.1-4.9-5.9-10.1-9.2-14.9c-3.8-5.6-9.2-8.2-16-7.7
		c-0.3,0-0.7,0.1-1,0.2c-0.5,0.1-0.7,0.5-0.7,1c0,0.5,0.2,0.9,0.7,1c0.5,0.1,1.1,0.2,1.6,0.3c2.6,0.4,4.9,1.3,7.1,2.8
		c6,4.3,10.3,9.9,13.4,16.6c0.1,0.2,0.4,0.4,0.2,0.6c-0.2,0.2-0.5,0.2-0.8,0c-1.5-0.9-3.2-1.7-4.7-2.6c-4.5-2.7-8.4-6.2-12.4-9.3
		c-4.5-3.5-9.1-6.6-13.8-9.8c-0.5-0.3-1-0.7-1.6-0.2c-1.2,0.9-1,2.7,0.4,3.4c3,1.5,5.7,3.5,8,6c1.8,2,3.5,4.1,5.7,5.8
		c3.8,3,8.5,4.3,12.8,6.4c2.4,1.2,5,2,7.4,3.4c-1.7,1.1-3.5,1.2-5.2,1.1c-1.7,0-3.5-0.2-5.1,0.4c-0.6,0.2-1.3,0.4-1.2,1.1
		c0.1,0.6,0.9,0.7,1.5,0.6c1.6-0.2,3.2-0.5,4.8-0.9c3-0.6,5.9-0.6,8.5,1.4c2.6,1.9,3.9,4.5,5.1,7.2c1.3,2.8,2.5,5.6,3.8,8.4
		c0.3,0.5,0.7,1.3,1.5,0.9c0.7-0.3,1.3-0.8,1-1.8C157,689.5,155.9,686.2,154,683.2z M287.9,476c-1.1,0-1.5,0.7-1.5,1.7
		c0,1.1,0,2.1,0,3.2c-0.1,2.4,0.5,4.5,2.2,6.2c2,2.1,4,4.1,6.1,6.1c2.5,2.4,4.2,5.3,4.5,8.8c0.3,3.7,0.1,7.3-3,9.9
		c-0.8,0.7-1,1.4-0.8,2.4c2.2-0.6,3.4-2.3,4.5-4c1-1.6,1.5-3.4,1.9-5.2c0.7-3.5,3.2-5.6,5.9-7.4c3.6-2.4,5.6-5.5,5-10
		c-0.1-1,0.1-1.9,0.7-2.7c2.7-4,5.4-8.1,8-12.1c0.4-0.6,0.7-1.4-0.1-1.8c-0.9-0.6-1,0.4-1.3,0.9c-1.2,1.8-2.4,3.7-3.7,5.7
		c-2.2-3.6-4.5-6.8-7.3-9.7c-2.9-2.9-2.7-5.9,0.2-8.8c0.4-0.4,1-1,0.5-1.5c-0.5-0.6-1.3-0.4-1.9,0.2c-1.4,1.3-2.3,2.9-2.6,4.8
		c-0.4,1.2-0.4,2.5,0,3.7c0.2,1.2,0.7,2.3,1.5,3.2c1.8,2,4,3.7,5.4,6.1c1.5,2.5,1.4,5.3-0.6,7c-0.4,0.3-0.5,0.5-1-0.1
		c-0.8-1-1.4-2.3-2.6-3.1c-0.7-0.5-1.4-0.4-2.2,0c-1.2,0.5-0.8,1.2-0.2,1.9c0.6,0.7,1.3,1.3,1.9,1.9c2.8,2.5,3.2,6.1,1.3,8.5
		c-2.1,2.7-4.8,4.9-7.3,7.3c-0.5,0.4-0.8,0.2-0.9-0.4c-0.3-3.6-2.2-6.5-4.2-9.3c-1.5-2.1-2.2-4.4-1.3-7c0.4-1.3,1.1-2.6,1.3-4
		c0.1-0.9,0.4-1.9-0.3-2.7c-0.3-0.3-0.5-0.7-1-0.6c-0.5,0.1-0.3,0.6-0.4,0.9c-0.4,1.6-0.7,3.2-1.4,4.8c-0.9,1.8-0.9,3.7-0.1,5.6
		c0.2,0.6,0.4,1.2,0.6,1.9c0.2,0.7,0,0.8-0.7,0.6c-1.7-0.6-2.7-1.8-3.4-3.4c-1-2.4-0.6-4.9-0.6-7.4C289.1,476.8,289,476,287.9,476z
		 M315.8,588.5c0.9-0.4,1.7-1.1,2.3-2c1.3-1.7,1.7-3.7,1.9-5.8c0.3-2.9,0.5-5.8-0.1-8.8c-0.2-1.2-0.7-2.5,0.1-3.8
		c1.1-1.8,1.8-3.8,1.6-5.9c-0.1-1.7,0.7-2.2,2.2-2.4c3.3-0.4,5.1-2.6,6.1-5.6c0.7-1.9,0.6-4.2,2.6-5.6c0.4-0.3,1.1-0.7,0.6-1.3
		c-0.5-0.6-1.1-0.1-1.5,0.2c-0.9,0.8-1.1,0.2-1.4-0.6c-1-2.4-0.1-5.6,1.9-7.2c1.3-1,2.6-1.8,4-2.6c0.5-0.3,1-0.9,0.7-1.4
		c-0.4-0.6-1-0.2-1.4,0.1c-1.6,1.1-3.1,2.2-4.7,3.2c-0.6,0.4-1,1.3-1.9,0.2c-0.9-1.2-0.1-1.7,0.6-2.2c1-0.7,2.1-1.2,3.1-1.9
		c1.2-0.9,1.4-2.2,0.5-3.3c-0.4-0.6-0.8-0.6-1.3-0.1c-1.1,1.2-2.3,2.3-3.4,3.5c-1.8,2.2-2.5,4.7-1.5,7.5c0.5,1.5,1.1,2.9,1.5,4.4
		c1.3,4.2-1.6,9.7-5.6,10.8c-0.7,0.2-0.9,0.2-1.2-0.6c-0.8-2.3-1.8-4.6-2.6-6.9c-0.3-0.8-0.8-1.4-1.4-1.9c-0.4-0.3-0.9-0.4-1.3-0.2
		c-0.5,0.3-0.3,0.8-0.1,1.2c0.2,0.9,0.9,1.5,1.5,2.2c4.2,4.8,3.9,10.5,1.3,14.7c-0.6,0.9-0.9,0.9-1.4,0c-0.6-1.1-1.2-2.2-1.9-3.2
		c-0.3-0.5-0.6-1.2-1.5-0.5c-0.7,0.6-1,1.1-0.2,1.8c0.6,0.6,1.2,1.4,1.8,2c1.8,1.8,2.5,3.9,2.4,6.4c-0.1,1.7-0.1,3.5,0,5.2
		c0.1,2.5-0.8,4.5-2.6,6.1c-2,1.9-4.3,3.5-5.4,6.2c-0.7,1.7-0.8,3.6-1.4,5.3c-0.7-0.2-1.1-0.6-1.1-1.2c-0.2-2.6-0.3-5.2,1.1-7.7
		c1.1-1.8,2.9-3.1,4.1-4.8c0.3-0.4,0.8-0.9,0.4-1.3c-0.4-0.4-1.1-0.1-1.5,0.3c-5.6,4.4-7.4,9-6.1,16c0.6,2.9,1.6,5.6,1.5,8.6
		c-0.1,1.9-0.6,3.6-1,5.3c-0.9,0.9-0.8,2.3-1.5,3.3c-0.4,0.7-0.8,1.5-1.1,2.2h0c-0.1,0.2-0.2,0.4-0.3,0.6c-0.5,1.1-1.3,2.1-1.5,3.4
		c0,0,0,0,0,0c0,0.4-0.6,0.8-0.1,1.2c0.3,0.2,0.7,0.1,1.1-0.2c1.1-1,2.1-1.9,2.7-3.3c0.8-0.2,0.7-1,0.8-1.6l0,0
		c0.2-0.4,0.4-0.8,0.6-1.2l0,0c0.5-1.2,0.9-2.4,0.9-3.7l0,0c0,0,0,0,0,0c0.4-1.7,0.9-3.3,1.2-5c0.5-3.4,2-6.1,5.1-7.9
		c1.3-0.7,2.4-1.8,3-3.3c0.3-0.7,0.3-1.3-0.3-1.8c-0.7-0.6-1.1,0.1-1.5,0.5c-1.8,1.9-3.6,3.9-5.4,5.9c-0.2-0.1-0.3-0.3-0.5-0.4
		c0.1-1.7,0.9-3.2,1.4-4.7C311.8,592.4,312.7,589.6,315.8,588.5z M154,671.8c0.2,0.6,0.3,1.4-0.2,1.7c-0.6,0.4-1.1-0.3-1.5-0.7
		c-2.3-2.4-3.8-5.3-5.1-8.3c-2.4-5.4-4.7-10.7-9.2-14.8c-3.7-3.4-8.1-5.2-13-6.2c-4.5-0.9-9-0.3-13.4,0.4c-0.7,0.1-1.5,0.2-2.2-0.2
		c-0.7-0.4-1-1-1-1.7c0.1-0.7,0.8-0.8,1.4-0.8c2.8-0.2,5.7-0.5,8.5-0.4c0.5,0,1,0,1.5,0c0.3,0,0.5-0.1,0.6-0.4
		c0.1-0.4-0.2-0.4-0.4-0.5c-2.3-1.8-4.6-3.7-6.9-5.5c-3.1-2.5-5.3-5.6-7.1-9.2c-1.1-2.2-2.3-4.2-4-6c-1.3-1.3-2.8-2.2-4.8-1.8
		c-0.2,0.1-0.5,0-0.8,0c-0.6,0-1.3-0.1-1.4-0.8c-0.2-0.8,0.5-0.9,1-1.1c0.6-0.3,1.2-0.5,1.8-0.7h0c1-1.2-0.1-2.4,0-3.6
		c-1.4-2.9-3.4-5.2-6.4-6.3c-0.3-0.1-0.7-0.3-1-0.2c-3.5,0.6-7.2,0.3-10.7,1.2c-4,0.9-7.2,3.2-10.2,5.9c-1.5,1.3-2,3.1-2.7,4.8
		c-0.2,0.5-0.2,1-0.8,1.3c-1.2-3,0.1-5.4,1.3-7.9c1.3-2.5,3.5-4.1,6.3-5.5c-1.4-0.4-2.5-0.2-3.4-0.9c-0.7-0.6-0.5-0.8,0-1.1
		c0.5-0.4,1.2-0.3,1.8-0.2c4.7,0.3,9.3,0.1,14,0.6c0.2,0,0.4,0,0.6-0.3c-0.8-1.1-2.2-1.4-3.3-1.9c-7.1-2.9-14.5-4.5-22-5.6
		c-1-0.1-1.4-0.6-1.4-1.5c0-1,0.6-1.4,1.6-1.4c4,0,7.9,0.5,11.7,1.5c7,1.8,13.6,4.8,19.7,8.7c2.3,1.5,4.3,3.3,6,5.5c0,0,0,0,0,0v0
		c1.4,2,3.4,3.6,3.9,6.1c0,0,0,0,0,0c0,0,0,0,0,0c1.5,1.7,2.3,3.8,3.4,5.8c2.8,5.4,5.8,10.5,10.9,14.1c2.8,2,6,3.5,9,5.1
		c5.7,2.9,11.6,5.4,16.3,9.9c3.6,3.4,5.9,7.6,7.6,12.2c1.3,3.4,2.6,6.7,3.9,10.1C153.9,671.5,154,671.6,154,671.8z M247.3,642.7
		c0.8-0.5,1.8-1,2.2-2c0.2-0.4,0.8-0.7,0.4-1.2c-0.4-0.5-0.9-0.2-1.4-0.1c-3.5,1.2-6.6,3-9.1,5.8c-4.5,5.1-6.6,11.1-6.4,17.8
		c0,1.4-0.5,2.4-1.6,3.2c-1.6,1.1-3.1,2.3-4.6,3.5c-0.2,0.2-0.4,0.4-0.6,0.2c-0.2-0.3-0.1-0.5,0.1-0.9c1.7-2.6,3.6-5.1,4.6-8.1
		c0.5-1.6,0.7-3.1,0.4-4.8c-0.1-0.5-0.4-1-0.9-1.1c-0.4,0-0.8,0.3-1,0.8c-0.3,0.7-0.7,1.4-0.9,2.2c-1.4,4.7-4,8.7-7.8,12
		c-2.9,2.5-6.5,4-9.4,6.3c-3.1,2.5-6,5.2-8.3,8.5c2.3-0.2,4.6-0.5,6.1-2.2c3.3-3.6,7.6-5.1,12.1-6.3c3.3-0.9,6.5-2.1,9.1-4.4
		c5-4.7,9.7-9.7,13.4-15.5c0.8-1.3,0.9-2.9,0.5-4.4c-0.1-0.4-0.1-0.9-0.6-0.9c-0.5,0-0.4,0.5-0.5,0.8c-1.6,3.4-3.3,6.8-6.1,9.4
		c-0.3,0.3-0.6,0.9-1,0.7c-0.4-0.2-0.4-0.8-0.5-1.2c-0.2-3.7,0.7-7.1,2.7-10.2C240.4,647.4,243.7,644.9,247.3,642.7z M204,635.5
		c3.6-0.5,7.2-0.3,10.8-0.5c1.5-0.1,3-0.2,4.3-0.9c2.8-1.5,4.9-3.8,7-6.1c0.8-0.8,0.2-1.7-0.3-2.3c-0.4-0.6-1.1-0.2-1.6,0.2
		c-1,0.7-2,1.5-3.1,2.1c-1.7,1-3.1,2.3-5.3,3.2c1.8-3.5,3.4-6.7,5.1-9.9c3.3-6.2,5.2-12.7,4.9-19.8c0-0.7,0.2-1.8-0.8-1.8
		c-1-0.1-0.8,1.1-1,1.7c-0.7,2.3-1.4,4.7-2.1,7.1c-0.1,0.3-0.1,0.8-0.6,0.8c-0.4,0-0.6-0.3-0.8-0.6c-0.6-0.9-1.3-1.8-1.8-2.7
		c-0.3-0.6-0.9-1-1.4-0.8c-0.6,0.3-0.3,1,0,1.5c2,3.8,2.6,7.7,1.3,11.8c-1.5,4.6-3.9,8.4-8.4,10.7c-3.4,1.8-7.2,2.3-10.7,3.5
		c-3.6,1.2-4.9,2.9-4.7,6.6c0.2,3.3,1.1,6.6,1.7,9.8c0.3,1.5,0.9,1.1,1.7,0.5c1.4-1.1,1.7-3.3,0.8-5.1c-0.9-1.9-2.1-3.7-0.7-5.9
		C199.6,636.5,201.7,635.8,204,635.5z M214.3,444.7c-0.8,0.8-0.8,1.6-0.1,2.4c0.8,0.9,1.2,0.2,1.8-0.3c0.6-0.5,1.2-0.9,1.9-1.2
		c2.8-1.2,5.6-2.4,8.5-3.5c2.6-1,5.3-1.1,7.7,1.6c1.4,1.5,2.4,3.3,3.6,5c1.6,2.5,3.8,3.9,6.9,3.7c4.6-0.3,7.9-3.1,11.1-6
		c0.2-0.2,0.3-0.6,0.2-0.8c-0.2-0.4-0.5-0.1-0.8-0.1c-0.5,0-0.8,0.3-1.2,0.6c-1.2,0.9-2.4,2-3.8,2.6c-4.1,1.9-7.2,2.9-10-2.8
		c-1.1-2.3-2.6-4.3-4.8-5.6c-2.1-1.3-2.1-1.3,0.1-2.5c0.2-0.1,0.4-0.2,0.7-0.3c1.1-0.6,2.3-1.2,3.4-1.9c0.3-0.2,0.8-0.6,0.7-0.9
		c-0.2-0.4-0.6-0.8-1.2-0.8c-0.7,0-1.4,0.1-2.1,0.3c-2.1,0.7-3.9,2.1-5.9,2.9c-3.1,1.2-6.2,2.4-9.5,3.7c0.5-1.3,0.8-2.3,1.2-3.3
		c1.9-4.7,3-9.6,4-14.5c0.8-1,1-2.1,0-3.1c-0.3-0.4-0.7-0.7-1.1-0.5c-0.6,0.3-0.7,0.9-0.7,1.5c-0.4,3.4-0.7,6.7-2.6,9.7
		c-0.1,0.2-0.1,0.6-0.5,0.6c-0.4,0-0.4-0.3-0.5-0.6c-0.8-1.7-1.6-3.3-2.4-5c-1.4-3-2.4-6-1.3-9.3c0.2-0.5,0.2-1.2,0-1.8
		c-2,1-3.2,3.9-2.4,6.2c1.3,3.8,2.5,7.5,4,11.2c1.4,3.5,1,6.5-1.4,9.4C216.7,442.3,215.5,443.5,214.3,444.7z M170.4,409
		c-0.4-0.1-0.4,0.3-0.6,0.5c-0.8,1.1-1,2.5-1.6,3.7c-0.8,1.7-1.9,3.2-3.5,4.1c-1.2,0.7-2.1-0.4-3.1-0.6c-0.2-0.1-0.5-0.3-0.6,0.1
		c-0.1,0.3,0,0.6,0.3,0.8c1.4,1,0.9,2.1,0.1,3.2c-0.2,0.3-0.3,0.6-0.5,1c-0.8,1.7-0.8,3.5-0.6,5.4c0,0.3,0.3,1.1-0.6,0.8
		c-2.5-0.9-3.4-2.7-2.6-5.3c0.2-0.6,0.3-1.2-0.4-1.4c-0.6-0.2-1.1,0.2-1.3,0.8c-0.2,0.6-0.4,1.3-0.5,1.9c-0.3,2-0.5,2.2-2.6,2.2
		c-1.9,0-3.8,0.1-5.6,1c-0.5,0.2-1.1,0.5-0.9,1c0.2,0.6,0.9,0.3,1.3,0.3c0.2,0,0.4-0.1,0.6-0.1c1.9-0.3,3.9-0.7,5.8-0.9
		c4.4-0.4,7.5,2.6,8.3,7c0.8,4.6-2.1,7.8-3.4,11.8c-1-1.6-1.2-3.2-1.7-4.7c-0.2-0.5-0.5-1.1-1-0.9c-0.6,0.2-0.6,0.8-0.5,1.4
		c0.2,2.4,1,4.6,1.4,7c0.5,2.8,1.5,5.6,1.5,8.5c0,0.3,0.2,0.7-0.3,0.7c-0.1,0-0.4-0.1-0.5-0.3c-1.3-2.1-2.8-4.1-3.8-6.4
		c-1.8-4-1.8-8.3-2.7-12.5c-0.1-0.5,0.3-1.3-0.4-1.4c-0.6-0.1-1.1,0.7-1.3,1.2c-0.2,0.4-0.2,0.9-0.2,1.3c-0.3,6.9,1.7,13.1,6,18.5
		c0.5,0.7,1.3,1.2,1.3,2.3c-2.1-0.9-3.8-2.5-5.7-3.7c-0.7-0.4-1.5-0.8-2-0.1c-0.5,0.7-0.2,1.4,0.4,2c0.8,0.7,1.6,1.4,2.6,1.8
		c2.3,1.1,4.8,2.2,6.4,4.3c0.6,0.7,1.4,1,2.3,1.1c1.6,0.2,2.4-0.6,2-2.2c-0.6-2.5-1.1-5.1-1.9-7.6c-1.3-3.9-0.8-7.6,0.9-11.3
		c1.3-2.8,2.6-5.5,5.1-7.4c0.2-0.2,0.8-0.6,0.4-1.1c-0.4-0.4-0.8-0.3-1.2,0c-0.5,0.3-0.9,0.8-1.5,0.8c-0.5-1.5-0.8-3.1-1.5-4.6
		c-1.2-2.5-1.6-5.1-1.3-7.8c0.3-3,1.8-5.1,4.6-6.1c4-1.3,5.2-6.2,5-9.4C170.7,409.4,170.7,409.1,170.4,409z M154.5,402.7
		c-0.6,0-0.5,0.7-0.6,1.1c-0.5,3.8-2.6,6.6-5.4,9c-2,1.7-3.9,3.5-5.9,5.3c-3.9,3.6-4.1,8.3-3.6,13.1c0.1,0.8,0.7,1.8,1.6,1.8
		c0.9,0,1-1,1-1.8c0.1-3.8,2.4-6.2,5.5-7.7c4.5-2.2,8.7-4.8,12.2-8.4c2.5-2.5,5.6-4.6,6.5-8.4c0.2-0.8,0.5-1.7-0.3-2
		c-0.6-0.2-0.8,0.8-1.1,1.4c-0.9,1.9-1.7,3.9-3.8,5.3c0.1-2,0.2-3.7,0.2-5.4c0.1-2.9,1.4-5.5,3.8-7.1c3.1-2,3.9-5,4.3-8.3
		c0.1-0.5,0.1-1-0.6-1.2c-0.4,1.1-0.7,2.3-1.1,3.4c-0.9,2.2-2,4.3-4.2,5.5c-0.3,0.2-0.7,0.6-1,0.4c-0.4-0.3,0-0.7,0-1.1
		c0.5-2.8,1.1-5.5-0.2-8.3c-1.1-2.3-3.7-4.3-5.8-4.2c-0.3,0-0.7-0.2-0.7,0.3c0,0.3,0.2,0.5,0.5,0.6c1.1,0.4,2.2,0.7,3.1,1.5
		c2.4,2.1,1.9,4.8,1.8,7.9c-1.7-2.4-3-4.5-5.3-5.9c-2.9-1.9-5.3-2.2-7-0.7c1.4,0.1,2.6,0.6,3.9,0.9c2.8,0.7,4.2,2.5,4.9,5.1
		c0.7,2.6,1.2,5.2,1.6,7.9c0.4,2.7,0.7,5.5-0.3,8.3c-1.2,3.2-3.5,5.4-6.2,7.1c-3.7,2.3-7.7,4.3-10.7,7.6c-0.2,0.2-0.3,0.3-0.6,0.3
		c-0.3-0.1-0.3-0.4-0.3-0.6c0-2.1,0.7-3.9,2.2-5.4c1.8-1.8,4-3.1,6.1-4.6c3.9-2.9,6.2-6.5,5.7-11.5
		C154.8,403.4,155.1,402.7,154.5,402.7z M52,527.1c0.9-0.1,1.6,0.3,1.9,1c1.8,4,5.3,6.7,7.9,10.1c0.5,0.6,1.1,0.7,1.8,0.5
		c0.7-0.2,0.9-0.8,1-1.4c0.2-1.1-0.3-1.9-0.9-2.7c-0.8-0.9-1.7-1.8-2.3-3c-1.7-3.5-1.9-7.5-3.5-11c-0.8-1.8-1.3-3.7-2-5.5
		c-0.3-0.8-0.9-1.7-2.1-1.3c-1.2,0.4-1,1.3-0.6,2.2c1.1,2.7,2.2,5.5,3.3,8.2c0.4,0.9,1,1.8,0.8,3.3c-1.4-1.9-2.8-3.2-4.1-4.6
		c-1.5-1.5-3-3.1-4.5-4.6c-0.8-0.8-1.7-0.9-2.5,0c-0.9,1-0.1,1.4,0.6,1.9c1.5,1.2,2.8,2.5,4.2,3.7c0.7,0.6,0.7,0.8-0.2,1.2
		c-1.9,0.7-3.8,1-5.8,1.1c-3.3,0.2-6.3-0.7-9-2.6c-1.3-0.9-2.7-1.7-4.1-2.5c-0.5-0.3-1-0.4-1.4,0.1c-0.4,0.5-0.2,1.1,0.4,1.4
		c0.3,0.2,0.6,0.4,0.9,0.6c2.1,1.2,4.2,2.4,6.2,3.6c0.2,0.1,0.6,0,0.5,0.4c0,0.4-0.3,0.5-0.6,0.6c-4,1.3-8,1-11.9-0.5
		c-2.9-1.1-5.6-2.5-8.5-3.8c-0.7-0.3-1.7-0.9-2.2,0.3c-0.4,1.1,0.3,1.7,1.2,2.1c0.6,0.3,1.3,0.5,1.9,0.8c3.1,1.3,6.2,2.6,9.6,3.1
		c1.5,0.2,3,0,4.5,0.9c-1.1,1.3-2.6,1.8-4.1,2.4c-0.5,0.2-1.3,0.4-0.9,1.2c0.3,0.6,0.9,0.3,1.4,0.1c3-1.5,6.1-3,9.1-4.4
		c1.6-0.7,3.2-1.9,5-2.1C46,527.4,49,527.3,52,527.1z M15.7,598c0,0.9,0.6,1,1.2,0.8c2.1-0.7,4.1-0.3,5.9,0.7
		c3.8,2,7.7,2.4,11.5,0.3c1.1-0.6,1.7-0.3,2.5,0.5c1.8,2,4,3.7,6.6,4.6c2,0.7,4.2,0.7,6.2,1.2c2.1,0.5,3.6,1.5,4.2,3.6
		c0.2,0.5,0.3,1.5,1.3,0.9c0.8-0.5,1.9-0.8,1.3-2.1c-2.5-5-5.8-9.3-11.2-11.5c-2.1-0.9-3.9-2.2-5-4.4c-0.6-1.3-1.3-2.6-2.5-3.5
		c-0.3-0.2-0.5-0.4-0.8-0.3c-0.3,0.1-0.2,0.4-0.2,0.6c1,4,3,7.3,6.6,9.4c1.9,1.1,4,2.1,5.7,3.6c0.6,0.5,1.5,1,1.2,1.7
		c-0.2,0.5-1.2,0-1.9-0.2c-1.9-0.6-3.7-1.4-5.4-2.4c-4.6-2.7-7.8-6.3-8.8-11.7c-0.3-2,0.3-3.8,0.2-5.7c0-0.4,0.1-1.1-0.5-1.2
		c-0.5-0.1-0.8,0.3-1,0.8c-0.7,1.6-0.7,3.4-0.7,5.1c-0.1,1.3,0,2.7,0,4c-0.1,0-0.3,0.1-0.4,0.1c-0.9-0.8-1.5-2.1-3.2-1.8
		c1.7,2.2,4.1,3.7,5.3,6.2c-2.5,0.8-5,0.7-7.6,0.4c-0.6-0.3-1.2-0.6-1.8-0.8c-2.6-1-4.9-2.4-6.8-4.5c-1-1.1-1.8-2.4-2.9-3.4
		c-0.3-0.3-0.6-0.7-0.9-1c-0.7-0.7-1.5-1.2-2.3-0.4c-0.7,0.7-0.7,1.5,0.2,2.3c1.4,1.4,2.6,2.8,4,4.2c0.5,0.9,1.3,1.5,2.2,2.5
		C16.6,596.6,16.2,597.4,15.7,598z M209.6,452c-0.3-0.3-0.8-0.7-1.4-0.3c-0.5,0.3-0.2,0.7,0,1.1c0.5,0.9,1,1.9,1.5,2.8
		c1,1.8,2,3.6,3,5.4c0.1,0.3,0.3,0.6,0.1,0.8c-0.1,0.1-0.4,0.1-0.5,0.1c-2.4-1.4-5.1-2.3-6.7-5c-1.3-2.2-2.4-4.5-3.8-6.7
		c-0.9,2.1-0.2,3.9,0.7,5.5c1.8,3,4.5,5.3,7.3,7.2c4.8,3.2,7.2,7.6,7.9,13.3c0.4,3.3-0.4,2.5-2.6,2.1c-0.5-0.1-0.9-0.4-1.4-0.5
		c-0.9-0.3-1.8-0.4-2.7-0.3c-0.3,0-0.7-0.1-0.7,0.4c0,0.3,0.2,0.5,0.4,0.7c1.4,1.1,3.1,1.4,4.7,1.8c2.7,0.5,5.3,1.4,7.3,3.6
		c1,1.1,1.8,2.3,1.6,3.9c0,0.4-0.1,0.8-0.4,0.9c-0.4,0.2-0.6-0.3-0.8-0.5c-0.8-1.1-1.8-1.8-3-2.4c-2.9-1.7-6.6-1.1-9.4-3
		c-0.4-0.3-1,0.2-1.3,0.5c-0.2,0.3,0.2,0.7,0.5,1c0.8,0.7,1.9,0.9,3,1c2.5,0.4,4.7,1.3,6.5,3.1c1,1,2.1,1.9,3.4,2.6
		c3.5,1.9,6.2,4.4,7.2,8.4c0.3,1.4,1.4,1.3,2.4,1c1-0.3,1.4-1.1,1.1-2.2c-0.3-0.9-0.6-1.7-0.9-2.5c-1-3.1-2.9-5.9-2.1-9.4
		c0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.2,0-0.5-0.4-0.6c-0.4-0.1-0.5,0.2-0.7,0.4c-0.6,0.8-0.5,1.7-0.5,2.6c0,1.1,0.1,2.3-1,3.2
		c-0.4-1.4-0.8-2.6-1.1-3.8c-0.6-2.8-2-4.9-4.6-6.1c-1.7-0.8-2.7-1.8-2.4-3.8c0.1-0.4,0-0.9-0.2-1.3c-1-2.9-1.2-5.7-0.3-8.6
		c0.2-0.8,0.4-1.8-0.1-2.8c-0.8,1.4-0.7,2.9-1.5,4.2c-0.7-0.4-0.8-1.1-1.1-1.8c-1.6-3.6-2.7-7.4-4.6-10.9
		C211.4,454.6,210.6,453.2,209.6,452z M155.4,654.4c-0.2-2.2-0.8-4.3-2.3-6c-3.3-4-7.2-7.3-12-9.4c-1.3-0.6-2.5-1.3-2.5-2.9
		c-0.2-4.1-0.5-8.3,1.1-12.3c0.5-1.2,0.6-2.5,0.8-3.8c0.1-0.8-0.4-1.2-1.2-1.3c-0.6,0-1.1,0.3-1.2,0.9c-0.3,2-0.6,4.1-1,6.1
		c-0.4,2.1-0.6,4.3-1.6,6.5c-0.4-0.8-0.9-1.3-1.1-1.9c-1-2.8-3-4.7-5.7-5.9c-2.5-1.1-5.2-2.1-6.7-4.6c-1.5-2.6-2.1-5.6-3.2-8.4
		c-1.4-3.6-3.1-6.9-6.6-9c-0.6-0.4-1.3-1.3-1.9-0.6c-0.3,0.4,0.3,1.3,0.8,1.9c4.1,4.2,6.9,9.1,7.7,15.1c0.4,3.2,4.1,6.2,7.4,6.6
		c4,0.5,6.7,3.3,7.1,7.4c0.3,3.4,2.1,5.8,5.1,7.3c1.9,0.9,3.8,1.7,5.3,3.2c4.2,4.1,7,8.9,9,14.4
		C154.9,657.1,155.6,656.2,155.4,654.4z M120.2,429.7c1.6,3,4.4,4.5,7.6,5.4c2.3,0.6,2.3,0.7,1.1,2.6c-0.6,0.9-1.2,2-2.3,0.4
		c-0.4-0.7-1.2-0.9-1.9-0.4c-0.8,0.6-0.2,1.3,0.2,1.9c1.7,2.8,4.2,4.7,7.2,6c3.1,1.3,5.3,3.6,6.7,6.7c-3.1,0.5-5.9,0.5-8.5-1
		c-0.6-0.3-1.4-0.6-1.8-0.1c-0.3,0.5,0.3,1.1,0.8,1.5c2.7,2.1,5.8,3.1,9.2,2.2c1.2-0.3,1.5,0.2,1.9,1.1c0.5,1.2,0.4,2.5,0.6,3.7
		c0.2,1.4,1.3,1.3,2.3,1.3c1,0,1.1-0.6,1.1-1.4c0-2.1,1-3.6,2.7-4.7c1-0.6,1.4-1.7,0.7-2.4c-0.7-0.7-1.6,0.2-2.1,0.8
		c-0.8,0.9-1.5,1.9-2.5,2.6c-0.4-0.6-0.2-1.2-0.1-1.8c0.6-3.8,1.3-7.7,2-11.5c0.1-0.8,0.4-1.5-0.8-1.7c-1.1-0.2-1.6,0.4-1.6,1.4
		c-0.1,1.5-0.1,2.9-0.2,4.4c-0.9,1.3-0.7,2.9-0.8,4.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.7,0.1-0.7,0.1-0.9-0.5c-1-2.6-2.7-4.6-5.1-6.1
		c-1.6-1-3.4-1.6-5.1-2.2c-1.3-0.5-1.5-1.2-0.7-2.2c2.6-3.3,3.1-7.2,2.6-11.2c-0.4-2.8-1.8-5.2-4-7c-0.6-0.5-1.4-0.9-2-0.1
		c-0.6,0.7-0.4,1.4,0.2,2c0.7,0.8,1.3,1.7,2,2.5c1.5,1.9,2.4,3.9,2.1,6.4c-0.2,1.4-0.7,2-2.2,1.8c-2.1-0.3-3.9-1.3-5.1-3
		c-1.3-1.8-2.6-3.7-4.1-5.8C119.1,427.4,119.6,428.6,120.2,429.7z M169.3,652.8c0.1-0.9-0.7-1.1-1.2-1.3c-0.4-0.2-0.7,0.4-0.9,0.8
		c-0.3,0.6-0.5,1.3-1,1.8c-0.5,0.6-0.4,1.9-1.4,1.7c-0.6-0.1-0.7-1.3-0.8-2.1c-0.8-6.6-1.5-13.2-2.3-19.7c-0.1-0.6-0.1-1.2-0.5-1.7
		c-0.3-0.4-0.7-0.6-1.2-0.4c-0.5,0.2-0.7,0.6-0.6,1.2c0.7,4.2,0.6,8.4,1.1,12.6c0.1,0.5,0.3,1-0.3,1.4c-0.7-0.7-1.3-1.5-1.8-2.3
		c-2.9-4.9-7.6-7.4-12.4-9.6c-0.5-0.2-1.1-0.3-1.4,0.1c-0.3,0.5-0.3,1.1,0.1,1.5c0.5,0.5,1.1,1,1.8,1.4c1.6,1,3.3,1.8,5,2.8
		c2.3,1.4,4,3.2,5.1,5.7c1.6,3.5,3.3,7,4.3,10.7c0.4,0.8,0.5,1.7,0.9,2.5c0.9,3.4,2.2,6.7,3.6,9.9c0.4,0.9,0.6,1.1,1.2,0.1
		c0.9-1.5,1.3-3.1,1.3-4.9c-0.1-3.6-0.1-7.3,1.3-10.8C169.3,653.8,169.2,653.3,169.3,652.8z M95.4,675.9c0.4-0.2,0.7-0.5,1.1-0.1
		c0.3,0.3,0,0.7,0,1c-0.3,1.3-1.2,2.2-1.9,3.2c-0.3,0.4-1,0.9-0.3,1.5c0.6,0.5,1.2,0.3,1.8-0.1c0.4-0.3,0.8-0.8,1-1.3
		c2.1-4.6,6.4-7,11.4-6.7c0.9,0.1,1.9,0.2,2.3-0.9c0.4-1.1-0.5-1.8-1.3-2.2c-3.8-2.2-8-3.1-12.4-2.5c-2.2,0.3-4.3-0.1-6.4-0.9
		c-2.9-1-5.8-2.2-8.9-2.3c-1,0.1-1.9,0.1-2.9,0.3c-0.6,0.1-1.3,0.6-1.1,1.4c0.1,0.6,0.8,0.7,1.3,0.7c1.1,0,2.1-0.1,3.2-0.2
		c2.7-0.2,5.4-0.1,7.7,1.5c0.3,0.2,0.7,0.2,0.7,0.7c0,0.5-0.5,0.4-0.8,0.5c-1.9,0.6-3.9,1.2-5.8,1.7c-0.8,0.2-2,0.5-1.9,1.3
		c0.2,1.1,1.3,1.4,2.5,1.1c0.2,0,0.4-0.1,0.6-0.1c4.2-1,8.4-2,12.6-3c1.5-0.4,2.9-0.2,4.1,0.9c0.5,0.5,0.6,0.7-0.2,1
		c-1.3,0.4-2.6,0.7-4,0.8c-3.7,0.3-7,1.4-9.4,4.3c-1.4,1.7-3.1,2.9-5.2,3.3c-1.6,0.3-3.3,0.4-5,0.6c-0.4,0-0.8-0.2-1,0.4
		c-0.2,0.6,0.4,0.6,0.7,0.9c0.2,0.3,0.7,0.5,1.1,0.5c3.5,0.3,7-0.1,9.9-2.3C91,679,92.9,677,95.4,675.9z M144.4,687.9
		c-1.9,0.4-3.8,0.8-5.8,1.3c-0.4,0.1-1.1-0.1-1.1,0.6c0,0.6,0.3,1.1,0.8,1.5c1.2,0.8,2.4,0.6,3.6,0.2c3.1-1.1,5.5-0.2,6.9,2.7
		c1.1,2.2,2,4.4,2.8,6.8c1,3.1,3.1,4.7,5.8,4.5c0.7,0,1.6-0.2,1.7-0.7c0.2-0.6-0.6-1.1-1.2-1.4c-1.3-0.5-2.1-1.5-2.7-2.8
		c-2.5-5.5-4.5-11.1-7.2-16.5c-1.4-2.8-2.5-3.6-5.6-3.5c-3.3,0.2-6.7,0.4-10,0.5c-1.2,0.1-2.3-0.3-3.1-1.4c-1.5-1.9-3.2-3.2-5.7-3.2
		c0,0,0,0.1,0,0.1c-0.7-0.8-1.6-1-2.6-0.6c-0.4,0.1-0.7,0.5-0.2,0.7c0.9,0.4,1.4,1.4,2.4,1.7c0,0,0,0.1,0,0.1c0.8,0.9,2,1.4,2.8,2.3
		c0.4,1.1,0.1,1.9-0.8,2.7c-0.7,0.6-0.5,1,0.3,1.2c1.4,0.3,2.8,0.5,4.2,0.2c0.2,0,0.3-0.1,0.4-0.2c0.3,0,0.6-0.1,0.9-0.1
		c0.4,0,0.8,0,1.3,0c0.6-0.1,1.4,0,1.9-0.6h0c0.8-0.1,1.6-0.2,2.4-0.3c3.2-0.4,5.9,0.4,8,3C145.5,687.5,145.3,687.7,144.4,687.9z
		 M178.7,514c0.1,0.5,0,1.3,0.6,1.4c0.6,0.1,1.2-0.3,1.7-0.8c0.2-0.3,0.4-0.6,0.4-0.9c0-1.7,1.1-2.5,2.5-3c1-0.4,2.1-0.8,3.1-1.2
		c2.5-1,5.1-1.9,7.6-3c0.6-0.2,1.6-0.6,0.8-1.6c-1.1-0.8-2.2-0.3-3.4,0c-3,0.3-5.3,2.1-8,3.3c-0.2,0.1-0.4,0.6-0.7,0.2
		c-0.3-0.3,0-0.5,0.3-0.8c3-3,6.7-4.9,10.7-6.2c4.2-1.4,8.5-2.8,12.6-4.6c0.7-0.3,1.4-0.7,1.2-1.2c-0.3-0.7-1.1-0.3-1.8-0.1
		c0,0-0.1,0-0.1,0.1c-2,0.7-4,1.8-6.1,2c-3.6,0.4-7.1,1.5-10.2,3.4c-1.4,0.9-2.7,1.8-4.1,2.7c-0.3,0.2-0.6,0.3-0.8,0.1
		c-0.3-0.2-0.1-0.5,0.1-0.8c1.5-2.3,3.4-4.1,5.6-5.5c3.1-1.9,5.6-4.4,7.4-7.7c0.2-0.5,0.6-1,0.1-1.4c-0.5-0.4-0.7,0.3-1.1,0.5
		c-2.3,2-4.5,4.3-7.3,5.8c-2.7,1.5-2.7,1.4-3.4-1.6c-0.2-1.1-0.6-2.2-2.1-2.5c0.2,1.3,0.5,2.4,0.6,3.5c0.3,2.3-0.2,4.3-1.8,6.1
		C180,504.4,178.1,508.8,178.7,514z M71,398.8c1.4,1.1,2.3,2.5,3,4c1.1,2.3,2.2,4.6,3.8,6.6c1.8,2.3,3.3,4.7,3.7,7.6
		c0.1,0.8,0.7,1.5,1.4,2c2.4,1.8,4.3,4.1,5,7.1c0.1,0.4,0.1,1.2,0.7,1.2c0.6,0,0.9-0.8,1-1.4c0.1-0.6,0.1-1.3-0.1-1.8
		c-0.4-1-0.7-2.1-1.5-2.9c-1-1.1-1.4-2.5-1-3.9c1.6-5.5,2-11.2,1.7-16.8c-0.2-3.8-1.1-7.4-4.1-10.1c-0.3-0.3-0.7-1-1.2-0.4
		c-0.4,0.4-0.1,0.9,0.3,1.3c3.2,2.8,3.5,6.4,3,10.3c-0.5,4.4-1.1,8.7-1.7,13.1c-0.1,0.6-0.1,1.2-0.8,1.6c-0.1-1.1-0.6-2.1-0.6-3.2
		c0.1-5.9-0.1-11.7-1.3-17.5c-0.1-0.5-0.1-1.2-0.7-1.1c-0.6,0-0.5,0.7-0.6,1.2c-0.2,1,0.4,2.1-0.4,3.3c-0.4-0.6-0.7-1-1-1.5
		c-0.7-1.2-1.3-2.3-2-3.5c-0.3-0.5-0.9-1.1-1.4-0.8c-0.5,0.4-0.3,1.2,0,1.8c0,0,0.1,0.1,0.1,0.1c2.4,3.4,4.1,7.1,4.9,11.2
		c0.2,1.1,0.2,2.1,0.2,3.2c0,0.5-0.2,1-0.6,1.1c-0.4,0.1-0.5-0.4-0.7-0.7c-0.5-0.7-1-1.4-1.3-2.2c-1.4-3.9-4-6.9-6.4-10.1
		c-0.5-0.7-1.2-0.6-1.6-0.3C70.3,397.7,70.5,398.4,71,398.8z M163.2,622.2c0.1,1,0.2,2.3,1.5,2.2c1.1,0,2.2-0.9,2.2-2.1
		c0.2-4.4,2.3-8.1,4.7-11.5c2.3-3.2,3.4-6.8,4-10.6c0.1-0.9,0-1.7-0.8-2.3c-0.5-0.4-0.8-0.4-1,0.2c-0.6,1.5-1.2,3.1-1.8,4.6
		c-1.1,2.9-2.3,5.7-4.9,8c-0.4-3.1-0.2-5.8,1.7-8.3c0.8-1,1.5-2.2,1.5-3.6c0-0.8-0.5-1.1-1.1-1.3c-0.7-0.3-1,0.3-1.2,0.8
		c-0.6,1.2-1.2,2.5-1.9,3.7c-0.2,0.4-0.3,1-0.9,0.9c-0.5-0.1-0.4-0.7-0.5-1.1c-1.1-3.9-2.5-7.7-6.4-9.8c-1.1-0.6-1.9-1.5-2.7-2.4
		c-1.4-1.8-2.4-3.9-2.8-6.2c-0.1-0.8-0.3-2.2-1.4-1.9c-0.7,0.2-0.2,1.4,0,2.1c1,3.7,3.2,6.8,5.7,9.6c3.4,3.8,6.4,7.8,7,13.1
		c0.4,3,0.9,6,0.3,9.1C163.8,617.7,162.9,619.9,163.2,622.2z M193.1,665.3c3.4-4,6.2-8.5,8.7-13c0.4-0.8,1-1.5,1.2-2.4
		c0.2-1.1-0.3-1.8-1.1-2.3c-0.7-0.4-1.1,0.5-1.5,0.9c-0.8,1-1.4,2.1-2.1,3.1c-1.3,1.8-2.3,3.7-4,5.6c-0.6-5.3,0.8-10.7-2.5-15.3
		c-1.4,1.3-1.9,2.7-1.7,4.4c0.3,3.1,0.4,6.3,0.5,9.4c0.2,2.9-0.2,5.6-1.4,8.3c-0.8,1.9-1.5,3.8-2,5.8c-0.8,3.3-0.9,6.6-1,9.9
		c0,0.8-0.3,1.6-0.1,2.4c0.2,0.9,0.9,0.8,1.5,0.8c0.8,0,0.5-0.6,0.5-1c0.2-1.7,0.2-3.4,0.4-5.1C189.2,672.6,190.3,668.6,193.1,665.3
		z M205.2,665.2c-0.4,0.4-0.8,0.8-0.3,1.3c0.4,0.4,0.6,1,1.3,1c0.3,0,0.3-0.4,0.5-0.6c3.3-3.9,6.6-7.7,9.9-11.6
		c2.6-3.2,4.7-6.7,6-10.7c1.5-4.9,4.4-8.9,8.8-11.5c2.4-1.4,4.7-2.8,6.7-4.7c0.3-0.3,0.8-0.7,0.6-1.1c-0.2-0.5-0.8-0.2-1.2-0.1
		c-2.1,0.6-4,1.7-5.8,2.7c-5.5,3.1-9.6,7.3-11.6,13.3c-0.5,1.5-0.9,3.1-1.9,4.4c-0.2,0.2-0.2,0.6-0.6,0.6c-0.4,0-0.4-0.4-0.5-0.7
		c-0.8-2.3-1.6-4.7-2.3-7c-0.2-0.7-0.5-1.2-1.1-1.3c-0.6-0.1-1.2,0.3-1.5,0.8c-0.4,0.6-0.2,1.3,0.1,2c0.6,1.5,1.2,3.1,1.2,4.8
		c0.1,4.8-1.2,9.2-3.9,13.1C208.4,661.9,206.8,663.6,205.2,665.2z M108.3,662.4c-1.5,0-2.9-0.5-4.3-0.9c-0.9-0.3-1.5-0.1-1.8,1
		c-0.3,1.1,0.2,1.3,1.1,1.5c4.2,0.6,7.6,2.6,11,5c2.2,1.5,4.4,3.1,6.9,4.1c2.5,1.8,5.2,1.8,7.9,0.9c0.5-0.1,1.1-0.1,1.6-0.3
		c0.9-0.4,1.1-1,0.8-1.8c-0.3-1-1-0.4-1.5-0.2c-2.2,0.7-4.5,1-6.4-0.6c-2.3-1.9-4.7-3.7-6.7-5.9c-2-2.2-3.4-4.8-5.7-6.7
		c-3-2.6-6.6-4.3-9.9-6.4c-2.2-1.4-4.1-2.9-4.6-5.7c-0.2-1.1-1.3-1.2-2.2-1c-0.9,0.2-0.7,1-0.5,1.7c0.1,0.4,0.3,0.7,0.5,1.1
		c1.9,4,5,6.6,8.9,8.5c2.2,1.1,4.3,2.3,5.5,4.6C109.6,662.1,109.4,662.4,108.3,662.4z M324.3,528.3c0.3-0.7,1-1.9-0.1-2.4
		c-0.8-0.3-1.3,0.8-1.7,1.5c-1,1.9-2.2,3.7-4.1,4.8c-5.4,3-10.6,0.7-16-0.5c0.6-0.7,1.2-1,1.8-1.2c2.3-1,4.7-1.8,6.9-2.9
		c1.2-0.6,2.6-1.3,2.4-3.1c0-0.4-0.1-0.7-0.4-0.9c-0.5-0.4-0.4,0.4-0.8,0.4c-0.5,0.1-0.7,0.6-1.2,0.9c-2.7,1.8-5.8,2.4-8.7,3.6
		c-2.9,1.2-5.7,2.5-7.2,5.4c-0.2,0.3-0.4,0.4-0.9,0.5c0.3-1.3,0.5-2.5,0.8-3.7c0.5-2.4,2-4.1,4.2-5.3c1.1-0.6,2.4-0.8,3.5-1.4
		c0.8-0.4,0.9-1.1,0.7-1.6c-0.2-0.5-0.8-0.5-1.4-0.4c-1.2,0.2-2.2,0.7-3.2,1.2c-3,1.2-5.3,3.1-6.3,6.3c-1.4,4.5-2,9.1-1.8,13.8
		c0,0.9-0.4,2.5,0.4,2.5c0.8,0,1.3-1.6,1.4-2.5c0.5-3.7,2.5-6.5,5.1-8.9c1.2-1.1,2.5-1.4,4.2-0.8c4.3,1.5,8.7,2.3,13.4,1.6
		C319.4,534.5,322.5,532.4,324.3,528.3z M222.1,701.6c-0.7-0.1-1.4,0-2.1-0.2c-2.3-0.5-3.7-2.1-5.2-3.7c-4.4-4.4-11.5-4.5-15.8,0.3
		c-2.3,2.6-4.1,5.5-5.8,8.5c-0.8,1.4-0.7,1.7,0.9,1.9c3.1,0.4,6.1,0.6,9.2,0.6c1,0,1.9-0.2,2-1.1c0.1-0.9-1-1.1-1.7-1.3
		c-1.3-0.2-2.5-0.6-3.9-1.2c1.5-0.9,2.8-1.4,4.4-0.9c2.3,0.7,4.6,1.4,7,1.9c1.7,0.3,3.5,1.1,5.4,0.3c0.4-0.2,1-0.2,1-0.7
		c0.1-0.6-0.6-0.6-1-0.8c-2-0.8-3.9-1.5-5.9-2.2c-2.4-0.8-4.8-1.6-7.4-1.4c-1.1,0.1-2,0.7-3.4,0.4c0.7-2,2-3.5,3.8-4.4
		c3.1-1.6,5.8-0.4,8.4,1.3c2.1,1.4,4,3.2,6.5,3.9c1.2,0.3,2.4,0.3,3.6,0.2c0.4-0.1,0.8-0.3,0.8-0.8
		C223,701.5,222.5,701.7,222.1,701.6z M281,539.9c-1.3,3.6-1.6,7.4-0.7,11.1c0.2,1.1,1,1.4,2.1,1.1c0.7-0.8,0.9-1.6,0.9-2.6
		c-0.1-1.9,0-3.8-0.1-5.6c0-2.2,1-4,2.8-5.1c2.4-1.5,3.4-3.5,3.1-6.3c-0.4-5.5,2.6-10.1,7.8-12.1c1.1-0.4,1.8-1.4,1.9-2.7
		c0-0.7-0.3-0.9-0.9-0.6c-1.8,1-3.6,2-5.4,3c-1.9,1.1-2.9,3-4,4.9c-1.2-1.5-2.4-2.7-3.3-4.1c-1.2-1.8-2.1-3.7-1.9-6
		c0.1-1.6,0.8-2.9,1.5-4.2c0.2-0.4,0.5-0.7,0.2-1.1c-0.3-0.4-0.9-0.3-1.3-0.1c-0.9,0.4-1.5,1.1-1.8,2.1c-0.9,2.5-0.2,4.9,0.9,7.1
		c1.4,3,3,5.9,4,9c0.9,2.8-0.4,7.7-2.6,9.4c-0.1-3.2,0.7-6.4,0.1-9.6c-0.4-2.2-1.4-3.9-3.4-5.1c-0.4-0.2-0.7-0.6-1.1-0.2
		c-0.3,0.4,0.1,0.7,0.3,1c1.2,1.6,2.1,3.4,2.3,5.5c0.4,3.5-0.1,7-1,10.4C281.3,539.5,281.1,539.7,281,539.9z M197,605.8
		c2.5-4.3,5.2-8.4,7.8-12.6c0.2-0.6,0.5-1.2,0.1-1.7c-0.4-0.5-0.9,0-1.3,0.1c-4.6,3.8-6.8,8.9-8.8,14.3c-0.4,1.2-0.8,2.5-1.8,3.9
		c0-4.2,0-7.9,0-11.6c0-1.5,0.6-2.8,1.4-4.1c1.2-2.2,3.1-3.8,4.5-5.8c0.2-0.3,0.7-0.6,0.3-1c-0.3-0.4-0.7-0.1-1.1,0
		c-2.1,0.8-3.8,2.2-5.2,4c-4.1,5.2-4.4,11.2-3.4,17.4c0.7,4.2,2.1,8.3,3.4,12.4c0.3,0.8,0.9,1.4,1.6,1.3c0.8-0.2,0.7-1,0.4-1.8
		c-0.5-1.4-1.3-2.7-1.1-4.3C194.1,612.5,195.1,609,197,605.8z M192.9,685.5c1,0.2,1.2-1,1.5-1.8c0.4-1.5,1-3,1.8-4.3
		c1.7-3,4.1-5.4,7-7.2c1.2-0.8,0.8-1.4,0.2-2.2c-0.7-1-1.1-0.5-1.8,0c-1.7,1.4-3.3,3.1-5.5,4.6c0.5-2.6,1.5-4.5,2.7-6.3
		c2.5-3.8,5.8-6.9,8.3-10.7c2.5-3.9,4.2-8,2.4-12.8c-1-2.7-3.6-5.2-5.8-5.3c-0.6,0-1.3,0-1.6,0.6c-0.3,0.6,0.4,0.9,0.9,1.2
		c1.4,1.1,2.9,2.1,3.8,3.8c1.8,3.4,0.9,6.7-0.4,9.9c-1.4,3.4-4,6.1-6.4,8.8c-3.5,4-7,8-8.1,13.4c-0.4,2-0.7,4-0.6,6
		C191.2,684.3,191.8,685.3,192.9,685.5z M181.7,633.2c1.2,0.6,2.4,1.3,3.9,0.6c2.6-1.2,5.2-2,8-2.5c5.6-1,10.9-2.9,15.3-6.8
		c3.3-3,5.2-6.6,6-10.9c0.1-0.8,0.1-1.5-0.9-1.6c-0.9-0.1-1.2,0.5-1.4,1.3c0,0.2-0.1,0.4-0.1,0.6c-0.5,3-1.9,5.6-4.4,7.4
		c-1.8,1.4-3.7,2.5-5.7,3.5c-0.8,0.4-1.7,1.3-2.6,0.7c-1-0.7-0.3-1.9-0.4-2.8c0-0.1,0-0.2,0.1-0.3c0.8-2.5,1.5-4.9,1.9-7.5
		c0.1-0.4,0.4-1,0-1.3c-0.4-0.3-1,0.1-1.4,0.4c-0.3,0.2-0.6,0.5-0.8,0.9c-1,1.9-1.3,3.9-1.7,5.9c-0.5,2.8-2,4.8-4.4,6.3
		c-2.2,1.3-4.7,1.7-7,2.7c-1.4,0.6-2.9,1.1-4.1,2C181.7,632.2,180.7,632.7,181.7,633.2z M99.2,462.9c-1,1.8-2.4,3.4-2.3,5.6
		c0,0.6,0.3,1.2,0.7,1.4c0.6,0.3,0.9-0.4,1.1-0.7c1-1.2,1.7-2.7,3-3.5c4.5-2.9,9.1-5.7,13.7-8.3c3.9-2.2,6.9-4.9,6.2-9.9
		c-0.3-1.9-0.7-3.8-1.4-5.6c-0.1-0.3-0.3-0.8-0.9-0.6c-0.5,0.2-0.5,0.6-0.5,1c0,0.3,0.1,0.6,0.2,0.9c0.5,2.2,0.8,4.5,0.8,6.8
		c0,2.2-1.5,4.8-4.2,5.4c-3.4,0.8-6.2,2.6-9,4.6c-0.7,0.5-1.1,1.6-2.4,1.3c2-2.2,3.9-4.5,6-6.5c4.1-4,4.6-9.9,2.3-14.1
		c-0.3-0.6-0.6-1.3-1.3-1.1c-0.7,0.3-0.6,1-0.3,1.7c0.8,1.8,0.9,3.8,0.9,5.8c0,2.7-1.2,4.9-3.4,6.6c-1.3,1-2.6,1.8-4,2.7
		c-0.4-1-0.8-1.8-1-2.7c-0.2-0.7-0.4-1.3-1.1-1.2c-0.7,0.1-1.1,0.8-1,1.5c0.1,0.9,0.1,1.9,0.6,2.8c0.5,0.9,0.4,1.6-0.2,2.5
		C100.8,460.4,100,461.6,99.2,462.9z M127.4,613.8c-0.4-0.5-0.8-0.9-1.2-1.4c-1.9-2.5-2.5-5.4-3.2-8.4c-0.9-4.2-2.3-8.2-5-11.6
		c-1-1.3-2.2-2.1-3.8-2.2c-0.4,0-0.9,0-1,0.5c-0.2,0.4,0.1,0.8,0.4,1c0.8,0.6,1.6,1.2,2.4,1.8c2.5,1.6,4.2,3.7,4.8,6.8
		c0.5,2.5,0.7,5,0.8,7.5c0.1,1.8,0.7,3.3,1.5,4.8c1.2,2,3.1,3.3,4.8,4.8c2.1,1.8,4.1,3.7,5.5,6.3c0-1.6,0.1-3,0-4.4
		c-0.7-5.9-1.5-11.7-2.2-17.6c-0.4-3.4-1.2-6.6-3.2-9.4l0,0l0,0c-0.4-1-1.3-1.7-2.1-2.4v0c0,0,0,0,0,0c-0.7-0.7-1.4-1.5-2.4-1.9
		c-0.4-0.7-1.2-0.8-1.9-1.2l0,0c0,0,0,0,0,0c-0.4-0.4-0.8-0.5-1.3-0.7c-1.4-0.5-2.6-1.3-3.2-2.7c-1-2.5-2.4-4.9-3.6-7.3
		c-0.4-0.7-1-1.1-1.5-0.8c-0.7,0.4-0.4,1.1-0.1,1.7c1.2,2,1.9,4.2,2.7,6.4c0.3,0.8,0.3,1.1-0.6,1c-2-0.3-3.9,0.6-5.8,1
		c-0.5,0.1-1.1,0.4-1,1c0.1,0.6,0.7,0.4,1.1,0.4c0.4,0,0.7,0,1.1,0c4-0.3,7.8,0.5,11.3,2.5c0,0,0,0,0,0c0,0,0,0,0,0
		c0.6,0.6,1.2,1.1,1.9,1.4l0,0c0.6,0.5,1.2,1.1,1.8,1.6c0.7,0.6,0.9,1.7,1.8,2.2c0.6,1.3,1.4,2.6,1.8,4c1.4,4.7,0.9,9.6,0.9,14.5
		c0,0.2,0,0.4-0.1,0.6C128.5,614.3,128.1,614.8,127.4,613.8z M36.4,631.8c0.4-1.2-0.7-1.3-1.3-1.6c-2.4-1-3.1-2-3-4.6
		c0.1-1.7,0.3-3.4,0.4-5.1c0.1-0.9-0.3-1.7-1.1-1.7c-0.8,0-1.2,0.9-1.1,1.7c0.1,1.3,0.2,2.6-0.1,4.1c-1-1-1.7-2-1.8-3.4
		c-0.1-2.9-0.6-5.7-1.6-8.4c-1-2.7-1.9-5.5-3-8.2c-0.4-1-1-2.2-2.6-1.9c0.6,1.6,1.2,3,1.6,4.5c0.9,3,2.1,5.9,2.1,9.1
		c-0.1,0.3-0.1,0.6-0.2,1c-0.3-0.2-0.5-0.4-0.7-0.6c0,0,0,0,0,0c-0.6-1.1-1.3-2.2-1.9-3.4c-1.5-3.2-3-6.3-4.5-9.5
		c-0.2-0.5-0.7-1-1.1-0.9c-0.5,0.1-0.8,0.7-0.8,1.3c0,0.4,0,0.8,0.2,1.2c1.3,2.9,2.6,5.9,3.9,8.8c2.1,4.6,5.2,8.7,8,13
		c1.6,2.4,3.4,4.6,6.3,5.5C35.1,632.8,36,632.9,36.4,631.8z M227.1,388.1c2.1,1.6,3.6,3.6,4,6.3c0.2,1.4,0.5,2.8,0.6,4.2
		c0.1,0.6,0.7,1.4,0.1,1.6c-1,0.3-0.9-0.9-1.4-1.5c-0.1-0.1-0.1-0.4-0.1-0.6c-0.9-1.8-0.6-4.1-1.8-5.8c-2.1-3.2-6.8-3-8.1-0.5
		c0.9,0,1.8,0,2.6-0.1c3.5-0.4,5.1,1.9,5.4,4.7c0.3,2.1,0.6,4.1,2.1,5.7c0.6,0.6,1,1.4,1.5,2.1c1.5,2.1,3.1,4.2,4.6,6.3
		c0.5,0.8,1.3,0.7,1.7,0.3c0.6-0.5-0.3-0.8-0.6-1.1c-0.5-0.6-1.1-1.2-1.6-1.8c-0.3-0.4-0.5-0.8-0.1-1.2c1.9-2.2,4.1-3.9,7.2-3.6
		c0.8,0.1,1.5,0,2.3-0.6c-1.4-0.6-2.8-0.1-4.2-0.4c0.9-1.5,2.5-1.6,3.8-2c2.1-0.7,4.2-1.3,6.3-2c0.5-0.1,0.9-0.5,0.7-1
		c-0.2-0.6-0.7-0.3-1.2-0.2c-0.5,0.1-1,0.5-1.6,0.4c-0.5,0.2-1,0.5-1.5,0.5c-0.3,0-0.5-0.1-0.3-0.5c0.5-0.6,0.9-1.2,1.4-1.9
		c1-1.5,1.2-3,0.5-4.3c-0.1,2.3-1.2,4-3,5.2c-2.1,1.5-4.2,2.9-6.3,4.3c-0.9,0.6-1.7,0.5-2.3-0.4c-0.6-1-0.6-1.9,0.1-2.8
		c0.5-0.7,1-1.3,1.6-1.9c0.3-0.3,0.2-0.6,0-0.8c-0.2-0.2-0.4,0.1-0.6,0.2c-0.6,0.5-1.2,1.1-1.6,1.8c-0.1,0.2-0.3,0.5-0.6,0.4
		c-0.4-0.1-0.3-0.5-0.3-0.7c0-1.4,0-2.7,0.3-4.1c0.1-0.3,0.2-0.8-0.3-0.9c-0.5-0.1-0.4,0.4-0.5,0.7c-0.1,0.8-0.3,1.6-0.3,2.4
		c0,2.1-0.5,4.2,0.5,6.3c1,1.8,0.9,1.8-0.8,3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.8,0.8-1,0.3-1.1-0.5c-0.2-1-0.3-2.1-0.6-3.1
		c-0.5-2.2-0.5-4.6-1.1-6.7c-0.7-2.7-1-5.3-0.4-8.1c0.4-2.1,0.4-4.2-1.3-5.9c-0.3-0.3-0.8-0.8-1.2-0.4c-0.3,0.3-0.3,0.9,0.1,1.4
		c0.1,0.1,0.2,0.2,0.3,0.3c1.5,2.3,1.6,5.8,0.2,7.8c-0.9-0.5-1.4-1.8-2.7-1.8c-0.3,0-0.7-0.1-0.8,0.2
		C226.6,387.6,226.8,387.8,227.1,388.1z M292.8,665.9c-1.1,0.6-2.2,1.2-3.2,1.9c-4,2.9-8.3,4.4-13.3,3.6c-2.4-0.4-4.7-0.3-7.1,0.1
		c-1.6,0.3-3.1,0.9-4.7,1.1c0.6-0.5,1.1-1.2,1.8-1.6c2.2-1.2,4.6-1.8,7.2-1.9c2.7,0,5.5-0.1,8.2-0.2c0.9,0,1.8-0.1,2.6-0.6
		c0.4-0.3,0.9-0.7,0.7-1.2c-0.2-0.5-0.8-0.2-1.2-0.1c-2.9,0.4-5.8,0.6-8.8,0.8c-5.5,0.3-10.1,2.5-13.9,6.6c-1.5,1.6-3.1,3-5.1,4.1
		c-2.3,1.2-4.7,0.7-7.1,0.8c-0.7,0-1.3,0-1.9,0.4c-0.3,0.2-0.5,0.5-0.4,0.9c0,0.3,0.1,0.6,0.5,0.6c3.1,0.3,6.2,0.8,9.3-0.6
		c1.8-0.8,3.3-2.1,4.7-3.5c1.8-1.7,3.9-2.3,6.4-1.7c3.5,0.7,7,1.2,10.5,1.7c0.6,0.1,1.2,0.1,1.7-0.4c0.2-0.2,0.5-0.4,0.4-0.7
		c-0.1-0.4-0.5-0.3-0.7-0.3c-2,0.1-3.8-0.4-5.7-0.9c-0.6-0.2-1.6-0.3-1.5-1c0.1-0.7,1-0.8,1.8-0.8c0.7,0,1.3,0,2,0
		c2.9-0.1,5.9,0,8.7-0.9c3.3-1.1,6.7-2.1,8.8-5.3c0.2-0.3,0.6-0.5,0.3-0.9C293.5,665.3,293.1,665.7,292.8,665.9z M87.7,388.1
		c3.6,2.9,5.1,6.6,4.7,11.2c-0.2,2.3-0.5,4.6-0.4,7c0.1,2.5,1.1,4.3,3.4,5.4c1,0.5,2.1,1.1,3,1.7c0.6,0.4,0.8,0.8-0.1,1.1
		c-0.7,0.2-1.4,0.5-2,1c-0.3,0.3-1,0.7-0.6,1.3c0.4,0.6,0.8-0.1,1.2-0.2c3.6-1.1,7.1-1.3,10.6,0.5c1.6,0.8,2.8,2.3,4.6,2.9
		c0.3-1.3-0.9-2-1.3-3.1c-2.2-2-4.9-2.6-7.8-2.7c-0.7,0-1.3-0.2-1.6-0.8c-0.7-1.5-2-2.4-3.3-3.2c-1.5-1-1.7-1.9-1.2-3.6
		c1-3.2,2.4-6.1,4.3-8.9c1.5-2.1,2.1-4.5,2.4-7.1c0.1-0.6-0.2-1.3-0.7-1.4c-0.4-0.1-0.9,0.5-1.1,1c-0.1,0.2-0.1,0.4-0.1,0.6
		c-0.5,1.7-1.1,3.3-1.7,5c-0.1,0.3-0.1,0.6-0.5,0.6c-0.3,0-0.5-0.2-0.5-0.4c0-1.2-0.6-2.2-1-3.3c-1-2.9-2.7-5.3-5.5-6.8
		c1.6,3.3,5,5.7,4.8,10c-0.2,3.9-0.7,7.6-2.8,11c-0.9-1-1.1-2-0.8-3c1-3.9,0.6-7.6-1.1-11.2c-1-2-2.2-3.9-4.2-5.2
		c-0.3-0.2-0.8-0.7-1.2-0.2C86.8,387.7,87.4,387.9,87.7,388.1z M288.8,424.6c-1.1,0.6-2,1.4-2.8,2.4c-2.7,3.5-3.1,7.6-2.9,11.8
		c0.1,1.6,0,3.4,1.4,4.7c0.6,0.6,1.3,1.4,2.2,0.8c1-0.6,0.5-1.6,0.2-2.1c-2.3-3.8,1.2-9.1,4.9-10.4c2.3-0.9,4.6-1.9,6.9-2.9
		c1.1-0.5,2.1-1.1,3.1-1.8c0.7-0.5,0.8-1.2,0.2-2c-0.6-0.8-1-0.3-1.6,0.1c-0.9,0.6-1.7,1.6-3,1.8c0.1-1,0.3-1.9,0.4-2.8
		c0.4-2.9,0-5.5-1.7-7.9c-0.5-0.7-1.5-1.4-2-1c-0.7,0.6,0.3,1.3,0.7,1.8c3.2,4,1.9,9.8-2.7,12.1c-1.7,0.8-3.2,1.8-4.4,3.3
		c-0.9,1-1.5,2.3-2.9,3c-0.3-1.4,0.3-2.7,0.4-4c0.2-2.4,1.6-3.9,3.9-4.6c0.8-0.2,1.6-0.4,2.3-0.8c1.9-1,2.5-3.5,1.5-6.3
		c-1-2.9-2.9-5.3-4.8-7.7c-0.7-0.9-1.8-1.4-2.6-0.6c-0.8,0.8,0.2,1.5,0.8,2.1c1.4,1.5,3.1,2.8,4.2,4.5
		C291.9,420.4,292.1,422.7,288.8,424.6z M148,375.6c-0.5,0.9-1.1,1.6-1.7,2.5c-1.4,2-2.3,4.4-4,6.3c-1.9,2.1-4.5,3.1-6.8,4.5
		c-3.7,2.2-6.6,5-7.5,9.4c-0.5,1.2-0.4,2.5-0.4,3.7c0,0.6-0.5,1.7,0.8,1.7c1.1,0,0.7-1.1,0.9-1.8c0.1-0.2,0-0.4,0-0.6
		c-0.1-1.4,0.1-2.7,0.6-4c0-0.5,0.2-0.8,0.4-1.3c2.1-4.2,6-5.8,10-7.2c2.7-0.9,5.4-1.7,8-3.1c4.4-2.3,7.7-5.7,9.6-10.4
		c0.6-0.1,0.7-0.6,0.7-1.1c0-0.6,0-1.3,0.3-1.9c0.4-1.7,0.3-3.5,0.3-5.3c0-0.8-0.4-1.4-0.8-2.1c-0.2-0.3-0.3-0.5-0.7-0.3
		c-0.3,0.2-0.2,0.4-0.1,0.6c1.2,2.6,0.6,5.1-0.2,7.7c-0.9,3-2.1,5.9-4.5,8.1c-1.7,1.5-3.1,2.2-4.4,2c7.3-9.1,7.7-11.1,6.8-15.4
		c-0.8,0.9-0.7,2.1-1.1,3c-0.5,1-0.4,2.2-1.1,3.1c-0.7-0.5-0.9-1.3-1.1-2.1c-0.4-1.3-0.8-2.6-1.3-3.9c-0.2-0.4-0.4-1.1-1-0.9
		c-0.6,0.2-0.3,0.9-0.1,1.3c0.3,0.8,0.7,1.7,1.1,2.5c1.7,3.5,1.3,6.2-1.5,8.8c-1.2,1.1-2.3,2.4-3.4,3.7c0.4-1.1,1.1-1.9,1.6-2.8
		c0.9-1.4,2.1-2.7,1.9-4.6c-0.1-0.3,0.2-0.8-0.3-0.9C148.4,375,148.2,375.3,148,375.6z M75.6,458.6c-0.1,1.1-0.2,2.1,0.4,3.1
		c0.3,2.1,1.3,3.9,2.2,5.7c1.8,3.3,3.9,6.5,5.4,10c0.7,1.6,1.2,3.2,2.2,4.7c2.2-3.1,1.7-3.9,0.7-6.9c-0.1-0.3-0.3-0.6-0.4-1
		c-0.3-4.1-0.3-8.1,1.3-11.9c1.3-3,3.1-5.7,4.7-8.5c0.5-0.8,0.7-2,0.1-2.3c-0.7-0.4-1.3,0.5-1.7,1.3c-0.8,1.5-1.6,3.1-2.6,4.9
		c-0.2-1.1-0.3-1.8-0.4-2.6c-0.1-0.5,0.1-1.2-0.5-1.2c-0.5,0-0.5,0.7-0.6,1.1c-0.4,3.3-0.9,6.6-1.2,9.9c-0.2,1.8-0.3,3.6-1,5.3
		c-1.2-3.6-2.2-7.1-2.6-10.8c-0.3-2.7-0.5-5.3-0.4-8c0-0.5,0-1.3-0.7-1.3c-0.5,0-0.8,0.6-0.8,1.2c0.1,3-0.2,6,0.2,9
		c0.5,3.5,1.4,6.9,2.3,10.3c-2.5-4-5.1-8-5.6-12.9C75.7,457.4,75.6,458.1,75.6,458.6z M338,493.5c-0.3-1.5-0.5-3-1.3-4.3
		c-0.2-0.4-0.4-1.1-1.1-0.8c-0.6,0.2-0.4,0.9-0.4,1.3c0.4,2,0.8,3.9,1.2,5.9c0.5,2.7,0.9,5.3,0.6,8.2c-0.7-0.6-0.7-1.3-0.9-1.9
		c-0.2-0.7-0.1-1.9-0.9-1.8c-0.7,0.1-0.9,1.2-0.8,2c0,0.4,0.2,0.8,0.2,1.2c0.5,3.2,2,6.2,2.7,9.4c0.9,3.9,1.3,7.8-0.2,11.6
		c-0.5,1.1-1.3,2-2.2,2.9c-0.5,0.5-1.1,1.2-0.5,1.7c0.6,0.5,1.2-0.3,1.5-0.8c1-1.4,2.2-2.8,2.8-4.4c2.7-6.6,4.9-13.2,4.2-20.5
		c-0.1-0.9,0-1.7-0.1-2.6c-0.1-0.3-0.1-1-0.7-1c-0.5,0-0.6,0.5-0.7,0.9c-0.4,1.9-0.7,3.8-0.8,5.7c-0.1,0.9,0,1.9-1,2.6
		C340,503.6,339.2,498.5,338,493.5z M271.6,452.7c-1.3-1.8-1.1-3.7,0.7-5c2.2-1.7,4.6-3.3,6.9-5c0.3-0.3,1.1-0.3,0.8-1
		c-0.2-0.5-0.7-0.8-1.3-0.8c-0.7,0-1.3,0.1-2,0.6c-1.8,1.4-3.7,2.7-5.5,4.1c-3.2,2.3-4.1,5-2.5,7.7c0.7,1.2,1.8,2.1,3,2.7
		c3.1,1.6,6,3.4,9,5.2c3.9,2.3,6.1,5.8,6.2,10.5c0,0.4,0,0.8,0,1.2c0,0.6,0.2,1,0.9,1c0.6,0,1.1-0.3,1.1-1c0.2-4-0.6-7.7-3.3-10.8
		c-1.9-2.2-4.6-3.4-7-4.9c-1.9-1.2-1.9-1.6,0-2.9c0.3-0.2,0.7-0.4,1-0.6c2-1.1,3.7-2.7,5.3-4.3c0.5-0.5,1-1.3,0.5-1.8
		c-0.4-0.5-1.2-0.1-1.8,0.2c-0.3,0.2-0.6,0.4-0.8,0.6c-1.9,1.8-4,3.4-6.2,4.8C274.8,454.2,272.7,454.1,271.6,452.7z M128.5,485.8
		c1.3-1.6,1.5-2.5,0.6-3.7c-1.6,1.5-2.4,3.5-3.6,5.2c-0.7,1-1.6,1.3-2.7,0.8c-0.3-0.1-0.6-0.3-0.8-0.3c-0.4-0.1-0.9-0.3-1.1,0.1
		c-0.2,0.5,0.3,0.7,0.5,0.9c1.3,1.1,2.6,2.2,2.6,4.2c0,0.7,0.1,1.4,0.2,2.1c0.2,1.1-0.3,1.3-1.2,1.1c-1-0.2-1.9-0.8-2.9-0.6
		c-0.6,0.1-1.1,0.2-1.1,0.8c-0.1,0.6,0.3,0.9,1,1.1c3.4,0.9,6.9,1.4,10.4,1.7c1.5,0.2,2.5,0.8,3.3,2.2c-1.9,0.2-3.6,0.4-5.4,0.5
		c-1.1,0-2.1,0.5-3.1,0.6c-0.5,0.1-0.9,0.5-0.9,0.9c0,0.5,0.5,0.7,0.9,0.8c1,0.2,2,0,3-0.3c3-0.6,6-1,8.8,0.7c1.6,0.9,2.8,2.2,4,3.6
		c0.7,0.9,1.2,0.5,1.7,0c0.8-0.9,0.8-2.5-0.1-3.3c-2.7-2.4-5.2-5-8.5-6.7c-1.7-0.9-3.1-2.1-3.1-4.3c0-0.6-0.3-1-0.8-1.5
		c-1.1,0.9-0.9,2.2-1,3.4c0,0.3,0.2,1.1-0.3,1c-0.7-0.2-1.5,0-2.1-0.7c-1.7-2-2.7-4.9-0.7-7.4C126.7,487.7,127.7,486.8,128.5,485.8z
		 M144.2,627.2c0.8,1.9,2.5,3,4,4.1c3,2.2,6,4.3,9.3,6.6c-0.2-2-0.8-3.3-1.8-4.5c-1.6-2.1-2.7-4.5-3.1-7.2c-0.3-2.2-0.7-4.4-1.2-6.5
		c-0.1-0.6-0.1-1.9-1.1-1.6c-0.9,0.2-0.4,1.3-0.3,2c0.3,2.8,0.6,5.5,0.9,8.3c-2.7-0.5-4.6-2.8-4.6-5.5c-0.1-2.7,0-5.5-0.1-8.2
		c-0.2-4.7-2.4-8.7-4.8-12.6c-0.2-0.4-0.7-0.5-1-0.3c-0.4,0.2-0.3,0.6-0.1,0.9c0.3,0.7,0.7,1.3,1.1,2c1.3,2,2.3,4.1,2.3,6.6
		c0,4.3,0,8.6-0.1,12.9C143.5,625.3,143.8,626.2,144.2,627.2z M343.4,515.7c-1.2,5.1-2.4,10.1-2,15.4c0,0.5,0.2,1.1-0.4,1.6
		c-1-0.9-1.3-2.2-1.9-3.2c-0.4-0.5-0.6-1.3-1.4-0.9c-0.8,0.5-0.2,1.2,0,1.7c0.2,0.5,0.5,0.9,0.8,1.3c1.9,3.1,1.5,5.7-1.4,7.9
		c-1.5,1.2-3.2,2.2-4.6,3.5c-0.5,0.5-0.6,0.9-0.2,1.4c0.4,0.5,0.8,0.6,1.4,0.2c5.3-2.8,8.9-6.7,9.6-12.9c0.2-1.8,1-3.4,2.6-4.4
		c2-1.2,3-3.1,3.7-5.3c0.9-2.8,0.2-5.5-0.6-8.2c-0.2-0.5-0.6-1.4-1.2-1.2c-0.7,0.2-0.4,1-0.5,1.6c0,1.9,0.1,3.8-0.1,5.6
		c-0.3,2.6-0.8,5.2-4,6.3c0.2-3.5,1-6.7,1.7-9.9c0.8-4.1,0.8-8.6-0.4-10C344.6,509.8,344.1,512.8,343.4,515.7z M285.1,566.1
		c0.8,3.1,1.7,6.1,2.5,9.2c0.2,0.9,0.7,1,1.4,0.8c0.8-0.2,0.8-0.8,0.7-1.4c-0.1-0.6-0.2-1.3-0.4-1.9c-1.2-4.2-0.8-7.8,3-10.7
		c1.7-1.3,3.2-2.9,4.9-4.2c0.8-0.6,0.8-1.4,0.6-2.2c-0.3-1.4-1.3-1.7-2.4-0.8c-1.7,1.4-2.8,3.3-4,5c-0.7,1-1.4,2.1-2.6,3
		c-0.2-1.9,0.1-3.5,0.3-5.1c0.3-4.7-0.4-9.4-1.3-14c-0.1-0.7-0.8-1.1-1.1-1c-0.7,0.2-0.9,0.9-0.6,1.6c0.7,1.9,0.8,3.9,0.8,6
		c-0.1,2.6-0.1,5.3-0.1,7.9c0,1.2-0.3,2.3-1.2,3.4c-0.5-1.7-0.6-3.1-0.6-4.6c0-0.9-0.5-1.8-1.1-1.8c-0.8,0-0.8,1-0.8,1.7
		c-0.1,1.6,0.2,3.1,0.7,4.6C284.5,563.1,284.7,564.6,285.1,566.1z M152.5,708.6c0.4-0.6,0.2-1.4-0.5-1.8c-0.5-0.3-1-0.7-1.5-1
		c-0.6-0.3-1.1-0.7-1.5-1.2c-1.1-1.6-2-3.2-2.8-5c-1.1-2.4-2.8-4.2-5.5-4.7c-2.8-0.6-5.5-1.3-8.1-1c-1.5,0-2.7,0-4,0
		c-0.5,0-0.9,0.3-0.9,0.6c-0.1,0.4,0.3,0.7,0.7,0.8c0.3,0.1,0.7,0.3,1,0.3c2.9,0,5.6,0.8,8.4,1.1c1.7,0.2,3.6,0.2,4.8,1.9
		c0.8,1.1,0.6,1.5-0.8,1.6c-2.3,0.2-4.6-0.4-6.6-1.4c-4.8-2.3-9.3-1.1-13.9,0.4c-0.8,0.3-0.9,0.9-0.8,1.5c0.1,0.7,0.8,0.5,1.2,0.5
		c1.6,0.1,2.8-1,4.3-1.2c3.2-0.5,6.4-0.7,9.5,0.9c0.3,0.2,0.7,0.3,0.5,0.8c-0.1,0.4-0.5,0.3-0.7,0.4c-1.4,0.3-2.8,0.6-4.3,1
		c-0.4,0.1-1,0.4-1,0.8c0.1,0.5,0.7,0.7,1.2,0.7c0.3,0,0.6-0.1,0.9-0.2c1.7-0.5,3.3-1.2,5-1.6c4.3-1.1,7.8-1.1,11,3.5
		c0.6,0.9,1.4,1.8,2.4,2.4C151.4,709,152.1,709.2,152.5,708.6z M132.9,689.7c0.3,0,0.8-0.2,0.7-0.6c-0.1-0.4-0.4-0.5-0.8-0.6
		c-1.8-0.3-3.6-0.6-5.4-1c-3.2-1.3-6.4-2-9.8-1.3c-0.6,0.1-1.2,0.2-1.8-0.1c-0.9-0.2-1.7-0.5-2.5-0.9c-0.9-0.6-1.9-0.9-2.9-1.1
		c-1.1-0.2-1.9,0.6-2,1.4c-0.1,0.6,0.7,1.3,1.6,1.3c1,0,1.9,0.2,2.8,0.4c3.1,0.6,6.4,0.4,9.4,1.4c0.3,0.1,0.7-0.1,0.7,0.3
		c0.1,0.5-0.4,0.5-0.7,0.6c-3,1.5-6.1,1.7-9.3,1c-1.2-0.3-2.2-1.2-3.6-1.2c-0.5,0-1.1-0.2-1.3,0.4c-0.2,0.7,0.4,0.9,0.8,1.1
		c1.8,0.8,3.7,1.6,5.8,1.5c2.4-0.1,4.8-0.2,7.1-0.5c0.5-0.1,1.1-0.3,1.5,0.1c-2.7,3.2-6.3,4.1-10.2,3.9c-1.8-0.1-3.5-0.7-5.3-1.2
		c-0.4-0.1-1-0.3-1.2,0.2c-0.2,0.4,0.3,0.8,0.6,1c1,0.7,2.1,0.9,3.2,1.3c4.7,1.5,9.2,1.2,12.9-2.1
		C126.3,692.4,128.9,689.8,132.9,689.7z M75.1,567.9c0.7,0.3,1.1,1,1.6,1.5c0.5,0.5,1.1,1,1.7,1.2c0.5,0.2,1.2,0.4,1.5-0.2
		c0.3-0.6-0.3-1-0.6-1.4c-0.5-0.5-1.1-0.8-1.7-1.2c-2.8-2-3.6-4.4-2.5-7.6c0.4-1.1,1-2.1,1.5-3.1c0.4-0.7,0.5-1.6-0.2-1.8
		c-0.7-0.3-1.1,0.5-1.4,1.2c-0.4,1.1-0.6,2.3-1.3,3.2c-0.4,0.4-0.7,1.1-1.5,0.8c-0.6-0.2-0.2-0.9-0.3-1.4c-0.1-0.5-0.1-1-0.1-1.5
		c0-3.7,1.4-6.6,4.8-8.2c2.5-1.2,4.9-2.7,7.1-4.4c1.4-1,2.2-2.3,2.2-4c0-0.9,0-1.7,0-2.6l0,0h0c0-0.2,0-0.5,0-0.7c0,0,0,0,0,0
		c0,0,0,0,0,0c0-0.7,0-1.4,0-2c0.2-0.8-0.5-1.4-0.6-2.1c-1.2,0.5-1.5,1.3-1.1,2.3c0,0.1,0,0.3,0.2,0.4l0,0c-0.2,0.6-0.5,1.1-0.4,1.7
		c0,0-0.1,0.1-0.1,0.1c-0.2,0.5-0.2,1-0.3,1.5c-0.3,2.2-2.3,4.2-4.5,4.6c-0.1,0-0.2,0-0.3,0c-0.4-0.5-0.5-1.2-1.2-1.5
		c-0.4-0.5-0.8-1-1.3-1.5c-0.2-0.2-0.3-0.7-0.7-0.3c-0.3,0.3,0,0.6,0.1,0.8l0,0c0,0.7,0,1.3,0.5,1.8c0.5,0.6,0.7,1.2,0.7,1.9
		c0,0.1-0.1,0.1-0.1,0.2c-1.3,1.3-2.6,2.5-4.1,3.5c0-1.5,0.4-3-1.2-4.1c-0.1,0-0.2,0-0.2,0c-0.5,1.3-0.2,2.4,0.3,3.6l0,0h0
		c-0.1,0.5-0.4,1-0.4,1.5c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.6-0.2,1.2-0.4,1.8c-1,2.5-1.5,5.1-0.8,7.9c0.1,0.3,0.2,0.6,0,1
		c-1.1-1.2-1.5-2.6-1.7-4.1c-0.1-0.7-0.1-1.3-0.5-1.9c-0.3-0.6-0.6-1.3-1.5-0.9c-0.8,0.4-1.4,0.9-1,1.9c0.3,0.7,0.5,1.5,0.7,2.1
		c1.2,2.8,2.4,5.6,5.3,7.2C72.5,566.4,73.8,567.1,75.1,567.9z M313.9,497c-1.4,2.2-2.1,4.6-1.7,7.2c0.1,0.6,0.1,1.4,0.8,1.5
		c0.7,0.1,1-0.6,1.2-1.2c0.4-1.2,1.2-2.1,2.2-2.8c1.2-0.9,2.6-1.5,3.8-2.4c1.6-1.2,3.5-2,4.3-4.1c1.9-5.3,0.9-10.1-2.9-14.2
		c-0.7-0.7-1.4-1.5-2.3-0.8c-0.9,0.7-0.3,1.6,0.2,2.4c0.8,1.3,1.8,2.5,2.3,4c1.7,5.4-2.7,10.7-7.4,13.1c0.7-1.7,1.4-3,2.4-4.1
		c2.3-2.8,2.5-6.1,2.2-9.4c-0.1-0.9-0.5-1.5-1.6-1.4c-1,0.1-1.2,0.7-1.2,1.6c0,0.6,0.2,1.1,0.4,1.6c0.6,1.2,0.6,2.4,0.2,3.7
		C316.1,493.6,315,495.3,313.9,497z M145.4,514.1c-1.5-0.2-2.7-0.8-3.9-1.7c-1.4-1-2.2-2.4-3.2-3.7c-0.2-0.3-0.5-0.8-0.9-0.6
		c-0.4,0.2-0.2,0.7-0.1,1.1c0,0.1,0.2,0.3,0.2,0.4c1.5,3.7,4.3,5.6,8.2,5.8c2.6,0.2,5.3,0,7.8,1.3c2.6,1.3,5.4,2.2,6.7,5.2
		c0.1,0.2,0.2,0.5,0.6,0.5c1.6-0.2,2.5-1.9,1.6-3.3c-1.1-1.7-1.9-3.6-2.5-5.6c-0.6-1.9-1.5-3.8-2.3-5.7c-0.2-0.5-0.8-1.2-1.4-0.8
		c-0.6,0.3-0.9,1-0.5,1.8c1.1,2.3,2.1,4.7,3.3,7.3c-2.6-0.5-5-1-7.4-1.5c-0.7-0.1-1.5-0.3-1.6-1.1c-0.2-1.4-0.7-2.6-0.9-4
		c-0.3-1.8-0.5-3.6,0.7-5.2c0.7-1,1.2-2.1,1.7-3.2c0.1-0.3,0.3-0.6,0-0.8c-0.3-0.2-0.7-0.1-1,0.2c-0.3,0.3-0.5,0.6-0.7,1
		c-0.6,1.2-1.2,2.4-2,3.6c-0.9-1.2-1.7-2.2-2.6-3.3c-0.8-1-1.6-1.9-2.5-2.8c-0.4-0.4-0.9-0.3-1.3,0.1c-0.6,0.5-0.1,0.8,0.2,1.1
		c0.2,0.3,0.5,0.6,0.8,0.9c2.6,3.4,4.3,7.1,4.7,11.4C147.3,513.9,146.7,514.3,145.4,514.1z M96.8,629c-2.4,0.1-4.9,0.4-7.3,0.6
		c-1.6,0.1-3.2,0.4-4.8,0.4c-0.9,0-1.5,0.7-1.3,1.6c0.1,1,0.9,0.7,1.6,0.6c1.3-0.2,2.6-0.5,3.9-0.7c4.2-0.6,8,0,11.1,3.3
		c0.6,0.7,1.4,1.1,2.1,1.6c3.3,2.1,7.1,2.1,10.8,2.5c0.6,0.1,1-0.2,1.2-0.7c0.2-0.5-0.2-0.8-0.6-1.1c-0.5-0.4-1-0.7-1.6-0.8
		c-1.1-0.2-2.3-0.4-3.4-0.6c-2.5-0.4-4.1-1.9-5-4.2c-1.1-2.6-2.4-5-4-7.4c-1.3-1.9-3.3-2.5-5.4-2.7c-1.2-0.9-2.2-0.2-3,0.6
		c-0.6,0.5-0.3,1.3,0.5,1.5c1.2,0.2,2.4,0.8,3.7,0.7c0,0,0,0,0,0c1.3,0.6,2.3,1.6,2.8,2.9C98.6,628.7,98.5,628.9,96.8,629z
		 M57.7,505.3c-1.7-2.7-4-5-4.7-8.2c-0.1-0.5-0.6-0.8-1-0.7c-0.5,0.1-0.8,0.6-0.7,1.2c0.1,0.3,0.2,0.6,0.3,0.8
		c0.9,1.9,1.9,3.8,3.4,5.3c1.8,1.9,3.3,4.1,6.1,4.6c1.3,0.3,1.7,1.5,2.1,2.7c1.3,3.5,1.1,7.1,0.9,10.7c-0.2,4.2,0.9,7.9,3.5,11.2
		c0.6,0.8,1.1,1.6,2.3,1c0.9-0.5,1-1.7,0.2-2.7c-1.4-1.9-1.7-4-1.7-6.3c0.1-2.2,0.7-4.2,1.1-6.2c0,0,0,0,0,0v0
		c0.1-0.1,0.2-0.3,0.3-0.4c0-0.1,0.1-0.1,0.1-0.2c0.7-1.2,1.2-2.5,1.7-3.8l0,0h0c1.3-2.3,2.6-4.6,3.8-6.9c0.4-0.8,1.1-1.6,0.9-2.6
		c-0.1-0.3,0-0.8-0.5-0.8c-0.4,0-0.6,0.3-0.7,0.5c-1.3,3.1-2.6,6.3-3.9,9.4c-1,1-1.3,2.2-1.7,3.4c0,0,0,0,0,0c0,0,0,0,0,0
		c-0.6,1.2-1.1,2.4-1.7,3.5c-0.2,0.4,0,1.3-0.7,1.1c-0.6-0.2-0.2-1-0.1-1.5c0.3-2.3,0-4.6-0.5-6.8c-1-3.9-2.9-7.5-5.1-10.9
		c-0.4-0.6-0.8-1.3-1.5-0.9c-0.8,0.4-0.6,1.3-0.3,1.9c0.4,0.9,0.9,1.8,1.3,2.7C59.4,507.1,58.5,506.6,57.7,505.3z M105.6,469.1
		c-0.8,0-1.7-0.1-2.1,1c0.3,0.1,0.5,0.3,0.7,0.4c0.5,0.2,0.9,0.4,1.4,0.5c5.2,1.3,9.1,4.4,12.3,8.6c0.5,0.7,1.4,1.5,0.9,2.7
		c-1.8-0.9-3.1-2.3-4.7-3.5c-1.6-1.2-3.2-2.5-4.8-3.8c-0.6-0.5-1.4-0.7-1.8-0.3c-0.5,0.6,0.2,1.3,0.6,1.7c1.1,1.2,2.3,2.3,3.5,3.2
		c3.2,2.6,6.5,5.1,10.7,6c0.4,0.1,0.8,0.5,1.1,0.2c0.4-0.5-0.1-0.9-0.3-1.4c-1.2-2.1-1.2-4.2,0.1-6.3c0.3-0.5,0.4-1,0.6-1.5
		c0.3-0.8,0.3-1.5-0.6-1.7c-0.8-0.2-0.9,0.6-1,1.2c-0.2,1.2-0.4,2.4-1.2,3.5c-1-1.2-2.1-2.3-2.9-3.5c-0.6-1-1.9-1.7-1.9-3
		c-0.1-3.1-0.3-6.3,1-9.3c0.3-0.6,0.7-1.2-0.2-1.5c-0.8-0.3-1.5-0.1-1.8,0.8c-0.4,1.2-0.8,2.3-0.8,3.5c0,1.8-0.2,3.7,0.5,5.5
		c-0.3-0.1-0.4-0.1-0.5-0.1C111.7,470.4,108.9,469.1,105.6,469.1z M175.8,431.5c-0.9,0.6-0.3,1.7-0.1,2.6c0,0.2,0.2,0.3,0.3,0.5
		c1.4,2.3,2.7,4.5,4.2,6.7c1.9,2.9,3.5,6.1,6.4,8.2c2.8,2.1,6.1,2.8,9.5,2.6c0.5,0,1.2-0.2,1.3-0.8c0.1-0.7-0.5-1.1-1-1.4
		c-0.2-0.1-0.4-0.1-0.6-0.1c-4.2-1.4-6.9-5.7-6.6-9.5c0.1-1.5,0.5-3,0.8-4.5c0.2-0.9,0.2-1.7-0.6-2.3c-0.7,1.2-1,2.5-1.1,3.9
		c-0.9,0.6-0.5,1.6-0.6,2.4c0,0,0,0,0,0c-1.1-0.3-1.3-1.3-1.8-2.1c-1-1.5-1.9-3.1-2.9-4.7c-0.2-0.4-0.6-0.6-1-0.5
		c-0.5,0.2-0.3,0.7-0.2,1.1c0.3,1.3,1.2,2.4,1.9,3.6c1.9,3.6,3.3,7.5,6.1,10.5c0.2,0.2,0.3,0.4,0.2,0.7c-0.2,0.3-0.5,0.2-0.8,0.2
		c-1.5-0.1-2.1-1.2-2.8-2.2c-2.9-4.5-5.8-9.1-8.8-13.6C177.3,432.2,176.8,431,175.8,431.5z M46.9,432.5c0.8,0.3,1.1,0.2,0.9-0.7
		c-0.4-1.5,0.3-2.5,1.6-3.2c1.9-1,3.7-2.1,5.6-3.2c4.4-2.5,6.8-6.2,6.9-11.7c0.2-1.1-0.4-2.5-0.5-4c-0.1-0.7-0.6-1.7-1.1-1.6
		c-0.8,0.2-0.5,1.1-0.4,1.8c0.6,4.2-0.3,8-3.8,10.8c-2.7,2.2-5.8,4.1-7.8,7.2c-0.2,0.3-0.7,1-1.3,0.5c-0.6-0.5-0.9-1.2-0.6-2
		c0.4-1,1.1-1.7,1.8-2.4c2.8-2.6,5.5-5.2,8.3-7.7c1.2-1.1,2-2.6,2.6-4.1c0.2-0.6,0.2-1.3-0.4-1.6c-0.5-0.2-0.7,0.4-0.9,0.9
		c-0.9,1.6-2.2,2.8-3.6,4c-3.4,2.8-6.4,6-9.2,9.4C42.7,427.4,43.7,431.2,46.9,432.5z M41.5,615.4c-1.3,0-2.5,0-3.8,0
		c-0.8,0-1.5,0.1-1.5,1c0,1,0.7,1.2,1.5,1.3c2.9,0.2,5.4-0.6,7.3-3c1.3-1.7,1.9-1.6,3.6-0.3c0.4,0.3,0.7,0.8,1.3,0.5
		c0.6-0.3,0.5-0.8,0.6-1.3c0.1-0.7-0.2-1.2-0.7-1.6c-2.6-2.9-5.7-4.6-9.6-4.6c-2.7,0-4.7-1.1-6.3-3.3c-0.4-0.6-1-1-1.6-0.7
		c-0.6,0.3-0.3,1.1-0.2,1.7c0,0.2,0.3,0.4,0.4,0.6c1.9,2.8,4.3,4.3,7.8,3.8c0.8-0.1,1.6,0,2.3,0.6c0.3,0.3,0.6,0.7,0.5,1
		c-0.1,0.6-0.7,0.3-1,0.3c-1,0-1.9-0.1-2.9,0.1c-2,0.6-3.8,0-5.6-1.1c-2-1.2-2.8-3.3-4.2-5.1c-0.3-0.4-0.6-1.1-1.1-0.8
		c-0.5,0.3-0.3,1-0.1,1.5c0.8,1.9,1.7,3.8,2.8,5.6c0.8,1.3,1.8,1.9,3.3,2c0.9,0,1.8,0,3,0c0.6,0,1.5-0.1,2.4-0.1
		c0.9,0,1.7,0.2,2.2,1C42.3,614.9,42.4,615.4,41.5,615.4z M296.5,597.1c0.7-0.2,1.3-0.4,0.9-1.3c-0.3-0.7-0.6-1.3-1.6-0.7
		c-1.1,0.6-2.2,1.2-3.3,1.8c-0.2-0.8,0.2-1.1,0.5-1.6c1.1-1.9,2.3-3.8,2.2-6.1c0-0.6,0-1.4-0.7-1.5c-0.6-0.1-0.9,0.6-1.2,1.1
		c-0.6,1.3-1.2,2.6-1.9,3.8c-1.4,2.3-2,4.7-2.1,7.3c0,0.6,0,1.2-0.4,1.8c-1.8-2.7-2.5-5.6-1.7-8.8c0.2-0.9,0.3-1.9,0.4-2.9
		c0-0.3,0.2-0.8-0.3-0.9c-0.5-0.2-0.7,0.2-1,0.5c-1.6,1.9-2,4.1-1.7,6.5c0.3,1.9,1,3.7,1.6,5.5c1.2,3.9,3.5,7.8,1.5,12.1
		c0,0.1,0,0.2,0,0.3c-0.1,0.8,0.4,0.8,0.8,0.5c1.2-1.1,2.4-2.3,2.9-3.9c0.7-2.5,0.4-5,0.5-7.6C292,598.5,292.2,598.3,296.5,597.1z
		 M17.2,571.5c0.8-0.2,0.4-1.4,0.6-2.1c1-3.7,2-7.4,1.6-11.3c-0.1-0.7-0.1-1.4-0.6-2c-0.2-0.2-0.4-0.1-0.5,0c-0.6,0.6-0.2,1.4-0.1,2
		c0.6,2.3,0.3,4.5-0.8,6.6c-0.9,1.7-1.5,1.7-2.4,0.2c-3.8-6.4-6-13.2-4.8-20.7c0.1-0.4,0-0.8,0.1-1.2c0.1-0.5-0.1-1-0.6-1.1
		c-0.4-0.1-0.7,0.4-0.9,0.8c-0.8,2-1.1,4.1-0.6,6.2c0.5,2.6,0.9,5.2,1.3,7.9c-0.1,0-0.3,0-0.4,0.1c-1.1-2.2-1.9-4.5-2.7-6.8
		c-0.7-2.1-0.8-4.1,0-6.2c0.4-1,0.7-2,1-3c0.1-0.4,0.6-1-0.1-1.3c-0.7-0.3-0.8,0.5-1,0.9c-0.5,0.7-0.3,1.7-0.6,2.5
		c-1.9,6-0.3,11.4,2.5,16.7c2,3.7,4.6,7.1,7.4,10.3C16,570.5,16.3,571.7,17.2,571.5z M13.2,529.5c0.5-0.1,0.4-0.6,0.4-1
		c-0.1-1.2-0.2-2.3-0.4-3.5c-0.7-4.5,0.9-7,5.3-8.3c2.6-0.7,3.6-2.2,3.6-4.8c0-1.1-0.2-2.1-0.5-3.1c-0.7-2.5-0.2-4.8,1.9-6.6
		c1.3-1.1,3-1.2,4.4-1.9c0.6-0.3,1.5-0.4,1.3-1.2c-0.2-0.8-1.2-0.8-1.9-0.6c-0.3,0.1-0.6,0.2-0.9,0.3c-1.7,0.7-3.3,0.8-4.7-0.5
		c0,0,0,0,0,0l0,0c-0.4-0.6-0.8-1.3-1.5-1.6c0,0,0,0,0,0c0,0,0,0,0,0c-2-1.9-3.8-3.9-1.9-6.9c1.5-1.6,2.6-3.4,3.3-5.4
		c0.1-0.2,0.3-0.5,0-0.6c-0.3-0.1-0.4,0-0.6,0.3c-0.9,2-2.3,3.7-3.1,5.8h0l0,0c-0.5,0.7-0.9,1.4-1.6,2.4c0-0.6-0.1-0.8-0.1-1
		c0-2.3,0.1-4.6,0.2-7c0-0.8-0.2-1.5-0.7-2.1c-0.2-0.3-0.6-0.5-0.9-0.4c-0.3,0.1-0.2,0.5-0.2,0.8c0,2.8,0.1,5.7,0.1,8.5
		c0,1,0.4,1.9,0.9,2.7c0.8,1.3,1.8,2.6,2.7,3.9c0.4,0.6,0.8,1.2,1.5,1.5c0,0,0,0,0,0c0,0,0,0,0,0c0.3,0.4,0.7,0.8,1.2,0.9
		c1.2,0.4,1.2,0.9,0.5,1.8c-0.8,1-1.5,2.2-1.4,3.5c0,1.7,0.1,3.4,0.1,5.2c0,1.6-1.7,2.8-3.6,2.7c-1.8-0.1-0.9-1.5-1-2.3
		c-0.3-2.1-0.4-4.2-0.6-6.4c-0.1-0.7-0.2-1.5-1.2-1.3c-1,0.1-1,0.8-0.9,1.6c0,0.3,0,0.5,0.1,0.7c1.6,4.8,0.7,9.2-1.4,13.6
		c-1.5,3.2-1.6,6.4,0.4,9.5C12.4,529.5,12.7,529.6,13.2,529.5z M278.2,557.9c0.6-0.3,1.1-0.6,1.4-1.2c-0.6-0.3-1-0.3-1.6,0.1
		c-1.1,0.7-2.3,1.3-3.5,2c-0.2,0.1-0.4,0.1-0.5-0.2c-0.3-3.6-1.1-7.2,0.7-10.6c0.3-0.5,0.7-1,0.4-1.7c-0.5-0.4-1-0.3-1.6-0.1
		c-0.4,0.1-0.5,0.6-1,0.7c-0.8-1.3-1.5-2.8-2.9-3.7c-1.8-1.8-3.5-3.9-5.8-5c-2.1-1.1-4.4-2.2-6.7-0.3c0.9,1,2.2,0.8,3.2,1.1
		c6.4,1.9,11.1,7.7,11.1,13.8c0,0.5,0.2,1.2-0.3,1.5c-0.6,0.2-1.1-0.2-1.6-0.5c-1.8-1.3-3.5-2.6-5.2-4.1c-0.6-0.5-1.2-0.9-2-0.3
		c1,1.9,2.8,2.8,4.5,3.9c3.9,2.5,5.4,6.8,3.9,11.1c-0.5,1.5-1.1,3-1.7,4.5c-0.3,0.8,0,1.4,0.7,1.7c0.7,0.3,1.4,0,1.6-0.7
		c0.4-1.1,0.8-2.1,1-3.3C273,562.8,274.5,559.6,278.2,557.9z M186,625.4c1.6,0.6,4.2-1.1,4.2-2.8c0-1.9-0.6-3.8-1.3-5.6
		c-1.4-3.9-2.6-7.7-2.5-11.9c0-1.1-0.1-2.3-0.8-3.7c-0.2,2.9-0.4,5.3-0.7,8c-1.6-1.2-2.8-4.1-3-6.5c-0.3-4-0.1-8,0.7-11.9
		c0.2-1-0.2-1.8-1-1.9c-0.8-0.2-1.4,0.6-1.5,1.4c-0.4,2.6-0.7,5.1-0.9,7.7c-0.4,5,1.6,9.3,4,13.5c2.3,4,4.2,8.1,2.4,12.9
		C185.6,624.7,185.4,625.2,186,625.4z M170,569.2c0.1-0.4,0.5-1.1-0.2-1.3c-0.5-0.1-0.6,0.5-0.8,0.9c-1,2.2-2,4.4-2.8,6.7
		c-0.5,1.7-1.1,1.8-2.6,0.8c-1.3-0.9-2.1-2.2-2.7-3.5c-0.8-1.7-1.5-3.4-2.2-5.1c-0.3-0.6-0.8-1-1.3-0.8c-0.4,0.2-0.7,0.8-0.4,1.4
		c1.6,3.9,3.2,7.8,7,10.1c1.1,0.6,1.4,1.5,1.5,2.8c0.1,2.7,1,5,3,7c1.2,1.2,2.4,2.4,2.4,4.3c0,1.2,0.6,2,2,1.9c1.4-0.1,2.1-1,2-2.3
		c-0.4-3.2-0.8-6.3-1.2-9.5c-0.1-0.7,0.1-1.9-0.9-1.8c-1,0.1-1,1.2-1,2.1c0,0.7,0,1.4,0,2.1c0,0.8-0.2,1.5-0.8,2.1
		c-0.8-0.7-1.6-1.5-2-2.5c-1.4-3-1-6.2-0.6-9.3C168.8,573.2,169.5,571.2,170,569.2z M265.1,496.3c1.8,0.6,3.5,1.2,5.4,1.3
		c2.9,0.2,5.4-1.1,8-2c0.7-0.2,1.9-0.5,1.5-1.5c-0.3-0.7-1.4-0.2-2.1,0.1c-2.8,1.1-5.7,1.8-8.7,1.1c-4.1-0.9-8.2-2.1-12.4-1.5
		c-2.3,0.3-4.7,0.5-6.5,2.4c-0.6,0.6-1.3,1.4-0.9,2c0.4,0.7,1.4,0.2,2.1-0.1c1.7-0.6,3.3-0.8,5.1-0.4c2.9,0.6,5.7,1.8,8.5,2.6
		c4.8,1.5,9.5,1.6,14.2-0.3c1.5-0.6,3-1.4,4.2-2.6c0.6-0.6,0.7-1.2,0.2-1.7c-0.4-0.4-1-0.1-1.5,0.2c-1,0.8-2,1.4-3.2,2
		c-4.5,2.2-9.1,2.5-13.8,0.9c-2.5-0.9-4.6-2.8-7.5-2.9C260.2,495,262.6,495.5,265.1,496.3z M25.8,490c-0.4-0.3-0.9-0.8-1.3-0.5
		c-0.5,0.4-0.2,1,0.1,1.6c0.9,1.5,2.2,2.6,3.6,3.5c3.8,2.7,8.3,4.5,10.1,9.3c0.1,0.2,0.3,0.6,0.7,0.4c0.3-0.2,0.6-0.5,0.5-1
		c-0.2-1-0.4-2-0.7-2.9c-1-3.3-2-6.5-0.3-9.8c0.2-0.4,0-1,0.1-1.5c0-0.4-0.1-0.7-0.5-0.7c-0.4,0-0.7,0-0.9,0.4
		c-0.2,0.5-0.3,0.9-0.4,1.4c-0.3,1.7-0.7,3.3-1.1,5c-0.9-0.6-1.2-1.5-1.7-2.2c-2.2-2.7-2.8-5.7-1.9-9.1c0.5-2.1,0.2-4.2,0-6.4
		c-0.1-0.9-0.7-1.8-1.6-1.7c-0.9,0.2-0.3,1.2-0.3,1.8c0,0.3,0.1,0.6,0.1,0.9c0,1.9,0,3.8,0,6c-1-1.6-1.7-2.9-2.6-4.2
		c-0.3-0.4-0.8-1-1.3-0.7c-0.6,0.3-0.4,0.9-0.2,1.5c0.9,2.3,1.8,4.6,3,6.7c1.4,2.6,2.9,5.1,4.5,7.8c-1.4-0.1-2.3-0.8-3.1-1.5
		C28.8,492.9,27.3,491.4,25.8,490z M18.8,467.1c0.3-0.1,0.5-0.3,0.8-0.4c4.5-2.4,9.1-3.5,14-1.1c0.8,0.4,1.6,1,2.7,1.6
		c-0.2-1.3-0.4-2.2-0.5-3.2c-0.3-4.2-1.2-8-4.2-11.3c-2.9-3.1-3.2-6.5,0.6-10.2c2.1-2.1,4.1-4.2,6-6.4c0.4-0.5,0.7-1,0.3-1.5
		c-0.3-0.4-0.9,0-1.4,0.1c-0.2,0-0.3,0.3-0.5,0.4c-3.5,2.3-5.9,5.4-7.8,9.1c-1.2,2.5-1.8,5-0.2,7.5c0.9,1.4,2.2,2.6,3.3,3.9
		c1,1.2,1.9,2.6,2.1,4.2c0.2,1.4-0.5,1.6-1.5,1.2c-2.5-0.9-5.1-0.8-7.7-0.8c-0.6,0-1.2,0.1-1.4,0.9c2.6,0.4,5.3,0,7.7,1.7
		c-0.9,0.4-1.6,0.3-2.2,0.2c-3.8-0.1-7.3,1.1-10.7,2.9c-0.5,0.3-1,0.7-0.7,1.3C17.8,467.8,18.4,467.3,18.8,467.1z M75.4,643.8
		c1-0.1,1.8-0.1,2.6-0.4c3.4-1.3,6.8-2.5,10.4-3.1c2-0.3,3.8-0.3,5.7,0.4c2.5,0.9,4.4,2.6,6.4,4.4c1.2,1.1,2.5,2.1,3.8,3.1
		c1.2,1,1.9-0.2,2.5-0.9c0.6-0.6,0.3-1.2-0.3-1.8c-0.8-0.7-1.4-1.5-2.1-2.2c-3-2.9-6.4-5.1-10.7-5c-4.9,0.1-9.7-0.1-14.3-1.7
		c-1.8-0.6-3.7-1.1-5.6-1c-1-0.2-1.6,0.4-1.7,1.1c-0.1,0.6,0.8,0.8,1.4,0.9c1.6,0.3,3.2,0.9,4.9,0.7l0,0h0c1.3,0,2.4,0.4,3.9,0.9
		C79.8,640.8,76.8,640.9,75.4,643.8z M196.7,430.3c-0.8-1.8-1.5-3.6-2.6-5.3c-0.6-0.9-0.9-1.8-0.4-3c1-2.6,1.4-5.3,0.5-8
		c-0.2-0.7-0.2-1.5-0.5-2.2c-0.6-1.7-0.6-3.6-1.7-5.1c-0.6-0.8-1.2-0.6-2-0.2c-0.8,0.4-0.4,0.9-0.2,1.5c0.3,0.8,0.7,1.4,1.2,2
		c1.4,1.7,2,3.7,1.8,5.9c-0.1,1-0.1,2,0,3c0.1,1.3-0.2,2.5-1.1,3.5c-0.5-0.3-0.9-0.8-1.5-1c-0.6-0.1-1.3-1-1.7-0.2
		c-0.3,0.5,0.6,0.9,1.1,1.2c3.5,2.4,5.7,6.6,4.2,11.1c-0.2,0.5,0,1.2-0.9,1.2c-0.1-0.8-0.2-1.6-0.7-2.3c-0.3-1.3-0.7-2.6-1.3-3.7
		c-0.9-1.5-3.6-3.5-5.3-2.4c0.5,0.3,1,0.5,1.5,0.7c1.9,0.7,3.3,1.9,4.1,3.9c0.8,2,0.3,4.1,0.4,6.1v0c0,0,0,0,0,0
		c-0.1,1.2-0.2,2.3-0.4,3.5c-0.1,0.7,0.1,1,0.8,0.5c1.5-1,2.7-2.2,3.7-3.8c0.8-1.3,0.7-2.8,1.2-4.2c1.1-2.7,3.7-3.7,5.8-5.1
		c1.3-0.9,2.8-1.6,4.1-2.4c0.5-0.3,0.8-0.8,0.5-1.4c-0.4-0.7-0.9-0.1-1.2,0.1c-0.8,0.5-1.6,1-2.4,1.5c-1.6,1-1.7,1-2.8-0.6
		c-0.3-0.4-0.6-0.8-0.9-1.2c-0.4-0.4-1-0.9-1.4-0.5c-0.5,0.4-0.1,1.1,0.3,1.6c0.3,0.4,0.7,0.9,1,1.3c0.4,0.5,0.4,1-0.1,1.4
		C198.8,428.8,198,429.9,196.7,430.3z M208.6,437c0.1-0.4,0.1-0.8-0.3-1.1c-0.4-0.4-0.9-0.2-1.2,0.1c-0.3,0.2-0.5,0.6-0.7,0.9
		c-2.1,3.1-4.9,5.3-8.5,6.4c-0.2,0.1-0.5,0.5-0.8,0.1c-0.2-0.3,0.1-0.5,0.2-0.8c0.8-1.5,2-2.7,3-4.1c0.4-0.6,0.8-1.3,0.1-1.7
		c-0.7-0.4-1.6-0.1-1.9,0.8c-1,2.6-2.8,4.3-5.3,5.5c-0.8,0.4-1.6,1.1-0.9,2.2c0.7,1.1,1.7,1.8,3,2.1c5.2,1.1,9.6-0.4,13.6-3.6
		c2.3-1.9,3.7-4.3,4.4-7.1c0.2-0.7,0.1-1.4-0.5-1.6c-0.6-0.3-1,0.4-1.3,0.9c-0.2,0.4-0.3,0.8-0.5,1.3c-1.5,4.3-6.6,8.5-11.2,8.5
		c1.1-0.9,2.1-1.5,3.1-2C206,442.3,207.7,439.9,208.6,437z M158.2,376.2c0,0.4-0.1,0.8-0.1,1.1c-0.3,0.9-0.4,1.8,0,2.8
		c0.2,2.3,1.4,4,3.3,5.1c2.5,1.6,3.7,3.7,3.2,6.7c-0.1,0.6-0.7,1.1-0.3,1.8c1.4-0.9,1.3-2.3,1.2-3.7c-0.1-1.3,0.4-2.2,1.4-3
		c3.4-2.7,5.4-6.1,4.9-10.7c0-0.4,0.1-0.9-0.5-1c-0.7,0-0.4,0.6-0.4,0.9c0.1,2.8-1.1,5.1-3,7.1c-1-0.7-0.5-2.2-1.6-3
		c-0.3,1.2-0.1,2.2,0.6,3.1c0.7,1.1-0.5,1.6-0.9,2.2c-0.2,0.4-0.6,0.6-1,0.2c-1.4-1.4-3.1-2.5-4.5-4c-0.8-0.9-0.9-2-0.5-2.8
		c1.5-2.8,3.4-5.4,6.5-6.6c3.1-1.2,5.6-3.1,7.8-5.4c0.6-0.7,1.4-1.3,1-2.7c-2.1,1.7-4,3.7-6.4,4.9c-0.3-4.2-0.3-4.2-1.1-4.5
		c0.6,3.2-1.1,5.2-3.4,6.8c-1.5,1-2.6,2.3-3.4,3.9c-0.2,0.5-0.4,0.9-0.8,1.2c-0.5-1.6,0.2-2.7,1.1-3.8c0.6-0.8,1.3-1.5,2-2.2
		c1.2-1.2,1.6-2.8,2-4.5c0.1-0.4,0-1-0.5-1.2c-0.6-0.2-0.5,0.4-0.7,0.8c-0.5,1-0.8,2.1-1.6,2.9c-1.5,1.6-2.4,3.6-3.4,5.5
		C158.4,374.7,158.5,375.5,158.2,376.2z M279.1,426.4c-0.3,0.8-0.4,1.7-0.8,2.4c-1.7,3.7-4.7,5.4-8.7,5.3c-2.9-0.1-5.7,0.1-8.4,1.6
		c-1,0.5-1.3,0.1-1.1-0.9c0.2-1.6,1.1-2.3,3.2-2.6c2.6-0.3,5.1-0.9,7.2-2.4c1.7-1.2,2.9-2.6,2.6-4.9c-0.1-0.8-0.7-1.1-1.3-1.3
		c-0.8-0.3-0.7,0.6-0.8,1c-0.7,2.3-2.1,4-4.4,4.8c-2,0.7-4,1.4-5.9,2.2c-4.2,1.8-4.7,5.7-3.5,8.1c0.4,0.7,0.9,1.3,1.8,1.1
		c0.9-0.2,1.2-0.8,1.2-1.7c0-1.2,0.7-1.8,1.8-2c0.8-0.2,1.6-0.3,2.4-0.5c3.7-0.8,7.5-1,11.1-2.2c3.3-1.1,5.1-3.3,5.2-6.9
		c0-0.4,0.1-0.7,0-1.1c-0.1-0.6-0.2-1.3-0.9-1.4C279.5,425.2,279.3,426,279.1,426.4z M109.4,678.7c-0.5-0.9-1.1-1.9-2.3-1.5
		c-2.9,1-5.7,2.4-7.4,5.1c-0.7,1-1.4,1.9-2.2,2.8c-1.4,1.5-3,2.1-5,1.7c-1.8-0.4-3.6-0.7-5.3-1c-0.5-0.1-1.3-0.2-1.4,0.5
		c-0.1,0.5,0.2,1,0.8,1.4c2.8,1.7,5.7,1.6,8.7,0.4c2.5-1,4.2-3.1,6.2-4.7c1.5-1.2,3-2.4,5.1-3.2c-0.3,0.5-0.3,0.7-0.5,0.8
		c-2,1.2-3.3,3.1-3.9,5.3c-0.9,3-2.7,4.6-5.8,4.7c-0.8,0-1.7,0.3-2.5,0.5c-0.5,0.1-0.9,0.5-0.8,0.9c0,0.4,0.5,0.7,1,0.7
		c0.9,0.1,1.8,0.3,2.7,0.4c5.3,0.9,7.9-1.2,8-6.6c0-1.6,0.3-3.1,1.4-4.4c0.7-0.8,1.6-1.3,2.5-1.8C109.3,680.3,110,679.7,109.4,678.7
		z M204.9,472.6c-0.7,0.5-1.4,1.1-2.2,1.6c-3.8,2.7-7.6,5.5-11.6,8.4c-0.1-0.9-0.1-1.7,0.1-2.3c1.4-4.3,2.8-8.6,4.2-12.9
		c0.6-1.9,0.7-3.9,0.8-5.8c0-0.3,0-0.5-0.3-0.6c-0.3-0.1-0.5,0-0.7,0.2c-0.2,0.3-0.4,0.6-0.5,0.9c-2,5.3-4.3,10.5-5.7,16
		c-0.9,3.6-1.3,7.3-0.7,11c0.1,0.9,0.6,1.5,1.4,1.8c0.8,0.4,1.4-0.3,1.8-0.7c0.7-0.7,0.9-1.7,0.6-2.7c-0.4-1.5,0-2.8,0.9-4
		c0.9-1.2,1.9-2.3,3-3.3c2.8-2.7,6.3-4.4,9.7-6.1c0.6-0.3,1.2-0.5,0.7-1.3C206,472.1,205.4,472.2,204.9,472.6z M143.3,487.5
		c0.6-1.1,1.7-1.1,2.7-1.3c2.5-0.4,4.9-0.9,7.1-2.3c1.7-1.2,2.9-2.6,2.8-4.8c-0.1-0.8-0.6-1.4-1.3-1.5c-0.8-0.1-0.8,0.7-0.9,1.2
		c-0.5,1.9-1.6,3.3-3.4,4.1c-2.2,1.1-4.5,1.6-6.7,2.6c-3.4,1.6-4.7,4.3-3.6,7.7c0.3,0.9,0.7,1.5,1.8,1.5c1,0,1.2-0.8,1.3-1.6
		c0.1-1,0.6-1.8,1.7-2.1c0.8-0.2,1.6-0.3,2.4-0.5c3.8-0.8,7.8-1,11.6-2.3c3.8-1.3,5.3-4.2,5-7.9c0-0.6-0.4-1.4-1-1.4
		c-0.7-0.1-0.8,0.7-0.9,1.3c-0.5,1.7-1.1,3.3-2.2,4.7c-2.2,2.6-5.2,3.1-8.3,2.9c-2.6-0.1-5,0.4-7.2,1.6c-0.9,0.3-1.3,0.1-1.1-0.9
		C143,488.4,143,487.9,143.3,487.5z M324.6,453.7c-0.7,0-1.3,0.1-1.5,0.8c-0.2,0.8,0.7,0.8,1.1,1c2.1,0.8,3.8,1.9,5.2,3.7
		c1.7,2.1,2.8,4.4,2.2,7.5c-1-1.7-1.8-3.1-2.7-4.5c-1.5-2.4-3.5-4.2-6.4-4.9c-2.1-0.5-4,0-6,0.2c-0.6,0.1-1.4,0-1.3,0.9
		c0.1,0.9,1,0.3,1.5,0.4c0.2,0,0.5-0.2,0.7-0.2c2.2-0.3,4.4-0.5,6.3,0.9c1.6,1.2,2.5,2.8,3.4,4.5c0.9,1.7,1.3,3.7,2.8,4.9
		c1.8,1.5,2.9,3.3,3.7,5.5c0.5,1.5,1.2,3,2.3,4.2c0.3,0.3,0.6,0.6,1,0.4c0.4-0.2,0.4-0.7,0.3-1.1c-0.1-0.4-0.4-0.8-0.6-1.2
		c-0.5-0.9-1.2-1.8-1.6-2.8c-1.2-2.9-2.4-5.8-1.3-9c0.5-1.3-0.1-2.8-0.8-4c-1.3-2.3-2.9-4.5-3.3-7.2c-0.1-0.5-0.4-1.1-0.9-1.1
		c-0.7,0-0.6,0.7-0.6,1.2c0.1,1.3-0.6,1.3-1.5,0.8C326.1,454,325.5,453.6,324.6,453.7z M254.8,475.5c-1.7,1.6-3.6,2.8-5.8,3.7
		c-2,0.8-4.1,1.4-5.8,2.9c-2.3,2-2.9,4.7-3.8,7.4c-0.3,0.9-0.6,0.9-1.2,0.3c-1.5-1.6-1.2-3.4-0.9-5.3c0.3-2.5,2.2-3.8,4-5.2
		c0.6-0.8,1.4-1.5,1.8-2.5c-0.1-0.2-0.2-0.3-0.5-0.3c-1.7-0.3-2.5,1-3.5,2c-3.7,3.5-5.5,7.5-3.4,12.6c0.6,1.4,1.3,2,2.8,1.9
		c2.4-0.2,3.4-0.8,3.7-2.4c0.3-1.8,1-3.3,2-4.8c0.5-0.7,1-1.4,1.8-1.9c1.8-1.8,4.2-2.8,6.4-3.9c1.6-0.8,3-1.6,4-3.1
		c0.5-0.7,0.8-1.5,0.1-2C255.8,474.3,255.3,475,254.8,475.5z M215,372.8c-0.4,0.5,0,0.8,0.3,1.2c0.5,0.6,1,1.2,1.4,1.9
		c1.5,2.9,0.6,5.5-0.5,8.1c-1.2,2.7-3.5,4.7-5.4,6.9c-1.7,2-3,4.1-2.9,6.9c0.1,2.9,1.2,5.5,2.1,8.2c0.1,0.4,0.5,0.9,1,0.7
		c0.4-0.2,0.6-0.8,0.5-1.3c-0.1-0.4-0.3-0.7-0.5-1.1c-0.7-1.2-0.7-2.3-0.1-3.5c0.5-0.9,1-1.7,1.6-2.5c0.4-0.6,0.7-1.2,0.5-2
		c-1.4,0.3-1.9,1.7-3,2.7c-0.5-2.2,0.1-3.8,1.7-5.2c1.9-1.7,4.3-2.1,6.4-3.1c3.1-1.4,5.3-3.8,7.2-6.6c0.4-0.5,0.5-1.3-0.2-1.6
		c-0.7-0.4-0.7,0.5-1,0.8c-2.1,3.2-5.3,4.7-9.2,6c3.8-4.1,4.8-8.5,3.6-13.3c-0.3-1.3-0.7-2.6-2.1-3.3
		C215.9,372.5,215.4,372.2,215,372.8z M276.1,396.2c-3,0.8-5.8,1.4-8.6,1.9c-4.4,0.9-7.9,3.1-9.9,7.3c-1.1,2.3-1.6,4.7-2,7.2
		c0,0.3-0.4,0.7,0.1,0.9c0.5,0.2,0.7-0.3,0.9-0.6c0.4-1,0.7-2.1,1.1-3.1c1.3-4,5.3-6.1,9.5-5.2c2.8,0.6,5.5,1.1,8.3,1.6
		c0.4,0.1,0.9,0,1.4,0c0.2,0,0.4-0.1,0.4-0.3c0-0.2-0.1-0.4-0.3-0.5c-0.3-0.1-0.6,0-0.9-0.1c-2.1-0.4-4.3-0.9-6.4-1.4
		c1-1.1,2-1.4,3.1-1.4c1.5,0,3,0.1,4.5-0.4c1.2-0.4,2.2-1,2.6-2.6c-1.4,0.8-2.6,1.6-4,1.6c-2.8,0.1-5.6-0.9-8.9-0.1
		C270.1,399.1,274,399.5,276.1,396.2z M101.3,537.9c0.3-1.2,0.7-2.5-0.5-3.6c-0.7,0.7-1.1,1.4-1.1,2.3c0,2.1-1,3.9-1.5,5.8
		c-0.1,0.3-0.1,0.8-0.6,0.7c-0.4-0.1-0.5-0.5-0.6-0.8c-0.2-1-0.4-2.1-0.6-3.1c-0.4-2.5-0.4-5.1-1.6-7.4c-0.8-1.4-0.3-2.9,0.6-4.1
		c2.3-2.9,1.9-6,0.6-9.2c-0.3-0.7-0.9-1.2-1.5-0.9c-0.6,0.3-0.3,1.1,0,1.6c1.7,2.9,1,5.6-0.7,8.2c-0.7,1.1-1.4,1.4-2.4,0.2
		c-0.8-0.9-1.7-1.7-2.6-2.5c-0.7-0.7-1.6-1.1-2.6-0.7c-0.8,0.4-1.4,0.8-0.4,1.7c1,0.9,2.1,1.7,3.2,2.4c1.4,0.9,2.6,1.9,3.5,3.4
		c1.8,3,2,6.5,2.7,9.8c0.6,2.9,1.9,5.1,4.4,6.6c0.3,0.2,0.7,0.4,1,0.2c0.2-0.1,0.1-0.6,0.1-0.8c-0.3-1.3-0.5-2.6-0.3-3.9
		C100.4,541.9,100.9,539.9,101.3,537.9z M84.1,661.5c0.3-0.7,0.4-1.6-0.3-2.4c-0.8-0.8-1.7-1.3-2.7-1.8c-6.4-3-10.5-8.2-13.3-14.5
		l0,0c-1.5-3-2.8-6.1-2.9-9.4c0-1.1,0.1-2.3,0.2-3.4c0,0,0,0,0,0c0.1-1.3,0.8-2.3,1.5-3.4c0.6-1-0.1-1.6-0.6-2
		c-0.7-0.5-1.2,0.1-1.5,0.8c-0.4,1-1.1,1.8-1.2,2.9c-0.8,1.1-1,2.4-0.8,3.7v0c0,0,0,0,0,0c0,0.3,0,0.6,0,0.9
		c-0.4,2.1,0.4,4.2,0.2,6.4c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0.1-0.8-0.3-1.3-0.9-1.8c-2.2-3.6-2.3-7.4-1.2-11.4
		c0.8-3.1,1.1-6.3,0.8-9.5c-0.1-0.8-0.2-1.5-0.8-2c-0.7-1-1.5-0.8-2.2,0.1h0c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2
		c1.8,2.9,1.4,6,0.7,9.1c-0.1,0.5-0.3,1-0.8,1.2c-0.6,0.3-0.9-0.2-1.3-0.6c-0.8-0.7-1.4-1.6-1.9-2.7c-0.7-1.4-0.6-3.1-1.2-4.5
		c-0.2-0.5-0.5-1.2-1.3-1c-0.7,0.2-0.9,0.9-0.8,1.6c0.8,3.5,1.8,7,4.9,9.4c0.9,0.7,1.4,1.6,1.6,2.8c0.3,1.4,0,2.8,0.1,4.2
		c0.1,0.7,0.1,1.6-0.5,1.9c-0.7,0.4-1.6,0.2-2.3-0.3c-0.4-0.3-0.9-0.6-1.3-0.9c-1.6-1.3-1.9-3.1-2.1-5c-0.1-0.8,0-1.9-1.4-1.9
		c-0.6,0.1-0.6,0.5-0.7,1c-0.1,0.5,0,1-0.6,1.2c0,0,0,0,0,0c-0.3-0.2-0.4-0.5-0.5-0.9c-0.7-2.4-1.4-4.8-2.1-7.2
		c-0.2-0.8-0.5-1.7-1.6-1.3c-0.9,0.3-1,1.1-0.6,2c0.8,2.1,1.4,4.3,2.4,6.3c2.1,4.2,5.4,7,10,8.1c0,0,0,0,0,0c0,0,0,0,0,0
		c1.4,0.5,2.1,1.5,2.8,2.7c1.3,2.2,2.7,4.3,4.5,6.1l0,0v0c1.4,1.5,2.9,2.9,4.6,4.2c0.5,0.4,1.5,0.7,1.2,1.3
		c-0.3,0.7-1.3,0.2-1.9,0.2c-0.1,0-0.1,0-0.1,0c-0.5-0.8-1.3-0.7-1.7-0.1c-0.5,0.7-0.1,1.6,0.8,1.9c0,0,0,0,0,0
		c2.4,0.2,4.4,1.2,6.3,2.6c2.8,2.1,5.5,4.3,9,5.3C82.8,661.7,83.6,662.4,84.1,661.5z M275.7,510.5c-0.3-0.2-0.9-0.3-1.2,0
		c-0.3,0.3,0,0.7,0.3,1c0.2,0.2,0.2,0.4,0.4,0.6c2.1,2.3,2.4,4.8,1.1,7.6c-0.8,1.7-2.1,2.7-3.6,3.7c-1.9,1.2-3.7,2.5-5.9,3.6
		c0.2-1.3,0.6-2.2,1.5-2.9c1.5-1.3,3.1-2.5,4.5-4c2.6-2.6,2.1-5.9-1.1-7.5c-1.4-0.7-2.9-0.7-4.4-0.3c0.5,1.2,1.8,1.2,2.6,1.6
		c1.8,0.9,2.4,2.2,1.8,4c-0.8,2.3-2.5,3.8-4.4,5.1c-1.9,1.3-3.5,2.8-3.2,5.4c0.2,1.1,0.9,1.7,1.8,2c0.7,0.2,1.4,0.1,1.6-0.7
		c0.5-1.6,1.8-2.4,3.2-3.2c2.7-1.5,5.6-2.7,7.6-5.2C281.5,517.3,279.9,512.8,275.7,510.5z M117.7,513.1c2-1.7,4-3.3,4.6-6.1
		c0.2-0.8,0-1.4-0.6-1.6c-0.6-0.3-0.8,0.4-1,0.8c-0.5,0.8-0.9,1.7-1.4,2.5c-1.8,2.4-4,4.3-6.1,6.4c-2.9,3-3.8,6.6-2.8,10.6
		c0.3,1.1,1.6,1.7,2.4,1c0.6-0.5,0.5-1.3,0.6-2c0.3-2,0.9-2.8,2.8-3.2c1.9-0.3,3.5-1.2,5-2.3c2.9-2.2,5.1-4.8,5.1-8.8
		c0-0.6,0-1.2-0.8-1.6c-0.2,0.5-0.3,0.9-0.4,1.2c-1,3.8-3.3,6.2-7.1,7c-2.1,0.4-3.6,1.7-4.5,3.6c-0.2,0.5-0.5,0.9-1.2,1.1
		c0-2.3,0.5-4.3,2.2-5.8C115.4,514.9,116.5,514,117.7,513.1z M172.7,628c1.9-2.2,4.3-3.8,6.7-5.4c1.3-0.8,2.8-1.4,3.9-2.5
		c0.7-0.6,0.6-1.5,0.4-2.1c-0.4-0.8-1.1-0.2-1.5,0c-1.1,0.5-2.1,1.1-3.3,1.7c-0.1-0.3-0.2-0.5,0-0.8c0.9-1.6,1.8-3.2,2.7-4.8
		c0.3-0.5,0.6-1.1,0.1-1.4c-0.5-0.3-1.2-0.2-1.6,0.4c-0.3,0.4-0.6,0.9-1,1.3c-2.4,3.4-4.7,6.8-7.2,10.1c-0.2,0.3-0.3,0.7-0.8,0.5
		c-0.3-0.1-0.2-0.5-0.3-0.8c-0.2-1.9,0.1-3.7,1.5-5.2c0.7-0.8,1.7-1.7,1.2-3.1c-0.8,0.2-1.3,0.5-1.8,1.1c-2.7,3-3.6,6.6-4,10.5
		c-0.1,0.7,0.1,1.5,1,1.6C170.2,629.3,171.6,629.3,172.7,628z M246.8,515.8c2.2-1.9,4.8-3,7.5-4c0.6-0.2,1.7-0.3,1.5-1.1
		c-0.2-0.8-1.3-0.4-2-0.3c-5.4,1.4-10,3.9-13,8.8c-1.2,2-2.4,3.9-3.2,6.1c-0.3,0.7-0.3,1.6,0.4,2.1c0.8,0.6,1.2-0.4,1.7-0.8
		c1.3-1.2,2.5-2.6,4-3.6c4.6-2.8,9.5-4.9,15-5.6c1-0.1,2.1-0.4,3.1-0.7c0.6-0.2,1.2-0.4,0.2-1c-1.2-0.4-2.5-0.4-3.7,0
		c-5.5,0.4-10.5,2.7-15.7,5.3C243.8,518.8,245.2,517.2,246.8,515.8z M296.1,654.6c-2.9,0-3.6,0.6-4.7,3.3c-0.8,2.2-2,4.1-4.7,4.3
		c-1.8,0.2-3.6,0.7-5.4,0.6c-0.9-0.1-2.2,0.7-2.5,0c-0.5-0.9,0.9-1.6,1.4-2.4c0.8-1.2,1.7-2.3,1.6-4c-1.1,0.2-1.7,0.9-2.2,1.5
		c-1.8,2.1-3.6,4.3-6.5,4.8c-2,0.3-3.9,1-5.7,1.8c-0.5,0.2-1.3,0.3-1.1,0.9c0.2,0.6,0.9,0.5,1.6,0.3c2.5-0.7,5-1.5,7.7-0.7
		c4.4,1.2,8.6,0.7,12.6-1.3c1.2-0.6,2.4-1.3,2.8-2.5c0.8-2.2,2.3-2.4,4.3-2.3c1.1,0.1,2.4,0.3,3.6-0.6c-1.5-0.5-2.8-0.9-4.6-1.2
		c1.9-1.3,4.1-1.3,5.7-2.5C298.7,654.6,297.4,654.6,296.1,654.6z M203.1,620.9c0.3,0.2,0.8,0,1.1-0.2c0.2-0.2,0.4-0.3,0.6-0.5
		c3.6-2.9,4.5-7,5-11.4c0.7-6,0.4-11.9,0.3-17.9c0-1.5-0.5-2.8-0.8-4.1c-0.1-0.3-0.3-0.6-0.6-0.5c-0.4,0.1-0.2,0.4-0.2,0.6
		c-0.1,1.3-0.1,2.5-0.1,3.8c0.2,5.5,0.3,10.9-0.4,16.4c-0.2,1.4-0.5,2.7-1.7,3.9c-1.1-3-1.4-6.1-2.5-9.1c-1.1,1-1.5,2.1-1.1,3.3
		c0.3,1.1,0.5,2.1,1,3.2c1.7,3.9,1.9,7.7-0.3,11.4C203,620.2,202.7,620.6,203.1,620.9z M63.7,467.6c0.8,0.5,1.7,0.3,2.2-0.6
		c0.5-0.7,1-1.5,1.4-2.3c1.3-2.4,3.2-4.6,4.1-7.3c0.3-0.7,0.6-1.5,0.1-2c-0.8-0.8-0.5-1.6-0.4-2.5c0-0.4,0.4-0.9,0-1.3
		c-1-1.9-0.7-3.6,0.6-5.3c0.8-0.9,1.4-1.9,1.9-3c0.2-0.5,0.2-1-0.3-1.4c-0.4-0.3-0.9-0.2-1.2,0.2c-1.9,2.1-4.6,6.4-2.8,10.3
		c0.8,1.7,1,3.8-0.6,5.5c-0.5,0.4-1,1.1-1.7,0.3c-0.3-1-0.6-1.9-0.8-2.9c-0.8-3-1.4-5.9,0.7-8.6c0.3-0.4,0.8-1,0.3-1.5
		c-0.5-0.4-1,0.1-1.5,0.3c-0.2,0.1-0.4,0.3-0.6,0.5c-1.4,1.7-2,3.6-1.6,5.8c0.5,2.4,0.9,4.8,1.4,7.1c0.5,2.4,0.6,4.8-1.1,6.9
		C63.3,466.3,62.8,467,63.7,467.6z M36.4,575.2c1.3-0.8,2.6-1.2,4-1.8c0.9-0.4,1.3-1.1,0.8-2c-0.4-0.8-1.1-0.4-1.7,0
		c-0.9,0.5-1.9,0.9-2.8,1.4c-0.7,0.3-1.3,0.7-1.8,1.3c-0.1,0.3-0.3,0.3-0.5,0.3c-1.6,0.7-2.5,2.2-3.7,3.4c1-1.5,2.2-2.8,3-4.4
		c0.3-0.5,0.6-1.1,1-1.5c2.1-2.3,3.1-4.8,2-7.9c-0.9,0-0.1,0.9-0.6,1.1c-0.3,1.2-0.5,2.3-1.3,3.3c-1.3,1.6-2.7,3.2-4.1,4.7
		c-0.5,0.6-0.8,0.2-1.1-0.2c-1-1.3-1.8-2.6-2.1-4.2c-0.2-1.3-0.2-2.5-0.1-3.8c0-0.2,0-0.5-0.3-0.6c-0.4-0.1-0.4,0.2-0.4,0.4
		c-0.1,3.1-0.5,6.3,1.7,9c0.6,0.8,0.8,1.6,0.2,2.6c-1,1.7-1.6,3.6-1.8,5.5c0.2,0.3,0,0.6,0.1,0.9c0.4,1.4,0.1,3.1,1.6,4.1
		c0.5,0.4,0.9,0.3,1.3-0.1c0.7-0.7,0.8-1.6,0.4-2.4c-0.6-1.2-0.5-2.3,0.2-3.3C32.1,578.7,34,576.7,36.4,575.2z M342.2,581.7
		c0.2-1.1,0.4-2.3,0.6-3.4c0.5-2.5-0.4-4.9-2.8-6.4c-0.2,0.7,0,1.3,0.2,1.9c0.6,1.7,0.7,3.5,0.3,5.4c-0.6,2.6-1.8,4.7-3.8,6.4
		c-1.6,1.3-1.7,3.1-1.2,4.8c0.5,1.9,1.7,3.4,3.7,3.9c0.6,0.2,1.3,0,1.6-0.6c0.3-0.7-0.4-0.9-0.9-1.1c-2.2-1.1-2.9-4.4-1.3-6.2
		c0.5-0.5,1-1.1,1.5,0c0.3,0.7,0.9,1.2,1.3,1.8c1.4,2.1,1.8,4.2,0.5,6.5c-0.7,1.3-1.7,2.5-2.4,3.8c-0.3,0.4-0.5,1,0.1,1.3
		c0.4,0.3,1,0.2,1.4-0.2c0.2-0.2,0.5-0.4,0.6-0.7c0.9-1.8,2.1-3.4,2.7-5.3c0.9-2.8,1.1-5.7-1.1-8.1
		C342.2,584.3,341.9,583.1,342.2,581.7z M45.6,482.4c-3.6-2.9-6-6.7-7.9-10.9c-0.3-0.6-0.7-1.4-1.3-1.1c-0.7,0.4-0.2,1.1,0.2,1.7
		c2.5,3.8,4.9,7.6,7.6,11.3c1.9,2.6,5.6,4.4,9.2,2.3c1.9-1.1,2.8-2.6,2.4-4.5c-1,0.4-1.9,1-2.6,1.9c-1.4,1.9-3,1.7-4.1-0.4
		c-0.2-0.5-0.4-0.9-0.4-1.4c-0.3-2.1,0.3-4,1.3-5.8c0.6-1.1,0.8-2.3,1-3.5c0.1-0.9-0.4-1.6-1.2-1.8c-0.9-0.2-0.8,0.8-0.9,1.3
		c-0.5,1.4-0.7,2.9-1.1,4.4c-0.1,0.3-0.1,0.6-0.5,0.6c-0.5,0.1-0.4-0.4-0.5-0.6c-0.6-1.1-1.2-2.2-1.7-3.3c-1-2.1-2-4.3-3.7-6
		c-0.2,0.1-0.3,0.1-0.5,0.2c2.1,5.5,4.3,11,6.5,16.6c-0.6-0.2-0.9-0.3-1.1-0.5C45.9,482.8,45.7,482.6,45.6,482.4z M66.2,662
		c-1.2-2.4-2.8-4.2-5.1-5.5c-0.4,0.6,0.2,0.9,0.2,1.3c0.1,0.5,0.2,1.1-0.5,1.3c-1.4,0.4-2.7,1.6-4.2,0.5c-0.1-0.1-0.3,0.1-0.6,0.3
		c2.4,2.5,4.7,4.9,6.9,7.4c0.9,1,2.1,1.9,2.5,3.3c0.8,2.7,2.7,4.2,5.1,5.6c0-0.3,0-0.5,0-0.6C69.4,670.9,68.3,666.3,66.2,662z
		 M348.4,541.4c-2-3.4-3.4-7,0.3-10.3c0.4-0.4,0.5-0.9-0.1-1.3c-0.5-0.3-1-0.4-1.4,0.2c-1.1,1.5-2.1,2.9-2.2,4.9
		c-0.3,4.5-0.1,9,0.3,13.5c0.3,3.4,0.5,6.9-0.2,10.3c-0.8,1.9-1.5,3.8-2.8,5.3c-0.3,0.4-0.9,0.8-0.3,1.2c0.5,0.4,0.9-0.1,1.2-0.6
		c0.9-1.6,1.8-3.2,2.2-5c1.7-4.9,2.4-9.9,1.3-15c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.6,0.4-0.7c0.3-0.1,0.3,0.3,0.5,0.5
		c1.2,1.5,1.5,3.3,1.7,5.2c0.3,2.8-0.3,5.5-1,8.2c-0.3,0.1-0.4,0.4-0.5,0.7c-0.3,1.9-0.4,3.7-1.2,5.5c0,0.2-0.1,0.4-0.1,0.5
		c-1.1,2-2.4,3.8-4.3,5.2c-0.4,0.2-0.3,0.6-0.3,1l0,0c0.6,1.1,1.1,0.7,1.6,0c1-1.3,2-2.5,3.2-4.1c0,1.8,0.3,3,1.5,4.2
		c0-0.8,0.8-1.3,0-1.9h0c-0.1-0.3-0.2-0.6-0.4-0.9l0,0c0-1.9-0.1-3.8,1-5.5c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.5,0.4-1,0.6-1.5
		C351.9,553.6,351.9,547.4,348.4,541.4z M101.5,382.6c2.1,5.6,6.2,8.7,12,10c2.4,0.5,4.5,1.6,6.4,3.2c3,2.4,4.4,5.8,5.7,9.3
		c0.2-3.9-1.6-7.1-4.4-9.6c-2-1.8-3.8-3.7-4.2-6.5c-0.2-1-0.7-1.9-1.5-2.6c-1.8-1.5-3.5-3-2.9-5.8c-1.4,0.5-0.5,2.1-1.7,2.4
		c-0.4-0.6-0.8-1.1-1.1-1.8c-0.4-0.8-0.9-1.6-1.4-2.3c-0.3-0.3-0.7-0.9-1.2-0.6c-0.5,0.3-0.1,0.9,0,1.2c0.8,2.2,1.9,4.3,3.8,5.6
		c1.3,0.9,2.6,1.8,3.8,2.8c1,0.9,1.8,1.9,1.3,3.6c-0.9-0.6-1.7-1.2-2.5-1.8c-2.4-1.7-4.9-3.5-6.3-6.2c-0.7-1.2-1-2.6-1.7-3.8
		c-0.2-0.4-0.6-0.9-1.1-0.7c-0.6,0.2-0.4,0.8-0.3,1.2c1.3,3.6,2.9,6.9,6.6,8.8c1,0.5,2.1,0.9,2.8,2.2c-0.9-0.2-1.7-0.2-2.4-0.4
		c-1.7-0.6-3.7-0.6-4.9-2.1c-1.1-1.4-2-3-3.1-4.4c-0.9-1.2-1-2.6-1.7-3.8c-0.2-0.3-0.4-0.7-0.8-0.5c-0.5,0.2-0.3,0.6-0.2,0.9
		C100.9,381.4,101.3,382,101.5,382.6z M318.7,592.2c0.6,0.6,1,0.3,1.4-0.3c1.5-1.9,2.6-4.1,3.4-6.3c1-2.9,1.9-5.8,4.1-8.1
		c3.7-3.7,3.7-8.1,2.7-12.7c-0.1-0.3-0.1-0.7-0.5-0.6c-0.4,0.1-0.2,0.5-0.2,0.8c0,1.8,0,3.5,0,5.3c-0.1,2.2-1,4-2.7,5.4
		c-0.4-0.4-0.3-0.8-0.2-1.2c0.4-1.9,0.1-3.6-0.6-5.3c-0.8-2-0.4-3.9,0.6-5.6c0.4-0.6,0.7-1.3,0.9-2.1c-2.9,1.5-4,3.7-2.9,6.5
		c0.8,2.4,0.8,4.7,0,7.1c-0.4-0.5-0.5-1-0.7-1.5c-0.5-1.5-0.8-3-2.1-4c-0.3-0.2-0.5-0.7-0.9-0.4c-0.3,0.2,0,0.6,0.1,1
		c0.8,1.8,1.5,3.6,1.8,5.6c0.8,4.5-0.1,8.6-2.6,12.4c-0.6,0.9-1.2,1.7-1.8,2.6C317.8,591.3,318.1,591.7,318.7,592.2z M98,504.8
		c-2-1.8-4.2-3.3-6.3-4.8c-1.5-1.1-2.9-1.7-4.7-0.4c2.3,1.5,4.5,2.9,6.7,4.4c2.9,1.9,4.7,4.6,4.9,8.1c0,0.4,0.1,1.1-0.3,1.2
		c-0.4,0.1-0.7-0.4-1-0.7c-2.6-2.9-5.8-4.4-9.7-4.1c-0.4,0-0.9-0.3-1,0.3c-0.1,0.7,0.5,0.6,0.9,0.7c2.2,0.7,4.6,0.8,6.6,2.1
		c2.8,1.7,4.5,4.1,4.9,7.4c0.1,0.9,0.6,1.7,1.5,1.7c0.6,0,0.7-0.9,0.7-1.6c0-1.9,0.8-3.5,1.8-5c0.4-0.6,0.7-1.3,1.1-2
		c0.3-0.5,0.3-1.2-0.2-1.5c-0.5-0.3-0.9,0.2-1.2,0.6c-0.4,0.5-0.8,1-1.2,1.6c-0.6-0.8-0.6-1.7-0.7-2.5
		C100.5,508,99.6,506.3,98,504.8z M309.6,648.1c-0.5,0.2-1.2,0.4-1.6,0c-0.5-0.4,0-1,0.2-1.5c1.2-2.6,0.2-4.9-0.8-7.2
		c-0.1-0.2-0.3-0.3-0.6-0.1c0.1,0.7,0.2,1.4,0.4,2.1c0.5,2.3-0.1,4.2-1.7,5.8c-2.3,2.3-5.2,3.4-8.5,3.8c-0.8-0.4-1.7,0.5-2.5-0.1
		c0,0,0,0,0,0c1.6-1.1,3.4-2.1,4.9-3.4c2.3-2,2.8-4.5,1.3-7.2c-0.5-1-0.5-1-1.4-1.2c0.9,3.4,1,6.5-2.4,8.7c-0.7,0.5-1.5,0.9-2.3,1.3
		c-2.6,1.2-5.2,2.3-6.5,5.2c-0.5,1-1,2-1.6,2.9c-0.4,0.6-0.7,1.3,0.1,1.7c0.6,0.3,0.9-0.6,1.2-1c0.1-0.2,0.1-0.4,0.1-0.6
		c0.8-1.7,2-3,3.8-3.6c2.6-0.8,5.4-0.7,8-1.7c2.9-1.1,6-1.7,9-2.6c1.2-0.3,2.5-0.3,3.4-1.4C311.2,647.3,310.4,647.8,309.6,648.1z
		 M85.6,490.2c-0.5-0.1-0.4-0.5-0.5-0.8c-0.2-0.8-0.3-1.7-0.5-2.5c-0.9-4-2.5-7.6-5.4-10.6c-0.5-0.5-1-1-1.7-0.3
		c-0.7,0.7,0.1,1.2,0.4,1.5c1,1.1,1.8,2.4,2.6,3.7c1.7,3.1,2.1,6.7,3.6,9.9c0.9,2.1,2.1,3.9,4.2,4.9c0.6,0.3,1.3,0.7,1.8,0
		c0.4-0.6,0.2-1.4-0.3-2c-0.2-0.3-0.5-0.5-0.7-0.8c-0.8-0.9-1.4-1.8-1.4-3.2c0.2-3.4,1.6-6,4.4-7.9c1.3-0.9,3-1.4,4.1-2.7
		c0.5-0.5,1-1.1,0.5-1.7c-0.5-0.5-1.1-0.1-1.7,0.4c-1.5,1.3-3.2,2.4-4.7,3.6c-0.4-0.3-0.2-0.5-0.2-0.7c0.7-1.6,1.4-3.2,2-4.9
		c0.2-0.4,0.4-0.9-0.1-1.2c-0.3-0.2-0.8-0.2-1.1,0.2c-0.2,0.3-0.4,0.7-0.5,1.1c-1.5,4.4-3,8.9-4.5,13.3
		C86,489.7,86.3,490.3,85.6,490.2z M223.4,494.5c-0.5-0.4-1-0.7-1.4-1.1c-2.8-3.1-6.1-5-10.2-5.7c-3.6-0.7-6.4-2.6-8.8-5.3
		c-0.6-0.7-1.3-1.4-2.1-2c-0.3-0.2-0.6-0.5-1-0.1c-0.4,0.3-0.4,0.7-0.1,1c0.3,0.3,0.5,0.6,0.7,0.9c1,1.1,2.3,1.9,2.7,3.4
		c-0.8,0.3-1.4,0-2-0.3c-1.2-0.6-2.3-1.2-3.5-1.8c-0.4-0.2-1-1-1.4-0.3c-0.3,0.5,0.4,0.9,0.8,1.3c0.2,0.2,0.4,0.2,0.6,0.3
		c4.4,2.5,9.4,3.2,14.1,4.5c1.9,0.5,4,0.8,5.7,2.7c-3.8-0.6-7.2-1-10.7-1c-0.6,0-1.4,0.1-1.4,0.8c0.1,0.6,0.9,0.2,1.3,0.2
		c3.8-0.3,7.4,0.2,10.9,1.8c1.4,0.6,2.6,1.5,3.7,2.5c0.7,0.7,1.6,1,2.4,0.1C224.8,495.6,224.1,495,223.4,494.5z M233.1,505
		c0-0.4-0.2-0.7-0.6-0.8c-0.4-0.2-0.4,0.3-0.6,0.5c-0.7,0.4-0.7,1.2-1,1.8c-0.3,0.7-0.7,0.9-1.4,0.5c-1.2-0.6-2.5-1-3.7-1.6
		c-2.7-1.4-5.5-1.6-8.4-1.4c-0.3,0-0.8-0.1-0.8,0.4c0,0.6,0.5,0.4,0.8,0.4c2-0.1,4,0.1,5.9,0.9c3.4,1.5,6.3,3.5,7.5,7.4
		c0.3,1.1,0.1,1.5-1,1.3c-2-0.5-4-1-6-1.6c-3-1-5.2-3-6.5-5.9c-0.7,0.5-0.3,1-0.2,1.5c0.8,3,3,4.9,5.8,5.5c3.9,0.9,7.1,2.6,9.8,5.5
		c0.3,0.3,0.7,0.9,1.3,0.5c0.5-0.3,0.8-0.8,0.8-1.4c0-0.5,0.1-1.1-0.1-1.5c-0.6-1.7-1.3-3.5-2.1-5.1c-0.9-1.8-1.1-3.4,0-5.1
		C233,506.2,233.1,505.6,233.1,505z M40.4,437.2c-0.5,1.1-1.4,1.9-2.2,2.8c-3,3-3.1,4.2-0.5,7.4c1.8,2.2,1.5,4.6,1.2,7.4
		c-2-2.9-3.8-5.6-5.7-8.2c-0.1-0.2-0.1-0.7-0.5-0.6c-0.4,0.1-0.5,0.5-0.5,0.8c-0.2,2,0.6,3.7,2.2,4.8c2.1,1.6,3,3.7,2.8,6.3
		c-0.2,2,0.3,4,0.5,6c0.1,0.7,0.6,1.3,1.3,1c0.9-0.3,0.2-0.9,0-1.3c-0.6-1.4-0.6-2.8,0.2-4.1c0.4-0.7,1-1.4,1.1-2.1
		c0.5-3.8,1-7.7-2.2-10.8c-1.2-1.2-1.1-2.8-0.1-4.1c0.5-0.7,1.2-1.3,1.8-1.8c0.8-0.7,1.8-1.3,2.3-2.3c1.4-2.6-0.7-7.2-3.5-8.1
		c-0.2-0.1-0.5,0-0.7,0.4c0.6,0.6,1.2,1.3,1.8,2C41.1,434.2,41.2,435.4,40.4,437.2z M232.3,456.4c0.9-0.9,2.2-1.5,2.7-2.8
		c1-2.3,0.3-4.6-2.3-6.9c-0.5-0.4-1-1.4-1.7-0.7c-0.4,0.4,0.5,1,0.9,1.4c2.3,2.4,2.3,4,0.2,6.6c-0.5,0.6-1,1.1-1.5,1.6
		c-2.5,2.6-2.7,4.3-0.3,7c1.9,2.2,1.6,4.6,1.1,7.2c-1.9-2.8-3.9-5.7-5.9-8.6c-0.7,0.9-0.7,1.7-0.5,2.6c0.3,1.4,1,2.5,2.2,3.4
		c2.1,1.5,3,3.5,2.8,6c-0.1,0.8-0.1,1.6,0,2.4c0.1,1.3,0.2,2.6,0.5,3.9c0.1,0.7,0.6,1.3,1.4,1c0.8-0.3,0.2-0.8,0-1.2
		c-0.6-1.4-0.6-2.8,0.2-4.2c0.4-0.7,0.9-1.3,1.1-2c0.7-3.9,1-7.8-2.2-10.9c-0.7-0.7-0.8-1.6-0.7-2.5
		C230.3,458.2,231.3,457.3,232.3,456.4z M103.5,544c-0.2,1,0.3,1.7,1.3,1.9c1.1,0.2,1.6-0.5,1.7-1.4c0.2-1,0.3-2,0.4-3
		c0.3-2.5,1.1-4.8,1.1-7.3c0.2-4.9-0.9-9.6-2.1-14.3c-0.2-1-0.4-2.1-1.7-2.7c-0.2,3.8,1.8,7.3,1.4,11.1c-0.7-0.4-1.1-0.8-1.3-1.4
		c-0.3-0.7-0.6-1.3-0.9-1.9c-0.3-0.7-0.7-1.2-1.3-0.8c-0.6,0.3-0.1,0.9,0.1,1.3c0.3,0.5,0.6,0.9,0.9,1.4c1.2,2,2.5,4,2,6.4
		C104.3,536.8,104.2,540.5,103.5,544z M255.4,392.6c1.9,3.6,2,7.2-0.3,10.7c-0.8,1.3-2.2,2.2-2.8,3.6c0.7,0.4,1.1,0,1.6-0.3
		c1.6-1.1,2.5-2.7,3-4.6c0.4-1.4,1-2.8,2.5-3.4c1-0.4,1.9-0.9,2.6-1.6c1.2-1.1,2.6-1.4,4.2-1.4c1.1,0.1,2.2,0.3,3.2,0
		c1.4-0.4,3.2-0.4,3.9-2.3c-1.9-0.1-3.5,1.6-5.5,0.7c0.6-1.2,2.4-1.3,2.6-2.8c-2.7,3-7.1,2.3-10.1,5.4c0.1-1.6,0.1-2.7,0.3-3.8
		c0.2-1.2,0.3-2.3,0.3-3.5c-0.1-1.5-0.4-2.9-1.6-3.9c-0.3-0.3-0.6-0.5-1-0.3c-0.5,0.2-0.1,0.6,0,0.9c0.3,1.1,0.7,2.1,1,3.1
		c0.7,2.3,0.3,4.7-1,6.5c-0.4-0.2-0.6-0.5-0.5-1c0.1-1.5-0.7-2.6-1.4-3.8c-1.1-1.6-2.2-3.3-2.7-5.2c-0.3-1.1,0-2.4-0.6-3.5
		c-0.8,0.3-0.7,1-0.6,1.6C253,386.9,253.9,389.8,255.4,392.6z M251,423.8c1.2-1.7,2.5-3.3,3.7-4.9c0.4-0.6,1-1.2,0-1.8
		c-1-0.6-1.4,0-1.8,0.7c-0.1,0.2-0.2,0.4-0.4,0.7c-1,2.2-2,4.3-3,6.5c-0.2,0.4-0.4,0.6-0.8,0.6c-0.6-3.2-1.1-6.5-3.6-8.9
		c-1-0.9-2.1-1.4-3.3-1.5c-0.5-0.1-1.1,0-1.3,0.5c-0.3,0.5,0.2,0.8,0.6,1.1c0.9,0.7,1.9,1.3,2.8,1.9c0,0.1,0,0.3,0,0.4
		c-3.2-0.4-5.7,1.2-8.4,2.4c-0.6,0.3-1.4,1.1-0.9,1.8c0.5,0.7,1.3,0.2,1.8-0.2c2.1-1.4,4.5-2,6.9-2.3c2.4-0.3,2.9,0.2,3,2.6
		c0.1,2.4,0.4,4.7,1.3,7c0.3,0.7,0.3,1.5,0.9,2c0.3,0.3,0.7,0.5,1.1,0.3c0.4-0.2,0-0.7-0.1-0.9c-0.9-1.6-0.8-3.2-0.1-5
		C249.8,425.8,250.3,424.8,251,423.8z M333.4,584.3c1.5-0.8,3.1-1.7,3.8-3.4c1.5-3.7,1.3-7.5,0.2-11.3c-0.2-0.6,0.2-1.7-0.9-1.7
		c-0.8,0-0.9,0.9-1.2,1.5c-2.7,4-3.8,8.9-7.8,12c-0.2,0.2-0.4,0.4-0.2,0.6c0.1,0.1,0.4,0.2,0.5,0.2c0.5-0.2,1-0.4,1.3-0.7
		c1.4-1.1,2.5-2.5,3.5-4c1.1-1.8,1.6-3.8,2.9-5.5c0.6,0.6,0.7,1.3,0.7,2c0,3.5-1.3,6.3-3.9,8.6c-1.5,1.3-3.3,2.3-4.6,3.8
		c-2.8,2.8-3.1,6.2-0.9,9.9c0.3,0.6,0.7,1.2,1.5,0.7c0.7-0.4,0.9-1,0.4-1.8c-1.7-2.4-1.4-4.8,0.1-7.2c0.5-0.5,0.8-0.6,1.4,0
		c0.9,1,1.3,2.3,2.3,3.3c0.5-0.8,0.2-1.3-0.2-1.8C330.5,587.1,330.9,585.7,333.4,584.3z M170.7,512.5c0.5-0.2,0.2-0.8,0.3-1.2
		c0-0.4-0.2-0.7-0.4-1.1c-1.5-3-4.1-4.5-7.2-5.3c-2.8-0.8-4-2.9-3.5-5.7c0.2-1.2,0.9-2.2,1.1-3.4c0.1-0.4,0.2-1-0.2-1.2
		c-0.6-0.2-1,0.3-1.3,0.8c-0.1,0.2-0.2,0.4-0.2,0.6c0,1.2-0.7,2.2-1,3.3c-0.1,0.3,0,0.6-0.4,0.6c-0.6,0-0.4-0.4-0.5-0.8
		c-0.3-1.4-0.5-2.9-1-4.3c-0.2-0.7-0.5-2-1.8-1c-0.5,0.6-0.3,1.3,0,1.9c0.5,2.1,1.1,4.2,1.7,6.2c0.5,1.6,1.4,2.8,2.8,3.5
		c3.7,1.8,7.3,3.7,10.1,6.8C169.6,512.7,170.2,512.7,170.7,512.5z M60.3,576.4c0.2,0.3,0.5,0.2,0.8,0.1c2.9-1,5.4-0.3,7.4,2.1
		c0.4,0.5,0.7,1.1,1.4,0.6c0.7-0.5,0.4-1.2,0-1.7c-2.7-4.2-4.8-8.6-6.8-13.2c-1.1-2.5-3.2-4.2-5.7-5.3c-0.7-0.3-1.3,0-1.5,0.6
		c-0.3,0.7,0.5,0.6,0.9,0.7c1.9,0.6,3.6,1.5,4.8,3.2c1.7,2.4,2.2,5.4,3.2,8.1c0.3,0.7-0.4,0.5-0.8,0.4c-1.5-0.4-2.7-1.2-3.8-2.4
		c-1.2-1.4-1.9-3.2-2.7-4.8c-0.8-1.4-1.6-2.7-3.3-3.2c-0.6-0.2-1.2-0.1-1.4,0.5c-0.2,0.5,0.2,0.8,0.7,1c1.7,0.6,2.6,1.9,3.3,3.4
		c0.9,1.9,1.6,4,3.3,5.5c1,0.9,2.4,1.1,3.7,2c-1.2,0.6-2.2,1.1-3.3,1.6C60.4,575.7,60.1,575.9,60.3,576.4z M280.6,503
		c-1.2,0.8-2.5,1.5-3.2,2.8c-0.8,1.3,0.1,2.1,1,2.9c0.9,0.8,1.5,1,2.4,0c1-1.1,2.4-1.8,3.9-2c2.4-0.3,4.7-0.5,7.1-0.2
		c0.7,0.1,1.5,0.1,2-0.6c-0.7-0.6-1.6-0.4-2.5-0.8c0.2-0.3,0.3-0.4,0.5-0.5c0.7-0.5,1.5-0.9,1.8-1.7c0.3-0.7,0-1.3-0.5-1.6
		c-0.6-0.3-0.6,0.4-0.8,0.7c-1,1.3-2.2,2.4-3.9,2.6c-2.3,0.2-4.6,0.7-6.9,1.4c-0.2-0.6,0.1-0.9,0.5-1.1c1.3-0.9,2.6-1.7,3.8-2.7
		c2.7-2.3,3.3-6.9,1.3-9.5c-0.6,0.3-0.5,0.8-0.4,1.3c0.1,1,0,2-0.3,3C285.4,499.9,283,501.5,280.6,503z M252.7,487.5
		c2.2-0.3,4.2-1.3,6.2-2.1c0.7-0.3,0.9-1,0.7-1.3c-0.3-0.5-0.8-0.2-1.2,0.2c-0.3,0.3-0.7,0.4-1,0.6c-1.7,1.1-3.7,0.9-5.6,1.3
		c-2.4,0.4-4.8,0.7-6.3,2.9c-1.3,1.9-1.5,4-1.5,6.2c0,0.6-0.5,1.4,0.2,1.7c0.8,0.3,1.7-0.1,2.4-0.6c0.3-0.3,0.5-0.8,0.7-1.1
		c0.3-0.6,0.7-1.2,1.3-1.5c2.5-1.2,5-2.3,7.8-2.5c1.9-0.1,3.8-0.4,5.7-0.7c0.4-0.1,1-0.2,0.8-0.8c-0.1-0.5-0.7-0.3-1-0.3
		c-2.5,0.1-5,0.2-7.4,0.3c-2.3,0.1-4.4,0.7-6,2.5c-0.4,0.4-0.9,0.9-1.6,0.9c-0.2-0.5-0.2-1,0-1.4
		C248.4,489.6,250.1,487.9,252.7,487.5z M174.4,489.8c-1.3-1.3-2.8-1.9-4.7-1.5c-0.2,0.1-0.5,0-0.6,0.3c0,0.3,0.2,0.4,0.4,0.6
		c0.7,0.4,1.3,0.7,2,1c3.1,1.7,5.5,4,6,7.7c0.2,1.1,0,2.2,0.1,3.3c0,0.6,0,1.4,0.7,1.5c0.7,0.1,0.8-0.6,1-1.1
		c0.8-1.7,0.6-3.5,0.5-5.2c-0.1-2.9-0.2-5.9,0.9-8.7c0.5-1.2,0.8-2.5,0.6-3.8c0-0.4,0-0.9-0.6-0.9c-0.5,0-0.4,0.5-0.5,0.8
		c-0.5,1-0.4,2.3-1.3,3.6c-0.7-2.3-1.3-4.3-1.8-6.3c-0.2-0.7-0.7-1.1-1.2-1.1c-0.5,0.1-0.5,0.8-0.3,1.3c0.4,1.5,0.9,3,1.2,4.5
		c0.6,2.7,1.6,5.3,1.1,8.3C176.9,492.5,175.7,491.1,174.4,489.8z M154.5,578.2c-0.7,1.1-0.3,2.3,0,3.4c0.2,2.1,1.1,3.9,2.3,5.6
		c2.4,3.4,6.5,5.1,9,8.5c0.3,0.4,0.8,0.2,1.2-0.1c1.1-0.8,1.2-2.2,0.1-3.1c-2.3-1.7-3.5-4-4.5-6.5c-0.5-1.3-0.6-2.8-1.7-4.2
		c-0.4,1.6-0.3,3.1-0.5,4.5c-0.1,0.4,0.2,0.9-0.4,1c-0.4,0-0.7-0.4-0.9-0.7c-0.3-0.7-0.7-1.4-1-2.2c-0.6-1.8-1.2-3.6-2-5.4
		C155.9,578.7,155.8,577.2,154.5,578.2z M332.9,609.9c-1.3-0.5-2.6-1-4.1-1.7c1.2-0.5,2-0.9,3-1.1c0.8-0.2,1.5-0.5,1.9-1.3
		c0.2-0.4,0.5-1,0.1-1.4c-0.4-0.4-0.8,0.1-1.2,0.3c-1.3,0.8-2.6,1.6-4.2,1.5c-0.4,0-0.9,0.2-1-0.3c-0.1-0.4,0.4-0.5,0.6-0.7
		c1.3-1.4,3-2.4,4.2-3.9c1.2-1.5,1.5-4.2,0.6-5.7c-1.2,5.2-4.5,8.3-9.1,10.2c-2,0.8-4,1.5-6,2.2c-0.6,0.2-1.3,0.6-1.1,1.2
		c0.2,0.7,0.9,0.4,1.6,0.3c3.2-0.7,6.3-1.3,9.4,0.3c1.6,0.8,3.4,1.2,5.1,1.6c0.7,0.2,1.4,0.3,1.5-0.4
		C334.2,610.4,333.5,610.1,332.9,609.9z M217.6,513.5c-0.4-0.2-0.8,0.1-1,0.5c-0.6,1.2-1.2,2.5-1.9,3.7c-0.2,0.3-0.2,0.9-0.7,0.8
		c-0.5-0.1-0.9-0.5-1-1c-0.3-1.2,0-2.4,0.7-3.4c2.2-3.3,1.3-6.2-0.8-8.5c-1.6-1.8-4.9-2.8-6.6-2c-0.3,0.2-0.8,0.3-0.8,0.7
		c0,0.3,0.4,0.6,0.8,0.6c0.8,0.1,1.5,0.6,2.2,0.9c3.1,1.5,5.1,4.8,2.8,8.6c-1,1.6-1.4,3.9,1,5.7c1.5,1.1,3,2.4,5.2,2.3
		c-1.1-3.5-1.1-3.7,0-7.9C217.6,514.1,218.1,513.7,217.6,513.5z M301.7,586.4c-0.3,0.4-0.4,1.2,0.2,1.5c0.5,0.3,1.1,0.2,1.6-0.4
		c1-1.2,1.2-2.5,1-4c-0.1-1.1-0.1-2.1,0.2-3.2c0.5-1.8,1.4-3.2,2.7-4.4c0.5-0.4,1-0.9,0.8-1.6c-0.1-0.3-0.3-0.8-0.8-0.8
		c-0.7,0-1,0.5-1.3,1.1c-0.4,0.6-0.8,1.2-1.2,1.7c-0.2,0.2-0.2,0.6-0.6,0.5c-0.3-0.1-0.3-0.4-0.4-0.6c-1.3-3.5-0.4-6.9,2.5-9.4
		c1-0.9,2-1.8,3.1-2.6c0.5-0.4,0.5-0.8,0.3-1.2c-0.3-0.5-0.7-0.2-1.1,0c-1.2,0.9-2.4,1.8-3.5,2.8c-2.8,2.4-3.8,5.3-2.8,8.9
		c0.3,1.2,0.2,2.5,0.1,3.7c-1.1-2.3-1.5-4.8-2.6-7.2c-0.6,0.8-0.6,1.4-0.4,2c0.4,1.8,1,3.6,1.8,5.2
		C302.4,581.2,303.4,583.7,301.7,586.4z M201.7,516.8c1.1-0.6,2.2-1.2,3.2-1.9c1.2-0.8,2.6-1.3,3.6-2.4c0.5-0.6,0.7-1.3,0.1-1.9
		c-0.6-0.6-0.9,0.2-1.3,0.5c-1.4,1.1-2.7,2.2-4.3,2.9c-3.8,1.9-7.9,2.8-11.8,4.5c-2.5,1.1-4.8,2.3-5.9,4.9c-0.3,0.8-1.2,1.7,0,2.3
		c1,0.5,1.8,0.2,2.4-1c1.5-3.2,4.3-4.6,7.7-5.3c2.7-0.5,5.4-0.2,8.3-1c-0.7-0.5-1.2-0.7-1.8-0.8c-0.2,0-0.7,0.1-0.7-0.3
		C201.2,517,201.4,516.9,201.7,516.8z M308.3,551.4c1.3-0.8,2.7-1.2,4.1-1.9c0.6-0.3,1.3-0.7,1.3-1.5c0-0.3-0.3-0.5-0.6-0.6
		c-0.8-0.3-1.2,0.3-1.7,0.7c-0.5,0.4-0.9,0.9-1.7,1.1c0-2,0.2-3.9-0.9-5.7c-0.2-0.3-0.3-0.4-0.7-0.3c-0.3,0.1-0.3,0.4-0.3,0.6
		c0.1,0.3,0.2,0.7,0.3,1c1.1,2.9,0.7,4.7-2,6.8c-1.3,1.1-2.4,2.4-3,4c-0.3,0.9-0.3,2.1-1.4,2.5c-0.3,0.1-0.6-2.6-0.1-3.6
		c0.6-1.3,1.3-2.5,1.9-3.8c0.1-0.3,0.4-0.5,0.1-0.8c-0.5-0.4-0.6,0.2-0.7,0.4c-2.4,3-3.1,6.3-2.2,10.1c0.5,2.1,0.3,4-1,5.9
		c-1,1.4-2.5,2.2-3.7,3.5c1.4,1.3,2.4,1.1,3.5-0.4c1.1-1.5,1.4-3.4,2.3-5.1c1.2-2.4,3.5-3.8,5.2-5.8c0.4-0.4,1.1-1,0.5-1.5
		c-0.4-0.4-1.2-0.2-1.7,0.4c-0.8,1-1.7,1.8-2.9,2.9C303.7,556.4,305.2,553.4,308.3,551.4z M94.1,454.7c-1,2.2-2.1,4.2-3.4,6.2
		c-1.8,2.8-3.1,5.7-2.9,9c0.1,1.1-0.4,2.8,1.1,3.1c1.6,0.2,1.8-1.5,2.1-2.7c0.6-2.4,1.8-4.3,4-5.7c1-0.7,1.9-1.5,2.8-2.3
		c0.7-0.7,0.8-1.4,0.1-2.2c-0.8-0.9-1.4-0.2-1.9,0.2c-1.3,1.1-2.5,2.2-4.1,3.6C92.7,460.5,94.8,457.9,94.1,454.7z M190.6,393.5
		c-0.5,0.1-0.4,0.5-0.4,0.8c-0.3,1.9,0.6,3.4,1.9,4.7c2.8,2.6,4.7,5.5,4.4,9.5c0,0.5,0.1,1,0.7,1c0.5-0.1,0.6-0.6,0.5-1.1
		c-0.2-1.2-0.4-2.4-0.6-3.6c-0.2-1.8-0.8-3.6-0.4-5.4c0.6-3.2-0.1-5.9-2.5-8c-0.8-0.7-1.1-1.5-0.6-2.6c0.4-0.8,0.6-1.7,0.8-2.6
		c0.4-1.7,1.1-3.4,1.3-5.1c0-0.4,0.2-1-0.3-1.1c-0.5-0.1-0.7,0.5-0.9,0.9c-0.1,0.3-0.1,0.6-0.2,0.9c-0.5,2.1-0.7,4.3-1.5,6.6
		c-0.9-1.3-0.8-2.7-1.4-3.8c-0.2-0.3,0.1-1-0.5-0.9c-0.5,0.1-0.5,0.7-0.4,1.1c0.5,3,1.1,6,3,8.6c1.2,1.7,1.8,3.7,2.2,5.7
		c0,0.2,0,0.5-0.1,0.7c0,0.1-0.5,0.1-0.5,0c-1.4-1.8-3.5-3-3.8-5.5C191,393.8,191.2,393.4,190.6,393.5z M339,476.3
		c0.1,2.4,0.2,4.9-0.1,7.3c-0.2,1.3,0,2.6,0,3.9c0.1,2.9,1.6,5.3,2.1,8c0.1,0.6,0.7,1.1,1.4,0.8c0.8-0.3,1-0.9,0.7-1.7
		c-1-2.4-0.9-4.7,0.4-7c0.8-1.4,0.4-3.4-0.6-4c-0.2,1.3,0.2,2.5-0.5,3.5c-0.2,0.3-0.4,0.9-0.9,0.8c-0.7-0.1-0.4-0.8-0.4-1.1
		c0.1-2.5,0.2-5.1-0.1-7.6c-0.3-3.1-1.3-6-2.5-8.8c-0.2-0.4-0.5-1.1-1.2-0.9c-0.5,0.2-0.9,0.6-1,1.2c0,0.6,0,1.1,0.5,1.6
		C337.9,473.4,338.9,474.7,339,476.3z M203.9,398.5c0.7,1.4,1,2.9,0.9,4.5c0,0.5-0.2,1.3,0.6,1.3c0.7,0,1.1-0.6,1.1-1.3
		c0.1-1-0.2-2-0.4-3c-0.8-3.1-0.7-6.1,1.2-8.8c1.9-2.7,4.2-5.1,6.2-7.7c0.6-0.7,1.3-1.4,1.7-2.2c0.2-0.4,0.1-0.8-0.3-1.1
		c-0.5-0.4-0.6,0.2-0.8,0.4c-0.4,0.4-0.8,0.9-1.2,1.4c-0.2,0.3-0.5,0.7-0.8,0.6c-0.5-0.1-0.3-0.7-0.3-1.1c0-1.2,0.1-2.3-0.3-3.5
		c-0.2-0.7-0.1-2-1.1-1.9c-0.9,0.1-0.4,1.3-0.2,1.9c0.9,4.4-0.6,8-3.1,11.4c-1.3,1.7-2.4,3.6-2.5,5.8c-0.7,0-0.8-0.5-0.8-1
		c-0.3-2.3,0.1-4.4,1.3-6.5c1.2-2.2,0.8-3.8-1.2-5.4c0,0.4-0.1,0.6,0,0.8c1.4,2,0.8,4-0.1,5.9C202.2,392.3,202.3,395.4,203.9,398.5z
		 M227.7,518c-2.2-0.9-4.5-1.5-6.9-1.3c-0.5,0.1-1.2,0.3-1.2,0.8c0,0.5,0.6,0.6,1.2,0.7c1.2,0.1,2.4,0.1,3.6,0.4
		c2.5,0.5,6.1,2.5,6.7,5.7c0.2,1,0,2.1,0.1,3.2c0,0.4-0.1,1,0.5,1.1c0.5,0.1,0.9-0.2,1.2-0.5c0.4-0.5,0.8-1.1,1.1-1.6
		c2.9-4.7,5.4-9.7,9.1-13.9c0.4-0.4,1.4-0.9,0.6-1.7c-0.9-0.8-1.5,0.1-2.1,0.7c-0.1,0.1-0.2,0.4-0.3,0.5c-2.7,4-4.9,8.2-8.1,12
		C230.7,519.4,230.7,519.3,227.7,518z M180.1,407c-0.2-1-0.5-2.1-0.8-3.1c-0.2-0.5-0.4-1.4-0.9-1.2c-0.6,0.2-1.2,0.8-1.1,1.6
		c0.1,0.6,0,1.2,0.2,1.8c1.6,5.1,4.6,9.2,9.4,11.7c0.9,0.5,2,1.2,3.3,0.6c-0.5-0.6-0.9-1-1.5-1.3c-2.5-1.2-3.4-3.3-3.6-6
		c-0.3-2.9-0.8-5.8-1.2-8.7c-0.1-0.6-0.4-1.1-0.8-1.1c-0.7,0-0.8,0.7-0.8,1.2c0.1,1.3,0.3,2.5,0.4,3.8c0.2,1.7,0.4,3.5,0.6,5.6
		C181.5,410.3,180.5,408.8,180.1,407z M235.1,509.5c-0.4,1.6-0.3,3.1,0.9,4.7c0.2-0.4,0.3-0.6,0.4-0.9c0.1-0.2,0.2-0.4,0.3-0.7
		c1.9-3.8,4.4-7.2,8.4-9.1c0.7-0.3,1.4-0.5,2-0.9c0.6-0.4,1.4-0.8,0.8-1.7c-0.6-1-1.3-0.5-1.9,0c-0.8,0.6-1.5,1.3-2.3,1.9
		c-1.7,1.3-3.3,2.7-5.1,4.1c-0.2-0.9,0.2-1.4,0.5-1.9c1.2-2.8,1.7-5.7,0.7-8.7c-0.2-0.6-0.4-1.5-1.4-1.2c-0.7,0.3-0.8,1-0.6,1.8
		c0.5,1.8,0.4,3.6-0.1,5.4C237.1,504.7,235.7,506.9,235.1,509.5z M196.2,375.9c1.7,1.6,2.1,3.4,1.5,5.6c-0.4,1.4-0.8,2.7-1,4.1
		c-0.2,1-0.9,2.1-0.4,3.6c1.4-2.2,1.7-4.5,2.1-6.6c0.7-3.7,2.7-6.2,6.4-7.4c3.7-1.2,7.3-2.5,10-5.5c0.5-0.5,1.8-1,1.2-1.6
		c-0.6-0.7-1.1,0.6-1.7,1c-1.1,0.8-2,1.9-3.7,1.9c0.3-0.9,0.7-1.6,0.5-2.4c-0.5,0.1-0.7,0.4-0.9,0.8c-0.8,1.7-2.3,2.6-3.9,3.3
		c-1,0.4-1.4,0.1-1.6-1c-0.2-1.1-0.2-2.4-1-3.4c-0.9,0.6-0.8,1.5-0.6,2.3c0.5,2,0,3.6-2,4.5c-0.8,0.3-0.9,1.7-1.8,1.4
		c-0.8-0.3-1.4-1.1-2.2-1.5c-0.6-0.4-1.2-1.4-1.9-0.6C194.6,374.9,195.7,375.5,196.2,375.9z M101.2,495.9c-0.6,0.4-0.2,1,0.2,1.6
		c1.8,2.5,3,5.2,3.9,8.1c1,3.2,2.9,5.7,6.1,7.1c0.6,0.3,1.1,0.4,1.6-0.2c0.6-0.8,1.4-1.4,1.6-2.4c0-0.1-0.1-0.3-0.3-0.4
		c-2.2-1.5-2.8-3.9-3.5-6.3c-0.2-0.8-0.5-1.8-1.3-1.7c-0.9,0.1-0.6,1.2-0.5,2c0,0.3,0.2,0.7,0.2,1c0.3,1.4,0.8,2.7,0.7,4.3
		c-2.6-1-3-3.3-3.7-5.4c-0.8-2.5-2.1-4.9-3.3-7.3C102.4,495.8,101.8,495.4,101.2,495.9z M186.5,393.1c-0.8-2.3-0.9-4.8-2.8-6.5
		c-1.7-1.4-3.5-2.7-5.7-3.2c-0.5-0.1-1.4-0.4-1.5,0.5c-0.1,0.7,0.7,0.7,1.2,0.8c0.8,0.3,1.7,0.4,2.6,0.9c2.9,1.5,3.8,4.2,4.4,7.2
		c0.7,3.3,1.9,6.4,4.3,8.8c0.3,0.3,0.8,0.9,1.2,0.4c0.2-0.2,0-0.9-0.4-1.3c-2.6-2.1-3.2-5.6-1.1-8.6c1.2-1.8,0.9-4.3-0.6-5.8
		c-1.9-1.9-1.9-1.9-0.7-4.2c0.1-0.2,0.7-0.5,0.3-0.8c-0.4-0.4-0.7,0-1,0.3c-0.2,0.2-0.4,0.4-0.5,0.6c-0.7,1.1-1.3,1.7-2.1,0.1
		c-0.3-0.6-1-0.9-1.5-0.5c-0.6,0.5,0.1,1.2,0.4,1.5c1,0.9,2.1,1.7,3.1,2.6c2.7,2.4,2.9,4.6,0.7,7.6
		C186.6,393.3,186.5,393.2,186.5,393.1z M185.6,377.5c-1.1,0.5-2.4,0.6-3.5,1.8c2.7,0,5-0.6,7-1.9c3.3-2.2,4.9-5.6,5.9-9.3
		c0.2-0.7,0.5-1.9-0.3-2.1c-0.6-0.2-0.7,1.1-0.9,1.8c0,0,0,0.1,0,0.1c-0.8,2-1.4,4.1-3.2,5.4c-0.3,0.2-0.7,0.8-1.1,0.5
		c-0.4-0.3,0.1-0.7,0.2-1.1c0.4-1.4,0.9-2.7,0.3-4.5c-1.5,1.8-1.3,4.1-2.3,5.9c-1.8-1.5-2-3.4-1.5-5.5c0.2-0.9,0.4-1.9,0.6-2.8
		c0.1-0.5,0-1.1-0.5-1.2c-0.4-0.1-0.7,0.4-0.8,1c-0.3,2.1-0.6,4.2-0.8,6.3c-0.1,1.4,0.4,2.7,1.4,3.7
		C187,376.5,186.7,377.1,185.6,377.5z M51.3,583.8c0.1,0,0.2-0.1,0.3-0.1c2.6-0.6,5.2-0.5,7.3,1.4c2.3,2.1,4.9,3.8,8,4.4
		c0.5,0.1,1,0.3,1.3-0.3c0.6-1.2,0-3.5-1.2-4.1c-2.2-1.1-4-2.5-5.3-4.5c-0.3-0.5-0.8-1.1-1.4-0.6c-0.5,0.4-0.1,1,0.2,1.5
		c0.2,0.3,0.4,0.6,0.6,0.9c0.6,0.8,1.3,1.7,1.4,2.9c-1.5-0.3-2.4-1.2-3.4-1.9c-2.5-1.6-5.4-0.9-8.1-1.2c-0.6-0.1-1.3,0-1.8,0.6
		c-0.1,0.1,0,0.5,0.1,0.5C49.9,583.6,50.5,584,51.3,583.8z M314.3,450c-0.4,2.2-0.7,4.6-2.4,6.4c-0.3,0.3-0.2,0.6-0.1,0.8
		c0.2,0.2,0.5,0.2,0.8,0.2c0.9-0.1,1.2-0.9,1.6-1.4c2-2.6,4.3-4.1,7.7-4.2c1.7-0.1,3.6-0.3,4.8-1.8c-0.3-0.4-0.5-0.3-0.8-0.3
		c-3,0.5-6,1-8.9,1.6c-1,0.2-1.2,0-1.1-1c0.2-1.5,0.9-2.5,2.1-3.3c1.6-1,3.4-0.5,5.1-1c0.4-0.1,0.9,0.1,1-0.4
		c0.1-0.6-0.5-0.6-0.8-0.7C318.6,444,315.2,445,314.3,450z M223.8,402.3c-1.1-1.7-1.8-3.7-2.6-5.6c-0.9-2.2-3.6-3.5-5.7-2.6
		c0.8,0.8,1.9,0.9,2.8,1.5c2.2,1.6,3,4,3.8,6.3c1.4,3.8,5.6,5.7,9.1,5.5c0.3,0,0.8-0.1,0.9-0.4c0.2-0.4-0.2-0.7-0.4-1
		c-0.8-0.8-1.6-1.6-2.3-2.4c-1.8-2.1-2.9-4.4-3.4-7.1c-0.1-0.6-0.2-1.6-1-1.4c-0.8,0.1-0.8,1.1-0.7,1.8c0.1,1,0.4,2.1,1,3
		c0.9,1.5,2.3,2.8,2.9,4.5C226,404.6,225.1,404.2,223.8,402.3z M313.8,632.8c-2.5-1.4-5.2-1.7-7.8-0.1c-1.1,0.7-1.3,1.9-0.4,2.6
		c2.2,1.6,4.3,3.3,6.8,4.3c1.3,0.6,2.8,0.8,4.2-0.4c-3.2-1.7-6.2-3.3-9.3-5c0.2-0.4,0.4-0.6,0.8-0.7c1.7-0.5,3.3-0.2,4.9,0.4
		c1.8,0.7,3.5,1.5,5.3,2.1c1.8,0.7,2.7,0.3,3.9-1.9C318.9,635.5,316.4,634.3,313.8,632.8z M60,666.9c0.2,0.3,0.8,0.7,0.4,1.2
		c-0.4,0.5-0.8,0-1.2-0.2c-2.6-1.7-4.5-4-6.7-6.1c-2.4-2.2-4.6-4.6-6.6-7.1c0,0,0,0,0,0c-0.9-0.4-1.4-1.3-1.9-2.1
		c-2.3-2.5-4.1-5.4-6.1-8.1c-0.4-0.5-0.4-1.1,0-1.7c0.1-0.2,0.3-0.5,0.7-0.4c0.5,0.3,0.7,0.7,0.9,1.3c0.7,3,2.7,5.3,4.9,7.3
		c0.3,0.2,0.6,0.4,0.6,0.8c0,0,0,0,0,0c0,0,0,0,0,0c0.7,0.3,1.2,0.9,1.7,1.3c0,0,0,0,0,0c0,0,0,0,0,0c0.5,0.5,1,1,1.6,1.5
		c0,0,0,0,0,0c0.7-1,0.4-1.9-0.1-2.9c-0.2-0.3-0.6-0.6-0.6-1c-1.2-1-2.2-2.2-3.1-3.4c-0.5-0.7-0.6-1.7,0.2-2.1
		c0.7-0.4,1.4,0.2,1.8,0.9c0.7,1.3,1.4,2.7,2.1,4c0,0,0,0,0,0c0,0,0,0,0,0c1.1,1.8,2.1,3.7,2.7,5.8c0,0,0,0,0,0c0,0,0,0,0,0
		c0.1,0.2,0,0.5,0.3,0.6c0,0,0,0,0,0c1,0,0.9-0.7,0.9-1.4c0.1-1.9,0.3-3.8-0.3-5.6c0,0,0,0,0,0c0,0,0,0,0,0c-1-4.4-3.6-7.8-7.4-10.3
		c-2.5-1.7-5.1-2.6-8.2-1.9c-0.7,0.1-1.7,0.4-1.9-0.7c-0.1-1,0.8-1.1,1.6-1.3c0.2-0.1,0.5-0.1,0.7-0.1c2-0.2,2.1-0.3,1.6-2.3
		c-0.2-0.8,0-1.4,0.7-1.5c0.7-0.1,0.7,0.7,0.8,1.2c0.5,3,0.7,3.1,3.8,3.9c2,0.5,3.5,1.8,4.9,3.3c2.3,2.5,4.1,5.2,5.2,8.5
		c0,0,0,0,0,0c1,0.8,0.7,2,0.8,3c0.1,1.6,0.2,3.2-0.7,4.7c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,1.8,0,3.2,1.5,4.4
		C57,663.5,58.6,665.1,60,666.9z M332.8,524.9c1.1-1.3,1-1.7-0.5-2.6c-1.2,1.9-2.3,3.7-3.6,5.7c-0.5-1-0.7-1.9-0.8-2.7
		c-0.3-1.2-0.6-2.3-0.9-3.5c-0.8-2.5-3.1-4.3-5.3-4.1c-0.3,0-0.9-0.3-1,0.4c0,0.6,0.5,0.5,0.9,0.6c3,0.7,5,2.2,5.4,5.4
		c0.2,1.5,0.1,3,0.1,4.6c-0.1,0.4-0.1,0.9-0.3,1.3c-0.7,2.4-2.1,4.2-3.9,5.9c-2.6,2.5-3.1,5-1.3,7.7c0.6,1,1.6,2.6,2.7,1.9
		c1.3-0.9-0.1-2.1-0.7-3c-0.1-0.1-0.1-0.2-0.2-0.2c-1-1.3-1.1-2.5-0.2-4C326.2,533.6,329.2,529,332.8,524.9z M140.6,480.8
		c-0.4,0.2-0.8,0.5-1.2,0.9c-1.6,1.6-3.1,3.2-4.8,4.9c-0.6-2.1-1.2-3.6-2-4.4c-0.1,0.5-0.1,0.8-0.2,1.2c-0.7,3,0.7,6.3,3.3,7.9
		c0.4,0.2,0.7,0.7,1.2,0.4c0.4-0.2,0.1-0.7,0.1-1.1c0-1.6,0-3.1,0.9-4.5c1-1.5,2.5-2.5,3.7-3.8c0.3-0.3,1.3-0.6,0.7-1.2
		C142.1,480.9,141.4,480.4,140.6,480.8z M114.9,419.9c-0.8,0-1.7-0.1-2.1,0.9c0.4,0.7,0.2,1.4,0.2,2.1c0,3.2-0.1,6.5,0,9.7
		c0,2.5,0.9,4.8,1.8,7.1c0.2,0.5,0.6,1.3,1.3,1c0.6-0.2,0.5-1,0.3-1.6c-0.5-1.6-1.1-3.1-1.4-4.8c-0.8-4.4-0.1-8.3,3.7-11.1
		c0.5-0.4,1.4-0.5,1.6-1.6c-3.3,0.2-4.4,3-6.2,5.4c-0.2-2.6,0.1-4.6,0.9-6.6C115.2,420.3,115.1,419.9,114.9,419.9z M117,681.2
		c1.8,1.7,3.9,2.8,6.4,2.7c0.1-0.8,0.2-1.5,1.3-1.7c0.4-0.1,1-0.4,0.5-1c-1.1-0.3-2.1-0.7-3.2-0.9c-2-0.4-3.7-1.5-5.3-2.8
		c-1-0.2-1.5-1-2.3-1.5c-0.8-0.5-1.8-0.8-2.3-0.1c-0.5,0.6,0.1,1.4,0.7,1.9C114.1,679,115.5,680.2,117,681.2z M130.4,406
		c-0.4,0.1-0.5,0.5-0.4,0.9c0.2,1.5,0.1,3.1,0.7,4.6c0.2,0.6,0.4,1.2,0.7,1.8c1,2.3,3.1,4.1,3.6,6.7c0.5,3.9-0.6,7.7-0.8,12
		c1.7-3.1,2.4-6.1,1.9-9.3c-0.3-1.5-0.5-3-0.4-4.5l0,0c0.9-1.9,2.5-2.6,4.5-2.8c2-0.2,3.8-1,5.2-2.6c-2.8,0.6-5.7,0.8-8.2,2.5
		c-0.9,0.6-1.9,1.1-2.5-0.1c-1-2.4-2.3-4.7-3-7.2c-0.2-0.4-0.4-0.8-0.6-1.2C130.9,406.3,131,405.8,130.4,406z M142.5,373.8
		c-3.3,1-6.1,4-5.2,8c0.3,1.5,0.4,2.9-0.3,4.4c0.5-0.3,0.8-0.8,1.2-1.2c2.4-2.7,4.1-5.7,6.3-8.5c1-1.3,2.1-2.6,3.4-3.6
		c0.3-0.3,1-0.5,0.7-1c-0.4-0.6-0.8,0.1-1.1,0.2c-0.9,0.4-1.5,1.1-2.1,1.9c-2.1,2.8-4.3,5.6-6.6,8.5
		C137.3,378.5,139.9,376.3,142.5,373.8z M335.4,481.1c-1.5-1.1-2.4-2.6-3.1-4.2c-0.4-1-0.7-2.1-1.3-3c-1.2-2-3.1-3.5-4.2-5.6
		c-0.2-0.6-0.4-1.2-0.7-1.8c-0.8-1.7-1.4-3.5-2.4-5c-0.5-0.7-1.2-1-1.9-0.4c-0.7,0.6-0.1,1.1,0.3,1.7c1.6,2.4,3.8,4.3,4.7,7.2
		c0,0,0,0,0,0c-0.5,0.5-0.9,0.1-1.3-0.3c-0.7-0.6-1.4-1.2-2.2-1.8c-0.3-0.2-0.7-0.6-1.1-0.2c-0.5,0.5,0.2,0.8,0.4,1.1
		c0.1,0.2,0.4,0.3,0.6,0.4c1.8,1,3.4,2.2,4.9,3.6c2,2,3.1,4.5,4.4,6.9c0.5,0.9,1,2,2.2,2.4c0.3,0.1,0.8,0.4,1.1-0.1
		C336.1,481.6,335.7,481.3,335.4,481.1z M174.5,372.2c0-0.3,0.3-0.8-0.3-0.8c-0.6-0.1-0.5,0.4-0.5,0.8c0,1.9,0.3,3.7,0.7,5.6
		c0.3,1.2,0.3,2.2-0.6,3.2c-1.2,1.3-2.2,2.8-2.8,4.5c-0.6,1.7-0.9,3.5-0.8,5.3c0.1,1.9-0.2,3.8-1.2,5.6c-0.6,1.2-1.4,2.2-2.3,3.2
		c-0.4,0.4-1,0.8-0.6,1.3c0.4,0.4,1.1-0.1,1.4-0.4c1-0.9,2.1-1.3,3.3-1.7c0.6-0.2,1.1-0.5,1.6-0.8c0.2-0.1,0.4-0.2,0.3-0.4
		c-0.1-0.3-0.3-0.3-0.6-0.2c-0.7,0.3-1.5,0.7-2.4,0.5c0.9-1.8,1.3-3.6,1.2-5.5c-0.1-1.8-0.1-3.5,0.3-5.3c0,0,0,0,0,0c0,0,0,0,0,0
		c0.4-0.9,0.7-1.8,1.1-2.6c1.3-2.7,3.9-4.1,6.3-5.6c1.8-1.2,3.9-2.2,4.6-4.5c0.1-0.4,0.6-1.1,0-1.3c-0.5-0.2-0.6,0.6-0.7,0.9
		c-1,2.3-3,3.6-5.2,4.5c-0.9,0.4-1.5,0.2-1.9-0.8C174.7,375.8,174.5,374,174.5,372.2z M293.4,511c0.5-0.5,0.7-1,0.1-1.5
		c-0.5-0.5-0.9-0.8-1.5-0.1c-1.8,1.8-3.6,3.6-4.3,6.2c-0.4,0.9-0.5,1.9,0,2.8c0.1,0.1,0.2,0.2,0.3,0.3c0.4,0.5,0.6,1.2,1.4,0.9
		c0.8-0.3,0.5-1,0.5-1.6c0-1.3,0-2.5,0.6-3.7C291.3,512.9,292.4,512,293.4,511z M216.1,406.5c2,4,6,5.3,9.6,7.1
		c0.1,0.5,0.5,0.6,0.9,0.6v0c0,0,0,0,0,0c2,1.5,3.8,3.1,3.8,5.9c0,1.5-0.4,2.9-0.9,4.2c-0.2,0.6-0.4,1.5,0.3,1.9
		c0.6,0.3,1.2-0.3,1.6-0.8c1.1-1.4,1.8-2.9,1.6-4.6c-0.2-2.5,1.1-4.1,2.9-5.4c2.7-2,5.8-3.1,8.7-4.6c2.6-1.3,3.4-2.3,2.9-4.5
		c-3.7,4.9-9.7,6.1-14.3,9.4c-0.5-2.7-1-5.1-3.6-6.5c-1.2-0.7-2.5-1.2-3.8-1.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.4-0.5-0.9-0.7-1.5-0.6
		c0,0,0,0,0,0c0,0,0,0,0,0c-1.5-1-2.5-2.3-3.3-3.9c-0.7-1.4-1.5-2.8-2.3-4.2c-0.2-0.3-0.5-0.6-0.9-0.5c-0.5,0.1-0.3,0.6-0.3,0.9
		c0,1.3,0.4,2.5,0.7,3.7c0.9,2.9,2.9,4.7,5.4,4.9c0.3,0.5,0.7,0.6,1.2,0.6c0,0,0,0,0,0c0.9,0.5,1.8,1,2.8,1.5c0.7,1,1.4,2,0.9,3.3
		c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0h0l0,0c-1-0.8-2.1-1.5-3.4-1.8c-4.4-1.7-7.8-4.4-9.1-9.6C214.5,404.1,215.5,405.3,216.1,406.5z
		 M293.9,630.6c0.6,0,1.2,0.1,1.6-0.6c1.5-0.2,2.7-1.1,3.9-2c0.4-0.3,0.6-0.8,0.3-1.2c-0.3-0.4-0.7-0.2-1.1,0
		c-3.2,2.1-7,2.2-10.5,1.9c-2.7-0.2-5.3-0.8-8.1-0.6c-1.3,0.5-2.6,0.8-3.7,1.7c-0.1,0-0.2,0-0.2,0.1c-1,0.7-2.2,0.9-3.1,1.8
		c-0.9,0.5-1.7,1.1-1.5,2.2c0.2,1,1.1,0.5,1.8,0.5l0,0c1.2-0.5,2.6,0.1,3.7-0.8c1.4-0.3,2.8,0.3,4.2,0.3c0.8,0.4,1.6,0.8,2.4,1.2
		c0.8,0.6,2,0.8,2.5,0c0.6-0.8-0.5-1.3-1.2-1.6l0,0c-0.6-0.6-1.4-0.8-2.2-0.8c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1
		c-0.8-0.6-1.8-0.6-2.8-0.6c1.6-0.7,3.1-1.6,4.9-1.6C287.7,630.6,290.8,630.6,293.9,630.6z M324.7,506.3
		C324.7,506.3,324.7,506.3,324.7,506.3C324.7,506.3,324.7,506.3,324.7,506.3c-0.9,1.8-0.4,3.9-0.7,5.8c-0.7-1.3-1.3-2.4-1.4-3.8
		c-0.1-1.6-0.2-3.2,0-4.8c0-0.3,0.1-0.8-0.4-0.8c-0.6-0.1-0.5,0.4-0.5,0.7c-0.1,0.9-0.3,1.7-0.3,2.6c-0.1,3.5,0.3,6.8,3,9.4
		c2.2,2.1,4.6,4,5,7.4c0,0.3,0.1,0.5,0.4,0.5c0.3,0,0.4-0.3,0.5-0.5c0.9-1.5,0.5-3.6-1-5.3c-1.4-1.6-3-3-0.7-5.1
		c0.2-0.2,0.2-0.5,0-0.7c-0.3-0.3-0.5-0.1-0.6,0.1c-0.6,0.6-1.1,1.3-1.7,2.1c-0.4-0.7-0.7-1-0.9-1.5c-0.9-1.9-0.5-3.7,0.1-5.6
		c0.1-0.2,0.3-0.4,0.4-0.6c1-2.4,2.3-4.7,4.5-6.2c0.5-0.3,1.4-1,0.9-1.5c-0.6-0.6-1.3,0.2-1.8,0.8c-0.7,0.7-1.5,1.6-2.1-0.1
		c-0.2-0.4-0.5-0.8-0.9-0.6c-0.3,0.1-0.6,0.6-0.5,1c0.2,1.9-0.4,3.6-1,5.3C324.7,505.3,324.5,505.8,324.7,506.3z M130.8,401.3
		c-0.1,1.2,0.3,2,1.5,2.4l0,0c0.3-0.3,0.3-0.8,0.2-0.9c-1.2-1.2-0.4-2.4-0.1-3.6c1.5-3,3.7-5.3,7.1-6.4
		C135,392.2,131.1,396.2,130.8,401.3z M119.8,374.2c3-0.7,5.3,0.4,7.2,2.6c0.3,0.4,0.5,0.9,1.1,1c0.6,0.9,1.6,1.3,2.5,1.9
		c1.1,0.7,2.2,1.3,3,2.3c1.6,2.1,0.6,3.9-0.5,5.7c-0.2,0.3-0.7,0.7-0.3,1c0.4,0.3,0.6-0.2,0.9-0.5c1.3-1.5,1.7-3.2,1.2-5.1
		c-0.2-0.8-0.4-1.6-0.1-2.4c1.2-2.7,1.5-6.1,4.7-7.5c0.3-0.1,0.3-0.4,0.3-0.6c-0.1-0.4-0.4-0.2-0.6-0.1c-1.7,1-3.5,2.1-3.9,4.2
		c-0.1,0.5-0.2,0.9-0.8,0.8c-0.1-0.5-0.2-1-0.2-1.5c-0.6,0.6-0.3,1.6-1,2.2c-1.9-3-0.1-6.1-0.2-9.5c-2,3.4-2.2,6.6-1.9,10.1
		c-1.1-0.6-1.8-1-2.5-1.4c-1.7-0.8-1.9-1.6-1-3.3c0.4-0.7,0.7-1.4,0.8-2.6c-0.9,0.9-1.2,1.8-1.6,2.6c-0.4,0.8-0.7,1.1-1.6,0.2
		c-1.6-1.6-3.5-2.8-5.9-1.7c-0.6,0.3-1.6,0.4-1.4,1.3C118.2,374.7,119.2,374.4,119.8,374.2z M174.1,396.4c-0.2,0.2-0.7,0.3-0.6,0.8
		c2.3-0.4,6.3-4.3,6.5-6.5c-1.1,0.5-1.8,1.5-2.4,2.3c-1,1.2-1.4,0.8-1.9-0.2c-0.2-0.4-0.1-1.1-0.8-0.9c-0.6,0.2-0.3,0.9-0.3,1.3
		c0,0.2,0.1,0.4,0.2,0.6C175.8,395,175.1,395.7,174.1,396.4z M28.4,595.5c0.2-0.4-0.1-0.7-0.3-1c-1.3-0.3-2.2-1.3-3.3-1.9
		c-0.3-0.5-0.5-1-0.8-1.6c0-0.1,0.1-0.1,0.1-0.2c-0.3-2.1-0.9-4-2-5.8c-0.7-2.6-0.8-5.3-0.6-8c0.1-1.2,0.2-2.4,0.4-3.6
		c0.1-0.8,0.3-1.9-0.8-2c-0.9-0.1-0.9,1-0.9,1.8c0,0.7,0,1.4,0,2.1c0,1.2,0,2.5,0,3.7c-0.7,0-0.8-0.3-1-0.7
		c-1.4-2.8-3.2-5.3-5.6-7.3c-0.4-0.3-0.9-1.3-1.6-0.5c-0.6,0.7,0.1,1.2,0.6,1.7c0.1,0.1,0.3,0.2,0.5,0.4c3.4,3.2,5.4,7.2,6.3,11.7
		c0,0,0,0,0,0c-0.8,1.6-1.7,0.1-2.6,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.4-0.6-0.8-1.2-1.1-1.7c-0.9-1.4-1.6-2.8-2.3-4.3
		c-0.2-0.5-0.6-1.2-1.2-0.9c-0.4,0.2-0.8,0.9-0.5,1.6c0.3,0.6,0.4,1.3,0.9,1.9c1.1,1.6,2.1,3.2,3.1,4.9c1.1,1.1,1.9,2.4,2.7,3.6
		c0.5,0.6,0.9,1.4,1.5,2c0,0.1,0,0.1,0,0.2c0.5,0.9,0.7,2.1,2.1,2.1c0,0,0,0,0,0c0.1,0.8,0.8,1,1.3,1.6c0,0,0,0,0,0c0,0,0,0,0,0
		C25,596.1,26.7,596.9,28.4,595.5z M62.5,548.3c-0.4-0.4-0.8-0.6-1.3-0.2c-0.5,0.4-0.8,0.9-0.4,1.5c0.2,0.3,0.4,0.6,0.6,0.9
		c0.1,0,0.2,0,0.3,0.1c0.4,0.6,0.8,1.2,1.5,1.5c0,0,0,0,0,0c0.2,0.9,1,1.5,1.5,2.3c0.2,0.4,0.6,0.5,1,0.2c0.4-0.3,0.8-0.5,0.7-1.1
		c-0.1-0.4-0.3-0.7-0.4-1.1c-0.2-0.4-0.2-0.9-0.8-1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.2c-0.4-0.6-0.8-1.2-1.6-1.5l0,0v0
		c-0.3-0.1-0.2-0.4-0.2-0.6C63,548.8,62.8,548.5,62.5,548.3z M135.1,368.8c-0.4,1.9-0.8,3.6-0.5,5.4
		C135.3,372.5,136.4,370.9,135.1,368.8z M303.7,431.9c0.2,0.1,0.4,0.3,0.6,0.4c1.2,1.1,1.7,2.8,2.7,4c0.4,1.6,1,3.1,0.9,4.7
		c0,0.5,0.4,1.3-0.3,1.4c-0.6,0.1-0.7-0.6-1-1c-0.7-1.3-1.5-2.3-2.8-3.1c-2.1-1.4-4.6-2.5-5.8-5c-0.3-0.6-1-0.7-1.6-0.2
		c-0.5,0.4-0.3,1,0,1.5c1,1.8,2.5,3.1,4.3,4.2c1.4,0.8,2.8,1.6,3.7,3.1c1.9,3,2.6,6.1,1.9,9.6c-0.1,0.3-0.4,0.7,0.2,0.9
		c0.6,0.2,0.5-0.3,0.6-0.6c1.1-1.7,1.4-3.7,1.5-5.7c0.2-3.7,1.1-7.4-0.7-11c-0.4-1.1-0.9-2.1-1.8-2.8c-0.5-0.6-1-1.2-1.7-1.5
		c-0.3-0.1-0.8-0.3-1.1,0.2C303.2,431.4,303.4,431.7,303.7,431.9z M122,413.2c0.3-0.1,0.6-0.3,0.5-0.7c-0.1-0.5-0.6-0.2-0.9-0.2
		c-0.1,0.1-0.2,0.2-0.3,0.3C121.3,413.1,121.5,413.4,122,413.2z M60.2,645.8c-0.1-0.8-0.8-1.1-1.3-1.5c-0.6,0-0.9,0.5-0.5,0.9
		C58.9,645.5,59.2,646.7,60.2,645.8z M118.9,415.7c0.1-0.7,0.1-1.4-0.9-1.3l0,0c-1.1,1.1-1.1,1.3,0,1.9
		C118.3,416.1,118.6,415.9,118.9,415.7z M306.1,427.3c1,1.7,2.6,3.1,3.3,5c1.6,4.1,2.5,8.3,2.3,12.7c-0.1,2.9,0.2,6-2.2,8.3
		c-0.3,0.3-0.3,1,0.1,1.3c0.4,0.3,0.8,0,1-0.4c0.3-0.7,0.8-1.2,1-2c0.6-1.8,0.8-3.7,1.6-5.5c0.8-1.6,1.8-2.9,2.7-4.3
		c1.9-3,0.5-10.8-2.3-13.1c-0.3-0.3-0.8-0.8-1.3-0.4c-0.5,0.4,0,1,0.2,1.4c1,1.8,1.6,3.7,2.3,5.5c0.5,1.5,0.8,3.2-0.1,4.7
		c-0.2,0.4-0.6,0.9-0.9,0.8c-0.5,0-0.5-0.6-0.6-1.1c-0.7-5.6-2.9-10.5-6.5-14.8c-0.4-0.5-1.1-1.2-1.7-0.1
		C305,426.3,305.4,426.8,306.1,427.3z M135.1,398.3c0,0.3,0,0.5,0.3,0.6c0.6-0.7,1.5-0.9,1.9-1.7c0.9-0.1,1.4-0.7,1.8-1.7
		c-1,0.4-1.7,0.7-2,1.5C136.1,397,135.7,397.9,135.1,398.3z M172.4,451.9C172.4,451.9,172.4,451.9,172.4,451.9
		c-1.4-0.2-2.8-0.5-3.6-1.9c-0.4-0.1-0.4-0.5-0.6-0.8c-0.6-1.3-1-2.6-1.8-3.8c-0.3-0.4-0.7-0.8-1.2-0.6c-0.6,0.2-0.6,0.7-0.6,1.3
		c0,0.5,0.2,0.8,0.4,1.3c0.7,1.6,1.7,3,2.7,4.4c1.5,0.7,2.7,2,4.5,1.8c0.5,0.2,1,0.4,1.6,0.6c0,0,0,0,0,0c0.8,0.6,1.6,1.2,2.1,2.1
		c0.3,0.5,0.6,1.1,0.8,1.7c-0.1,0-0.1,0.1-0.2,0.1c-0.4,0.2-0.8,0.2-1.2,0.1c-2.5-0.7-5-1.4-7.4-2.4c-1.2-0.9-1.8-2.1-2.4-3.4
		c-0.2-0.3-0.4-0.7-0.8-0.6c-0.5,0-0.5,0.5-0.7,0.9c-0.2,0.6-0.1,1.1,0.1,1.6c0.7,1.9,2.1,3.2,4,4c1.9,1.5,4.2,2,6.5,2.6
		c0.4,0.1,0.9,0.1,1.3,0.3c1.9,0.9,3.5,2,4.4,4.1c0.2,0.6,0.7,1.5,1.5,1.1c0.8-0.4,1.2-1.2,0.8-2.2c-0.1-0.2-0.3-0.4-0.4-0.6
		c-0.4-1-1.1-1.8-1.3-2.8c-0.9-1.2-1.5-2.6-2.2-3.9c0,0,0,0,0,0h0c-0.1-1.2-1.4-1.5-1.8-2.5l0,0c0,0,0,0,0,0
		c-0.8-0.6-1.3-1.5-2.3-1.6c-0.2,0-0.3,0-0.4,0c0.1,0,0.3,0,0.4,0C173.9,452.4,173.3,451.9,172.4,451.9L172.4,451.9z M128.5,404.4
		c-0.6-0.1-0.4,0.7-0.5,1.2l0,0c0.4,0.1,0.4,0.7,1.1,0.6C128.6,405.6,129.5,404.6,128.5,404.4z M119.9,413.3
		c-0.5,0.9,0.1,1.2,0.8,1.4C121.2,413.8,120.5,413.5,119.9,413.3z"/>
	<path class="st1" d="M55.6,466.1c-0.1-2.5-0.3-4.8-0.8-7.1c-0.2-0.6-0.1-1.3-0.1-2c0-0.6-0.1-1.3,0.7-1.4c0.8-0.1,1.5,0.2,1.8,1
		c1.5,4.5,2.4,9,1.1,13.7c-0.8,2.8-2,5.4-3.9,7.6c-0.5,0.6-1.2,1.1-1.9,0.5c-0.7-0.6-0.4-1.2,0.1-2c2-3,2-8.5-0.3-11.3
		c-0.4-0.5-0.6-1.1-1-1.7c-0.5-0.9,0-1.7,0.6-2.1c0.8-0.4,1.4,0.1,1.9,0.9C54.4,463.5,54.4,464.9,55.6,466.1z M210.3,428
		c1.3-2,1.7-4.1,0.8-6.4c-0.2-0.4-0.4-1.2-1.2-0.9c-0.7,0.3-0.4,1-0.3,1.4c0.7,2.4-0.4,4.3-2.1,5.8c-1.9,1.7-3.9,3.2-5.9,4.8
		c-0.7,0.6-1.2,1.4-0.8,2.2c0.4,0.8,1.4,0.4,2,0.1c1.6-0.6,3.2-1.3,4.8-1.9c2.1-0.8,4.3-1.4,6.6-1.9c0.8-0.2,1.4-0.9,1.2-1.8
		c-0.2-1-1-0.6-1.6-0.6c-0.6,0-1.2,0.2-1.9,0.5c-1.1,0.6-2.3,1-3.9,1.6C208.9,429.7,209.7,428.9,210.3,428z M42,551.3
		c-0.3,0.2-0.1,0.6,0.4,0.7c1.3,0.4,2.6,0.7,3.9,0.8c2.5,0.2,4.9-0.3,6.7-2.3c0.8-0.9,0.9-1.7-0.1-2.5c-1-0.8-2.2-0.9-3.9-1
		C46.1,546.9,44.3,549.7,42,551.3z M247.4,526.2c2.4-1.1,4.7-2.2,7.1-3.2c0.9-0.4,2.1-0.8,1.6-1.9c-0.4-0.8-1.6-0.5-2.4-0.1
		c-2.5,1.1-5,2.3-7.5,3.6c-2.3,1.2-3,3.5-2.6,5.7c0.1,0.7,0.4,1.3,1.1,1.4c0.7,0.1,0.9-0.7,1.1-1.1c0.5-2,2.1-2.3,3.8-2.5
		c0.5-0.1,0.9-0.1,1.2-0.5c0.3-0.3,1.1-0.5,0.7-1.1c-0.3-0.5-0.9-0.7-1.6-0.6c-0.3,0-0.6,0.2-0.8,0.3c-0.9,0.5-1.9,0.9-3.2,1.6
		C246.5,526.8,246.8,526.4,247.4,526.2z M47.9,441.2c1.6,0.3,2.9-0.4,3.9-1.6c1.7-2,2-5.4,0.7-7.5c-0.4-0.6-0.9-1-1.6-0.1
		c-0.7,0.8-1.6,1.5-2.4,2.3c-1.1,1.1-2.3,2.3-2,4C46.4,439.6,46.2,440.9,47.9,441.2z M52.4,449.8c-1.7-3.5-4.6-5.7-8-7.2
		c-0.6-0.3-1.1-0.2-1,0.7c0.2,1.5,0.5,3,1.2,4.3c1.3,2.3,3.2,4,5.3,4C52.5,451.6,53,451,52.4,449.8z M147.7,477.8
		c2-0.7,2.9-1.9,2.9-4c0-1.2-0.3-2.1-1.6-2.5c-1.3-0.4-2.4-0.4-3.2,0.8c-0.9,1.3-1.6,2.7-2.6,3.8c-0.6,0.6-2,1.1-1.6,1.8
		c0.4,0.8,1.9,0.6,2.6,0.7C145.6,478.4,146.6,478.2,147.7,477.8z M131.5,465.9c-0.2-0.3,0-0.9-0.6-0.9c-0.6,0-0.7,0.4-0.9,0.8
		c-1.7,2.6-2.5,5.4-3.1,8.4c-0.2,1.1-0.1,2.1,0.8,2.5c1,0.5,2.1-0.1,2.7-1.1c1-1.9,1.8-3.9,1.6-6.1
		C131.8,468.3,132.4,467,131.5,465.9z M67.6,505.7c0.2,0.5,0.5,1.4,1.2,1.6c0.9,0.2,1.2-0.7,1.6-1.3c1.3-1.6,1.3-5.6,0.1-7.5
		c-0.6-1-1.2-2-2.4-2.4c-0.5-0.2-0.9-0.3-0.9,0.4c-0.1,1.5-0.3,3-0.5,4.4C66.7,502.7,67.1,504.2,67.6,505.7z M120,472.2
		c0.5,1.2,1.3,1.4,2.3,0.4c1.8-1.8,2.5-3.9,1.9-6.4c-0.3-1.2-0.7-2.4-1.2-3.5c-0.1-0.3,0.1-0.8-0.4-0.8c-0.5-0.1-0.8,0.3-0.9,0.6
		c-0.8,2.3-2,4.4-2.1,6.9C119.4,470.4,119.6,471.3,120,472.2z M126.8,545.4c-0.7-1-1.1-0.6-1.7,0c-1.8,1.7-2.9,3.8-3.5,6.3
		c-0.3,1.6,0.4,2.5,1.9,2.1c2.7-0.7,4.2-2.1,4.2-5.3C127.8,547.4,127.5,546.4,126.8,545.4z M153.4,465.1c-1.2-0.9-2.6-1.4-3.7-2.4
		c-0.3-0.3-0.7-0.3-1-0.1c-0.4,0.2-0.2,0.6-0.1,0.9c0.6,2.6,1.2,5.1,2.8,7.3c0.6,0.9,1.4,1.2,2.4,1.1c1-0.1,1-1,1-1.7
		c0-0.5,0.1-0.9,0.2-1.4C154.9,467.4,154.8,466.1,153.4,465.1z M46.1,590.6c-0.4,0.2-0.6,0.5-0.6,1c0.1,0.5,0.5,0.4,0.8,0.5
		c0.6,0.3,1.3,0.6,2,0.7c3,0.5,5.8,0,8.2-1.9c0.8-0.6,0.9-1.1-0.2-1.6c-1.2-0.6-2.4-0.8-3.7-0.6C50.2,588.2,48.2,589.6,46.1,590.6z
		 M243.3,625.1c2.6-0.2,5-1.2,6.9-3c0.5-0.5,0.7-1.1,0.4-1.7c-0.3-0.5-0.9-0.2-1.4-0.2c-2.4,0.2-4.7,0.8-7.1,1.2
		c-1.2,0.2-2.3,1.5-2.2,2.8c0.1,1.7,1.5,0.5,2,0.9C242.5,625.1,242.9,625.1,243.3,625.1z M252.6,459.1c-0.1-1.4-0.9-1.5-1.6-1.1
		c-2.2,1.3-4.8,2-6.1,4.5c-0.4,0.8-0.4,1.5-0.2,2.2c0.2,0.6,1,0.1,1.4,0.3C249.6,465,252.9,462.3,252.6,459.1z M94.8,491.9
		c-0.8-0.2-1.9-0.9-2.6-0.2c-0.6,0.5,0.4,1.2,0.4,1.9c0,0.2,0,0.4,0.1,0.6c0.5,2.5,1.6,4.5,4.2,5.4c1.4,0.5,2.2,0.1,2.2-1.3
		C99.1,496.5,96.5,492.3,94.8,491.9z M206.6,468.7c1.9,1.7,3.9,3,5.9,4.6c0.4,0.3,0.9,0.5,1.2,0.3c0.6-0.3,0.2-0.9,0.2-1.4
		c-0.5-2.8-2.2-4.8-4.5-6.4c-0.7-0.5-1.5-0.8-2.2-0.4c-0.8,0.3-1.3,1-1,2C206.4,467.8,206.1,468.3,206.6,468.7z M290.4,456.8
		c0.9-0.8,0.9-1.9,0.8-3c-0.1-0.9-1-0.6-1.6-0.7c-2.9-0.7-5.2,0.9-7.4,2.4c-0.5,0.4-0.8,0.8,0.2,1.3c1.4,0.6,2.7,1.1,3.8,0.9
		C287.9,457.7,289.3,457.9,290.4,456.8z M137.6,459.5c-0.4-1.1-1.3-1.4-2.4-1.2c-1.1,0.2-1.5,1-1.3,2c0.1,0.9,0.2,1.8,0.6,2.6
		c0.7,1.4,0.8,2.8,0.1,4.2c-0.2,0.5-0.8,1-0.3,1.4c0.5,0.4,1.1,0.1,1.8-0.2c2.1-1.3,1.9-3.4,2.1-5.1
		C138.1,461.8,138,460.6,137.6,459.5z M46.1,535.6c-0.7,0.2-1.6,0.4-1.7,1.2c-0.1,0.8,0.7,0.9,1.3,1.2c0.8,0.5,1.6,1,2.5,1.5
		c1.8,1.1,3.8,1.1,4.9,0c1.2-1.1,1.1-2.2-0.3-3c-1.4-0.7-2.8-1.3-4.4-1.2C47.6,535.5,46.8,535.4,46.1,535.6z M110.3,486.1
		c-0.8-2.1-2.6-3.3-4.7-4c-0.8-0.3-1.8-0.6-2.4-0.1c-0.6,0.5-0.1,1.4,0.2,2.1c0.2,0.4,0.3,0.7,0.4,1.1c0.7,1.8,2.8,3.3,4.8,3.3
		C110.6,488.4,111,487.9,110.3,486.1z M281.8,484.8c-0.1-2.2-1.6-3.2-3.3-4c-0.4-0.2-1-0.1-1.3,0.1c-0.5,0.3,0,0.8,0.2,1.2
		c1.1,2,1.4,4,0.5,6.2c-0.1,0.2-0.1,0.4-0.1,0.6c0,1.3,0.7,1.9,1.9,1.4c1.8-0.7,2.3-1.5,2.3-3.9C282.2,486,281.8,485.4,281.8,484.8z
		 M35.5,508.5c-0.1,0.4-0.1,1,0.3,1.4c0.3,0.3,0.4,0.8,0.6,1.2c1.1,2,2.7,3.5,4.6,4.5c1.3,0.7,2.4-0.1,2.2-1.5
		c-0.3-2.5-4-5.8-6.7-5.8C36.3,508.4,35.7,507.9,35.5,508.5z M75.9,494.2c-0.5,1,0.8,1.3,1.1,2c0.1,0.3,0.3,0.5,0.5,0.8
		c1.2,1.7,2.9,2.4,4.9,2.3c1.5-0.1,1.7-0.5,1.4-2c-0.5-2.3-2.7-3.9-5.6-3.7C77.6,493.8,76.4,493.1,75.9,494.2z M58.5,600.4
		c-2-1.9-4.5-2.4-7-3.2c-0.4-0.1-0.7-0.1-0.9,0.3c-0.2,0.4,0,0.6,0.3,0.9c1.1,1.1,2,2.4,3.2,3.5c1.3,1.2,2.6,2.4,4.6,2.4
		c0.8,0,1.4-0.2,1.2-1.1C60,601.9,59.2,601.1,58.5,600.4z M144.8,464.7c-0.3-0.9-0.6-1.9-1.8-2.1c-1.3-0.3-1.7,0.5-2.1,1.5
		c-0.7,2.1-0.6,4.4-1.2,6.6c-0.3,1.2,0.9,0.8,1.3,0.6c2.2-0.9,3.8-2.8,3.8-5.4C144.8,465.5,144.9,465,144.8,464.7z M303.7,473.2
		c0-0.6,0-1.2-0.5-1.5c-0.7-0.3-1.1,0.2-1.6,0.6c-2,1.9-3.2,4.2-3.1,7.1c0,1.4,0.4,1.6,1.7,1.2c1.6-0.6,3.9-3.5,3.6-6.4
		C303.7,473.9,303.7,473.6,303.7,473.2z M310.5,618.9c3-1.9,3.8-4.5,3.2-7.8c-0.2-1.1-0.9-0.7-1.2-0.4c-1.8,2.1-3.9,4.1-4.8,6.9
		c-0.4,1.2,0,1.7,1.2,1.7C309.4,619.3,309.9,619.3,310.5,618.9z M302.5,544.1c0.6-1.2,0.2-2.2-0.6-3c-0.4-0.5-2.8-0.2-3.3,0.4
		c-0.9,1.2-1.8,2.4-2.8,3.6c-0.9,1.1-0.4,2,1,2.1c0.3,0,0.6,0,0.7,0C300.2,547.4,301.6,546.1,302.5,544.1z M107.4,398.3
		c-0.2-0.8,0.3-2-0.8-2.4c-1-0.3-1.3,0.8-1.8,1.3c-1.7,1.5-2.9,3.1-2.9,5.5c0,1,0.3,1.3,1.3,1.4c2.1,0.2,4.4-1.9,4.3-5.5
		C107.4,398.8,107.5,398.6,107.4,398.3z M200,410.4c-0.5-1-0.9-0.7-1.2-0.1c-1.4,2.3-2.6,4.6-2.5,7.4c0,0.9,0.1,1.7,1.1,1.9
		c1,0.2,1.8,0,2.4-1.1c0.7-1.3,0.9-2.6,0.8-4.1C200.5,413.1,200.6,411.8,200,410.4z M231.6,593.9c-0.3-0.8-0.8-1.7-1.5-1.8
		c-1.1-0.1-0.9,1.1-1.1,1.8c0,0.1-0.1,0.2-0.1,0.3c-0.5,2-0.8,4-0.3,6c0.2,0.8,0.6,1.4,1.5,1.4c0.8,0,1.3-0.3,1.7-1
		c0.4-0.9,0.8-1.7,0.6-2.7C232.5,596.4,232,595.2,231.6,593.9z M315,619c0.3,0.5,0.5,1.2,1.2,1.3c0.8,0.1,0.8-0.6,0.9-1
		c0.6-2.1,2-3.4,3.8-4.3c0.7-0.4,1.3-1.1,1-2c-0.3-0.9-1.1-0.8-1.8-1c-2.8-0.5-5.3,3.1-5.2,5.8C315,618.1,314.8,618.6,315,619z
		 M65.3,486c-0.6-0.1-1.1,0.1-0.9,0.8c1,2.4,1.6,5.1,3.8,6.9c0.8,0.7,1.6,0.7,2.4,0.5c0.6-0.2,0.2-1.1,0.3-1.5
		C70.8,489.5,68.2,486.6,65.3,486z M198,455.2c-1.2-0.4-1.3-0.1-1.1,1c0.3,1.4,0.8,2.7,1.3,3.9c0.4,1.2,0.6,2.6,1.6,3.7
		c0.6,0.6,1.2,0.4,1.8,0.4c0.7,0,0.7-0.6,0.7-1.1c0-0.5,0-0.9,0-1.4C202.3,458.6,200.5,456,198,455.2z M297.2,463.6c0.8,1,2,1,2.7,0
		c2-2.8,0.3-6.6-2-7.7c-1.4-0.7-1.5-0.5-1.6,0.9c0,0.7,0,1.4,0,2.1C296.5,460.6,296.1,462.2,297.2,463.6z M178.9,478.7
		c0.1,0.4,0.5,0.8,0.9,0.9c0.6,0.2,0.7-0.5,0.9-0.8c0.9-1.4,1.2-3,1.3-4.6c0.1-1.5-0.4-3-1.6-4c-0.7-0.6-1.5-1-1.7,0.5
		c-0.2,1.3-0.8,2.5-0.8,3.8C177.9,476,178.6,477.3,178.9,478.7z M220.2,598.6c0.1,0.6,0.1,1.4,0.9,1.6c0.8,0.2,1.2-0.4,1.5-1
		c0.4-0.7,0.9-1.4,1.3-2.2c1-1.7,1.9-3.4,1.2-5.5c-0.2-0.8-1.2-0.8-1.2-0.6c-0.4,1-1.4,0.9-2,1.5c-0.5,0.4-0.2,1.3-1,2
		c-0.7,0.5-0.9,2.1-0.8,3.4C220.2,598,220.2,598.3,220.2,598.6z M307.1,623.3c-1.8,1-3.5,2.2-4.9,3.8c-0.5,0.5-0.7,1.4-0.3,2.1
		c0.4,0.7,1.1,0.4,1.8,0.3c1-0.2,1.8-0.7,2.8-1c1.6-0.6,2.7-2,2.7-3.7C309.2,623.1,308.5,622.5,307.1,623.3z M261.1,479.9
		c0,1.3,1,2.5,2.1,2.4c1.4-0.1,1.5-1.2,1.6-2.3c0.1-1.7-0.2-3.4-0.4-5.1c-0.1-0.7-0.6-1.5-1.2-2c-0.5-0.5-1.4-0.5-1.4,0.4
		c-0.1,1.9-0.9,3.7-0.7,5.6C261.1,479.3,261.1,479.6,261.1,479.9z M201.8,468.1c-0.7,0-1.5-0.1-2.2,0.2c-2.8,1.3-3.5,3.9-4.1,6.5
		c-0.1,0.4,0.1,0.8,0.4,1c0.3,0.1,0.6,0.2,0.8,0c1.3-1.5,3.2-2.3,4.3-4.1c0.5-0.8,1.2-1.6,1.3-2.5
		C202.2,468.7,202.5,468.1,201.8,468.1z M297.6,601.4c-0.9-1.4-1.4-0.6-1.8,0.1c-1.4,2.4-1.5,5-0.9,7.7c0.2,1.1,1.2,1.4,2,0.5
		c1.1-1.2,1.9-2.6,1.7-4C298.5,604,298.4,602.7,297.6,601.4z M114,399.6c-0.3-1.4-1.4-2.2-3-1.8c-1.2,0.3-1.7,1.2-1.5,2.4
		c0.2,1.5,0.6,3,0.8,4.6c0.1,0.6,0.4,0.7,0.9,0.6c1.9-0.5,2.8-1.6,2.8-4C114,400.7,114.1,400.1,114,399.6z M140.5,571
		c1.6,2.4,3.3,4.7,5.9,6.1c0.5,0.3,1.3,0.6,1.8,0.3c0.6-0.5-0.1-1.1-0.3-1.6c-0.1-0.2-0.1-0.5-0.2-0.7c-1.3-2.8-3.4-4.7-6.9-5.1
		C140.2,570,139.9,570.2,140.5,571z M319.5,602.4c0,0.9,0.4,0.9,1.1,0.8c1.6-0.2,3.5-2.6,3.2-5.1c-0.2-1.5-1.3-2.3-3.3-2.3
		c-1.7,0-1.2,1.2-1.3,2.2c0,0.3,0,0.6,0,1.3C319.1,600.1,319.4,601.2,319.5,602.4z M181.9,453.7c0.1,1.4,0.1,2.8,0.3,4.2
		c0.2,1.2,1,1.8,2.2,1.6c1.1-0.2,0.9-1.2,0.8-1.9c-0.2-1.2,0-2.4-0.2-3.6c-0.2-1.5-0.3-3-1.5-4.1c-0.3-0.3-0.5-0.8-1.1-0.5
		c-0.5,0.2-0.4,0.7-0.4,1.1C182,451.5,182,452.6,181.9,453.7C181.9,453.7,181.9,453.7,181.9,453.7z M273.4,483.2
		c0.2-0.4,0.3-1,0-1.3c-0.3-0.4-0.9-0.3-1.3-0.1c-1,0.4-2.1,0.5-3,1c-1.1,0.5-2.4,1-3.2,2.2c-0.4,0.6-0.8,1.2-0.5,1.9
		c0.3,0.8,1.1,0.3,1.5,0.4C270.1,487.2,272.2,486,273.4,483.2z M186.8,464.7c-0.6-0.5-1.4-0.7-1.5,0.1c-0.3,2.2-1.4,4.2-1,6.5
		c0.2,1,0.4,2,1.6,2.1c1.2,0.1,1.5-0.7,1.8-1.8c0.2-0.9,0.2-1.8,0.3-2.7C188,467.5,188.1,465.9,186.8,464.7z M233,607.2
		c-0.4-0.3-0.8-0.5-1.2-0.3c-0.4,0.2-0.2,0.6-0.2,1c0,1.3,0,2.5,0,3.8c0,1.1,0.1,2.1,0.4,3.1c0.1,0.4,0.3,1.1,1,1.2
		c0.7,0.1,1.1-0.3,1.3-0.8c0.6-1.1,1.3-2.1,0.9-3.4C235.6,609.6,234.5,608.3,233,607.2z M253.5,433.7c0-0.9,0-1.9-0.9-2.2
		c-1-0.3-0.9,0.9-1.2,1.4c-1,1.5-1.9,3.2-2.4,4.9c-0.2,0.8-0.4,1.9,0.4,2.3c0.7,0.3,1.6,0.3,2.4-0.4c1.5-1.3,1.8-3,1.7-4.7
		C253.5,434.6,253.5,434.1,253.5,433.7z M101.7,490.2c-0.3-0.8-0.4-1.6-0.8-2.4c-0.8-1.8-2.1-3.1-4-3.6c-0.9-0.2-1.5-0.2-1.2,1.1
		c0.4,2.3,1.5,4.2,3.3,5.6c0.5,0.4,1,0.8,1.7,0.6C101.8,491.7,102,491.2,101.7,490.2z M42.3,533.8c0.4-0.8,0.5-1.7,0-2.3
		c-0.6-0.6-1.5-0.3-2.2,0.2c-0.3,0.2-0.7,0.4-1.1,0.7c-1.1,1.1-2.5,1.9-3.6,3.1c-0.3,0.3-1,0.2-0.7,1c0.2,0.6,0.6,0.7,1.2,0.7
		c0.4,0,0.8,0,1.1,0C39.6,537.4,41.2,535.9,42.3,533.8z M206.3,414.6c0-0.3-0.1-0.5-0.4-0.5c-0.3,0-0.6,0-0.9,0.4
		c-0.5,0.7-0.7,1.5-1.1,2.2c-0.7,1.3-1.5,2.6-1.8,4c-0.2,0.8-0.1,1.6,0.6,2c0.6,0.3,1.2,0.2,1.8-0.2c1.9-1.1,1.9-3.1,2.3-4.8
		C206.6,416.7,206.4,415.7,206.3,414.6z M289.3,639.7c-1.8,0.2-3.5,1-5.2,1.6c-1.2,0.4-1.7,1.2-1.3,2.3c0.3,0.9,2.1,1.7,2.9,1.2
		c1-0.6,2.1-0.8,3.1-1.4c1.1-0.7,1.4-1.7,1.6-2.8C290.4,639.8,290,639.6,289.3,639.7z M78.8,488.9c0.2-0.9-0.6-3.2-1.4-3.4
		c-1.6-0.5-3.2-1.2-4.9-1.3c-0.7-0.1-0.9,0.1-0.7,0.9c0.2,0.8,0.8,1.3,1.2,1.9c1,1.4,1.7,3.3,3.6,3.1
		C78.1,490.1,78.7,489.7,78.8,488.9z M280.3,652.2c-0.1-0.8-0.1-1.2-1-0.7c-2,1-4,1.8-6,2.9c-0.5,0.2-0.7,1-0.5,1.5
		c0.1,0.4,0.5,0.8,1.1,0.8c0.6,0,1.1,0,1.5,0C278.8,657.2,280.5,654.6,280.3,652.2z M83.5,643.4c-1,0.4-2.4,0.6-2.5,1.8
		c-0.1,1.3,1.3,1.5,2.3,1.7c2.1,0.4,4.2,0.7,5.9-1c0.7-0.7,0.6-1.3-0.2-1.6c-1.2-0.5-2.4-1.3-3.9-1C84.6,643.3,84,643.2,83.5,643.4z
		 M326.5,476.6c-0.6-1-1.7-1.7-2.9-1.2c-0.9,0.4-1.3,1.3-0.9,2.3c0.6,1.8,1.7,3.4,2.6,5.1c0.4,0.6,1,0.8,1.5-0.2
		c0.4-0.9,0.9-1.8,0.7-2.8C327.6,478.6,327.1,477.6,326.5,476.6z M310.7,569.7c0,0.6-0.1,1.2,0,1.8c0.2,1.8,0.7,3.4,2.4,4.3
		c0.5,0.3,1.4,0.6,1.5-0.2c0.3-2.8-0.3-5.5-1.8-7.8c-0.2-0.3-1-0.9-1.7-0.6c-0.8,0.3-0.3,1-0.4,1.5
		C310.7,569,310.7,569.3,310.7,569.7C310.7,569.7,310.7,569.7,310.7,569.7z M4.9,537.6c-0.5-1.6-1.3-3-2.2-4.4
		c-0.2-0.3-0.4-0.5-0.7-0.4c-0.4,0.1-0.4,0.4-0.4,0.7c0,0.6-0.2,1-0.8,1.3c-0.6,0.3-0.8,0.8-0.6,1.5c0.2,0.7,0.1,1.4,0.3,2.1
		c0.3,1,0.3,2.3,1.9,2.4C4,540.8,5.4,539.1,4.9,537.6z M312,558.1c-0.1,0.3-0.3,0.6,0,0.8c0.2,0.1,0.6,0.2,0.9,0.1
		c2.4-0.6,4.2-3.4,3.9-5.8c-0.2-1.3-1-1.9-2.3-1.7c-1.6,0.2-2.1,1-2,3.7C312.8,556.1,312.4,557.1,312,558.1z M238.3,472.6
		c-0.1-0.4,0.1-1-0.6-1.1c-0.7-0.1-1,0.4-1.1,0.8c-0.7,1.7-1.8,3.2-2.4,5c-0.3,0.9-0.8,2,0.3,2.6c1,0.5,2.1,0.2,2.8-0.7
		c1-1.2,1.2-2.7,1.2-4.5C238.5,474.1,238.4,473.4,238.3,472.6z M76.1,424.3c0.3-0.8,0.7-1.8-0.4-2.1c-1.1-0.4-2.4-0.3-3.3,0.5
		c-1.3,1.1-2.2,2.5-3.1,3.9c-0.6,0.8-0.2,1.1,0.6,1c0.3,0,0.6,0,0.9,0C73.2,427.5,75,426.6,76.1,424.3z M216.4,453
		c-0.8-0.3-1.4-0.2-1.3,0.8c0.2,2.2,0.6,4.3,1.3,6.3c0.2,0.7,0.8,1.3,1.8,1.3c1-0.1,0.9-0.9,1-1.4c0.1-0.5,0.1-1,0.2-1.2
		C219.4,455.8,218.1,453.7,216.4,453z M39.2,558.5c0.4,0.7,1.1,1.3,1.4,2.1c0.5,1.1,1.5,1.4,2.3,2c0.5,0.3,1.2,0.3,1.7,0
		c0.5-0.3,0.6-0.9,0.5-1.5c-0.4-2.4-2.3-4.7-4.8-4.5C38.2,556.6,38.1,556.7,39.2,558.5z M189.5,453.6c-0.3-0.3-0.8-0.2-1.1-0.1
		c-0.5,0.1-0.3,0.6-0.3,0.9c0.4,1.6,0.5,3.2,0.6,4.8c0.1,1,0.1,2.1,0.9,3c0.7,0.7,1.5,0.6,1.8-0.4c0.4-0.9,0.6-1.9,0.6-3.2
		C192.2,456.6,190.9,455,189.5,453.6z M301.2,491.8c0.6,1.6,1.3,1.7,2.6,0.7c0.6-0.5,0.7-1.1,0.8-1.8c0.4-2.6-1-4.4-2.8-6
		c-0.4-0.4-0.9-0.3-0.9,0.4c0,1,0,1.9,0,3.2C301.1,489.2,300.7,490.5,301.2,491.8z M290.1,448.5c-0.5,0.7-1.4,0.3-2,0.6
		c-0.2,0.1-0.5,0.1-0.7,0.3c-0.5,0.4-0.8,1-0.7,1.6c0.2,0.5,0.9,0.3,1.3,0.3c1.9,0,3.5-0.8,4.9-2c0.8-0.7,1.1-1.7,1.1-2.8
		c0-1.2-0.6-1.6-1.7-1.5c-1.3,0.1-3,1.6-3.3,3C289.2,448.4,289.9,448,290.1,448.5z M150.3,556.4c0-0.7-0.5-1-0.9-1.2
		c-0.6-0.3-0.7,0.4-0.9,0.7c-0.5,0.7-1,1.5-1.4,2.3c-0.6,1.5-1.1,3.1-0.4,4.8c0.2,0.6,0.6,1.1,1.3,1.1c0.7,0,1-0.5,1.1-1
		c0.4-1.6,1.5-3.1,1.1-4.7C150.3,557.6,150.2,557,150.3,556.4z M49.8,505.7c0.1,0.1,0.1,0.3,0.2,0.4c0.9,1.6,1.8,3.2,3.4,4.2
		c0.9,0.6,2.1,0.4,2.2-0.4c0.2-2.6-1.6-6.1-4.7-5.8C49,504.1,49,504.2,49.8,505.7z M69.8,474.5c-0.5,0-1.4-0.6-1.5,0.6
		c-0.2,2.1,1.5,5,3.4,5.6c1.7,0.5,2.1,0.2,2.1-1.5C73.9,476.9,72,474.6,69.8,474.5z M296,645.8c1-0.7,1.4-1.8,1.3-3.2
		c-0.1-0.8-0.5-1.1-1.1-1.3c-1.2-0.4-2.2,0.1-3,1c-0.9,1-1.6,2.1-2.2,3.3c-0.4,0.7-0.2,1.1,0.7,1.1c0.4,0,0.7,0,1.2,0
		C294.1,647.1,295.1,646.4,296,645.8z M178.6,605.5c0-1.3-0.9-0.8-1.3-0.4c-1.9,2-3.3,4.3-3,7.2c0.1,0.6,0.4,0.9,0.8,1.2
		c0.6,0.3,1-0.3,1.2-0.6c0.6-1,0.9-2.1,1.6-3c0.6-0.8,1-1.7,1-2.8C179,606.5,178.6,606,178.6,605.5z M43.5,496.7
		c-0.4-0.5-0.8-0.4-1.2-0.4c-0.5,0-0.4,0.5-0.4,0.8c-0.1,0.4-0.2,0.8-0.2,1.2c-0.2,2-0.5,4.1,0.1,6.1c0.4,1.2,1,0.9,1.6,0.4
		c1.2-1.1,1.5-2.5,1.5-3.9C44.8,499.2,44.5,497.9,43.5,496.7z M62.5,476.4c-0.6-0.7-1.5-0.9-2.5-0.5c-1,0.4-0.8,1.2-0.8,2
		c0,1.5,1,2.8,1,4.3c0,1.4,0.9,1.1,1.5,0.6c1.2-0.9,1.8-2.2,1.9-3.6C63.7,478,63.1,477.1,62.5,476.4z M314.9,601.2
		c-1.9,0.9-3.7,1.7-4.2,4.1c-0.1,0.5-0.5,1,0,1.5c0.5,0.5,1,0.6,1.7,0.4c2.7-0.6,3.7-2.3,4.1-5C316.5,600.9,316.1,600.6,314.9,601.2
		z M44.6,466.5c0.8,0.7,1.9,0.9,2.9,0.4c0.8-0.5,0.6-1.4,0.3-2c-0.8-1.5-1.6-3-3-4.1c-0.4-0.3-0.5-1.2-1.3-0.8
		c-0.6,0.3-0.7,1-0.8,1.6c0,0.2,0,0.5,0,0.8C42.9,463.9,43.4,465.5,44.6,466.5z M172.7,465.2c-0.5-0.9-1.1-1.5-2-1.9
		c-0.4-0.1-0.7-0.4-0.9,0.3c-0.5,1.6,1.2,6.6,2.6,7.6c0.6,0.4,1.3,0.3,1.9,0.2c0.5-0.1,0.3-0.6,0.3-1.1
		C174.1,468.6,173.5,466.9,172.7,465.2z M168.5,423.3c0,0.8,0,1.5,0,2.3c0,0.4-0.3,0.9,0.3,1c0.5,0.1,1,0.2,1.4-0.3
		c1.2-1.3,2.2-2.8,2.2-4.7c0-2.4-0.6-2.8-2.9-2.1c-1,0.3-0.9,0.9-1,1.5C168.4,421.8,168.5,422.6,168.5,423.3L168.5,423.3z
		 M84.3,451.9c-0.3,0.3-0.7,0.7-0.3,1.1c0.4,0.3,0.9,0.2,1.4,0c1.8-0.7,2.9-2.2,3.5-4c0.3-1,0-1.8-0.7-2.5c-0.7-0.7-1.6-0.5-2.2-0.3
		c-0.8,0.3-1.3,1-1.2,2c0,0.3,0,0.7,0,1.1C84.8,450.2,85,451.1,84.3,451.9z M262.9,419.5c-0.1-0.7-0.4-0.9-0.9-0.6
		c-1.8,1.1-3.8,2.1-5.1,4c-0.4,0.6-0.6,1.1-0.4,1.7c0.3,0.7,1,0.2,1.4,0.3C261.9,424.3,263.3,422.9,262.9,419.5z M303.7,603.5
		c0.1-1.3-0.7-2.1-1.6-2.9c-0.3-0.3-1-0.4-1.1,0.1c-0.3,2.2-1,4.4-0.6,6.7c0.1,0.6,0.4,1.2,1.1,1.3c0.6,0.1,1.1-0.2,1.6-0.7
		c0.9-0.9,0.6-2.1,0.6-3C303.7,604.5,303.7,604,303.7,603.5z M221.9,542.5c-0.7-0.9-1.6-0.9-2.4-0.8c-0.9,0.2-0.4,1.1-0.4,1.7
		c0.2,1.7,0.6,3.3,1,5c0.1,0.6,0.6,1,1.2,1.3c0.7,0.5,1.1,0.2,1.3-0.5c0.2-0.9,0.3-1.9,0.3-2.7C222.8,545,222.8,543.6,221.9,542.5z
		 M322,622c0.4,1.3,1.8,1.8,3.2,1.3c0.8-0.3,1.1-1.6,0.3-2.7c-0.5-0.7-0.7-1.4-1.1-2.1c-0.4-0.5-0.4-1.2,0-1.7
		c0.3-0.4,0.7-0.9,0.1-1.2c-0.4-0.3-1,0-1.4,0.4c-1,1.1-1.5,2.3-1.7,3.7C321.2,620.6,321.7,621.3,322,622z M15.4,531.6
		c0.1,1.8,2.2,4.6,3.9,5.1c1.4,0.4,2.4-0.4,2-1.8c-0.5-2.1-2.5-4.1-5-4.1C15.8,530.7,15.4,530.7,15.4,531.6z M224.9,449.1
		c-0.7-0.4-1.8-0.8-2.1,0.3c-0.3,0.8-0.2,1.8,0.2,2.8c0.8,1.6,2.3,2.8,2.5,4.7c0.1,0.7,0.6,0.3,1,0c0.9-0.9,0.3-2,0.5-3
		C227.3,451.8,226.6,450.2,224.9,449.1z M229.2,613.9c-0.2-1-0.8-0.8-1.2-0.3c-1.5,1.8-2.9,3.7-3.4,6.1c-0.1,0.5-0.6,1.2,0,1.5
		c0.6,0.3,1.3,0.2,2-0.3c1.9-1.4,2.8-3.3,2.9-5.9C229.3,614.7,229.2,614.3,229.2,613.9z M163.8,469.2c-0.7-0.6-1.5-0.3-2.3-0.3
		c-0.3,0-0.3,0.3-0.3,0.6c0.2,1.7,0.6,3.4,1.6,4.9c0.5,0.8,0.9,2.3,2.1,1.9c1-0.3,0.9-1.8,0.8-2.9
		C165.9,471.7,165.1,470.4,163.8,469.2z M261,409.6c-0.2-0.1-0.6-0.2-0.7,0.1c-0.1,0.3-0.2,0.8-0.1,1c1.1,1.9,1.6,4,2.7,5.9
		c0.4,0.8,1.2,1.1,2,0.9c1-0.2,0.7-1.1,0.8-1.6C265.6,412.8,263.4,411,261,409.6z M112.3,411.8c-0.5-1.6-3.6-4.1-5.3-4.3
		c-0.7-0.1-0.7,0.4-0.6,0.9c0.1,1.4,0.7,2.7,1.6,3.7c0.8,1,1.6,2.1,2.9,1.9C112.3,414,112.7,413.2,112.3,411.8z M177.1,429.1
		c0.7,0.5,1.5,0.6,1.4-0.7c-0.1-2.5-0.2-4.9-2.6-6.5c-0.8-0.5-1.4-0.4-1.6,0.5c-0.1,0.4,0,0.9,0,1.4
		C174.2,426.2,175.2,427.9,177.1,429.1z M303.1,517c0.1,0.4-0.3,1,0.2,1.2c0.5,0.3,0.9-0.2,1.3-0.5c1.9-1.3,2.6-3.1,2.4-5.3
		c-0.1-0.8-0.1-1.8-1.4-1.8c-1.1,0.1-2,0.5-2.1,1.7c-0.1,1.3-0.6,2.6-0.4,3.7C303.1,516.5,303,516.7,303.1,517z M96.6,636.1
		c0.9-0.2,1.1-1,0.4-1.6c-1.8-1.9-5.5-2-7.3-0.2c-0.5,0.5-0.7,1.2,0.1,1.5c1.2,0.4,2.4,1.2,3.8,0.9C94.6,637,95.6,636.3,96.6,636.1z
		 M282.5,470.8c-1.2-0.4-2.9,0.4-3.1,1.7c-0.2,1.5-0.7,2.8-1.8,3.8c-0.4,0.4-0.3,0.8,0.4,0.9c2.7,0.3,5.1-1.8,5.2-4.5
		C283.3,472,283.4,471.1,282.5,470.8z M123.1,541.4c0,0.6,0.5,0.9,0.9,1.1c0.4,0.3,0.6-0.3,1-0.5c2.5-1.6,1.8-5.6,0.3-7.3
		c-0.9-1-1.3-0.8-1.8,0.4c-0.5,1.2-0.1,2.4-0.4,3.6C123.1,539.6,123.1,540.5,123.1,541.4z M268.4,515.3c-0.9-0.3-1.7,0-2.2,1
		c-0.7,1.2-1.6,2.2-2.3,3.4c-0.3,0.6-0.4,1.4,0.7,1.5c2.4,0.2,4.9-0.9,4.8-3.9C269.3,516.5,269.3,515.6,268.4,515.3z M297.6,561.9
		c-0.9-0.3-1.9-0.2-2.6,0.4c-0.9,0.9-1.7,1.8-2.6,2.7c-0.4,0.4-0.5,1.1-0.1,1.3c1.3,0.7,2.8,0.8,4.2,0.3c1.2-0.4,1.9-1.3,1.7-2.8
		C298.1,563.1,298.6,562.2,297.6,561.9z M267.8,506.5c0.4-0.5,0.4-1.1,0.3-1.6c-0.1-0.7-0.7-0.3-1.1-0.4c-1.7-0.3-3.2,0.5-4.7,1
		c-0.9,0.3-1.7,1.3-1.4,2.4c0.2,1.1,1.3,0.9,2.2,0.9C264.9,508.7,266.5,508.1,267.8,506.5z M273,475.4c-0.2-1.3-0.6-1.4-1.7-1.1
		c-2,0.5-3.3,1.8-4,3.8c-0.7,1.7-0.7,1.7,1,1.6C270.8,479.9,273.3,477.7,273,475.4z M220.9,476.3c0.1,1,1.2,1.7,1.8,1.3
		c2.3-1.6,2.2-6.7,0-8.2c-0.4-0.2-0.8-0.4-0.9,0.3c-0.2,1.6-1.3,3.1-0.9,4.7C220.9,475,220.8,475.7,220.9,476.3z M296.7,572.6
		c-0.3-1.2-1.2-1.7-2.3-1.4c-0.9,0.2-1.3,1.1-1.2,2c0.1,0.7,0.3,1.4,0.5,2c0.3,0.9,0.3,1.7-0.2,2.5c-0.2,0.4-0.9,0.8-0.3,1.3
		c0.4,0.3,1,0.2,1.6-0.1c1.7-1,2.2-2.6,2.3-4.8C297,573.8,296.9,573.2,296.7,572.6z M73.8,518.6c-0.3-1-1.1-0.9-1.5-0.3
		c-1.2,1.8-2.3,3.7-2.1,5.9c0,0.7,0.2,1.4,0.9,1.6c0.7,0.2,1.4-0.2,1.8-0.8c0.9-1.2,1.1-2.5,1.3-4.3C74.3,520.2,74,519.4,73.8,518.6
		z M20.3,544.7c-0.7,0.4-1,1.1-1.1,1.7c-0.2,1.8-1.1,2.9-2.6,3.5c-0.3,0.1-0.3,0.3-0.3,0.6c0,0.3,0.2,0.3,0.4,0.3
		c2.9,0.8,6-1.4,6-4.3C22.7,544.4,22,543.9,20.3,544.7z M68.4,659.9c0.8,2.3,2.2,4.2,4.7,5c0.3,0.1,0.6,0.1,0.8-0.4
		c0.7-2.7-1.5-5.9-4.3-6C68.7,658.3,68,658.8,68.4,659.9z M173,427.9c-1-0.5-1.2,0.6-1.8,1c-0.2,0.1-0.3,0.3-0.4,0.4
		c-1,1.4-2.2,2.7-2,4.7c0.1,0.7,0.6,1,1,1.3c0.3,0.3,0.7,0.1,1.1-0.3c1.5-1.5,2.8-3,2.5-5.6C173.2,429.2,173.8,428.3,173,427.9z
		 M89.9,505.9c0.4-0.8-0.5-1.1-0.9-1.5c-2-1.9-4.7-2-7.2-2.5c-0.3,0-0.7-0.1-0.7,0.3c0,0.3,0,0.7,0.2,0.8c2,1.7,4,3.4,6.9,3.4
		C88.8,506.4,89.6,506.6,89.9,505.9z M91.2,612.4c0.5-0.1,1.1,0.2,1.3-0.4c0.2-0.5-0.4-0.8-0.7-1.1c-1.9-2.2-4.5-2.7-7.3-2.6
		c-0.4,0-0.9-0.2-1.1,0.3c-0.2,0.5,0.3,0.8,0.6,1.1c1.8,1.6,3.9,2.6,6.4,2.7C90.8,612.4,91.1,612.5,91.2,612.4z M295.2,623.8
		c0.3-0.3,0.7-0.6,0.3-1.1c-0.1-0.1-0.2-0.2-0.3-0.3c-1.7-0.7-5.7,0.6-6.6,2.1c-0.8,1.3-0.2,2.1,1.3,2.2c1.5,0.1,2.9-0.4,4.2-0.9
		C294.3,625,294.6,624.3,295.2,623.8z M106.6,436.3c-0.2-0.8-0.6-1.4-1.5-1.5c-0.9-0.1-1.4,0.3-1.7,1.1c-0.7,1.7-0.8,3.4-0.4,5.1
		c0.2,0.9,0.6,0.8,1.2,0.6c1.4-0.4,2.8-1.9,2.6-3.8C106.7,437.3,106.7,436.8,106.6,436.3z M178.4,447.4c0.1-1.6-0.4-3-1.3-4.3
		c-0.4-0.5-0.8-0.8-1.4-0.6c-0.6,0.2-0.5,0.8-0.5,1.3c-0.1,1.5,0.2,3-0.4,4.5c0.1,0.9-0.1,1.9,1.1,2.1c1.3,0.3,2-0.4,2.3-1.5
		C178.2,448.3,178.4,447.8,178.4,447.4z M60.1,495c-0.2-0.9-0.3-2-1.3-2.1c-0.8-0.1-0.8,1-1.1,1.5c-0.8,1.3-1.5,2.7-1.3,4.3
		c0.1,0.9,0.7,1.4,1.3,1.8c0.5,0.4,1-0.2,1.5-0.6c1.1-0.9,0.9-2,1.1-3.2C60.3,496.2,60.3,495.6,60.1,495z M88.5,556.9
		c0.2,0.4,0.5,0.8,1.1,0.9c0.5,0.1,0.6-0.5,0.6-0.7c0.6-2.9,0.5-5.8-1.1-8.4c-0.3-0.4-0.7-0.4-1.1-0.4c-0.5,0-0.4,0.4-0.4,0.8
		c0,0.9,0,1.8,0,3.2C87.3,553.6,87.6,555.3,88.5,556.9z M251,694.1c-0.3,0.3-0.5,0.8-0.3,1.1c0.3,0.4,0.7,0.1,1.1,0.1
		c1.5-0.2,2.8-1,4.3-1.2c0.4,0,0.9-0.4,1.4-0.5c1-0.2,1.8-0.7,1.7-1.8c-0.1-1.3-1.3-0.8-2.1-0.9C254.6,690.8,252.8,692.4,251,694.1z
		 M60.7,451.3c-0.1-0.9-0.5-2.1-1.3-2.3c-0.8-0.2-0.9,1-1.4,1.6c-0.1,0.2-0.3,0.4-0.4,0.6c-0.7,1.5-1,3-0.3,4.5
		c0.3,0.8,0.7,1.4,1.9,0.6c1.4-0.9,1.6-2,1.5-3.5C60.8,452.4,60.8,451.8,60.7,451.3z M11.7,495.9c-0.7-0.3-1.3-0.2-1.8,0.3
		c-0.3,0.3-0.7,0.7-0.8,1.1c-0.5,1.4-1.1,2.8-1.8,4.2c-0.3,0.6-0.1,0.9,0.7,1c1.8,0.3,4.7-2.1,4.5-4.6
		C12.3,497.3,12.7,496.4,11.7,495.9z M237.5,620.7c0.2-0.4,0.8-0.8,0.4-1.2c-0.3-0.4-0.9-0.2-1.4,0c-1.5,0.6-3.1,1.1-4.6,1.7
		c-0.9,0.4-2.3,0.7-2.1,1.9c0.3,1.3,1.7,0.7,2.4,0.8C234.9,624.1,236.5,623,237.5,620.7z M128.4,457.4c0.1-1-0.3-1.7-1.2-2.1
		c-0.6-0.3-1.4-0.6-2-0.1c-0.8,0.7-0.6,1.7-0.4,2.5c0.3,1.4,0.7,2.7,1.1,4c0.1,0.3-0.2,0.9,0.5,0.9c0.5,0,0.9-0.1,1.3-0.6
		c0.7-1,0.8-2.1,0.7-3.4C128.4,458.3,128.3,457.8,128.4,457.4z M101.2,582.8c0,0.8,0.1,1.6,1,1.8c0.9,0.3,1.6-0.1,2-1
		c0.7-1.7,1.1-3.5,1.1-5.4c0-1.1-0.6-1.1-1.4-0.7c-1.8,1-2.8,2.5-2.7,4.6C101.2,582.3,101.2,582.6,101.2,582.8z M252.7,411.8
		c0-0.4,0.4-0.9-0.2-1.2c-0.6-0.3-0.9,0.3-1.3,0.5c-1.4,1-2.1,2.5-2.9,3.9c-0.3,0.6-0.5,1.7,0.3,2.2c0.7,0.5,1.5,0.3,2.4-0.1
		c2-1.1,1.6-3,1.8-4.5C252.8,412.2,252.8,412,252.7,411.8z M207.8,408.5c0-0.9-0.5-1.5-1.2-1.7c-0.8-0.3-1.5,0-2,0.9
		c-0.6,1.4-1.4,2.7-2,4.1c-0.1,0.3-0.4,1,0.4,1.2c2.4,0.6,4.7-1.2,4.7-3.7C207.8,409,207.8,408.8,207.8,408.5z M106.4,591.9
		c-0.3-0.5-0.6-0.6-1.1-0.6c-0.7,0.1-0.4,0.6-0.4,0.9c-0.2,2.2,0.1,4.3,0.9,6.3c0.3,0.7,0.6,1.8,1.7,1.7c1.2-0.1,0.6-1.2,0.8-1.9
		c0-0.2,0-0.4,0-0.8C108.5,595.5,107.5,593.7,106.4,591.9z M118.8,574.2c0.5-0.2,1.4,0.1,1.6-0.6c0.2-0.6-0.4-1.2-0.8-1.6
		c-1.7-1.9-5.1-1.8-6.9-0.1c-0.6,0.5-0.6,0.9,0.2,1.3c1.6,0.9,3.4,0.7,4.7,1C118.2,574.2,118.5,574.3,118.8,574.2z M85.3,653.3
		c-0.7-1.5-3.9-3.5-5.5-3.5c-0.8,0-1.2,0.4-1,1.1c0.3,1.5,2.5,4.4,4.6,4.1C84.9,654.9,85.8,654.2,85.3,653.3z M221.1,416.8
		c-0.6-0.7-1-0.6-1.4,0.2c-1,2.2-0.7,4.4,0.3,6.5c0.5,1,1.1,0.9,1.8,0.2c0.8-0.8,1.2-1.8,1-3C222.8,419.1,222.1,417.9,221.1,416.8z
		 M276.9,417.2c-0.5-0.4-1.3-0.7-1.5,0.2c-0.5,2.3-0.2,4.5,1.5,6.3c0.4,0.5,1.1,1.1,1.8,0.8c0.7-0.3,0.3-1.1,0.4-1.7
		c0-0.2,0-0.5,0-0.8C279.2,420,278.5,418.4,276.9,417.2z M267.9,490.5c-0.5,0.4-0.6,0.9-0.6,1.4c-0.1,0.8,0.7,0.4,1,0.6
		c1.5,0.5,2.9,0.5,4.5,0.3c1.2-0.2,1.9-0.8,2.5-1.8c0.3-0.6,0.3-0.8-0.4-1.1c-0.9-0.3-1.8-0.2-2.7-0.2
		C270.6,489.7,269.2,489.5,267.9,490.5z M255.6,455.9c0,0.8,0.1,1.6,0,2.4c-0.1,1,0.4,1.5,1.3,1.7c1.1,0.3,1.6-0.4,2-1.2
		c0.9-2.1-0.1-5-2.2-6.1c-0.7-0.4-1.1-0.3-1.1,0.6C255.6,454.2,255.6,455.1,255.6,455.9L255.6,455.9z M136.2,573.6
		c0.7,0.1,1.1-0.3,1.1-0.9c0.2-2.3-2.3-5.4-4.6-5.7c-0.7-0.1-0.9,0.2-0.9,0.8C131.8,570,134.2,573.2,136.2,573.6z M319.5,508.6
		c-0.5-1.1-1.6-1-2.6-0.9c-1.2,0.1-1.2,1-1,1.8c0.4,1.6,1.4,3.1,2.3,4.5c0.3,0.4,0.3,1.1,1,0.9c0.6-0.1,0.3-0.8,0.6-1.1
		c0.4-0.6,0.4-1.3,0.4-2.1C320.3,510.5,319.9,509.6,319.5,508.6z M44.7,453.3c0.6,1.3,0.6,2.7,0.8,4.1c0.2,1.1,0.9,1.7,1.8,2
		c0.8,0.3,1.5-0.2,2-0.8c0.6-0.8,0.5-1.6,0-2.4c-0.7-1.2-1.7-2-2.8-2.8c-0.4-0.3-0.5-1.1-1.2-0.8C44.7,452.5,44.5,452.7,44.7,453.3z
		 M12.7,534.4c0.1-2-1.3-1.5-2.4-1.4c-1.1,0-2.1-0.2-2.5-1.4c-0.1-0.3-0.2-0.4-0.5-0.4c-0.3,0-0.4,0.2-0.5,0.5
		c-1,2.3,0.8,5.1,3.3,5.1C11.7,536.6,12.6,535.8,12.7,534.4z M113.6,646.1c-0.6,0.4-0.5,0.9-0.6,1.4c-0.1,0.6,0.3,0.8,0.8,0.9
		c2.4,0.5,4.7,0,6.8-1.2c0.7-0.4,0.5-0.8,0-1.2c-1-0.8-2.1-0.7-3.3-0.7C116,645.1,114.8,645.2,113.6,646.1z M213.1,410
		c-0.6-0.4-1.4,0.2-1.7,0.6c-1.3,1.5-2.2,3.2-2.7,5.1c-0.1,0.3,0,0.8,0.5,0.8c1.2,0.2,2.4,0.2,3.2-0.9c0.8-1.1,1.2-2.4,1.2-4
		C213.7,411.1,213.6,410.3,213.1,410z M81.2,511.4c0-0.9-0.6-1.1-1.2-0.6c-1.9,1.5-3,3.5-3.3,5.9c-0.1,1,0.5,1.4,1.5,1
		c2.1-0.8,3.2-2.5,3.2-5C81.2,512.4,81.2,511.9,81.2,511.4z M269.5,438.6c-0.8-0.2-1.8,0.4-2.2,1.2c-0.8,1.4-1.6,2.9-2.3,4.3
		c-0.2,0.3-0.2,0.8,0.3,0.8c0.7,0,1.3,0.4,2.1,0.2c1.6-0.4,2.6-2.1,2.6-4.6C269.7,439.9,270.3,438.8,269.5,438.6z M120.4,400.6
		c-0.2-0.6-0.5-1.1-1.3-0.4c-1.7,1.6-2.6,3.6-2.6,5.9c0,1.2,0.7,1.5,1.8,1c1.9-1,2.3-2.7,2.4-4.9
		C120.6,401.8,120.6,401.2,120.4,400.6z M29.1,567.3c-0.1,1,0.2,1.9,1.3,2.1c1,0.1,1.4-0.7,1.7-1.5c1-2.2-0.2-4.2-0.6-6.3
		c-0.1-0.5-0.6-0.3-0.9-0.3c-0.3,0-0.5,0-0.5,0.4c0,1.7-1.3,3.2-0.9,4.8C29.1,566.8,29.1,567,29.1,567.3z M78.1,523.8
		c-1-0.2-2-0.2-2.7,0.8c-0.7,1.1-1,2.4-1.6,3.5c-0.4,0.7,0.3,1.3,1,1.3c2,0,4.3-1.6,4.1-4C79.1,524.8,79,524,78.1,523.8z
		 M155.4,432.3c0-0.9-0.1-1.7-1.2-2c-1.1-0.3-2.1-0.1-2.6,1c-0.3,0.8-0.9,1.4-1.3,2.2c-0.8,1.7-0.6,2.1,1.3,2
		C154,435.4,155.4,434.2,155.4,432.3z M16.4,471.9c-0.8-0.4-1.1,0.1-1.5,0.4c-2,1.8-2.7,4.2-2.6,6.8c0,0.3,0,0.6,0.4,0.7
		c0.5,0.1,0.5-0.3,0.7-0.6c0.8-1.2,1.5-2.4,2.7-3.3c0.8-0.6,1.3-1.5,1.4-2.5C17.5,472.7,17.1,472.3,16.4,471.9z M314.8,515.3
		c-0.4-1.1-1.9-1.4-2.5-0.4c-0.9,1.5-1.5,3.2-2.2,4.8c-0.3,0.6-0.1,1,0.7,1c1.8,0.1,4.4-1.3,4.2-3.8
		C315,516.4,315,515.8,314.8,515.3z M91,659.3c-0.6,0.2-1.1,0.5-0.4,1.1c1.7,1.6,6.5,1.8,8.4,0.5c0.8-0.5,0.9-0.9-0.1-1.5
		c-1.2-0.7-2.5-0.4-3.7-0.5C93.7,658.9,92.3,658.8,91,659.3z M259.5,503.7c0.2-1.1-0.1-1.4-1.2-1.1c-2.3,0.6-3.8,2.2-5.3,3.8
		c-0.3,0.3-0.3,0.9-0.2,1.3c0.2,0.5,0.7,0.2,1.1,0.3C256.3,507.7,258.9,506.5,259.5,503.7z M318.2,465.2c-0.2-0.6-1-1.3-1.3-0.6
		c-0.8,1.9-2.5,3.7-2,6.1c0.2,1.3,1.1,1.8,2.3,1.1c1.4-0.9,1.5-2.3,1.5-3.8C318.8,467,318.5,466.1,318.2,465.2z M38.5,549.4
		c0.5-0.5,0.4-1.1,0.3-1.7c-0.2-0.7-0.9-0.6-1.4-0.6c-0.8,0-1.5,0.3-2.2,0.7c-1.1,0.8-2,1.9-2.9,2.8c-0.3,0.3-0.9,0.5-0.7,1
		c0.2,0.7,0.9,0.2,1.3,0.4C35.1,552,36.9,550.9,38.5,549.4z M27.9,550.1c0.1-0.8-0.5-1.3-0.8-1.6c-0.5-0.4-1,0.3-1.4,0.6
		c-1.4,1.1-2,2.8-2.9,4.2c-0.2,0.3-0.4,1,0.4,1.2c2.1,0.6,4.8-1.3,4.8-3.6C27.9,550.6,27.8,550.4,27.9,550.1z M191.7,588.3
		c2.8,0.2,4.7-1.3,5.2-4c0.1-0.4,0.2-0.9-0.2-1.1c-0.4-0.2-0.9-0.1-1.3,0.1c-1.1,0.6-2.2,1.1-3.3,1.7c-0.8,0.5-1.5,1.1-1.5,1.8
		C190.6,588,190.8,588.2,191.7,588.3z M244.1,445c-0.6,0.8-0.6,1.5,0.5,2c2.1,1,4.8,0.3,6-1.6c0.4-0.6,0.7-1.5-0.7-1.4
		c-0.7,0-1.5-0.2-2.1-0.3C246.3,443.7,245,443.9,244.1,445z M314.7,625.4c0-0.9,0-1.7,0-2.6c0-0.5,0.2-1.3-0.6-1.3
		c-0.7,0-1.3-0.3-2,0.4c-1.7,1.8-0.4,5.7,1.6,6.7c0.8,0.4,1.2,0.3,1.1-0.8C314.7,627,314.8,626.2,314.7,625.4
		C314.7,625.4,314.7,625.4,314.7,625.4z M8.7,518.4c-0.4-1.1-1.8-2.2-2.5-1.9c-0.8,0.3-1.2,2-0.8,3.1c0.1,0.3,0.4,0.5,0.5,0.7
		c0.5,1,1.3,1.9,0.5,3.2c-0.2,0.3-0.3,1,0.2,1.2c0.5,0.2,0.9-0.1,1.3-0.5c0.8-1.1,1.3-2.2,1.2-3.7C9.1,519.8,8.9,519.1,8.7,518.4z
		 M55.9,486.7c-1.1-0.2-1.9,0.3-2.2,1.4c-0.4,1.3-1.1,2.2-2.3,2.8c-0.3,0.2-0.5,0.3-0.5,0.6c0,0.5,0.3,0.6,0.7,0.6
		c1.3,0,2.7-0.2,3.8-0.8c1.1-0.6,2.1-1.4,1.9-2.9C57.7,487.1,56.8,486.8,55.9,486.7z M166.7,427c-0.2-1.3-1.1-1.8-2.1-2.2
		c-1.2-0.4-1.9,0.2-1.5,1.4c0.5,1.5,1.3,3,1.9,4.5c0.3,0.8,1,1.4,1.6,1.3c0.7-0.1,1.1-1,0.9-1.7C167.7,429,166.9,428.1,166.7,427z
		 M108.1,435.4c0.4,0.3,0.8,0.7,1.3,0.4c0.6-0.3,0.3-0.9,0.3-1.4c-0.1-2.3-1.4-4.1-2.4-5.9c-0.2-0.4-0.5-1.2-1.2-0.9
		c-0.6,0.2-0.2,0.9-0.3,1.4c-0.1,0.4,0,0.8,0,1.4C105.5,432.4,106.3,434.1,108.1,435.4z M242.8,607.3c-0.3-0.7-0.7-0.6-0.9-0.1
		c-1.2,2-2.3,4.1-2.4,6.5c0,0.4-0.3,0.9,0.2,1.1c0.4,0.2,0.8,0,1.3-0.2c1.7-1,2.5-2.5,2.5-4.7C243.7,609,243.2,608.2,242.8,607.3z
		 M6.6,573.1c1.1,0.5,2.1,1.1,3.1,1.7c0.4,0.2,0.9,0.4,1.3,0c0.4-0.4,0.2-0.8,0-1.2c-0.5-1.2-1.5-2.2-2.1-3.4
		c-0.6-1.1-1.4-1.4-2.5-1.2c-1.1,0.2-0.7,1.3-0.9,1.9C5.5,571.9,5.7,572.7,6.6,573.1z M85.5,556.3c-0.9-0.9-1.5-0.1-1.5,0.4
		c-0.2,2.2-0.4,4.5,0.9,6.6c0.4,0.6,1.1,0.7,1.4,0c0.5-0.9,1.1-1.7,1-2.8C87.1,558.9,86.7,557.5,85.5,556.3z M5.6,560.1
		c-0.4-1.1-0.7-2.2-1.6-3c-0.4-0.4-0.9-0.5-1.4-0.4c-0.5,0.2-0.4,0.6-0.4,1c-0.2,2.9,1.4,4.9,3.3,6.8c0.2,0.2,0.7,0.4,1.1,0.2
		c0.3-0.2,0.2-0.5,0.2-1.1C6.1,562.7,6.2,561.3,5.6,560.1z M274,464.6c-0.3-0.5-1-1.1-1.3-0.2c-0.7,1.8-0.9,3.8-0.9,5.7
		c0,1,0.7,1.6,1.5,1.8c0.8,0.1,1.2-0.7,1.6-1.4c0.3-0.6,0.3-1.3,0.3-2.2C275,467.2,274.8,465.8,274,464.6z M294.6,468.1
		c0-0.4,0-0.7,0-1.1c0-1.8-1.1-3.1-2.2-4.4c-0.3-0.3-0.8-0.6-1.4-0.4c-0.5,0.2-0.3,0.6-0.3,0.9c0.3,2.4,1,4.7,2.3,6.7
		c0.3,0.5,0.7,0.8,1.2,0.7c0.6-0.1,0.3-0.7,0.4-1C294.6,469,294.6,468.6,294.6,468.1L294.6,468.1z M81.2,677.2
		c0.3-0.1,0.6-0.1,0.6-0.5c0-0.3,0-0.5-0.4-0.7c-2.2-1.2-4.4-2.4-6.6-3.6c-0.3-0.2-0.7-0.3-0.9,0.1c-0.1,0.3-0.1,0.7,0,1
		c0.8,2.2,3.4,3.9,6.1,4C80.3,677.4,80.7,677.3,81.2,677.2z M100.9,479.9c2-0.5,3.7-4,2.4-6c-0.6-0.9-1.3-0.8-1.7-0.2
		c-1.1,1.3-2.2,2.6-2.2,4.4C99.4,479.9,99.7,480.3,100.9,479.9z M32.4,545.5c-1.1-1.7-1.9-3.6-4.1-4.2c-0.4-0.1-0.9-0.4-1.2,0
		c-0.3,0.4-0.4,0.9-0.1,1.4c0.7,1.1,1.4,2.2,2.1,3.4c0.5,0.8,1.1,1.2,2.1,1.1C32.4,547.2,33,546.5,32.4,545.5z M88.6,515.2
		c-0.7-0.4-1.2,0.1-1.6,0.4c-1.5,1.2-2.6,2.8-3.9,4.3c-0.1,0.2-0.1,0.6-0.1,0.7c0.2,0.1,0.6,0.2,0.8,0.1c1-0.3,2-0.4,2.9-0.7
		c1.7-0.4,2.3-1.2,2.3-3.1C88.8,516.4,89.5,515.6,88.6,515.2z M285.1,653.1c1.5-0.4,3.1-3.1,2.8-4.6c-0.2-1.1-0.9-1.5-2.1-1.5
		c-1.1,0-1.6,0.5-1.6,1.5c0,1-0.6,2-0.4,2.8C283.9,652.3,283.5,653.5,285.1,653.1z M269,470.1c-0.6-1.2-1.2-2.4-1.8-3.6
		c-0.3-0.6-0.7-1.4-1.3-1.1c-0.7,0.3-0.3,1.3-0.2,1.9c0,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.4,0,0.6c-1.2,0.8-0.8,1.9-0.7,3
		c0.1,1.3,0.7,1.9,1.9,1.7c1.2-0.2,1.6-1,1.5-2.5C269,470.7,269.1,470.4,269,470.1z M141.4,400.9c-0.2-0.8-0.8-0.5-1.2-0.3
		c-1.7,1.2-3.1,2.6-4.4,4.2c-0.3,0.4-0.5,0.9-0.3,1.4c0.2,0.6,0.8,0.3,1.2,0.4C139.2,406.5,142,403.1,141.4,400.9z M84.8,621.5
		c-0.1-0.2-0.1-0.5-0.3-0.7c-0.5-1.1-1.6-1.1-2.6-1.3c-0.4-0.1-0.8-0.4-1.1,0c-0.2,0.3-0.1,0.8,0.2,1.1c0.9,1.1,1.7,2.2,2.3,3.5
		c0.3,0.7,1,1.1,1.5,1.3c0.8,0.4,2,0.6,2.6-0.1c0.4-0.4,0.4-1.7-0.5-2.2C85.7,623.3,85.1,622.6,84.8,621.5z M239.8,583.1
		c0.4,1,1.1,1.8,2.3,1.5c1-0.2,1.1-1.2,1-2.1c-0.1-1.4-0.4-2.7-1.3-3.8c-0.4-0.5-0.7-1.2-1.5-1.1c-0.7,0.2-0.6,1-0.7,1.5
		c-0.1,0.5-0.2,1-0.2,1.5C239.2,581.5,239.6,582.3,239.8,583.1z M211.7,601.2c0,0.9,0,1.8,0,2.7c0,1,0.6,1.4,1.3,1.5
		c0.8,0.1,1.3-0.3,1.6-1.1c0.7-1.9-0.4-5.2-2-6.4c-0.6-0.4-0.9-0.4-0.9,0.4C211.7,599.3,211.7,600.3,211.7,601.2L211.7,601.2z
		 M308.7,538c-0.9,0-1.8,0-2.7,0c-0.6,0-1.1,0.4-1.4,1c-0.2,0.6,0.2,1,0.5,1.3c1.5,1.4,5.5,0.9,6.5-0.8c0.3-0.4,0.3-0.9,0.2-1.3
		c-0.1-0.6-0.7-0.3-1-0.3C310.1,538,309.4,538,308.7,538C308.7,538,308.7,538,308.7,538z M250.8,689.9c-0.1,0.8,0.6,0.9,1,0.6
		c1.7-1.4,3.2-3.1,3.2-5.5c0-0.6,0-1.2-0.7-1.3c-0.5-0.1-1-0.2-1.6,0.4c-1.3,1.2-1.9,2.6-1.7,4.4
		C250.9,688.9,250.9,689.4,250.8,689.9z M26.7,510.6c-0.4-0.2-0.7-0.7-1.2-0.3c-0.3,0.3-0.3,0.9-0.1,1.3c0.8,1.7,1.6,3.4,2.4,5
		c0.4,0.7,1.1,1,1.8,0.8c0.7-0.2,0.5-1,0.6-1.4C30.3,514,29,512,26.7,510.6z M35.8,506.2c0.7-0.8,0.6-2.8-0.1-3.3
		c-0.8-0.6-2.2-0.1-2.9,0.9c-0.5,0.8-1,1.6-1.5,2.3c-0.3,0.5-0.5,0.9-0.3,1.4c0.2,0.7,0.9,0.2,1.3,0.3
		C33.7,508,34.8,507.3,35.8,506.2z M43.8,509.7c0.9,1.9,2.2,3.4,4,4.5c0.6,0.3,1.1,0.3,1.1-0.7c0-1.5-0.6-2.8-1.4-4
		c-0.5-0.8-1.2-1.5-2.3-1.3C43.6,508.2,43.2,508.6,43.8,509.7z M268,456.7c-0.3-1.3-1.3-1.6-2.5-1.5c-0.7,0.1-1.6-0.2-1.5,0.9
		c0.1,1.1-0.3,2.5,1.3,2.8c0.8,0.2,0.8,0.2,0.4,0.9c-0.4,0.6-0.1,1.3,0.5,1.7c0.8,0.5,1-0.3,1.4-0.7c0.7-0.7,0.2-1.5,0.3-2.2
		C268.1,457.9,268.2,457.3,268,456.7z M63.7,437.2c1.1,0.2,1.9-0.6,2.1-2c0.2-1.9-0.7-3.3-2-4.4c-0.5-0.4-1.1-0.5-1.2,0.5
		c-0.1,1.2-0.3,2.4-0.4,3.8C62.1,436,62.4,437,63.7,437.2z M57.4,545.7c-0.3-1.9-4.4-3.4-5.9-2.2c-0.5,0.4-0.6,0.8-0.2,1.4
		c1.1,1.5,2.6,2.1,4.4,2.3C56.8,547.2,57.6,547,57.4,545.7z M24.4,455.3c1.1,1.8,3,1.8,4.8,2.1c0.7,0.1,0.6-0.8,0.5-1
		c-1.1-1.1-1.4-2.7-2.6-3.7c-0.6-0.5-1.2-0.7-2.1-0.4c-0.7,0.3-1,0.8-1,1.5C23.9,454.4,24.1,454.9,24.4,455.3z M211.5,582.3
		c1.4-0.8,2.6-1.8,3.4-3.2c0.4-0.6,0.3-1.4,0-2c-0.3-0.6-1-0.5-1.5-0.4c-1.4,0.4-3.6,2.9-3.5,4.4
		C209.9,582.3,210.6,582.9,211.5,582.3z M125.6,444.1c-0.5-1.1-1.4-1.3-2.4-1c-1,0.3-0.9,1.1-0.7,2c0.3,0.9,0.3,1.8,0.7,2.6
		c0.4,0.7,0.3,2.1,1.4,2c1.2,0,1.2-1.3,1.4-2.1c0.1-0.3,0.1-0.7,0.2-0.8C126.2,445.7,126,444.9,125.6,444.1z M269.5,419.8
		c0.2,0.4,0.4,0.8,1,0.8c0.5,0,0.5-0.5,0.7-0.8c0.7-1.3,0.7-2.9,1.4-4.2c0.4-0.7-0.3-1.5-0.7-2c-0.3-0.5-0.9,0.1-1.4,0.3
		c-1.6,0.6-1.4,1.9-1.6,2.8C268.8,417.9,269,418.9,269.5,419.8z M273.8,504.6c-0.7-0.2-1.5-0.3-2.1,0.6c-0.7,1.3-1.7,2.6-2.5,3.8
		c-0.3,0.4-0.4,0.9,0.4,1c2.6,0.4,5.1-1.6,4.9-3.9C274.5,505.6,274.6,504.8,273.8,504.6z M318.6,540.1c0-1-0.5-1.1-1.1-0.7
		c-1.1,0.7-2.2,1.1-3.3,1.8c-0.8,0.5-1.5,1.4-1.2,2.6c0.2,0.9,0.9,0.6,1.4,0.7C317,544.5,318.7,542.6,318.6,540.1z M255.6,555.9
		c-0.2-1.3-3-4.4-4.2-4.7c-0.6-0.1-0.7,0.3-0.5,0.7c0.4,1.5,0.7,3.1,1.2,4.6c0.3,0.9,1.1,1.4,2.2,1.2c0.7-0.2,1.5-0.4,1.4-1.4
		C255.6,556.2,255.6,556,255.6,555.9z M136.4,555.4c-0.6-0.2-1.3-0.1-1.8,0.5c-1.3,1.4-1.9,3.1-2.4,4.8c-0.4,1.3,0.5,1.1,1.1,1
		c1.9-0.5,3.6-2.8,3.7-4.9C136.8,556.3,137,555.7,136.4,555.4z M323.3,629.9c-0.2-0.3-0.2-0.8-0.4-1.1c-0.6-1.2-0.9-2.6-2-3.4
		c-0.6-0.4-0.9-0.4-1.5,0.2c-1.2,1.3-0.6,2.5,0,3.6c0.6,1.1,1.7,1.9,3.1,2C323.5,631.3,324.4,631.3,323.3,629.9z M339.3,546.4
		c0-0.9-0.7-1-1.3-1.2c-0.6-0.2-1.1-0.1-1.4,0.5c-0.9,1.6,0.3,6,1.9,7c0.3,0.2,0.6,0.3,0.8,0.2c0.1-0.1,0.1-0.4,0.2-0.6
		C339.2,550.3,339.3,548.4,339.3,546.4z M173.6,437c-1-0.4-1.6,0.3-2.2,1.1c-1,1.2-1.1,2.7-1.6,4.1c-0.4,1,0.6,1,1.2,0.9
		c1.8-0.3,3.4-2.2,3.4-4.1C174.3,438.2,174.4,437.3,173.6,437z M134.7,480.5c0,0.4-0.1,1,0.6,1c0.6,0,1.1,0,1.2-0.8
		c0.1-0.7,0.3-1.5,0.6-2.2c0.6-1.4,0.3-2.9,0.2-4.3c0-0.6-0.6-1.1-1.2-1.3c-0.8-0.2-0.6,0.7-0.8,1c-0.7,1.4-0.7,3-0.7,4.6
		C134.7,479.2,134.7,479.8,134.7,480.5z M271.7,410.5c0.4-0.7-0.3-1.2-0.6-1.7c-1.1-1.3-4.1-2-5.6-1.3c-0.3,0.1-0.8,0.2-0.7,0.8
		c0,0.5,0.1,0.8,0.5,1.1c1.5,1,3.2,1.1,4.8,1.6C270.6,410.8,271.4,411.1,271.7,410.5z M130.4,540.3c-0.2-0.3-0.5-0.5-0.9-0.4
		c-0.4,0.1-0.4,0.4-0.3,0.7c0.1,1.7,0.1,3.4,0.5,5.1c0.2,0.9,0.7,2.3,1.9,2.2c1.2-0.1,0.6-1.5,1-2.1
		C132.5,543.9,131.7,541.6,130.4,540.3z M22.3,522.4c0.8,0.7,1.7,1.2,2.7,1.5c0.7,0.2,1.3,0.8,1.9,0.2c0.7-0.7,0.3-1.6-0.2-2.3
		c-0.9-1.4-2.4-1.7-3.8-2.3c-1.2-0.5-1.4,0.1-1.5,1.1C21.4,521.3,21.8,521.9,22.3,522.4z M312.2,461c-0.8-0.6-1.9-0.6-2.5-0.2
		c-0.6,0.4-0.4,1.5-0.1,2.2c0.5,1,1.1,1.8,1.8,2.7c0.9,1.2,1.6,1.1,2.2-0.3c0.2-0.4,0.1-0.9,0.2-1.3
		C313.9,462.8,313.3,461.8,312.2,461z M9.3,583.4c0.6,0.1,0.7-0.3,0.6-0.7c-0.7-1.9-1.5-3.8-2.7-5.4c-0.2-0.3-0.6-0.8-1.1-0.5
		c-0.4,0.3-0.9,0.6-0.8,1.2c0,0.3,0,0.7,0,1C5,581.6,7.5,583,9.3,583.4z M34.6,518.6c1,1.7,2.4,3,4.2,3.8c0.7,0.3,1.7,0.4,2.1-0.1
		c0.5-0.6-0.4-1.1-0.8-1.6c-0.1-0.2-0.2-0.4-0.3-0.5c-1.2-1.3-2.4-2.6-4-2.9C34.5,517.3,34.1,517.8,34.6,518.6z M228.4,430.7
		c-0.4-0.4-0.4-1.2-1.3-1c-0.8,0.2-0.8,0.8-0.9,1.4c-0.4,1.6-0.8,3.3-1.2,5c-0.1,0.3-0.2,0.6,0,0.8c0.3,0.2,0.7,0.2,1-0.1
		c1.5-1,2.9-2.1,2.7-4.3C228.8,431.9,228.9,431.2,228.4,430.7z M299.3,589.2c-0.6,0-1.2,0-1.3,0.9c-0.2,1.6,1.5,4.1,3,4.6
		c0.9,0.3,1.8-0.4,1.8-1.4C302.8,591.6,300.8,589.3,299.3,589.2z M261.9,562.7c-1.5-1.1-2.8-0.5-4.3,0c-0.4,0.1-0.9,0.2-1.1,0.7
		c-0.2,0.6,0.4,0.9,0.7,1.2c1.1,1,2.4,1,3.7,1.3c1.3,0.3,1.8-0.3,1.8-1.5C262.6,563.7,262.5,563.2,261.9,562.7z M12.9,538.9
		c-1.1-0.2-1.2,0.2-1.1,1.1c0.2,2,2.4,3.9,4.4,4c0.7,0,1.2-0.2,1.1-1C17.2,541.5,15.7,539.5,12.9,538.9z M284.5,611.5
		c-0.7-0.4-1.1,0.4-1.6,0.8c-0.3,0.2-0.5,0.5-0.7,0.7c-0.9,1.1-1.8,2.2-2,3.8c-0.1,0.9,0.4,1.2,1.2,1c1.4-0.3,3.7-3.2,3.7-4.8
		C285,612.5,285.1,611.8,284.5,611.5z M45.2,584.4c-0.6-1.7-1.8-3.1-3.5-3.8c-0.5-0.2-1.2-0.4-1,0.5c0.6,2.5,1.7,4.8,4,6.2
		c0.4,0.3,0.9,0.2,1.3,0.1c0.3-0.1,0.2-0.4,0.2-0.8C46.1,585.8,45.5,585.1,45.2,584.4z M2.6,547c0-1,0-1.9,0-2.9
		c0-0.9-0.5-1.4-1.3-1.5c-0.9-0.1-0.9,0.8-1,1.3c-0.4,2.3,0.2,4.5,0.6,6.7c0.1,0.4,0.4,0.8,1,0.8c0.6,0,0.5-0.6,0.5-0.9
		c0.2-1.1,0.2-2.3,0.3-3.5C2.7,547,2.7,547,2.6,547z M338.9,560.2c-0.9-0.7-1.5-1.7-1.9-2.7c-0.1-0.3,0.1-0.9-0.6-0.8
		c-0.5,0.1-0.4,0.5-0.4,0.8c-0.4,1.9-0.2,3.7,0.7,5.4c0.6,1.1,1.6,0.9,2.5,0.9c0.9,0,0.4-1.1,0.7-1.6
		C339.7,561.4,339.6,560.7,338.9,560.2z M327.8,493.8c0.3,0.7,0.8,1.4,1.8,1.3c0.9-0.1,0.5-0.9,0.7-1.4c0.1-0.2,0.1-0.5,0.2-0.7
		c0.1-1.9-0.2-3.7-1.6-5.1c-0.5-0.4-1-0.8-1,0.3c-0.1,1-0.4,2-0.3,3C327.3,492.1,327.4,493,327.8,493.8z M299.4,443.4
		c-1.2-0.4-1.4,0.8-1.6,1.5c-0.4,1.3-0.7,2.6-1.1,3.9c-0.2,0.7,0.1,1.2,1,0.9c1.5-0.4,3.2-2.6,3.3-4.2
		C301.1,444.5,300.3,443.7,299.4,443.4z M319.2,522.3c-0.3-0.5-0.7-1.3-1.6-1.3c-0.6,0-0.7,0.4-0.7,0.8c-0.1,1.8-0.3,3.6-0.4,5.4
		c0,0.5,0.2,0.9,0.6,1.1c0.5,0.2,0.9-0.1,1.2-0.4c1-0.9,1.3-2.1,1.2-3.4C319.7,523.7,319.5,522.9,319.2,522.3z M18.1,481.5
		c0,0.3,0,0.5,0,0.8c0,0.3,0,0.5,0,0.8c0,1,0.5,1.4,1.4,1.1c1.4-0.6,2.5-3.4,1.9-4.8c-0.4-0.9-1.1-1.5-2.3-1.3c-1.1,0.2-1,1-1,1.7
		C18.1,480.3,18.1,480.9,18.1,481.5C18.1,481.5,18.1,481.5,18.1,481.5z M332.1,555.8c0.1,0.4,0,1,0.6,1.1c0.6,0.1,0.9-0.4,1.1-0.8
		c0.6-1,1.1-2,1.6-3c0.4-0.9,0.5-2,0-2.7c-0.4-0.5-1.5-0.1-2.2,0.4c-1.3,0.9-1.2,2.2-1.3,3.4C332,554.7,331.9,555.3,332.1,555.8z
		 M296,635.5c-0.9-1.1-2.1-1.5-3.5-1.7c-0.8-0.1-1.7-0.3-2.1,0.3c-0.6,0.8,0.6,1.1,1,1.5c1,1.3,2.3,2.4,4.1,2.5
		c0.8,0.1,1.3-0.1,1.2-1.3C296.5,636.6,296.4,636,296,635.5z M220.3,573.7c-0.2-0.2-0.4-0.8-0.9-0.6c-0.6,0.2-0.3,0.7-0.4,1
		c0,0.9-0.1,1.7,0,2.6c0.1,1.3-0.2,2.6,0.8,3.8c0.3,0.3,0.5,0.6,1,0.6c0.5-0.1,0.6-0.3,0.8-0.8c0.3-0.7,0.3-1.5,0.3-2.5
		C222,576.3,221.5,574.9,220.3,573.7z M84,429.1c0.6,0.6,1.6,0.9,2.4,0.5c0.6-0.3,0.2-1.1,0.1-1.8c-0.2-1.5-1.1-2.5-2-3.5
		c-0.5-0.5-1.3-0.9-2-0.8c-0.6,0.1-0.2,0.7-0.4,1.2C82.5,426.2,82.8,427.9,84,429.1z M162,526c-0.6,0-1,0.3-0.7,0.8
		c0.9,1.7,1.6,3.6,3.4,4.6c0.9,0.5,1.7,0.1,1.7-1C166.4,528.2,164.1,525.9,162,526z M330.5,484.2c0,1,0,1.9,0,2.9
		c0,0.8,0.8,1.2,1.3,1.6c0.4,0.2,0.8-0.1,1.1-0.6c0.8-1.7,0.1-5.6-1.3-6.9c-0.2-0.2-0.4-0.4-0.8-0.4c-0.5,0.1-0.3,0.6-0.3,0.9
		C330.4,482.5,330.5,483.4,330.5,484.2C330.5,484.2,330.5,484.2,330.5,484.2z M48.9,530.5c0.2,1.4,3.7,4.3,5.1,4.2
		c0.4,0,0.7,0,0.7-0.5c-0.2-2.3-2.1-4.7-4.6-4.7C49.5,529.5,48.8,529.5,48.9,530.5z M96.2,450.2c0,0.9,0,1.8,0,2.7
		c0,0.6,0,1.3,0.8,1.4c0.8,0.1,1.3-0.4,1.4-1.2c0.3-1.2,0.1-2.5,0.2-3.8c0.1-1.1-0.3-1.9-1.2-2.6c-0.3-0.2-0.5-0.4-0.9-0.3
		c-0.4,0.1-0.4,0.5-0.4,0.9C96.1,448.3,96.1,449.3,96.2,450.2C96.1,450.2,96.1,450.2,96.2,450.2z M167.8,550.9c0.7,0,1.5-0.1,1.6-1
		c0.1-1-0.3-2.2-1.2-2.3c-1.5-0.3-3.1-0.4-4.4,0.8c-0.6,0.6-0.7,1.1,0,1.6c0.9,0.7,1.9,1,2.9,0.9
		C167.2,550.9,167.5,550.8,167.8,550.9z M170.1,480.3c-0.5-0.1-1.6-0.3-1.2,0.7c0.6,1.6,1,3.3,2.2,4.6c0.5,0.6,1.1,0.8,1.7,0.6
		c0.8-0.2,0.5-1,0.6-1.5C173.4,482.7,171.9,480.7,170.1,480.3z M278.3,446c-2.1,0.9-3.7,2.4-5,4.2c-0.4,0.6-0.2,0.9,0.6,1
		c1.1,0.1,1.6-0.7,2.5-0.9c1.4-0.3,2.9-2.2,3-3.6C279.4,446,279.3,445.6,278.3,446z M77.9,440.3c-0.6,0.5-0.8,0.8,0.1,1.3
		c1.8,1,3.7,0.8,5.6,0.5c0.5-0.1,1.4-0.6,1.2-1.5c-0.1-0.7-0.6-0.8-1.3-0.9c-0.7-0.1-1.4-0.3-1.7-0.3
		C80.2,439.4,78.9,439.5,77.9,440.3z M76.3,624.2c-1.3-0.9-2.7-1.8-4.4-1.9c-0.4,0-0.9-0.4-1.2,0.1c-0.3,0.4,0.1,0.9,0.4,1.2
		c1,1.1,1.9,2.2,3,3.1c1,0.9,1.7-0.4,2.5-0.5c0.4-0.1,0.7-0.2,0.6-0.7C77.4,624.7,76.7,624.5,76.3,624.2z M278.3,411.6
		c0.3,0.2,0.7,0,1-0.3c1.2-1.2,2.5-2.3,3.7-3.5c0.6-0.6,1-1.3,0.4-1.9c-0.6-0.6-1.5-0.7-2.2-0.1c-1.6,1.2-2.5,2.9-3.1,4.9
		C278.1,411,277.9,411.5,278.3,411.6z M101.9,564.3c1.7-0.1,4.6-2.3,4.8-4c0.1-0.4,0.5-1.2-0.5-1.3c-2-0.2-4.9,2.4-4.9,4.3
		C101.3,563.8,101.1,564.3,101.9,564.3z M30.8,470.8c-0.2,2.3,1.8,3.4,2.7,5.1c0.1,0.3,0.7,0.2,0.9-0.2c0.6-1.5,0.6-2.9-0.1-4.4
		c-0.5-1.1-1.3-1.6-2.4-1.4C30.9,469.5,30.9,470.1,30.8,470.8z M162.4,573.2c0.6,0.7,1.4,0.8,1.8,0.1c0.8-1.7,0-5.2-1.5-6.4
		c-0.3-0.2-0.5-0.2-0.8-0.2c-0.4,0.1-0.3,0.4-0.3,0.7c0,0.7,0,1.3,0,2.2C161.4,570.7,161.5,572,162.4,573.2z M46.5,570.7
		c-0.8-1.6-2.4-2.6-3.6-3.9c-0.2-0.2-0.7-0.2-1,0.1c-0.3,0.3-0.2,0.7-0.1,1c0.8,1.5,1.6,3,2.3,4.5c0.4,0.9,1.2,1.3,1.8,1.2
		c0.7-0.1,0.5-1.2,0.6-1.9C46.7,571.3,46.7,570.9,46.5,570.7z M166.6,492.8c-0.1-0.8-0.7-1.5-1.4-2.1c-0.5-0.4-1.2-0.5-1.2,0.4
		c0,2.2,0.4,4.4,1.1,6.5c0.2,0.6,0.8,0.7,1.4,0.7c0.6-0.1,0.6-0.6,0.6-0.8C167,495.7,166.9,494.2,166.6,492.8z M125.7,600
		c-0.4-0.3-0.8-0.8-0.9,0.1c-0.3,2.6,0,5.1,1.5,7.3c0.2,0.3,0.5,0.7,1,0.5c0.4-0.2,0.2-0.6,0.2-1c0-0.6,0-1.2,0-1.9
		C127.6,603.1,127.2,601.4,125.7,600z M220.8,566.2c-0.2-0.9-0.5-1.3-1.5-0.6c-1.5,1.1-2.4,2.7-3.2,4.4c-0.3,0.7-0.1,1.2,0.8,1
		C219.5,571.1,221.2,568.3,220.8,566.2z M38.6,541.1c-0.4,0.1-1.1-0.3-1.3,0.3c-0.2,0.6,0.4,0.9,0.7,1.2c1,0.8,2.1,1.6,3.2,2.4
		c0.6,0.5,1.3,0.4,1.9,0.2c0.6-0.2,0.3-0.9,0.3-1.3c0-1.6-1.7-2.9-4-2.9C39.1,541.1,38.9,541,38.6,541.1z M113.7,597.4
		c-0.6,0-1.2,0-1.4,0.5c-0.2,0.5-0.1,1,0.3,1.5c1,1.1,2,2.2,3.4,2.8c0.4,0.2,0.7,0.8,1.2,0.4c0.4-0.2,0.1-0.8,0.2-1.1
		C117.3,599.8,115.8,597.6,113.7,597.4z M158.1,480.4c0.6,0.2,1-0.4,1.3-0.8c1.1-1.5,0.8-5.1-0.4-6.5c-0.2-0.3-0.4-0.6-0.9-0.4
		c-0.4,0.1-0.3,0.5-0.3,0.7c-0.1,1.8-0.3,3.6-0.5,5.9C157.4,479.4,157.4,480.2,158.1,480.4z M290.7,435.1c-0.4,0-0.8,0.1-0.7,0.6
		c0.3,1.6,0.5,3.3,1.4,4.7c0.3,0.5,0.7,1,1.5,0.8c0.7-0.2,0.4-0.8,0.6-1.2c0.2-0.4,0.2-0.9,0.2-1.1
		C293.6,436,292.7,434.9,290.7,435.1z M214.5,588.4c-0.4-0.2-0.8-0.4-1.1-0.1c-0.3,0.2-0.1,0.7-0.1,1c0.3,2.1,1.3,3.9,2.1,5.8
		c0.2,0.5,0.7,0.5,1.2,0.5c0.4,0,0.4-0.4,0.3-0.5c-0.6-0.8,0-1.5,0.1-2.5C216.5,591.1,216.3,589.3,214.5,588.4z M243.8,696.9
		c1.2-0.5,2.5-0.9,3.3-2c0.5-0.6,0.6-1.4,0.3-2.1c-0.3-0.6-1-0.6-1.6-0.6c-1.7-0.1-2.9,1.3-3,3.7
		C242.6,696.6,242.9,697.3,243.8,696.9z M62.3,541.4c-0.5-0.8-1.2-1.5-2.2-1.5c-0.7-0.1-1.3-0.1-0.9,0.9c0.6,1.7,1.3,3.4,2.7,4.7
		c0.4,0.4,0.7,0.9,1.3,0.7c0.6-0.2,0.1-0.9,0.3-1.3C63.5,543.6,63,542.5,62.3,541.4z M201.9,507.4c-1.3,0.8-2.5,1.8-2.9,3.4
		c-0.2,0.9,0.4,1.6,0.9,2.1c0.5,0.5,1.1,0,1.5-0.2c1.6-0.8,1.3-2.4,1.6-3.5C203,507.5,202.7,506.9,201.9,507.4z M343,544.3
		c0.2-1.2-0.4-1.9-1.6-2c-1.1,0-1.9,0.9-1.7,2.2c0.2,1.3,0.6,2.5,1.6,3.5c0.7,0.7,1.3,0.9,1.5-0.4c0.2-0.8,0.2-1.6,0.2-2.4
		C343,544.9,342.9,544.6,343,544.3z M65.9,607.5c0.8-0.8,0.8-1.6,0.4-2.5c-0.4-0.8-1.3-0.7-2-0.4c-1.3,0.6-2.2,1.6-3.2,2.5
		c-0.4,0.3-0.6,1.2,0.5,1.2c0.4,0,0.9,0.2,1.2,0.2C64,608.4,65.1,608.4,65.9,607.5z M95.1,655.1c0.1-0.2,0.1-0.5-0.2-0.7
		c-1.7-1.5-3-3.5-5.2-4.4c-0.4-0.2-0.8-0.4-1.1-0.1c-0.4,0.3-0.3,0.8-0.1,1.1c1.4,2.1,3.1,3.8,5.8,4.4
		C94.5,655.2,95,655.5,95.1,655.1z M213.3,496.7c-0.7-0.6-0.9-0.3-1.1,0.3c-0.4,1.2-0.5,2.3-0.5,3.6c0.1,1.1,1,1.4,1.7,1.8
		c0.7,0.4,0.8-0.5,1.2-0.7c0.9-0.4,0.2-1.2,0.3-1.3C215.5,498.2,214.3,497.6,213.3,496.7z M114.8,495.8c0.6,0.8,1.6,0.9,2,0.2
		c1-1.8-0.5-5.3-2.4-5.9c-0.4-0.1-0.7,0-0.7,0.5c0,0.4,0,0.8,0,1.2C113.9,494,114.2,494.9,114.8,495.8z M68.4,536.5
		c-0.3-0.1-0.7-0.2-0.9,0.2c-0.1,0.3,0,0.6,0.2,0.8c1.2,1.5,2.3,3.1,3.5,4.6c0.3,0.4,0.8,0.6,1.4,0.4c0.7-0.2,0.2-0.8,0.4-1.1
		C73.2,539.3,70.1,537,68.4,536.5z M206.5,579.2c-0.8-0.3-1.4,0.4-1.9,1c-1.1,1.4-1.4,2.9-1.4,4.6c0,0.3-0.2,0.7,0.2,1
		c0.4,0.2,0.8-0.1,1.1-0.3c1.2-1.3,2.4-2.7,2.4-4.6C206.7,580.3,207.2,579.5,206.5,579.2z M83.7,616.7c0.4-0.1,1,0.1,1.1-0.5
		c0.1-0.7-0.6-0.5-1-0.6c-2-0.7-4.1-1.3-6.1-1.9c-0.4-0.1-0.9-0.7-1.3,0c-0.3,0.6,0,1.1,0.5,1.6c1.6,1.4,3.6,1.5,5.7,1.7
		C82.9,616.8,83.3,616.8,83.7,616.7z M137,578.8c-0.2-0.9-0.7-1.7-1.6-2.2c-1.2-0.5-2.3-1.1-3.5-1.7c-0.5-0.2-1.1-0.4-1.4,0.2
		c-0.2,0.5,0,0.9,0.5,1.2c1.1,0.7,2,1.7,3,2.4c0.6,0.4,1,1.1,2.2,1.3C136.9,580.3,137.2,579.6,137,578.8z M28,556.1
		c-0.7,0.3-1.2,0.8-1.5,1.3c-0.7,1.2-1.5,2.3-2.4,3.3c-0.2,0.2-0.5,0.4-0.4,0.7c0.1,0.5,0.6,0.3,0.9,0.3c2.4,0,4.6-2.4,4.6-4.9
		C29.2,556,28.9,555.7,28,556.1z M248,685.1c-1.8-0.5-5.3,1.8-5.6,3.7c-0.1,0.9,0.4,1,1,0.7c1.5-0.7,2.9-1.5,4.4-2.2
		c0.6-0.3,0.9-0.8,1.1-1.3C249,685.3,248.4,685.2,248,685.1z M29.6,504.4c0.3-1-0.5-2-1.3-1.6c-1,0.5-2.1,0.7-3,1.4
		c-0.7,0.5-1.3,1.1-1.1,1.9c0.2,0.7,1.1,1.1,2,1C27.8,507,29.2,505.9,29.6,504.4z M331.4,503.9c0-1.3-0.6-1-1.3-0.5
		c-0.8,0.6-1.2,1.5-1.8,2.2c-0.6,0.8-1,1.7-0.4,2.6c0.5,0.6,2.4,0.2,3.2-0.6c0.5-0.5,0.6-1.1,0.5-1.8
		C331.8,505.2,331.4,504.6,331.4,503.9z M147.5,404.4c-0.2-0.5-0.6-0.8-1.1-0.8c-0.6,0-0.4,0.6-0.4,1c-0.2,1.4-0.4,2.8-0.5,4.2
		c-0.1,1,0.4,1.9,1.5,1.9c1.1,0,1.1-0.9,1.2-1.8c0-0.3,0-0.5,0-0.8C148.1,406.9,147.9,405.6,147.5,404.4z M250.7,505.9
		c0.4-0.9,0-1.4-1.1-1.4c-1.5,0-2.6,0.7-3.7,1.5c-0.7,0.5-0.7,1.1-0.6,1.7c0.2,0.7,0.9,0.7,1.4,0.8
		C248.3,508.4,250.1,507.2,250.7,505.9z M71.1,468.3c0.2,1.4,2.5,3.7,3.9,3.9c0.7,0.1,1.2-0.2,1.3-1.1c0.3-1.8-2.1-3.5-4-3.5
		C72,468,71,467.2,71.1,468.3z M116.1,503c0.5,0.2,1.1,0.5,1.5,0c0.4-0.4,0.3-1,0.2-1.6c-0.4-1.8-2-2.2-3.4-3
		c-1.1-0.6-1.3-0.4-1.3,0.7C113.4,501,114.3,502.4,116.1,503z M194.7,527.4c0.1,0.6,0.4,1.3,1,1.5c0.6,0.2,1-0.4,1.3-0.8
		c1.3-1.5,0.9-2.8-1-4.7c-0.4-0.4-0.7-0.6-1.2-0.5c-0.8,0.2-0.2,0.7-0.3,1.2C194.3,525.2,194.5,526.3,194.7,527.4z M178.9,548
		c0.4,0.7,0.7,1.6,1.7,1.2c1-0.3,1-1.2,1-2.1c0-0.7-0.2-1.3-0.7-1.8c-0.6-0.6-1.3-1.3-1.8-1.9c-0.2-0.3-0.4-0.6-0.8-0.4
		c-0.4,0.2-0.2,0.5-0.2,0.8c0,0.3,0,0.6,0,0.9C177.9,545.9,178.2,547,178.9,548z M297.3,538c0,0.5,0,0.9,0.7,1
		c1.1,0.2,4.1-0.7,4.7-1.7c0.1-0.1,0.1-0.3,0.1-0.4c0.3-2-1.2-1.1-1.9-1.3C298.6,535.6,297.3,536.4,297.3,538z M98.7,424.7
		c-0.3,0.4-0.4,0.8-0.3,1.3c0.2,0.5,0.7,0.4,1,0.3c1.5-0.2,3-0.4,4.2-1.3c0.5-0.4,1.2-0.8,0.9-1.6c-0.3-0.7-1-0.7-1.7-0.7
		C101.2,422.8,99.8,423.5,98.7,424.7z M279.1,526.9c0-0.8-0.4-1.1-1-0.5c-1.2,1.2-2.5,2.4-2.9,4.2c-0.2,1,0.3,1.5,1.4,1.3
		c1.5-0.3,2.6-1.3,2.5-3.6C279.1,527.8,279.1,527.4,279.1,526.9z M175.1,416.4c0.2,0.4,1.1,0.3,1.5-0.3c1.1-1.7-0.2-4.7-2.1-5
		c-0.9-0.2-1.5,0.1-1.4,1.2C173.4,413.9,174.3,415.1,175.1,416.4z M173,559.9c-0.1-0.4-0.4-0.8-1-0.9c-0.6-0.1-0.6,0.4-0.9,0.8
		c-0.9,1.3-0.6,2.8-0.4,4.1c0.1,0.6,0.5,1.7,1.6,1.6c1-0.1,0.9-1.1,1.1-1.8c0.1-0.3,0-0.7,0-1.1C173.6,561.7,173.3,560.8,173,559.9z
		 M154.2,557.5c0.7,0.8,1.3,0.7,1.7-0.2c0.4-1.1,0.7-2.2,0.4-3.4c-0.3-1-0.8-2.1-1.9-1.9c-1.2,0.1-1,1.4-1,2.3c0,0.1,0,0.2,0,0.5
		C153.1,555.7,153.5,556.6,154.2,557.5z M60.8,514.2c-0.1-0.3-0.2-0.5-0.6-0.5c-0.3,0-0.5,0-0.6,0.3c-0.4,2.3-0.9,4.6,0.5,6.8
		c0.5,0.8,0.8,0.5,1.2,0c0.6-0.8,0.3-1.8,0.3-2.6C62,516.7,61.4,515.5,60.8,514.2z M245.9,561.8c-1.7,0.2-4.2,1.7-4.3,3.9
		c0,0.7,0.2,1,1,1c1.5-0.1,4-2.2,4-4C246.6,562.2,246.6,561.7,245.9,561.8z M242.5,553.5c-0.1-0.4,0.3-1.1-0.4-1.2
		c-0.6-0.1-1,0.2-1.3,0.8c-0.4,0.7-0.9,1.3-1.4,1.9c-0.7,0.9-0.3,2-0.2,2.9c0.1,0.6,0.9,0.3,1.3,0.2c1.4-0.2,2-1.4,2-3.5
		C242.5,554.2,242.6,553.8,242.5,553.5z M303.9,635.5c0.3-0.4-0.1-0.9-0.3-1.1c-1.7-1-3.3-2.3-5.5-2.3c-0.8,0-1,0.2-0.7,1
		c0.5,1.5,2.6,2.7,4.9,2.7C302.8,635.6,303.5,636.1,303.9,635.5z M151.6,568.7c-0.3-0.3-1-0.5-1,0.2c0,1.6-0.4,3.3,0.7,4.8
		c0.5,0.6,1,1.3,1.8,1.1c0.8-0.2,0.9-1,0.8-1.8C153.7,571.3,152.8,569.9,151.6,568.7z M243.1,440.3c1.3-0.1,1.9-1.7,1.7-2.6
		c-0.2-1.2-1.3-0.9-2-0.9c-1.1,0-1.8,0.9-2.7,1.4c-1.1,0.7-1,1.2,0.2,1.8c0.6,0.3,1.1,0.3,1.7,0.3
		C242.4,440.3,242.8,440.3,243.1,440.3z M190.4,640.2c0.3,0.3,0.6,0.5,1.1,0.4c0.3-0.1,0.4-0.3,0.4-0.6c-0.3-2.4-1.1-4.5-2.9-6.1
		c-0.2-0.2-0.5-0.4-0.8-0.3c-0.4,0.2-0.2,0.5-0.3,0.9C188.5,636.5,188.7,638.7,190.4,640.2z M289.3,588.5c0.1,0.6,0.4,0.7,0.9,0.7
		c0.7,0,0.7-0.4,0.7-0.9c0-1,0.2-1.9,0.1-2.9c-0.1-1.3,0.1-2.6-0.9-3.7c-0.3-0.3-0.4-0.5-0.9-0.5c-0.5,0.1-0.4,0.5-0.4,0.8
		c0,0.6,0,1.2,0,1.8C288.7,585.5,289,587,289.3,588.5z M8.8,513.3c0.3,0.7,0.7,0.9,1.3,0.3c1.5-1.4,1.3-5.7-0.2-7
		c-0.5-0.4-0.9-0.5-0.9,0.3c-0.1,1.4-0.3,2.7-0.4,4C8.7,511.9,8.6,512.6,8.8,513.3z M137.4,605.1c-0.3-0.4-0.9-0.4-0.9,0.3
		c0,2.3,0.3,4.5,2.3,6c0.3,0.3,0.6,0.8,1.1,0.5c0.4-0.2,0.1-0.7,0.3-1.3C139.8,608.7,138.9,606.7,137.4,605.1z M186.5,642.9
		c-0.2-0.2-0.6-0.5-1-0.3c-0.3,0.1-0.2,0.4-0.2,0.7c0.4,1.4,0.4,2.9,0.6,4.3c0.1,0.8,0.3,1.7,1.4,1.6c1.1-0.1,1.4-0.8,1.3-1.7
		C188.7,645.5,187.6,644.2,186.5,642.9z M126,430.8c0.6,0.1,1,0,1-0.7c0.2-1.9-1.7-4.2-3.5-4.3c-0.6,0-1,0.1-0.9,0.8
		C122.7,428.6,124.2,430.4,126,430.8z M241.6,459.5c0.8,0,2-1.5,1.8-2.3c-0.2-0.9-1-1-1.8-1c-1.7-0.1-2.6,1.3-3.8,2
		c-0.3,0.1-0.2,0.6,0.2,0.9c0.8,0.7,1.7,0.5,2.3,0.6C240.9,459.5,241.2,459.5,241.6,459.5z M262.2,512.5c0.6-0.5,0.5-0.8,0-1.2
		c-1.3-1.1-5.2-0.1-5.9,1.4c-0.3,0.6-0.5,1.4,0.7,1.5C258.8,513.7,260.7,513.7,262.2,512.5z M333.7,560.4c-0.3-0.8-1.6-1.3-2.3-0.9
		c-0.7,0.4-0.8,1.6-0.2,2.4c0.7,1,1.4,1.9,2.1,2.8c0.3,0.4,0.6,0.9,1.2,0.7c0.6-0.2,0.2-0.8,0.3-1.1
		C334.9,562.8,334.1,561.6,333.7,560.4z M118.3,542.7c0.1,0.4-0.3,1,0.4,1.1c0.5,0.1,0.8-0.3,1-0.7c1.1-1.7,2-3.6,1.4-5.7
		c-0.1-0.3,0.1-0.7-0.4-0.8c-0.5-0.1-0.6,0.3-0.8,0.6c-0.9,1.5-1.7,3-1.6,5C118.3,542.2,118.2,542.4,118.3,542.7z M153.3,560.6
		c-0.4-0.5-0.8-0.6-1.2,0.1c-0.8,1.2-0.8,2.6-0.5,3.9c0.2,0.8,0.5,2.2,1.8,2c1.4-0.2,0.5-1.5,0.9-2.1
		C154.2,563.1,154.2,561.7,153.3,560.6z M184.7,419.8c-0.8-0.8-1.8-1.6-2.7-2.3c-0.3-0.3-0.8-0.8-1.2-0.4c-0.4,0.4-0.3,1,0,1.6
		c0.5,1,1,2.1,1.7,3.1c0.5,0.8,1.4,1.3,2.4,1.2c0.6-0.1,0.3-0.7,0.3-0.7C185.4,421.1,185.2,420.4,184.7,419.8z M264.2,450
		c-0.8-1.7-2.5-2.5-3.6-3.8c-0.1-0.2-0.7-0.1-1,0c-0.4,0.2-0.2,0.6,0,0.9c0.3,0.6,0.5,1.3,0.9,1.9c0.8,1.1,1,2.8,2.4,2.8
		C264.4,452,264.7,451.3,264.2,450z M187.7,588.2c-0.2-0.7-0.7-1.3-1.3-1.4c-0.8-0.1-0.7,0.9-0.8,1.3c-0.3,1.1-0.2,2.2,0.2,3.3
		c0.4,0.9,0.2,2.5,1.5,2.4c1.2-0.1,0.6-1.7,0.8-2.5C188.1,590.2,188,589.2,187.7,588.2z M286.3,580.8c-0.4-0.6-0.7-1.5-1.5-1.3
		c-0.8,0.1-0.8,1-0.9,1.7c0,0.6-0.3,1.2-0.2,1.8c0.1,0.9-0.4,2.2,1,2.6c0.9,0.2,1.9-1.1,2-2.5c0-0.2,0-0.3,0-0.6
		C286.8,581.9,286.6,581.3,286.3,580.8z M83.6,549.3c0-0.3,0.2-0.8-0.3-0.9c-0.4-0.1-0.7,0-1,0.2c-1.5,1.4-2.4,3-2.1,5.1
		c0.1,1,0.7,0.9,1.2,0.5c1.4-1,2.4-2.2,2.2-4.1C83.6,549.9,83.6,549.6,83.6,549.3z M97.3,439.3c-0.6-0.4-0.9-0.1-1.1,0.5
		c-0.5,1.8,0.8,4.4,2.5,4.8c0.8,0.2,1.5,0.1,1.3-1C99.7,441.8,99,440.3,97.3,439.3z M232.4,429.2c-0.1-0.5-0.5-0.7-0.9-0.9
		c-0.2-0.1-0.5,0-0.6,0.3c-0.5,1.6-1.2,3.1-0.9,4.8c0.1,0.6,0.5,1.3,1.2,1.4c0.8,0.1,1-0.6,1.3-1.1c0.3-0.6,0.1-1.2,0.3-2.1
		C233,431,232.5,430.1,232.4,429.2z M126.8,657.8c0,0.4,0,0.8,0.3,1.1c1,1.3,2.2,2.1,3.5,2.9c0.7,0.4,1.6,1.1,2.1,0.5
		c0.7-0.7-0.4-1.3-0.8-1.9c-1.1-1.7-2.7-2.6-4.7-3.1C127.1,657.4,126.9,657.4,126.8,657.8z M342.1,554.8c-0.2-1-0.8-1.2-1.6-0.6
		c-0.3,0.2-0.7,0.5-0.9,0.8c-0.7,1.1-0.7,2.2,0,3.2c0.5,0.8,1.2,1.1,2.1,0.8c1.2-0.4,0.6-1.4,0.8-2.2
		C342.5,556.1,342.2,555.5,342.1,554.8z M13.3,559.1c0.1,0.7,0.6,1.1,1.4,1.1c0.8,0,0.8-0.7,0.8-1.2c0.1-1.8-0.2-3.5-1.3-4.9
		c-0.3-0.4-0.7-1-1.3-0.8c-0.5,0.2-0.2,0.8-0.3,1.3C12.8,556.1,13,557.6,13.3,559.1z M117.4,576.8c0.6,2.4,1.6,4.7,3.9,6.1
		c0.3,0.2,0.6,0.4,0.9,0.1c0.2-0.2,0.1-0.5-0.1-0.8c-0.7-1.4-1.2-2.9-2-4.2c-0.5-0.8-1.2-1.5-2-1.8
		C117.3,576,117.3,576.4,117.4,576.8z M232.2,576.9c0.7,0.7,1.3,0.4,1.4-0.4c0.3-1.7,0.5-3.4-1-4.8c-0.5-0.5-1-0.8-1.6-0.6
		c-0.8,0.2-0.2,1-0.4,1.4C230.7,574.2,230.9,575.7,232.2,576.9z M276.3,579.3c0-0.3,0.1-0.7-0.3-0.7c-0.4-0.1-0.8-0.2-1.1,0.1
		c-1.1,0.9-2,4-1.5,5.3c0.2,0.6,0.6,0.9,1.2,0.3c1.1-1,2.2-1.9,1.8-3.5C276.3,580.2,276.3,579.8,276.3,579.3z M278.8,533.1
		c-0.6-0.2-0.8,0.3-1.1,0.6c-0.8,0.8-1.6,1.7-2.5,2.5c-0.5,0.4-0.6,1-0.4,1.5c0.2,0.6,0.9,0.6,1.4,0.5c1.6-0.1,3-1.6,3-3.9
		C279.2,533.9,279.4,533.3,278.8,533.1z M135.3,492.8c-0.5-0.1-0.6,0.2-0.6,0.6c0,1.7,0.6,3.2,1.8,4.4c0.4,0.4,1,0.5,1.5,0.3
		c0.6-0.2,0.5-0.9,0.6-1.3C138.7,495,137,493,135.3,492.8z M333.6,511.9c-0.6-0.6-1.6-0.6-2.3-0.3c-0.7,0.3-0.3,1.2-0.2,1.8
		c0.1,1.1,0.9,2.1,1.5,3c0.2,0.3,0.8,0.8,1.1,0.3c0.4-0.8,1.2-1.4,1-2.5C335,513.2,334.3,512.5,333.6,511.9z M282.1,590.3
		c-0.1-0.4,0.2-1-0.5-1.1c-0.6-0.1-0.8,0.2-1.1,0.6c-1,1.4-1.5,2.8-1.2,4.6c0.2,1.3,0.9,0.9,1.5,0.5c1.3-0.8,1.3-2.2,1.4-3.5
		C282.2,591.1,282.2,590.7,282.1,590.3z M78.7,444.7c-0.8,0-1.7,0-2.1,0.9c-0.5,1.2-0.8,2.4-1.1,3.6c-0.3,1,0.4,1.1,1.1,0.9
		c1.3-0.5,2.7-2.6,2.7-4C79.3,445.4,79.4,444.7,78.7,444.7z M295.5,613.9c-0.2-1-1.3-1.3-1.6-2.2c-0.1-0.3-0.4-0.2-0.7-0.2
		c-0.4,0-0.4,0.4-0.4,0.7c-0.2,1.4-0.2,2.8,0.3,4c0.5,1.1,0.6,2.9,2.6,2.6C296,617.1,295,615.5,295.5,613.9z M268.5,680.1
		c0.4-0.1,1-0.1,0.9-0.8c-0.1-0.5-0.2-1-0.7-1.2c-1.6-0.6-3.2-1.3-5-0.4c-1.1,0.5-0.8,0.8-0.2,1.3c1.1,0.9,2.5,1.1,3.8,1.3
		C267.7,680.2,268.1,680.2,268.5,680.1z M157.9,438.5c0.1,0.3,0.3,0.7,0.8,0.8c0.5,0.1,0.7-0.3,0.9-0.6c1-1.3,1.1-2.8,0.6-4.3
		c-0.2-0.5-0.5-1.3-1.3-1.2c-0.8,0.1-1,0.7-1.1,1.4c-0.1,0.5-0.2,1.1-0.3,1.6C157.7,437.1,157.7,437.8,157.9,438.5z M240.3,427.9
		c-0.7-0.1-1.4-0.4-2,0.2c-0.9,1-1.5,2.2-2.1,3.4c-0.2,0.5-0.2,0.8,0.5,0.9c1.8,0.2,3.9-1.6,3.9-3.4
		C240.6,428.7,241,428,240.3,427.9z M108.2,497.5c0.4,0.5,1,0.8,1.6,0.6c0.6-0.2,0.6-0.9,0.6-1.5c0-1.4-0.6-2.4-1.7-3.3
		c-0.5-0.4-0.9-0.8-1.5-0.7c-0.8,0.2-0.2,1-0.4,1.4C106.7,495.4,107.3,496.5,108.2,497.5z M202.4,489.8c-1.4,0.8-2.6,2.2-2.8,4
		c-0.2,1.1,0.4,1.5,1.4,1.1c1.8-0.7,2.5-1.7,2.5-3.7C203.4,490.6,203.8,489,202.4,489.8z M178.9,532.1c0,0.6,0.1,1.1,0.7,1.4
		c0.6,0.3,0.9-0.2,1.2-0.6c1.5-1.4,1.7-3.6,0.5-5.2c-0.4-0.5-0.9-0.4-1.1,0.1c-0.5,1.2-1.3,2.3-1.3,3.7
		C178.9,531.7,178.9,531.9,178.9,532.1z M271.1,685.7c1.2,0,2-0.5,2.1-1.8c0.1-1.4-0.7-2.4-1.6-3.3c-0.3-0.3-1.1-0.9-1.4,0.2
		c-0.2,1-0.2,2-0.3,2.8C269.9,684.7,269.7,685.7,271.1,685.7z M34.3,485.5c0.2,0.4,0.1,1.1,0.6,1.3c0.5,0.2,1-0.3,1.3-0.7
		c1.1-1.3,1.6-2.7,1.2-4.4c-0.1-0.4-0.3-0.7-0.8-0.8c-0.4-0.1-0.7,0.1-1,0.5c-0.7,1.1-1.4,2.1-1.3,3.6
		C34.3,485,34.2,485.2,34.3,485.5z M164.9,641.6c0.1,0.4,0.3,0.6,0.8,0.6c0.2,0,0.4-0.1,0.4-0.3c0.4-1.6,1-3.2,1-4.8
		c0-0.5,0.1-1-0.5-1.2c-0.5-0.2-1-0.2-1.6,0.1c-0.9,0.6-0.9,1.5-0.8,2.6C163.9,639.6,164.6,640.6,164.9,641.6z M115.5,559.4
		c-0.4-0.1-0.9-0.1-1.3,0.1c-0.3,0.1-0.2,0.6-0.1,0.8c0.6,1.3,1.2,2.5,1.9,3.7c0.4,0.8,1.2,0.6,1.9,0.5c0.8-0.1,0.3-0.8,0.4-1.2
		C118.5,561.8,116.7,559.9,115.5,559.4z M121.9,417.3c-0.6,0.7-0.4,1.1,0.4,1.3c1.4,0.3,2.8,0.4,4.1-0.3c0.6-0.3,1.3-0.7,1.3-1.5
		c0-0.8-0.9-0.5-1.3-0.7c-0.5-0.3-1-0.3-1.5-0.3C123.7,416,122.7,416.4,121.9,417.3z M291.2,554.3c0,0.4-0.2,0.9,0.2,1.1
		c0.5,0.2,0.9-0.1,1.3-0.5c1.4-1.4,1.4-3.1,0.8-4.7c-0.1-0.4-0.5-0.8-1.1-0.9c-0.6-0.1-0.7,0.4-1,0.8c-0.7,0.8-0.2,1.8-0.4,2.5
		C291.2,553.3,291.2,553.8,291.2,554.3z M22.9,472.3c-0.4-0.1-0.9-0.2-1.1,0.3c-0.1,0.4-0.1,0.9,0.2,1.2c0.8,0.8,1.6,1.6,2.3,2.6
		c0.5,0.7,1.1,0.4,1.6,0.4c0.6-0.1,0.3-0.7,0.4-1.1C26.5,473.7,25.3,472.7,22.9,472.3z M209,535c0.1,1.2,1,1.8,1.9,1.8
		c0.9,0.1,0.9-1,1-1.7c0.2-0.9,0-1.9-0.5-2.6c-0.5-0.7-1.3-1.5-2.1-1.4c-0.7,0.1-0.2,1.2-0.4,1.9c0,0.1,0,0.3,0,0.1
		C208.9,534,209,534.5,209,535z M261.2,680.8c-1.3,0.2-3.1,2.8-2.9,4.1c0,0.3,0.1,0.5,0.5,0.6c1.6,0.2,3.8-1.8,3.8-3.6
		C262.9,680.8,262.2,680.7,261.2,680.8z M119.3,432c-0.4-0.3-0.7-0.4-1.1-0.2c-0.5,0.2-0.3,0.7-0.3,1.1c0.2,1.7,1,3.2,1.5,4.8
		c0.1,0.5,0.5,0.4,0.8,0.4c0.4,0,0.7-0.2,0.7-0.7c0-0.5,0-0.9,0-1.3C121.2,434.4,120.7,433.1,119.3,432z M199.1,397.1
		c0.1,0.3,0.6,0.7,1.1,0.6c0.5-0.1,0.3-0.6,0.3-0.9c0.1-1,0.2-2,0.3-3c0.1-1,0.6-2.2-0.9-2.6c-0.9-0.2-1.6,0.9-1.7,2.6
		c0,0.1,0,0.1,0,0.5C198.4,395.1,198.7,396.1,199.1,397.1z M251.7,500.8c-0.7,0.8-0.4,1.1,0.4,1.4c1.8,0.7,3.5,0,5.1-0.7
		c0.4-0.2,0.4-0.7,0.4-1.1c0-0.5-0.5-0.4-0.8-0.5c-0.6-0.1-1.1-0.4-1.8-0.3C253.9,499.9,252.7,499.7,251.7,500.8z M237.4,461.1
		c-0.3-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.3,0.4-0.4,0.6c-0.7,1.4-0.6,2.8-0.6,4.3c0,0.7,0.1,1.2,0.9,1.2c0.8,0,1.4-0.2,1.5-1.1
		c0.1-0.7,0.1-1.3,0.1-1.8C237.8,462.9,238.1,461.9,237.4,461.1z M9.7,491.2c0.3,1,1.7,1.7,2.7,1.2c1.1-0.6,0.3-1.4,0-2
		c-0.5-1.1-1.1-2.1-1.7-3.1c-0.2-0.3-0.5-0.5-0.9-0.4c-0.5,0.1-0.4,0.5-0.5,0.8c0,0.3,0,0.6,0,1C9.2,489.5,9.5,490.3,9.7,491.2z
		 M227.1,703.2c-0.2,0.7-0.2,1.1,0.8,1.1c1.1,0,2-0.6,3.1-0.7c0.8-0.1,1.7-0.5,1.6-1.6c-0.2-1.1-1-1.1-1.7-1
		C229.1,700.9,227.4,702,227.1,703.2z M5.9,507.4c0-0.6-0.2-1-1-1.1c-0.7,0-1.3,0.1-1.4,1c-0.2,1.8-0.1,3.4,1.5,4.7
		c0.3,0.3,0.6,0.6,1,0.4c0.4-0.2,0.2-0.6,0.3-1C6,510.2,5.9,508.8,5.9,507.4z M251,573.1c0.1,0.6,2.2,3.1,2.8,3.2
		c0.3,0.1,0.7,0.1,0.9-0.4c0.8-1.9-0.2-4.4-2.5-4.2C251.1,571.5,250.9,572.1,251,573.1z M137.4,562.1c-0.5,0-0.5,0.3-0.6,0.7
		c0,1.1,2.1,4.2,3.1,4.4c0.9,0.2,1.4-0.1,1.3-1C141.2,564.6,138.9,562.2,137.4,562.1z M167,538.5c0.1,0.9,0.9,1.5,1.8,1.3
		c0.9-0.2,0.9-1,0.9-1.6c-0.1-1-0.3-2-1.1-2.9c-0.5-0.6-1-1.3-1.7-1.2c-0.8,0.2-0.2,1.1-0.3,1.7C166.5,536.8,166.9,537.6,167,538.5z
		 M236.5,687.8c0.5-0.1,0.4-0.6,0.5-1c0-0.5-0.4-0.4-0.7-0.5c-1-0.3-2-0.3-3.1-0.1c-1,0.2-2.2,0.5-2.2,1.5c0.1,1.2,1.4,1,2.6,1
		C234.7,689.1,235.5,688.1,236.5,687.8z M199.6,406c0.2,0.2,0.5,0.1,0.7,0c1-0.5,2.4-3.2,2.3-4.3c0-0.5-0.2-0.9-0.6-1.1
		c-0.4-0.2-0.8-0.1-1.3,0.2c-1.8,1-0.8,2.7-1.4,4C199.4,405.2,199.2,405.7,199.6,406z M76.6,418.6c0.6,0.2,1.1-0.3,1.7-0.7
		c1.2-1,0.8-2.3,0.8-3.5c0-0.8-0.2-1.3-1.1-1.2c-0.8,0-1.4,0.2-1.4,1.2c0,0.9-0.4,1.8-0.2,3C76.5,417.6,75.7,418.4,76.6,418.6z
		 M174.4,631.9c1-0.7,1.4-2,2.2-2.8c0.3-0.3-0.2-0.7-0.6-0.6c-1.6,0.3-3,1-4.3,1.8c-0.6,0.4-0.6,1.1-0.4,1.6c0.2,0.5,0.8,0.6,1,0.6
		C173.3,632.5,173.9,632.3,174.4,631.9z M142.1,630.5c-0.1-0.3,0.1-0.9-0.6-0.8c-0.6,0-0.8,0.4-0.9,0.8c-0.2,0.8-0.4,1.7-0.4,2.5
		c-0.1,1.1-0.5,2.5,0.9,3c0.9,0.3,1.5-0.9,1.5-2.9C142.4,632.3,142.5,631.4,142.1,630.5z M142.2,408.8c-0.1-0.8-0.3-1.2-1.3-0.8
		c-1.2,0.6-2.1,1.3-2.7,2.5c-0.2,0.4-0.5,0.8-0.3,1.2c0.2,0.5,0.8,0.2,1.2,0.3C140.9,412,142.4,410.5,142.2,408.8z M185.9,539.5
		c1.4,0,3.4-1.4,3.7-2.7c0.2-0.9-0.1-1.5-1.1-1.5c-1.6,0-3.1,1.3-3.2,3C185.3,538.7,184.9,539.4,185.9,539.5z M150.9,531.6
		c-0.4-0.2-0.8-0.4-1.1-0.2c-0.4,0.3-0.3,0.9-0.1,1.3c0.7,1.2,1.4,2.4,2.4,3.4c0.4,0.4,1,0.2,1.4,0.1c0.6-0.1,0.3-0.7,0.4-1
		C154,533.8,152.7,532.3,150.9,531.6z M146,528.2c0.1,0.8,0.7,1.4,1.3,1.6c0.7,0.1,0.9-0.7,1.1-1.3c0.3-0.8,0.3-1.7,0.3-2.5
		c0-0.7,0.2-1.6-1-1.7c-1.1,0-1.6,0.7-1.8,1.7c-0.1,0.3,0,0.7,0,1.1C146,527.4,146,527.8,146,528.2z M201.2,522.1
		c-0.7,0.4-1.3,1.1-1,2c0.2,0.8,1.1,0.6,1.7,0.6c0.7,0.1,1.4,0,1.9-0.4c0.8-0.5,1.7-1.2,1.5-2.3c-0.2-0.9-1-0.3-1.3-0.4
		C202.8,521.5,201.9,521.6,201.2,522.1z M216.1,582.7c-0.4-0.2-0.8-0.3-1.1,0c-0.3,0.3-0.2,0.6-0.1,1c0.5,1.6,1.3,3.1,2.5,4.3
		c0.3,0.3,0.7,0.4,1.1,0.3c0.5-0.1,0.2-0.6,0.3-0.9C218.7,586,217.7,583.5,216.1,582.7z M273,527.9c-0.2-0.5-0.6-0.7-0.9-0.3
		c-1.2,1.2-2.2,2.5-2.1,4.4c0.1,0.9,0.5,1,1.2,0.9c1.3-0.1,2-1.5,2.1-3.3C273.3,529,273.3,528.4,273,527.9z M67.7,544.8
		c-0.8,0.2-0.5,1.2-0.7,1.8c0,0.4,0,0.7,0,1.1c0,1,0,1.9,0.4,2.8c0.2,0.5,0.5,1.1,1.3,1c0.7-0.1,0.7-0.8,0.6-1.2
		c-0.1-1.3,0.3-2.7-0.3-4C68.7,545.7,68.6,544.6,67.7,544.8z M74.8,589.4c-0.1,0.3-0.2,0.7,0.1,1c1,1,2.1,2,3.3,2.7
		c0.5,0.3,1.2,0.4,1.5-0.1c0.3-0.5,0.3-1.2-0.2-1.8c-1.1-1.3-2.4-1.9-4.1-2C75.3,589.2,74.9,589.1,74.8,589.4z M205.4,560.4
		c0.2-0.3,0.4-0.6,0.2-0.9c-0.2-0.3-0.7-0.2-1,0c-1,0.7-2.4,0.8-3.3,1.7c-0.6,0.6-1.3,1.1-1.6,2c-0.1,0.5-0.1,0.8,0.6,0.7
		C202.2,563,204.2,562.4,205.4,560.4z M260.6,521.1c-0.3-0.2-0.9-0.2-1.1,0c-0.4,0.3-0.3,0.9-0.1,1.3c0.4,1,0.7,2.1,0.9,3.1
		c0.2,0.8,1.2,1.1,1.7,0.5c0.4-0.5,0.9-1,0.7-1.6C262.7,522.6,261.6,521.8,260.6,521.1z M288.9,404.1c-0.4-0.8-1.5-1.4-2.3-0.8
		c-0.8,0.6-0.4,1.6,0.1,2.3c0.6,0.9,1.3,1.7,2,2.5c0.2,0.3,0.6,0.6,1,0.4c0.5-0.2,0.2-0.7,0.3-1.1
		C290,406.3,289.4,405.2,288.9,404.1z M236.2,530.6c-0.2-0.6-0.7-0.6-0.9-0.3c-0.8,1.2-2,2.2-1.9,3.9c0,0.6,0.2,1.2,1.1,1.1
		c1.4-0.2,2.2-1.1,2.3-2.6C236.6,532,236.4,531.3,236.2,530.6z M326.4,550.8c-0.1-0.4-0.3-0.7-0.8-0.8c-0.7-0.1-0.5,0.4-0.7,0.8
		c-0.5,0.9-0.9,1.8-1.5,2.6c-0.6,0.9,0,1.5,0.4,2c0.5,0.5,1.1,0,1.6-0.3c0.9-0.7,1.2-1.6,1.1-2.7
		C326.5,551.7,326.5,551.2,326.4,550.8z M159.5,557.2c-1-0.1-1.6,0.3-1.8,1.4c-0.1,0.7-0.3,1.5-0.7,2.1c-0.3,0.6-0.1,1.1,0.8,1.1
		c1.5-0.1,2.7-1.3,2.8-3C160.4,558.4,160.6,557.4,159.5,557.2z M298.8,581.4c-0.7-0.3-1.3-0.4-1.2,0.7c0,1,0,1.9,0,2.9
		c0,0.7,0,1.4,0.8,1.6c0.9,0.2,1.3-0.6,1.6-1.2c0.2-0.4,0.4-0.9,0.3-1.4C300.5,582.7,299.8,581.9,298.8,581.4z M57.4,644.8
		c-0.8-0.8-1.3-0.6-1.6,0.4c0.9,1.9,1.8,3.8,3.9,4.6c0.5,0.2,0.9,0.9,1.5,0.1c0.7-0.9-0.2-1.1-0.5-1.6C59.8,647,58.5,646,57.4,644.8
		z M77.8,585.8c-0.2,0.5,0.3,1,0.6,1.4c0.8,1.1,2,1.5,3.3,1.4c0.9,0,1.2-0.4,1-1.4c-0.3-1.1-1.7-2-3.4-2
		C78.9,585.4,78.1,585.2,77.8,585.8z M76.2,633c0.5,0,0.6-0.5,0.7-0.9c0.1-0.2,0.1-0.6-0.3-0.6c-1.2-0.1-2.1-0.9-3.2-1.2
		c-0.9-0.2-1.7,0-2.3,0.8c-0.3,0.5-0.4,1.2,0.3,1.3c1.1,0.2,2,0.9,3.2,0.6C75.1,633,75.7,633.1,76.2,633z M47.8,495.8
		c-0.4-0.5-1.1-0.5-1.7-0.4c-0.7,0.2-0.5,0.7-0.6,1.2c-0.2,1.2,0.6,2,1.1,2.9c0.3,0.5,1.2,0.8,1.9,0.6c0.7-0.2,0.2-1,0.3-1.4
		C49.2,497.3,48.3,496.6,47.8,495.8z M179.6,424.6c0.5,0.3,1,0.3,1.4,0.1c0.4-0.2,0.6-0.6,0.6-1.1c-0.1-1.1-1.6-3-2.6-3.3
		c-0.7-0.2-1.4-0.2-1.4,0.8C177.7,422.1,178.8,424,179.6,424.6z M54.7,570.3c-0.4,0.1-0.8,0.1-0.9,0.6c-0.1,0.4,0,0.7,0.4,1.1
		c0.7,0.7,1.4,1.4,2.2,2.1c0.5,0.4,1,0.6,1.5,0.4c0.5-0.2,0.2-0.8,0.3-1.2C58.1,571.5,56.4,570.1,54.7,570.3z M49.9,569.5
		c0.4,0.6,0.8,0.9,1.2-0.1c0.8-1.8,0.6-4.1-0.6-5.4c-0.3-0.3-0.6-0.6-1.1-0.4c-0.5,0.2-0.2,0.6-0.3,0.9c0,0.5,0,0.9,0.1,1.4
		C49,567.2,49.2,568.4,49.9,569.5z M276.3,592.3c-0.1-0.7-0.7-1.1-1.5-1c-0.7,0.1-1,0.6-0.9,1.1c0.3,1.4,0.4,2.8,1.1,4.1
		c0.2,0.3,0.3,0.9,0.9,0.9c0.7-0.1,0.4-0.7,0.5-1.1c0.2-0.6,0.4-1.2,0.2-1.9C276.8,593.7,276.4,593,276.3,592.3z M256.6,613.1
		c0.9-0.7,0.6-1-0.1-1.4c-1.5-0.8-4.3,0.7-4.6,2.4c-0.1,0.7,0,1,0.7,0.9C254.1,614.7,255.4,614.1,256.6,613.1z M100.9,405.2
		c-0.4-0.3-0.9-0.4-0.9,0.4c0,1.2-0.1,2.3,0,3.5c0.1,1,0.8,1.6,1.8,1.6c1-0.1,1-1,0.9-1.5C102.8,407.8,101.8,405.8,100.9,405.2z
		 M278.8,581.9c1.1,0.2,1.3-0.6,1.5-1.4c0.5-1.5-0.1-2.9-0.4-4.3c-0.1-0.6-0.6-0.4-1-0.4c-0.5,0-0.5,0.3-0.5,0.7
		c-0.1,1.3-0.2,2.5-0.3,4C278.4,580.7,277.5,581.7,278.8,581.9z M127.1,555.6c-0.9-0.2-1.4,0.4-1.6,1.2c-0.4,1.1-0.2,2.2-0.3,3.3
		c0,0.8,0.6,0.9,1.2,0.5c1.1-0.8,1.5-2,1.8-3.2C128.1,556.5,128.1,555.7,127.1,555.6z M109.1,514.4c-0.2-0.8-0.9-1-1.7-1
		c-0.4,0-0.7,0-0.7,0.5c0,1.4,0,2.7,0.1,4.1c0,0.4,0.3,0.9,0.9,0.9c0.5,0,0.7-0.3,0.9-0.7c0.5-0.8,0.7-1.5,0.6-2.5
		C109.1,515.3,109.2,514.8,109.1,514.4z M133.4,450.3c0.3-0.7-0.3-1.1-0.7-1.5c-1.1-1.2-2.6-1.2-4-1.1c-0.8,0-0.7,0.5-0.4,0.9
		c0.8,1.3,2,2.2,3.7,2.1C132.4,450.6,133.1,451,133.4,450.3z M140.7,435.9c-0.4-0.3-0.8-0.7-1,0.1c-0.3,1.4-0.8,2.7-0.5,4.2
		c0.1,0.6,0,1.5,0.9,1.5c0.8,0,1-0.8,1.2-1.5c0.2-0.6,0.1-1.1,0.2-1.6C141.4,437.5,141.5,436.6,140.7,435.9z M111,423.5
		c-0.1-0.6-0.3-1.2-0.9-1.4c-0.7-0.2-1.1,0.6-1.4,1c-0.7,1.2-0.7,2.6,0,3.9c0.4,0.7,1,0.9,1.4,0.3c0.5-0.9,1.2-1.6,0.9-2.7
		C111,424.1,111,423.8,111,423.5z M226.3,502c-0.3,1.3,1,1.4,1.6,2c0.2,0.3,0.5,0.2,0.8,0c0.6-0.7,0.9-1.5,0.6-2.3
		c-0.1-0.5-0.3-1-0.6-1.3c-0.5-0.6-1.1-1.4-1.9-1.2c-1,0.2-0.1,1.2-0.4,1.7C226.4,501.3,226.4,501.7,226.3,502z M105.1,447
		c-0.7-0.4-1.2-0.5-1.2,0.6c0,1.4,0.5,2.8,0.6,4.2c0.1,0.5,0.5,0.5,0.8,0.4c0.4,0,0.9,0.2,1.1-0.5c0.1-0.5,0.4-1,0.3-1.5
		C106.8,448.8,106.3,447.7,105.1,447z M219.9,497.6c-1.2-0.7-1.3,0-1.4,0.8c-0.1,0.7,0.2,1.4,0.6,2c0.6,0.7,1.5,1.2,2.5,1
		c0.7-0.2,0.9-0.7,0.8-1.4C222.2,498.6,220.9,498.2,219.9,497.6z M255.5,600.5c0.2,0.5-0.4,1.3,0.6,1.5c0.8,0.2,1.2-0.3,1.5-0.9
		c0.7-1.3,0.5-3.7-0.6-4.6c-0.5-0.5-0.8-0.2-1,0.3c-0.3,0.9-0.7,1.8-0.5,2.7C255.6,599.9,255.4,600.2,255.5,600.5z M241.5,575.2
		c0.4,0.3,0.8,0.6,1-0.2c0.3-1.3,0.3-2.6-0.3-3.9c-0.4-0.7-0.8-1.7-1.8-1.4c-0.7,0.3-0.1,1.3-0.2,1.6
		C239.8,573.2,240.4,574.3,241.5,575.2z M90.2,592.1c0.2-0.7-0.1-1.2-0.5-1.7c-0.9-1-2.1-1.2-3.2-1.5c-0.3-0.1-0.7-0.5-0.9-0.1
		c-0.2,0.3-0.2,0.9,0,1.1c0.9,1.3,1.9,2.5,3.7,2.6C89.6,592.5,90,592.6,90.2,592.1z M118.3,447.7c0.1-0.7-0.2-1.4-0.9-1.5
		c-0.9-0.2-0.8,0.7-1,1.2c-0.7,1.3-0.4,2.8-0.8,4.1c-0.1,0.2,0,0.6,0.2,0.7c0.2,0.1,0.5,0.1,0.8-0.2c1-1,2-2,1.8-3.6
		C118.3,448.1,118.3,447.9,118.3,447.7z M280.6,586.7c0.3-0.3,0.4-0.6,0.3-1c-0.2-0.4-0.6-0.3-0.9-0.2c-1.6,0.4-3.3,0.3-4.7,1.2
		c-0.3,0.2-0.7,0-0.7,0.6c0,0.4,0.3,0.6,0.7,0.7c0.4,0.1,0.9,0.2,1.3,0.3C277.9,587.9,279.5,587.9,280.6,586.7z M167.1,522.1
		c-0.3-0.3-0.6-0.3-1-0.2c-0.5,0.1-0.3,0.6-0.3,0.9c0,1.1,0.3,2.2,0.3,3.3c0,0.6,0.5,1.1,1.3,1c0.7-0.1,1.1-0.5,1.1-1.1
		c0-0.3,0-0.6,0-1C168.9,523.7,168,522.9,167.1,522.1z M261.2,530.3c-1.3,0.5-2.3,1.5-2.8,2.9c-0.2,0.6-0.5,1.6,1,1.3
		c1.6-0.3,2.8-1.5,2.9-3.2C262.3,530.7,262.2,529.9,261.2,530.3z M294,408c-0.5-0.8-1.2-0.8-1.8-0.5c-0.8,0.5-0.4,1.3,0,1.9
		c0.8,1.1,1.7,2,2.5,3c0.3,0.3,0.5,0.5,0.8,0.4c0.3-0.2,0.2-0.5,0.2-0.9C295.5,410.6,294.9,409.3,294,408z M149.5,398.9
		c-0.1-0.3-0.7-0.3-0.8,0.2c-0.1,1.3-0.6,2.7-0.2,4c0.2,0.8,0.6,1.3,1.4,1.2c0.7-0.2,1.5-0.5,1.3-1.5
		C150.9,401.3,150.2,400.1,149.5,398.9z M258.9,464.9c-1.5,0.9-0.2,1.9,0.1,2.6c0.4,0.9,1,2.3,2.1,1.8c1-0.4,0.8-1.8,0.5-2.8
		C260.8,464.6,260.5,464.5,258.9,464.9z M90.8,537.2c-0.5-0.3-1.2-0.4-1.1,0.6c0.1,1.1,0.4,2.2,0.7,3.2c0.2,0.7,0.7,1.4,1.7,1.2
		c1-0.2,0.5-1.1,0.8-1.5C92.9,539.1,92.1,538,90.8,537.2z M167.9,473.3c-0.1,1.4,0.4,2.5,1.5,3.3c0.6,0.5,1.5,0.9,2.2,0.5
		c0.7-0.5,0-1.4-0.3-1.9c-0.7-1.1-1.2-2.3-2.6-2.6C168,472.5,167.9,472.8,167.9,473.3z M300.8,613.3c-1.1,0.4-2.3,2.2-2.3,3.5
		c-0.1,1.3,0.8,1.3,1.4,0.9c1.1-0.8,2-1.9,2.3-3.1C302.3,613.4,301.7,613,300.8,613.3z M132.7,438.5c-0.1,1.9,1.7,3.4,3.5,3.1
		c0.4-0.1,0.6-0.2,0.6-0.6c0-1.4-1.6-3.1-3.1-3.1C133.4,438,132.8,437.6,132.7,438.5z M150.6,393c-0.1-1.5-1.2-1.4-2.2-1.4
		c-1.3,0.1-0.8,1-0.7,1.7c0.1,0.8,0.5,1.4,0.9,2.1c0.2,0.3,0.2,0.9,0.8,0.7c0.4-0.1,0.8-0.2,1-0.7c0.2-0.5,0.3-1.1,0.2-1.6
		C150.6,393.5,150.6,393.3,150.6,393z M218.3,638c-0.2-0.3-0.6-0.5-1.1-0.4c-0.5,0.1-0.3,0.6-0.3,0.9c0.1,1.5,0.2,2.9,0.3,4.4
		c0,0.6,0.5,0.9,1,1c0.7,0.1,0.8-0.5,0.9-1c0.1-0.4,0.1-0.9,0.1-1C219.1,640.3,219.1,639,218.3,638z M196.1,534.4
		c0.1-0.3,0.2-0.6,0-0.8c-0.3-0.3-0.7-0.2-1,0c-1.1,0.7-2.2,1.3-3.2,2c-0.6,0.4-0.3,1-0.2,1.5c0,0.5,0.6,0.2,0.8,0.3
		C193.8,537.5,195.5,536.7,196.1,534.4z M58.2,554.1c-0.3,0.5,0,1.2,0.3,1.6c0.8,1,1.7,1.7,3.2,1.7c1.2,0,1.2-0.8,0.9-1.3
		c-0.7-1-1.5-2.2-3-2.1C59,553.9,58.4,553.7,58.2,554.1z M133.8,612.3c0.4,1.4,1.4,2.4,2.5,3.2c0.5,0.4,1.4,1,2,0.4
		c0.5-0.6-0.4-1.1-0.6-1.7c-0.1-0.2-0.2-0.4-0.3-0.5c-0.9-0.9-1.7-1.9-2.7-2.3C133.5,611.3,133.6,611.8,133.8,612.3z M135.8,650.7
		c-0.8-0.2-0.9,0.3-0.7,0.9c0.6,1.5,1.4,2.9,2.6,4c0.3,0.3,0.6,0.3,0.9,0.2c0.5-0.1,0.3-0.5,0.4-0.8
		C139.1,653.3,137.4,651.1,135.8,650.7z M79.9,536c-0.2-0.5-0.7-1-1.2-0.8c-0.6,0.2-0.2,0.7-0.2,1.1c0.1,0.6-0.1,1.1-0.2,1.7
		c0,1,1,1.3,1.2,2.1c0.2,0.6,0.1,1.6,0.9,1.4c0.6-0.1,0.7-1.1,0.7-1.8c0-0.5-0.1-1.1-0.4-1.4c-0.7-0.5-0.5-1.2-0.6-1.8
		C80.1,536.4,80,536.2,79.9,536z M182.6,535.3c-0.3-0.2-0.7-0.1-1,0.2c-1.3,1-1.5,4.1-0.2,5.2c0.5,0.4,0.9,0.2,1-0.4
		c0.3-1.1,0.9-2,0.8-3.4C183.1,536.4,183.3,535.7,182.6,535.3z M170.7,638.8c-0.3-0.3-0.8-0.4-1.1-0.2c-0.3,0.2-0.2,0.6,0,1
		c0.1,0.3,0,0.7,0.1,1c0.4,1.4,1,2.6,2.1,3.5c0.3,0.2,0.6,0.3,1,0.3c0.2-0.1,0.3-0.3,0.3-0.5C172.4,642.1,172.1,640.2,170.7,638.8z
		 M143.4,434.5c0.1,0.8,0.1,1.6,0.3,2.4c0.1,0.5,0.4,1,1,0.9c0.6-0.1,0.9-0.3,1.1-1c0.3-1.1,0.1-2.2-0.3-3.1
		c-0.2-0.5-0.5-1.6-1.5-1.3c-1,0.2-0.3,1.2-0.5,1.8C143.4,434.3,143.4,434.4,143.4,434.5C143.4,434.5,143.4,434.5,143.4,434.5z
		 M62.5,598.3c-0.6,0-0.8,0.5-0.5,0.8c1.1,0.9,1.9,2.2,3.4,2.4c1,0.1,1.5-0.2,1.3-1.3c-0.3-1.3-1-1.9-2.9-1.9
		C63.5,598.2,63,598.3,62.5,598.3z M156.1,657.1c-0.5,0-0.9,0.1-0.6,0.8c0.6,1.3,1.3,2.6,2.5,3.6c0.3,0.3,0.7,0.7,1.2,0.5
		c0.5-0.2,0.2-0.8,0.3-1.2C159.4,658.9,157.8,657.2,156.1,657.1z M241.3,408.9c1.1-0.7,2.3-1.4,2.6-2.9c0.2-1.1-0.3-1.4-1.2-1.3
		c-1.4,0.1-2.3,1.4-2.3,3.4C240.3,408.7,240.7,409.2,241.3,408.9z M322.2,546.7c-0.6-0.6-1.5-0.6-2.2-0.2c-0.8,0.5-0.4,1.1-0.1,1.7
		c0.2,0.4,0.4,1,0.7,1.3c0.5,0.5,1,1.1,1.9,0.9c0.9-0.3,0.3-1,0.5-1.5C323.1,548,322.7,547.2,322.2,546.7z M92.5,572.2
		c0.2,0.5,0.6,0.6,1.1,0.6c0.4,0,0.9-0.1,0.9-0.6c0-1,0.4-2.1-0.3-3.1c-0.5-0.6-0.6-1.9-1.7-1.5c-1.1,0.3,0,1.3-0.3,1.8
		C92,570.5,92.1,571.4,92.5,572.2z M205.1,536.5c-0.1-0.4,0.3-1-0.3-1.2c-0.6-0.2-0.7,0.5-1,0.7c-0.7,0.7-1,1.7-1.8,2.3
		c-0.5,0.5-0.4,1.3-0.1,1.8c0.3,0.6,1,0.2,1.4-0.1c1.3-0.7,2-1.7,1.8-3.1C205.1,536.8,205.1,536.7,205.1,536.5z M50.2,578.3
		c-0.7,0.1-0.9,0.6-0.5,1.2c0.6,0.8,4.2,1.3,5.1,0.8c0.6-0.3,0.6-0.6,0.2-1.2c-0.8-1.1-1.9-0.7-2.8-0.8
		C51.5,578.2,50.8,578.2,50.2,578.3z M66.2,564.5c-0.7-0.1-1.2,0-0.8,0.8c0.4,1,0.9,2,1.5,3c0.3,0.5,0.7,1,1.5,0.7
		c0.7-0.3,0.6-0.8,0.6-1C69,566.2,67.6,564.7,66.2,564.5z M153.4,676.7c-0.3-0.1-0.7-0.3-0.9,0.1c-0.2,0.3-0.1,0.7,0.1,1
		c0.8,1,1.2,2.3,2.4,3c0.3,0.2,0.4,0.8,0.9,0.6c0.5-0.2,0.4-0.7,0.4-1.1C156.1,678.2,155.4,677.2,153.4,676.7z M225.8,578.6
		c-0.4-0.1-0.9-0.3-1.1,0.1c-0.2,0.3-0.2,0.8-0.1,1.1c0.2,0.7,0.7,1.4,0.8,2.1c0.2,1.1,1,0.9,1.7,0.9c1,0,0.6-0.8,0.7-1.3
		C227.9,580.4,226.9,578.9,225.8,578.6z M245.7,580.4c0.3,0.5,0.8,1.1,1.3,0.2c0.7-1.2,0.3-4.3-0.5-5.3c-0.2-0.2-0.3-0.4-0.6-0.4
		c-0.5,0-0.4,0.4-0.5,0.6c-0.3,0.7-0.3,1.5-0.2,2.4C245.1,578.7,245.3,579.6,245.7,580.4z M227.5,529.2c1.1,0.1,1.2-0.9,1.3-1.6
		c0.2-1.2-0.4-2.2-0.8-3.2c-0.2-0.4-0.2-0.7-0.8-0.7c-0.5,0.1-0.5,0.4-0.5,0.8c-0.1,1-0.2,1.9-0.2,2.9
		C226.3,528.2,226.3,529.1,227.5,529.2z M253.2,546.9c-0.4-0.1-0.7,0.1-1,0.3c-0.4,0.2-0.4,0.6-0.2,0.8c0.6,0.7,0.4,1.7,0.8,2.5
		c0.4,0.9,1.2,1.3,2,1.2c0.7,0,0.5-0.9,0.7-1.2C255.6,549.8,253.8,547.1,253.2,546.9z M281.9,610.2c0.8,0.3,1-0.6,1.3-1
		c0.8-1.2,0.8-2.5,0.4-3.9c-0.1-0.3,0.2-0.9-0.4-0.9c-0.5-0.1-0.9,0.4-1,0.8c-0.4,1.1-0.8,2.2-0.7,3.6
		C281.6,609.1,281.2,609.9,281.9,610.2z M176.5,538.9c0.2,0.4,0.5,0.6,1,0.6c0.5-0.1,0.7-0.5,0.7-0.9c0-1.5-0.1-2.9-0.6-4.3
		c-0.2-0.5-0.6-0.7-1.1-0.6c-0.5,0.1-0.3,0.6-0.3,0.9c0,0.5,0,0.9,0,1.4C176.1,536.9,176,537.9,176.5,538.9z M235.4,567.2
		c-0.2-0.2-0.5-0.4-0.8-0.3c-0.4,0.1-0.3,0.5-0.3,0.8c0,1.3,0.2,2.4,0.8,3.6c0.4,0.7,0.7,1.1,1.4,1c1-0.2,0.3-1,0.5-1.4
		C237.1,569.3,236.2,568.3,235.4,567.2z M165.2,566.6c0,0.3,0.1,0.6,0.5,0.6c0.3,0,0.7,0.1,1-0.2c1.1-1,1.2-3.3,0.4-4.6
		c-0.5-0.8-0.9-0.7-1.4,0c-0.6,0.8-0.4,1.6-0.4,2.2C165.2,565.5,165.2,566.1,165.2,566.6z M156.8,522c-0.4-0.1-0.7,0-0.6,0.5
		c0.3,1.3,0.6,2.6,0.8,3.8c0.1,0.5,0.5,0.6,0.9,0.7c0.5,0.2,0.7-0.3,0.9-0.6c0.3-0.4,0.4-0.9,0.4-1.4
		C159.1,523.7,158,522.3,156.8,522z M205.1,549c-0.5-0.2-1,0-1.3,0.5c-0.5,0.9-1,1.8-1.4,2.7c-0.3,0.6,0,1.2,0.4,1.5
		c0.5,0.3,1,0,1.4-0.4c0.8-0.9,1.2-1.9,1.3-3.1C205.3,549.8,205.7,549.2,205.1,549z M132.6,596.5c-0.1,0.2-0.2,0.6,0,0.7
		c1.2,0.6,2.1,1.8,3.5,2c0.4,0.1,1.1,0.4,1.3-0.2c0.1-0.5,0-0.9-0.4-1.5c-0.8-1.1-1.8-1.3-3-1.4C133.6,596.2,133,596,132.6,596.5z
		 M272.7,611.8c-0.1-0.5,0.4-1.2-0.5-1.3c-0.7-0.1-0.9,0.5-1.2,0.9c-0.6,1.3-0.5,2.7-0.5,4.1c0,0.4,0.2,0.6,0.5,0.6
		c0.3,0,0.5-0.1,0.7-0.4c0.5-1,1.2-1.8,0.9-2.8C272.7,612.4,272.7,612.1,272.7,611.8z M301.3,453c-0.4-0.1-0.7-0.8-1.1-0.4
		c-0.4,0.4-0.3,1,0.1,1.5c0.7,0.9,1.4,1.8,2.1,2.6c0.3,0.4,0.8,0.3,1.3,0.2c0.5-0.1,0.2-0.6,0.4-1
		C303.7,454.6,302.8,453.6,301.3,453z M307.7,500.7c-0.4-0.2-0.7-0.1-1.1,0.2c-1.3,1-1.6,2.7-2.5,3.9c-0.1,0.1,0,0.4,0.1,0.5
		c0.2,0.3,0.6,0.1,0.8,0.1c1.4-0.4,2.9-2.3,2.9-3.9C307.8,501.2,308,500.8,307.7,500.7z M262.2,690c0.4,0.2,0.8,0.1,1.1-0.2
		c1.2-1.1,1.7-2.5,2-4.1c0.1-0.8-0.5-0.9-0.9-0.5c-1.3,0.9-2.1,2.2-2.5,3.7C262,689.3,261.7,689.8,262.2,690z M147.6,496.2
		c-0.3-1-1.1-1.2-1.8-1.1c-1,0.1-0.3,1-0.3,1.5c0,0.5,0.1,1.1,0.2,1.6c0.2,0.8,0.3,2,1.4,1.9c1.1-0.1,0.6-1.3,0.8-1.9
		C147.8,497.5,147.8,496.9,147.6,496.2z M223.8,374c-0.4,0.6-1.3,1.2-0.6,1.9c0.5,0.6,1.4,0.2,2.2,0c0.2-0.1,0.4,0,0.6-0.2
		c0.7-0.6,1.3-1.3,1-2.3c-0.1-0.6-0.6-0.7-1.4-0.7C224.5,372.3,224.2,373.4,223.8,374z M146.1,398.6c0-0.9-0.5-1.4-1.4-1.4
		c-1,0-1.1,0.8-1,1.5c0.1,0.9,0.4,1.8,0.6,2.7c0.1,0.4,0.4,0.5,0.8,0.5c0.5,0,0.5-0.3,0.7-0.6c0.3-0.7,0.3-1.4,0.4-2
		C146.2,399.1,146.1,398.8,146.1,398.6z M110.3,561.3c-0.2-0.4-1-0.9-1.1-0.1c-0.1,1.3-0.6,2.5-0.7,3.9c0,0.7,0.4,0.9,0.8,1.2
		c0.6,0.3,0.7-0.3,1-0.6c0.5-0.5,0.7-1.2,0.6-2.1C111.3,562.7,110.7,562,110.3,561.3z M87.9,530.2c-0.6-0.3-1.6-0.9-2.2-0.2
		c-0.5,0.6,0.3,1.3,0.7,2c0.3,0.5,0.8,0.9,1.2,1.3c0.5,0.4,1.1,0.2,1.6,0.2c0.4-0.1,0.3-0.6,0.3-0.9
		C89.5,531.4,88.7,530.7,87.9,530.2z M207.4,518.2c-0.3,0-0.7-0.1-0.8,0.5c-0.1,1.3,1.8,3.7,3,3.8c0.6,0.1,1-0.1,0.9-0.7
		C210.5,520.7,208.4,518.3,207.4,518.2z M183.9,559.5c-0.3-0.4-0.3-1.1-1-1.1c-0.9,0-0.5,0.7-0.8,1c-0.6,1-0.6,2.2-1,3.3
		c-0.1,0.3-0.2,0.6,0.1,0.8c0.2,0.1,0.7,0.2,0.8,0.1c0.9-0.9,1.9-1.9,2-3.4C184,560,184,559.7,183.9,559.5z M227.3,561.1
		c0,1.2,0.9,0.8,1.4,0.6c0.8-0.4,1.4-2.9,1-3.8c-0.2-0.4-0.5-0.8-1-0.9c-0.6-0.1-0.7,0.3-0.9,0.8c-0.3,0.7-0.7,1.4-0.5,2.2
		C227.3,560.4,227.3,560.7,227.3,561.1z M185.2,534.3c1.2-0.4,2.6-2.7,2.6-3.7c0-0.7-0.3-0.8-0.9-0.7c-1.3,0.3-2.5,1.9-2.5,3.3
		C184.4,533.7,184,534.7,185.2,534.3z M192,528.1c-0.8-0.4-1.5-0.3-1.2,0.8c0.2,1.1,0.6,2.2,0.9,3.3c0.1,0.5,0.5,0.3,0.9,0.4
		c0.4,0.1,0.7-0.2,0.8-0.5c0.1-0.4,0.2-0.9,0.3-1.3C193.7,529.4,193.1,528.6,192,528.1z M156.1,534c-0.1,0.4-0.2,1,0.2,1.3
		c1,0.8,1.7,2.1,3.2,2.4c0.3,0.1,0.6,0.3,0.8,0.1c0.4-0.2,0.2-0.6,0.2-1c-0.2-1.2-2.3-2.9-3.9-3C156.4,533.8,156.2,533.9,156.1,534z
		 M124.2,378.6c-0.4-0.2-1-0.3-1.3,0.1c-0.6,0.5-0.8,1.2-0.6,2.1c0.1,0.3,0.1,0.7,0.3,1c0.3,0.4-0.1,1.3,0.6,1.3
		c0.8,0.1,1.1-0.7,1.4-1.2c0.2-0.4,0.5-0.9,0.3-1.4C125,379.7,124.9,379,124.2,378.6z M125.5,518.7c-0.6-0.4-1,0.3-1.1,0.6
		c-0.5,1.2-0.5,2.5-0.1,3.7c0.2,0.6,1,0.7,1.3,0.2c0.4-0.7,1.1-1.4,1-2.5C126.6,519.8,126.2,519.2,125.5,518.7z M267.2,534.2
		c-0.4-0.6-1-0.7-1.5-0.6c-0.7,0.1-0.3,0.8-0.4,1.2c-0.2,0.7,0.1,1.4,0.4,1.9c0.4,0.7,1,1.5,2,1.4c0.8-0.1,0.2-0.9,0.3-1.1
		C268.3,535.6,267.7,534.9,267.2,534.2z M35.2,559.5c0.5,0,1-0.1,0.8-0.7c-0.5-1.4-1.2-2.7-2.6-3.3c-0.6-0.3-1.2-0.2-1.1,0.7
		C32.4,558.3,34.1,559.4,35.2,559.5z M301.6,416.6c-0.7,0-1.2,0.2-1.3,1.1c-0.2,1.2-0.2,2.4-0.8,3.5c-0.1,0.3-0.3,0.7,0.2,0.9
		c0.4,0.1,1.1,0.2,1.1-0.1c0.1-1.5,1.4-2.6,1.3-4.2C302,417.4,302.6,416.6,301.6,416.6z M198.4,541c0.3,0.3,0.6,0.5,1,0.2
		c0.8-0.6,1.4-3.1,0.9-4c-0.2-0.3-0.6-0.6-0.9-0.7c-0.6-0.2-0.6,0.4-0.8,0.7c-0.5,0.7-0.8,1.4-0.7,2
		C198.1,540,197.9,540.6,198.4,541z M129.5,388.8c-0.7-0.4-1,0.2-1.2,0.5c-0.9,1-0.9,2.3-0.9,3.6c0,0.6,0.6,0.6,0.9,0.3
		c0.9-0.8,1.7-1.7,1.9-3C130.3,389.5,130,389.1,129.5,388.8z M178.5,633.7c-0.1-0.5-0.3-1.3-1-1.3c-0.8,0-0.3,0.8-0.5,1.2
		c-0.1,0.4-0.3,0.9-0.3,1.3c-0.1,0.8,0.1,2.1,0.6,2.1c1.7,0.1,1.1,1.2,1.2,2c0.1,0.8-0.1,1.7,0.2,2.5c0-1.8,0.1-3.6,0.1-5.4
		C178.5,635.4,178.7,634.5,178.5,633.7z M204.1,547.7c0.2-0.3,0.3-0.9,0-1.3c-0.2-0.3-0.7-0.1-1,0c-0.9,0.4-1.8,0.8-2.6,1.3
		c-0.4,0.2-0.8,0.7-0.5,1.3c0.2,0.5,0.7,0.6,1.2,0.6C202.6,549.6,203.3,548.7,204.1,547.7z M260.7,622.5c0,0.5,0.5,0.7,0.9,0.6
		c0.3-0.1,0.9,0,1-0.5c0.2-1.4,0.2-2.8,0-4.2c-0.1-0.5-0.6-0.7-1.1-0.6c-0.5,0.1-0.3,0.5-0.4,0.8c-0.1,0.9-0.2,1.9-0.3,2.7
		C260.7,621.8,260.7,622.2,260.7,622.5z M271.1,539.6c-0.1,1.1,0.1,2.1,0.9,2.9c0.7,0.7,1.2,0.9,1.6-0.2c0.3-0.9,0.4-1.8,0.1-2.7
		C272.9,538.9,272,538.2,271.1,539.6z M131,421.8c0.3,1.2,1.1,1.6,2.2,1.5c0.7,0,0.8-0.5,0.5-0.9c-0.8-1-1.3-2.1-1.8-3.2
		c-0.2-0.4-0.5-0.8-1-0.7c-0.7,0.1-0.3,0.7-0.4,1.1C130.3,420.5,130.8,421.1,131,421.8z M268.7,624.2c0,0.3,0.1,0.6,0.5,0.6
		c0.3,0,0.7,0.1,0.8-0.2c0.4-1.3,1.1-2.5,0.8-3.9c-0.1-0.5-0.5-0.9-1-1c-0.6-0.2-0.7,0.5-0.9,0.8c-0.5,0.6-0.1,1.4-0.2,1.9
		C268.7,623.1,268.7,623.6,268.7,624.2z M240,385.8c0.4,0,1,0.3,1-0.5c0-1.1-1.4-3-2.2-3.1c-1-0.1-1,0.7-1.3,1.2
		C237.3,384.6,238.8,385.8,240,385.8z M276.3,552c0.4-1,3.2-1.3,0.9-3.1c-0.4-0.3,0.2-0.4,0.4-0.6c0.4-0.4,0.3-0.9-0.2-1.2
		c-0.4-0.2-0.8-0.2-1,0.3c-0.7,1.4-1.4,2.9-1,4.6C275.7,552.1,276,552.1,276.3,552z M116.1,376.7c-0.1-0.6-0.3-1.3-1.2-1.3
		c-0.8,0.1-1.3,0.5-1.2,1.4c0,0.5,0,1,0.1,1.5c0.3,0.8,1,1.6,1.7,1.6c1,0,0.6-1.1,0.6-1.6C116.1,377.6,116.2,377.2,116.1,376.7z
		 M130.1,508.4c-0.3-0.2-0.8-0.7-1.2-0.4c-0.5,0.4-0.1,0.8,0.1,1.3c0.3,0.7,0.5,1.4,0.6,2.1c0.1,0.5,0.2,0.8,0.5,1.2
		c0.3,0.3,0.5,0.5,0.9,0.4c0.5-0.1,0.5-0.4,0.5-0.8c0-0.3,0-0.6,0-1C131.6,510.1,131.1,509.1,130.1,508.4z M142.1,390.8
		c-0.6-0.2-1.3,0.2-1.5,0.7c-0.3,0.9-0.6,1.9-0.7,2.9c-0.1,0.6,0.5,1.1,0.9,0.8c0.9-0.8,1.8-1.8,2.2-3.1
		C143.1,391.4,142.6,390.9,142.1,390.8z M114.6,503c-0.4-0.3-0.8-0.4-0.9,0.3c-0.1,1.1-0.2,2.2,0.1,3.3c0.2,0.6,0.6,1.3,1.3,1.2
		c0.7-0.1,0.7-0.8,0.7-1.4c0-0.3,0.1-0.6,0.1-0.9C116.1,504.4,115.5,503.6,114.6,503z M194,547.4c-0.2-0.6-0.7-0.9-1.3-0.6
		c-0.8,0.4-1.3,3.7-0.8,4.5c0.4,0.6,1,0.8,1.2,0.1c0.3-1,1.1-1.8,1-2.7C194.1,548.1,194.2,547.8,194,547.4z M228.8,693.8
		c0.2-0.7-0.3-1-0.7-1.4c-0.8-0.7-1.7-0.6-2.6-0.8c-0.4-0.1-0.9-0.3-1.2,0.3c-0.2,0.5,0.1,1,0.4,1.2c0.9,0.5,1.5,1.6,3,1.1
		C227.8,693.9,228.6,694.7,228.8,693.8z M202.6,527.7c-0.4-0.3-1.2-0.6-1.2,0.2c0.1,1.6,0.7,3.2,2.1,4.2c0.8,0.5,0.9-0.1,0.9-0.7
		C204.6,529.8,203.9,528.5,202.6,527.7z M135.4,515c-0.5-0.2-1.1,0.1-1.2,0.6c-0.3,1-0.5,2.1-0.7,3.2c-0.1,0.4,0,0.7,0.4,0.9
		c0.4,0.2,0.6-0.1,1-0.3c1-0.8,1.1-1.8,1.3-3.2C136.1,515.9,136.1,515.2,135.4,515z M251.7,472.8c-1.1-0.6-2-0.2-2.9,0.5
		c-0.7,0.6-1.1,1.2-0.5,2.1c1.5,0.6,2.7,0.2,4-0.6C252.5,474,252.5,473.2,251.7,472.8z M309.2,508.1c0,0.7,0,1.4,0,2.1
		c0,0.4,0,0.7,0.5,0.8c0.4,0.1,0.6-0.1,0.9-0.4c0.8-1,0.7-4-0.3-4.9c-0.2-0.2-0.5-0.3-0.8-0.2c-0.3,0.1-0.2,0.4-0.2,0.6
		C309.2,506.8,309.2,507.4,309.2,508.1C309.2,508.1,309.2,508.1,309.2,508.1z M199.4,530.5c-0.3-0.1-0.6-0.1-0.8,0.2
		c-0.3,0.3-0.3,0.6-0.1,1c0.4,0.8,0.9,1.5,1.3,2.3c0.3,0.6,0.8,0.7,1.3,0.6c0.6-0.1,0.5-0.7,0.5-1.1
		C201.7,532.1,200.7,530.8,199.4,530.5z M226.9,511.7c0.1-0.5,0.2-1.1-0.1-1.5c-0.8-0.7-1.8-1.4-2.8-2c-0.3-0.2-0.9-0.4-1.1,0.4
		c-0.2,1,2,3.3,3.1,3.3C226.4,511.9,226.8,512,226.9,511.7z M148.1,545.3c0.6-0.4,1-1,0.6-1.7c-0.4-0.7-1.1-1-1.7-0.5
		c-0.5,0.4-1.1,0.5-1.6,0.8c-0.4,0.3-1.3,0.4-1.1,1.1c0.1,0.6,0.9,0.5,1.4,0.6c0.2,0,0.4,0,0.5,0
		C146.8,545.7,147.5,545.7,148.1,545.3z M90.3,523.8c0.3,0.7,0.6,1.3,1.4,1.5c0.3,0.1,0.8,0.1,0.9-0.3c0.1-1,0.4-2-0.3-2.9
		c-0.4-0.5-0.8-1.4-1.7-1c-0.7,0.3-0.2,1.1-0.4,1.4C90.3,523,90.2,523.4,90.3,523.8z M99.1,528c-0.6-0.2-1,0.1-1.3,0.6
		c-0.5,0.9-1.2,1.8-1.1,2.9c0,0.4,0,0.9,0.5,1c0.4,0.1,0.8,0,1.2-0.4c0.8-0.9,0.9-1.9,1.1-2.9C99.3,528.8,99.7,528.2,99.1,528z
		 M275.4,679.1c-0.4,0-0.6,0.3-0.6,0.7c0,0.2,0,0.6,0.3,0.6c1.5,0.2,2.9,0.8,4.4,0.2c0.3-0.1,0.7-0.1,0.7-0.6c0-0.4-0.3-0.6-0.6-0.8
		c-0.7-0.4-1.4-0.2-2.4-0.3C276.8,679,276.1,679.1,275.4,679.1z M264.8,581.8c-0.4-0.7-0.9-0.5-1.4-0.5c-0.8,0-0.4,0.6-0.5,1
		c-0.2,0.9,0.5,1.6,0.7,2.3c0.2,0.7,0.5,1.3,1.3,1.2c0.9-0.2,0.2-1,0.4-1.4C265.4,583.4,265.3,582.6,264.8,581.8z M128.2,587
		c-0.3-0.3-0.7-0.4-1.1-0.2c-0.4,0.2-0.3,0.6-0.2,0.9c0.2,1,0.5,2,0.9,3c0.2,0.5,0.8,0.6,1.3,0.6c0.7-0.1,0.6-0.7,0.6-1.5
		C129.5,588.9,129.1,587.9,128.2,587z M187.1,547.3c0.4,0.1,0.8,0,1.1-0.3c1-0.7,1.9-1.5,2.9-2.3c0.4-0.3,0.5-0.7,0-0.9
		c-1.2-0.6-4.2,1.2-4.3,2.6C186.9,546.7,186.6,547.1,187.1,547.3z M278.2,568.1c0.4,0.2,0.7,0,1.1-0.3c0.9-0.7,1.2-1.7,1.5-2.7
		c0.1-0.6,0-1.2-0.9-1.3c-0.7,0-1.3,0-1.5,0.9c-0.1,0.8-0.3,1.6-0.4,2.3C278,567.5,277.9,567.9,278.2,568.1z M208,548.4
		c0,0.5-0.1,1-0.1,1.5c0,0.3-0.2,0.7,0.3,0.9c0.4,0.1,0.7,0,1-0.4c0.8-1,1-2.1,0.5-3.3c-0.3-0.7-0.8-1.1-1.5-1
		c-0.7,0.1-0.3,0.8-0.3,1.3C207.8,547.7,207.9,548.1,208,548.4C207.9,548.4,207.9,548.4,208,548.4z M171.5,403.6
		c-0.3-0.4-0.7-0.7-1.1-0.3c-0.4,0.3-0.8,0.7-0.5,1.4c0.4,1.1,2.4,2.5,3.5,2.4c0.3,0,0.4-0.2,0.4-0.6
		C173.3,405.4,172.4,404.5,171.5,403.6z M255.2,482.6c0.3-0.3,0.3-0.7,0.2-1c-0.2-0.3-0.5-0.2-0.8-0.1c-1.1,0.3-2.1,0.5-3.1,1.1
		c-0.6,0.3-1.1,0.7-1,1.3c0.1,0.7,0.9,0.2,1.3,0.4C253.1,484.1,254.2,483.6,255.2,482.6z M201.4,500.7c-0.8,0.4-1.5,1.1-2.2,1.7
		c-0.6,0.5-0.4,1.1-0.2,1.7c0.2,0.4,0.9,0.3,1.2,0.2c1.1-0.5,1.9-1.2,2.1-2.4C202.3,501.1,202.6,500.1,201.4,500.7z M243.7,682.1
		c-0.9,0-2.8,1.5-3,2.3c-0.1,0.3-0.2,0.7,0.2,1c1.1,0.6,3.4-0.9,3.4-2.3C244.2,682.8,244.6,682.1,243.7,682.1z M81.1,533.8
		c0.2,0.2,0.4,0.3,0.6,0.3c0.3,0,0.3-0.3,0.3-0.6c0-1.3,0.2-2.6-0.3-3.9c-0.2-0.5-0.5-1.1-1.2-0.9c-0.6,0.1-0.3,0.8-0.3,1.1
		c-0.1,0.3,0,0.6,0,1.2C80,531.9,80.3,532.9,81.1,533.8z M170.7,530.7c-0.1-0.7,0.1-1.8-1-1.8c-1.2-0.1-0.8,1.2-1.1,1.8
		c-0.1,0.1,0,0.3,0,0.4c0,1,0,2.3,1.1,2.3c1.2,0,0.9-1.3,1-2.1C170.7,531,170.7,530.9,170.7,530.7z M198,611.1c0.3,0.1,0.7,0,1-0.1
		c0.9-0.4,1.6-2.6,1.2-3.6c-0.3-0.8-0.8-0.9-1.3-0.3c-0.7,0.8-1.2,1.8-1.3,3C197.9,610.4,197.5,611,198,611.1z M282.7,571.3
		c-0.2-0.7-0.7-1.2-1.3-1.5c-0.4-0.3-0.8,0.2-0.9,0.6c-0.3,0.9-0.6,1.9,0.3,2.7c0.5,0.5,1,1,1.6,0.8c0.6-0.2,0.2-1,0.3-1.3
		C282.7,572,282.7,571.6,282.7,571.3z M260.8,575.5c0.1-0.3,0-0.6-0.3-0.8c-0.8-0.8-1.6-1.7-2.4-2.6c-0.2-0.3-0.5-0.3-0.8-0.3
		c-0.3,0.1-0.2,0.3-0.2,0.5c0,1.7,0.7,3.5,2.9,3.4C260.2,575.7,260.7,576,260.8,575.5z M198.1,552.5c-0.1-0.6-0.5-0.6-0.9-0.3
		c-0.7,0.5-1.3,3.1-0.9,3.9c0.2,0.4,0.5,0.8,1,0.8c0.6,0,0.6-0.5,0.7-0.8c0.2-0.6,0.3-1.3,0.4-1.9
		C198.3,553.6,198.2,553.1,198.1,552.5z M164.5,627.9c-0.8-0.1-1.5,0.6-1.5,1.5c0,1,0.6,1.7,1.5,1.7c0.8,0,1.5-0.7,2-1.4
		C166.3,628.6,165.4,628.1,164.5,627.9z M123.8,387c0-0.6-0.3-1-0.8-1.2c-0.6-0.3-0.9,0.3-1.2,0.7c-0.6,0.8-0.1,3.1,0.8,3.4
		c0.5,0.2,1.3,0.4,1.2-0.7c0-0.4,0-0.7,0-1.1C123.8,387.8,123.8,387.4,123.8,387z M108.4,464c-0.5,0-1.1,0.3-0.6,0.8
		c0.7,0.7,0.8,1.7,1.4,2.4c0.4,0.5,0.9,0.6,1.4,0.5c0.6-0.1,0.4-0.7,0.5-1.1C111.1,465.2,109.9,464,108.4,464z M181.6,371.1
		c0.3,0.1,0.6-0.1,0.7-0.4c0.5-0.7,1.1-1.4,1.5-2.2c0.5-0.8,0-1.6-0.5-2c-0.7-0.5-0.9,0.4-1.3,0.8c-0.8,0.8,0,2-0.8,2.8
		C181.4,370.5,181.1,370.9,181.6,371.1z M117.7,527.7c-0.3-0.2-0.5-0.3-0.8-0.2c-0.3,0.1-0.2,0.4-0.2,0.6c0.1,1.6,0.8,3,1.6,4.3
		c0.3,0.4,0.8,0.5,0.9-0.3c0-0.3,0-0.7,0-1.3C119.4,529.6,118.7,528.6,117.7,527.7z M127.3,383.9c-0.6,0-1.3-0.2-1.4,0.9
		c-0.1,0.8-0.2,1.7-0.7,2.4c-0.2,0.3-0.3,0.7,0,0.9c0.4,0.3,1.1,0.3,1.3,0c0.5-1,1.4-1.9,1.5-3.2C127.9,384.6,128,383.9,127.3,383.9
		z M244.6,649.3c0.8-0.5,0.9-1.5,0.5-2.1c-0.4-0.7-1.1-0.2-1.6,0.3c-0.3,0.3-0.9,0.3-1.2,0.6c-0.4,0.4-1.3,0.5-1.1,1.3
		c0.2,0.8,0.9,0.4,1.3,0.5C243.4,649.9,244,649.7,244.6,649.3z M182.4,627.6c0.8,0,1.3-0.4,1.4-1.1c0.1-0.7-0.7-0.6-1.1-0.7
		c-0.9-0.2-1.9-0.4-2.8-0.1c-0.4,0.1-0.8,0.1-0.9,0.6c-0.1,0.6,0.4,0.5,0.6,0.8c0.6,0.7,1.3,0.5,1.7,0.5
		C181.8,627.6,182.1,627.5,182.4,627.6z M161.1,606.5c0.2,0.3,0.5,0.4,0.8,0.3c0.3-0.1,0.2-0.4,0.2-0.6c0.1-1.2-0.7-2.3-0.9-3.4
		c-0.1-0.4,0.2-1.1-0.7-1c-0.8,0.1-0.9,0.6-0.9,1.3C159.7,604.3,160.6,605.4,161.1,606.5z M244.5,568.6c-0.2,0.9-0.8,1.9-0.2,2.9
		c0.2,0.4,0.7,0.4,0.9-0.1c0.5-1.1,1.2-2.1,1.4-3.2c0.1-0.6,0.1-1.1-0.8-1.2C245.4,567.5,244.7,567.8,244.5,568.6z M115.9,382.4
		c-0.5-0.5-1.1-0.4-1.7-0.2c-0.4,0.2-0.3,0.9-0.2,1.1c0.7,0.9,1.2,2,2.3,2.6c0.5,0.3,1,0.4,1-0.7
		C116.8,384.5,116.7,383.3,115.9,382.4z M248.9,383.8c0.1-0.7-0.4-1.1-0.8-1.3c-0.3-0.2-0.7-0.1-1.1,0.3c-0.4,0.5-0.5,0.9-0.3,1.5
		c0.3,0.6,0.4,1.3,0.7,2c0.2,0.5,0.7,0.6,1.1,0.5c0.6,0,0.3-0.5,0.4-0.8c0-0.3,0-0.6,0-0.9C248.9,384.6,248.9,384.2,248.9,383.8z
		 M154.8,529.9c-0.3-0.9-1.2-1.1-2-0.9c-0.9,0.2-0.5,1.1-0.3,1.6c0.2,0.7,0.8,1.2,1.3,1.8c0.3,0.3,0.7,0.5,1.1,0.4
		c0.4-0.2,0.1-0.6,0.2-1C155,531.1,155,530.5,154.8,529.9z M250.8,564.2c-0.4-0.2-0.8-0.6-1,0.1c-0.3,0.9,0.9,3.7,1.7,4.1
		c0.6,0.3,1.1,0.2,1-0.6C252.5,566.6,251.6,564.7,250.8,564.2z M259,578.3c-0.4-0.2-0.8,0.2-1.1,0.3c-0.6,0.3-1.5,3-1.4,3.5
		c0.1,0.5,0.5,0.4,0.8,0.3c1.4-0.5,1.9-1.6,1.9-3.1C259.1,579,259.5,578.5,259,578.3z M151.5,524.3c0,1,2.5,3,3.5,2.9
		c0.5,0,0.6-0.2,0.6-0.7c-0.2-1.2-2-2.7-3.2-2.7C151.9,523.8,151.4,523.6,151.5,524.3z M294,497.8c0.2-0.3,0.1-0.7-0.1-1
		c-0.7-0.7-1.4-1.4-2.1-2.1c-0.3-0.3-0.6-0.1-0.9,0.2c-0.2,0.3-0.7,0.6-0.4,0.8c0.9,0.7,1,2.3,2.3,2.4
		C293.3,498.2,293.7,498.3,294,497.8z M253.5,473.2c0.8-0.4,1.1-1,0.9-1.8c-0.5-1-1-1.9-2.4-1.6c-0.5,0.6-1.3,0.9-1.2,1.9
		C251.6,472.3,252.1,473.4,253.5,473.2z M147.8,613c0,0.8,0.3,1.7,1,1.7c1,0,0.8-1,0.8-1.7c0-0.3,0.1-0.6,0-0.9
		c-0.3-0.7,0.2-2.1-1-2.1c-1.2,0-0.7,1.3-0.8,1.9C147.8,612.4,147.8,612.7,147.8,613z M130.9,531c0.2,0.2,0.5,0.4,0.8,0.3
		c0.4-0.1,0.2-0.5,0.3-0.8c0.2-1.2-0.4-2.2-1.1-3.1c-0.3-0.3-0.6-0.8-1.1-0.5c-0.5,0.2-0.1,0.7-0.2,1.1
		C129.5,529.2,130.1,530.1,130.9,531z M199.9,385.7c0,0.3,0,0.6,0,0.9c0,0.8,0.4,1.4,1.1,1.5c0.8,0.1,0.4-0.9,0.6-1.3
		c0.2-0.6,0.3-1.3,0.4-1.9c0.1-0.5,0-1.1-0.7-1.1c-0.5,0-1.2,0.1-1.4,0.7C199.8,384.8,199.9,385.3,199.9,385.7
		C199.8,385.7,199.9,385.7,199.9,385.7z M108.5,542.1c0.1,0.4-0.2,0.9,0.3,1.1c0.6,0.2,1-0.4,1.1-0.7c0.4-1.1,0.9-2.2,0.6-3.3
		c-0.1-0.3,0-0.7-0.5-0.8c-0.6-0.1-0.6,0.4-0.8,0.7c-0.4,0.8-0.9,1.6-0.7,2.4C108.5,541.7,108.5,541.9,108.5,542.1z M226.4,381.7
		c0.2,0.3,0.7,0.2,1,0c0.3-0.2,0.2-0.5,0.1-0.8c-0.2-0.7-0.5-1.4-0.5-2.2c-0.1-0.7,0-1.4-1-1.2c-0.9,0.2-0.8,0.9-0.9,1.5
		C225,380.2,225.8,381,226.4,381.7z M93.6,580.8c-0.3-0.5-0.8-0.6-1.2-0.4c-0.5,0.2-0.3,0.8-0.2,1.1c0.7,1,0.9,2.3,1.5,3.4
		c0.2,0.3,0.3,0.8,0.8,0.7c0.5-0.1,0.2-0.6,0.3-0.9C94.7,583.3,94.3,582,93.6,580.8z M224.7,532.8c0-0.3,0.1-0.5,0.1-0.7
		c0-0.4,0.3-0.8-0.4-1c-0.5-0.1-0.8,0.1-1,0.5c-0.3,0.9-1,1.6-0.8,2.7c0.1,0.5,0.2,1,0.8,1.1c0.7,0.1,1.1-0.3,1.2-0.9
		C224.8,533.9,224.8,533.3,224.7,532.8C224.8,532.8,224.8,532.8,224.7,532.8z M204.9,377.5c-0.8,0-2.4,2.4-2.1,3
		c0,0.1,0.2,0.2,0.3,0.2c1.1,0.3,2.9-1.1,2.9-2.3C206.1,377.8,205.7,377.5,204.9,377.5z M186.4,639c0.4-0.4,0.7-1-0.1-1.1
		c-1.1-0.1-2.4-0.4-3.5,0.5c-0.4,0.3-0.8,0.5-0.7,1c0.1,0.6,0.6,0.6,1.1,0.5C184.5,639.9,185.5,639.7,186.4,639z M93.6,547
		c-0.1-0.4,0.2-0.9-0.4-1c-0.6-0.1-1,0.4-1,0.7c-0.2,1.1-0.7,2.2-0.3,3.4c0.1,0.3,0.1,0.7,0.6,0.8c0.4,0,0.4-0.3,0.6-0.5
		c0.7-0.7,0.4-1.5,0.5-2.1C93.6,547.7,93.7,547.3,93.6,547z M258,623.7c0-0.4,0-0.8-0.6-0.8c-1.2,0-2.8,1.6-2.8,2.7
		c0,0.3-0.1,0.8,0.5,0.7C256.2,626.4,258,624.7,258,623.7z M109.1,576.9c-0.3-0.2-0.7-0.2-1-0.1c-0.5,0.2-0.2,0.6-0.1,0.9
		c0.2,0.8,0.4,1.5,0.6,2.3c0.1,0.4-0.4,1.1,0.5,1.2c0.7,0,0.8-0.7,1-1.2c0.1-0.3,0.1-0.6,0.1-0.7
		C110.1,578.6,109.4,577.2,109.1,576.9z M191.7,378.1c-0.7-0.8-1.2-0.5-1.5,0.3c-0.4,1.1,0.7,3.1,1.8,3.4c0.6,0.1,0.7-0.3,0.6-0.9
		C192.2,380,192.5,378.9,191.7,378.1z M125.6,393.7c0-0.6-0.3-0.9-0.9-0.9c-0.7,0-0.9,0.4-0.9,1c0,0.8,0,1.7,0.3,2.4
		c0.3,0.5,0.1,1.7,1,1.5c0.8-0.1,0.2-1.1,0.4-1.7c0.1-0.2,0-0.4,0.1-0.5C125.7,394.8,125.6,394.3,125.6,393.7z M240.7,390.4
		c0-0.5,0.1-0.9,0-1.3c-0.2-0.6-0.4-1.4-1.2-1.3c-0.7,0.1-0.9,0.9-0.8,1.5c0.1,0.7,0.3,1.4,0.5,2.2c0.1,0.6,0.6,0.4,0.9,0.4
		c0.5,0.1,0.6-0.2,0.6-0.6C240.7,391,240.7,390.7,240.7,390.4C240.7,390.4,240.7,390.4,240.7,390.4z M243.6,384
		c0.6,0,0.8-0.2,0.8-0.8c-0.1-1.1-1.3-2.7-2-2.6c-0.9,0.1-0.7,0.8-0.8,1.4C241.6,383.4,242.3,384,243.6,384z M123.3,525.5
		c-0.2-0.2-0.3-0.6-0.8-0.5c-0.4,0.1-0.3,0.5-0.3,0.8c0,0.9,0,1.7,0,2.6c0,0.8,0.1,1.6,1,1.6c1.1,0,0.4-1,0.6-1.5c0-0.1,0-0.3,0-0.5
		C123.7,527.1,124.1,526.2,123.3,525.5z M52.9,574.5c-0.5-0.9-1.2-1.4-2.2-1.5c-1.1-0.1-1.2,0.6-0.8,1.3c0.4,0.8,1,1.6,2.1,1.5
		C53.2,575.9,53.6,575.7,52.9,574.5z M120.2,389c-0.4-0.5-0.6-0.5-1,0c-1.2,1.4,0.3,2.2,0.6,3.2c0.1,0.4,0.5,0.8,1,0.7
		c0.7-0.2,0.3-0.7,0.3-1.1C121.2,390.7,120.8,389.8,120.2,389z M243.5,393.6c-0.1,0.9-1,1.7-0.6,2.7c0.2,0.4,0.7,0.2,1,0.2
		c0.8-0.2,1.8-2.1,1.5-2.9c-0.1-0.4-0.2-0.8-0.8-0.7C244.2,392.9,243.6,393,243.5,393.6z M78.1,626.2c-0.7,0-2,0.6-1.9,1.5
		c0.1,1.3,1.7,0.9,2.5,1.4c0.5,0.2,0.8-0.2,0.9-0.8C78.9,627.7,79.3,626.1,78.1,626.2z M175.7,399.2c-1.1,0-1.3,0.9-1.3,1.6
		c0,0.8,0.1,2.1,1,2c0.8-0.1,1.3-1.3,1.4-2.3C176.8,399.6,176.6,399.1,175.7,399.2z M252.1,641.6c-0.8,0-1.1,0.9-1.2,1.7
		c0,0.4-0.2,0.8-0.1,1.2c0.2,0.5-0.2,1.3,0.6,1.4c0.7,0,1.1-0.6,1.4-1.2c0.2-0.3,0.1-0.8,0.2-1.3
		C252.7,642.7,253.2,641.6,252.1,641.6z M45.8,488.4c-0.6,0-1.1,0.2-1.2,1c0,0.4-0.3,0.9-0.3,1.3c0,0.5-0.9,1.2,0,1.6
		c0.8,0.4,1.3-0.4,1.7-0.9c0.3-0.4,0.5-1.1,0.5-1.6C46.5,489.1,46.5,488.5,45.8,488.4z M266,546.3c-0.1-0.2-0.7-0.1-1,0
		c-0.3,0.1-0.3,0.4-0.2,0.6c0.7,1.1,1.5,2.2,2.3,3.3c0.2,0.3,0.5,0.3,0.8,0.2c0.1-0.1,0.1-0.3,0.2-0.6
		C267.6,548.6,266.9,547.4,266,546.3z M230,690c-0.3,0-0.7-0.1-0.8,0.2c-0.2,0.4,0,0.7,0.4,0.9c1,0.6,2,1.3,3.1,1.6
		c0.5,0.2,0.8-0.1,0.7-0.6C233.3,691.1,231.5,690,230,690z M254.3,535.7c-0.6-0.2-0.7,0.5-1,0.7c-0.8,0.6-0.8,1.4-0.9,2.2
		c-0.1,0.4-0.1,0.9,0.3,1.1c0.5,0.3,0.6-0.3,0.9-0.5c0.9-0.5,1-1.4,1-2.3C254.5,536.6,255,535.9,254.3,535.7z M242.2,375.9
		c-0.5-0.1-0.9,0.1-1.2,0.5c-0.4,0.5-0.7,1.1-1.1,1.6c-0.2,0.3-0.3,0.7-0.1,1c0.2,0.3,0.7,0.2,1,0.1c0.9-0.4,1.9-0.8,1.7-2.2
		C242.4,376.6,242.8,376,242.2,375.9z M176.8,374.9c0.2,0.3,0.3,0.7,0.7,0.6c0.6-0.1,0.4-0.5,0.4-0.8c0-0.9,0.1-1.7,0.5-2.5
		c0.1-0.3,0.3-0.8-0.2-0.9c-0.4-0.1-0.9-0.3-1.2,0.2c-0.3,0.6-0.8,1.1-0.7,1.7C176.4,373.9,176.6,374.4,176.8,374.9z M114.7,408.3
		c-0.3-0.2-0.5-0.3-0.8-0.2c-0.3,0.1-0.2,0.4-0.2,0.7c0.2,1.1,0.5,2.2,1,3.3c0.3,0.5,0.7,0.6,0.9-0.1c0.1-0.4,0.2-0.8,0.3-1
		C115.8,409.7,115.4,408.9,114.7,408.3z M138.6,368.5c-0.6,0-1.8,2-1.8,2.8c0,0.7,0.5,0.5,0.9,0.5c0.8,0,1.9-1.4,1.9-2.5
		C139.6,368.9,139.4,368.4,138.6,368.5z M219.1,371.9c0.1,0.8,0.2,2,1.1,2c0.9,0,0.4-1.2,0.7-1.8c0.1-0.1,0-0.4,0-0.6
		c0-0.6,0.2-1.4-0.8-1.4c-0.9,0-1.2,0.6-1,1.4C219.1,371.6,219,371.7,219.1,371.9z M222.4,688.1c-0.5,0-1-0.1-1,0.7
		c0,0.6,0.3,0.9,0.9,0.8c0.7,0,1.4,0,2.1,0c0.7,0,1.2-0.5,1.2-1.1c0-0.8-0.7-0.4-1.1-0.5c-0.3-0.1-0.6,0-0.9,0
		C223.2,688.1,222.8,688.1,222.4,688.1z M245.7,390.1c0.4,0.1,0.7,0,0.7-0.5c0.1-1.1-0.8-2.8-1.5-2.8c-0.8,0.1-0.7,0.8-0.8,1.2
		C244,389.3,244.7,389.8,245.7,390.1z M275.6,571.9c0.3,0.6,0.8,1.1,1.4,0.8c0.4-0.2,0.3-0.9,0-1.4c-0.4-0.6-0.7-1.3-0.7-2.1
		c0-0.5-0.1-0.8-0.7-0.7c-0.6,0-0.6,0.4-0.5,1.2C275.2,570.2,275.2,571.1,275.6,571.9z M210.7,543.8c-0.3-0.3-0.2-1.2-0.9-0.7
		c-0.5,0.3-0.6,0.9-0.2,1.5c0.4,0.7,0.9,1.3,1.2,2.1c0.2,0.3,0.2,0.7,0.7,0.6c0.4-0.1,0.5-0.6,0.7-1
		C211.7,545.3,211.3,544.5,210.7,543.8z M268.1,423.7c-0.5-0.2-0.8,0.3-1.1,0.6c-0.5,0.4-0.4,1-0.5,1.5c-0.1,0.6-0.6,1.4,0.1,1.7
		c0.8,0.3,1.1-0.7,1.5-1.3c0.2-0.4,0.5-0.9,0.2-1.7C268.2,424.4,268.6,423.9,268.1,423.7z M221.3,382.1c1.3,0,1.4-1.2,1.5-2.2
		c0.1-0.4,0.1-1.5-0.6-1.4c-1,0.2-1.6,0.9-1.3,2.3C221.1,381.1,220.4,382.1,221.3,382.1z M251.9,399.9c-0.3-0.2-0.6-0.2-0.8,0
		c-0.4,0.5-0.8,1-0.9,1.5c-0.1,0.7-0.5,1.9,0.2,2.2c0.9,0.3,1-0.9,1.4-1.5c0.2-0.3,0.3-0.8,0.4-1.3
		C252.2,400.5,252.3,400.2,251.9,399.9z M144.2,566.9c0.2,0.8,0.5,1.6,1.1,2c0.3,0.2,1.2,1,1.6-0.1c0.3-1.1-0.9-2.5-2-2.5
		C144.5,566.3,144,566.4,144.2,566.9z M108.2,551.2c0.1,0.9,0.9,1.3,1.4,1.3c0.8-0.1,0.4-0.9,0.3-1.5c-0.1-0.4-0.1-0.9-0.3-1.3
		c-0.2-0.4,0.2-1.2-0.6-1.2c-0.8,0-0.4,0.9-0.8,1.3c-0.1,0.1,0,0.5,0,0.4C108.2,550.7,108.2,550.9,108.2,551.2z M167.9,375
		c-0.3-0.1-0.6-0.2-0.8,0c-0.6,0.6-0.4,1.4-0.2,2.1c0.2,0.7,0.3,1.5,1.2,1.4c0.9-0.2,0.2-1,0.4-1.5
		C168.5,376.3,168.8,375.4,167.9,375z M75.4,535c-0.1-0.7-0.6-1.2-1.1-1.1c-0.9,0-0.3,0.8-0.5,1.3c-0.1,0.3,0,0.7,0,1
		c0,0.7,0.1,1.2,0.9,1.2c0.9,0,0.8-0.7,0.9-1.4C75.7,535.8,75.4,535.4,75.4,535z M198.9,369.6c-0.9,0-1.2,0.6-1.2,1.4
		c0,0.8,0.2,1.7,1.2,1.7c0.9,0,0.9-0.9,0.9-1.4C199.9,370.4,200,369.7,198.9,369.6z M276.2,562.2c-0.5-0.1-1.7,1.8-1.7,2.7
		c0,0.4-0.2,1,0.6,0.9c1.4-0.2,1.6-1.1,1.6-2.4C276.4,563,277,562.3,276.2,562.2z M215,536.1c-0.3-0.2-0.6-0.2-0.8,0
		c-0.3,0.2-0.4,0.5-0.2,0.8c0.5,0.8,1,1.6,1.4,2.4c0.2,0.4,0.5,0.3,0.8,0.2c0.3-0.1,0.5-0.4,0.4-0.8
		C216.3,537.7,216.1,536.7,215,536.1z M177.3,592.8c-0.9-0.2-0.6,0.8-0.9,1.3c-0.3,0.4-0.3,0.9-0.2,1.3c0.2,0.5,0.1,1.2,0.9,1.2
		c0.8,0,0.4-0.8,0.7-1.2c0.2-0.3,0.2-0.8,0.2-0.9C177.9,593.7,178.1,593,177.3,592.8z M239.1,673.2c-0.9,0-1.5,0.6-2.2,1.1
		c-0.3,0.2-0.7,0.4-0.5,0.8c0.2,0.4,0.5,0.8,0.9,0.9c0.4,0.1,2.4-1.7,2.4-2.2C239.8,673.2,239.5,673.2,239.1,673.2z M235.2,381.9
		c1.3,0,1.1-1.1,1.2-1.8c0.1-0.7,0.1-1.6-0.9-1.5c-1.1,0.1-0.8,1.1-0.9,2C235,380.8,233.9,381.9,235.2,381.9z M238.8,654.3
		c-0.6-0.2-0.9,0.2-1,0.8c-0.1,0.4-0.5,0.7-0.5,1.1c0.1,0.6-1,1.4,0,1.8c0.8,0.3,1.3-0.7,1.6-1.3c0.2-0.3,0.2-0.8,0.2-1.1
		C239.1,655,239.5,654.4,238.8,654.3z M78.7,427.5c0.1,0.1,0.5,0,0.5-0.1c0.3-0.8,0.9-1.6,0.6-2.6c-0.2-0.6-0.3-1.6-1.1-1.5
		c-0.8,0.1-0.2,1-0.4,1.5c0,0.1,0,0.3,0,0.6C78.6,426,78,426.9,78.7,427.5z M231.3,537.4c-1.3,0.1-1.3,1.1-1.3,2.1
		c0,0.6-0.1,1.3,0.8,1.2c1.2-0.2,1-1.1,1.1-2C231.7,538.4,232.3,537.4,231.3,537.4z M236.7,691.9c0,0.6,0.3,0.9,0.8,0.8
		c1.3-0.1,1.6-1.1,1.9-2.1c0.1-0.6-0.3-0.6-0.7-0.6C237.8,689.9,236.7,691,236.7,691.9z M163.4,558.5c-1.1-0.1-1,0.7-1,1.5
		c0,0.3-0.2,0.6-0.2,0.9c-0.1,0.4-0.2,0.9,0.1,1.1c0.4,0.3,0.8-0.1,1.2-0.4c0.5-0.4,0.6-0.9,0.6-1.6
		C163.8,559.5,164.4,558.6,163.4,558.5z M143.5,371.3c0.9-0.1,1.5-0.6,1.5-1.5c0-0.6-0.1-1.3-1-1.3c-1.2,0-1,0.9-1.2,1.7
		C143,370.6,142.5,371.4,143.5,371.3z M224.9,677.8c-1,0.1-1.8,0.8-2.4,1.5c-0.2,0.3-0.5,1.2,0.5,1.2c0.7,0,2.4-1.6,2.4-2.2
		C225.5,677.9,225.3,677.8,224.9,677.8z M237.6,700.6c-0.2,0-0.5,0-0.6,0.3c-0.1,0.3,0.1,0.5,0.4,0.6c1.1,0.3,2.2,0.5,3.3,0.3
		c0.4-0.1,0.8-0.3,0.8-0.8c0-0.7-0.6-0.4-0.9-0.5c-0.5,0-0.9,0-1.4,0C238.7,700.6,238.2,700.6,237.6,700.6z M194.3,541.1
		c0.8-0.2,1-0.9,1.3-1.5c0.1-0.2,0-0.5-0.2-0.7c-0.7-0.5-1,0.4-1.6,0.4c-0.2,0-0.5,0.4-0.7,0.6c-0.3,0.3-1,0.2-0.7,0.9
		c0.2,0.5,0.7,0.3,1.1,0.3C193.7,541.2,194,541.1,194.3,541.1z M195.8,595.5c-0.6-0.1-1.7,1.7-1.7,2.6c0,0.5,0.2,0.6,0.7,0.6
		c1.3-0.1,1.7-1,1.8-2.2C196.4,596.2,196.5,595.6,195.8,595.5z M130.9,385.9c0.6,0,1.9-1.5,1.9-2.2c0-0.6-0.5-0.9-0.9-0.8
		c-1.3,0.1-1.1,1.4-1.6,2C130.4,385.4,130.3,385.9,130.9,385.9z M162.8,378.9c-0.6-0.1-1,0.4-0.9,1.1c0.1,1,0.6,1.7,1.6,1.8
		c0.7,0.1,0.4-0.6,0.5-1.1C163.8,380.1,163.8,379.1,162.8,378.9z M234.3,385.2c-0.9-0.1-0.9,0.7-0.8,1.3c0.1,0.7,0,1.8,1.2,1.8
		c1.1,0,0.4-0.9,0.6-1.2C235.3,386.1,235.3,385.3,234.3,385.2z M247.9,379.9c-0.4-1-1.4-1.5-2.4-1.7c-0.5-0.1-0.9-0.1-0.9,0.6
		c0.2,1.6,1.7,0.9,2.3,1.5C247.4,380.2,248.2,380.5,247.9,379.9z M150.9,589.2c-0.4-0.1-0.6,0.1-0.6,0.6c0,1.1,0.9,1.7,1.6,2.4
		c0.1,0.2,1,0.4,1.2-0.2C153,591.4,151.3,589.3,150.9,589.2z M139.2,534.4c0.1,0.5,0.4,0.7,0.8,0.5c0.4-0.1,0.5-0.5,0.4-0.8
		c-0.2-0.7-0.4-1.3-0.7-1.9c-0.1-0.3-0.3-0.8-0.8-0.7c-0.6,0.1-0.3,0.6-0.3,1C138.4,533.3,139.1,533.8,139.2,534.4z M120.4,382
		c-0.2-0.5,0.5-1.5-0.5-1.6c-0.7,0-0.6,1-0.7,1.6c0,0.2-0.1,0.6,0,0.7c0.3,0.6-0.2,1.9,0.8,1.8c0.8-0.1,0.2-1.2,0.4-1.8
		c0,0,0-0.1,0-0.2C120.4,382.4,120.5,382.2,120.4,382z M147.9,622c0,0.9-0.1,1.9,0,2.8c0.1,0.5,0.7,0.2,1,0.2c0.4,0,0.4-0.4,0.4-0.6
		c-0.1-0.7-0.2-1.5-0.4-2.2c-0.1-0.3-0.3-0.5-0.7-0.5C148.1,621.7,147.9,621.8,147.9,622z M145.1,603.8c0,1.1,0.8,1.5,1.6,2
		c0.3,0.2,0.7,0.2,0.8-0.3c0,0,0-0.1,0-0.1c0.1-0.5-1.3-2.1-1.8-2.1C145.3,603.2,145.1,603.3,145.1,603.8z M238.5,375
		c0.1-0.4-0.3-1-0.8-0.8c-0.9,0.2-1.9,0.2-2.2,1.3c-0.2,0.6,0.4,0.6,0.9,0.6C237.1,375.9,238.1,376,238.5,375z M189.9,560.9
		c-0.9,0.3-1.7,0.9-2.1,1.8c-0.2,0.3,0.1,0.9,0.6,0.8c1-0.3,1.7-0.9,2-1.6C190.5,561.1,190.4,560.7,189.9,560.9z M230.6,373.6
		c-0.8-0.1-0.6,0.6-0.6,1.1c0,0.9,0.2,1.9,1.1,2c0.9,0.1,0.3-0.8,0.5-1.2C231.5,374.7,231.8,373.7,230.6,373.6z M118.5,377.1
		c0.3,1,1.5,0.7,2.3,1.1c0.3,0.1,0.6,0,0.6-0.5c-0.1-0.9-0.7-1.3-1.5-1.4C119.1,376.4,118.3,376.5,118.5,377.1z M145.9,366.8
		c0,0.6-0.1,1.4,0.9,1.4c0.5,0,0.8-0.1,0.7-0.7c-0.2-0.7-0.1-1.5-0.8-1.8C145.9,365.7,145.9,366.3,145.9,366.8z M255,469.9
		c0.3-0.7,0.7-1.6-0.2-2c-0.6-0.3-1.4,0.1-1.4,1.1C253.5,470,254.1,470.2,255,469.9z M127.3,379.4c-0.5,0-0.6,0.3-0.5,0.7
		c0.1,0.7,0.4,1.5,1.1,1.7c0.7,0.2,0.4-0.6,0.5-0.9C128.4,380.1,128.1,379.5,127.3,379.4z M140.7,367.1c-0.1,0.5,0.2,0.6,0.7,0.5
		c0.7-0.2,1.8,0,2-0.9c0.2-0.7-0.7-0.3-1.1-0.5C141.6,366.3,140.9,366.3,140.7,367.1z M157,597.1c0.5,0.1,0.7-0.4,0.5-0.7
		c-0.3-0.6-0.2-1.6-1.1-1.7c-0.6-0.1-0.3,0.6-0.4,0.8C155.9,596.4,156.2,596.9,157,597.1z M207.2,637.7c-0.1,0.8,0.5,1.4,1.1,1.7
		c0.4,0.2,0.5-0.3,0.5-0.6c0.1-0.8-0.6-1.1-0.9-1.6C207.3,637.1,207.2,637.3,207.2,637.7z M68.4,630.8c0,0.5,0.4,0.7,1,0.8
		c0.5,0,0.9-0.2,0.9-0.8c0-0.6-0.5-0.5-0.9-0.5C69,630.4,68.3,630,68.4,630.8z"/>
</g>
<path class="st2" d="M143.7,141.5c0,2.6,0,5,0,7.6c1.4,0.1,2.7,0.1,4.1,0.2c1.3,0.1,2.7,0.1,4,0.2c0.7,0,1.2,0.3,1.3,1
	c0.2,0.9-0.5,1.1-1.1,1.3c-0.2,0.1-0.4,0-0.6,0c-6,0-11.9,0-17.9-0.1c-5.9-0.1-19.1-0.1-19.1-0.1s-1.2-0.1-1.4-0.9
	c-0.2-0.9-0.2-1.7,0.8-1.7s7.6-0.1,8.5-0.1c0-2.5,0-4.9,0-7.7c-0.7,0-1.3,0-2,0c-1.4-0.1-2.8,0-4.2-0.3c-1.7-0.3-3.2-2.1-3.4-3.6
	c-0.2-2.1,1.7-4.3,3.9-4.3c7.6,0,15.2,0,22.8,0.1c3.3,0,6.7,0.2,10,0.2c2.5,0,4.4,2.8,3.4,5.1c-0.8,1.8-1.8,3-4.2,2.9
	C147.1,141.5,145.4,141.5,143.7,141.5z M125.2,141.5c0,2.4,0,5,0,7.5c5.2,0.1,10.4-0.1,15.6,0c0-2.6,0-5,0-7.5
	C135.6,141.5,130.5,141.5,125.2,141.5z M149.1,138.7c0.3,0,1.4-0.2,1.3-1.5c0-1.3-0.8-1.3-1.2-1.3c-1.4-0.2-27.1,0-31.8,0
	c-1.5,0-1.8,0.9-1.8,1.4s0.3,1.4,0.9,1.4c0.8,0.1,1.3,0,2.1,0.1C123.6,138.7,143.9,138.7,149.1,138.7z M123.4,79.6
	c1.6,0,3,0.1,4.4,0c1-0.1,2.2,0,3.2-0.3c0.7-0.2,0.6-1.4,0.2-1.9c-0.2-0.3-0.7-0.5-1-0.5c-1.1-0.1-2.3-0.1-3.4-0.1
	c-1.1,0-2.1,0-3.3,0c0.1-2.3,0.1-4.6,0.2-6.9c2.1-0.1,4.4,0,6.4-0.1c0.8,0,1.5-0.5,1.4-1c0-0.4-0.6-1.1-1.1-1.1
	c-3.2,0-7-0.2-10.2-0.1c-2.2,0.1-4.1,0.1-6.3,0.3c-0.6,0-1,0.2-0.9,0.9c0.1,0.7,0.7,1.2,1.3,1.2c2.2-0.1,4.1,0,6.4-0.1
	c0,2.1,0,4.1,0,6c0,1.1,0,1.1-1.1,1.1c-1.9,0.1-5.3,0.4-5.3,0.4s-0.9,0.4-0.7,1.2s0.8,1,0.8,1s1.1,0,1.7,0c1.6,0,3.3,0,4.9,0
	c0,8.4,0,16.7,0,25c-0.3,0-0.4,0-0.6,0c-1,0-2.1,0-3.1,0c-1.1,0-1.9-1.6-1.5-2.6c0.5-1,1-2.1,1.3-3.1c1.2-4,0.9-7.7-1.8-11.1
	c-0.4-0.6-1-0.7-1.5-0.4c-0.5,0.3-0.9,0.9-0.5,1.6c0.4,0.8,0.7,1.6,1.1,2.3c1,2.2,1.2,4.4,0.2,6.7c-0.5,1.1-0.8,2.2-1.3,3.3
	c-0.8,1.8,0.6,5.7,3.6,5.7c3,0,8.6,0,10.3,0s3.4-0.9,4.4-3.1c0.5-1.2,0.7-2.3-0.1-3.5c-2.4-3.8-2.7-7.6,0-11.4
	c0.2-0.2,0-0.8-0.1-1.2c-0.1-0.3-0.6-0.5-1.2-0.5c-0.6,0-1.7,1.4-2.1,2.6c-1.4,3.8-1.2,7.5,0.8,11.1c0.5,0.9,0.5,1.8-0.1,2.6
	c-0.9,1.2-4.7,1.1-4.7,1.1S123.5,88,123.4,79.6z M149.9,75.7c0.6,0.7,0.5,1.3-0.1,2c-0.3,0.4-0.6,0.7-1,1c-3.5,2.9-7,5.7-10.5,8.6
	c-1.7,1.4-3.8,3-4.6,4.2c-0.7,1.2-0.4,14.4-0.4,14.4s0.5,1.1,1.1,1.2c0.7,0,1.3-1,1.3-1s0.1-12.2,0.6-13s1.8-1.9,1.8-1.9
	s2.2-1.5,3.4-2.3c0.2,0.3,0.3,0.5,0.4,0.7c2.9,5.5,5.8,10.9,8.7,16.4c0.2,0.3,0.4,0.6,0.7,0.8c0.7,0.3,1.5,0.3,1.9-0.3
	c0.2-0.4,0.1-1.2-0.1-1.7c-2.3-4.2-4.6-8.3-6.9-12.4c-1-1.8-1.9-3.6-2.9-5.4c0.3-0.3,0.5-0.5,0.8-0.6c2.2-1.7,4.5-3.5,6.7-5.2
	c1.1-0.8,1.7-1.9,1.8-3.3c0.1-1.4-0.4-2.6-1.2-3.7c-1.8-2.6-4.5-4.4-6.9-6.4c-0.2-0.2-0.7-0.2-0.9-0.1c-0.9,0.5-1.1,1.6-0.6,2.5
	c0.1,0.2,0.2,0.3,0.2,0.4c-1.2,1-2.2,2-3.3,2.9c-1,0.9-2.1,1.8-3.1,2.7c-1.2,1-2.4,2-3.5,3.1c-0.2,0.2-0.4,0.6-0.4,0.9
	c0.2,1,1.2,1.3,2.1,0.6c1.9-1.5,3.9-3,5.8-4.6c1.7-1.3,3.3-2.7,5.1-4.2C147.3,73,148.6,74.3,149.9,75.7z M123.8,113.5
	c0-1.1-1-1.9-2.1-1.8c-0.5,0.1-0.8,0.3-0.8,0.8c0,1.1,0,2.1,0,3.2c0,1.1,0,2.1,0,3.3c-1.2,0-2.3,0-3.4,0c-0.7,0-1.7-1.2-1.7-1.9
	c0-1.4,0-2.8,0-4.2c0-0.3-0.1-0.6-0.3-0.8c-0.4-0.5-0.9-0.7-1.5-0.5c-0.7,0.2-1,0.7-1,1.4c0,1.3-0.1,2.6,0,3.8
	c0,0.6,0.2,1.3,0.5,1.8c0.7,1.4,1.9,2.2,3.3,2.5c1.3,0.3,2.7,0.3,4.1,0.5c0,0.9,0,1.9,0,2.9c-2.4,0.3-4.7,0.7-7.1,1
	c-0.3,0-0.6,0.2-0.7,0.4c-0.4,0.6-0.3,1.2,0,1.7c0.4,0.6,0.9,0.6,1.6,0.5c3.2-0.5,6.4-1,9.6-1.5c1.5-0.3,3-0.6,4.4-1.2
	c1.7-0.7,2.7-2.1,2.7-4.1c0-0.9,0.1-1.8,0-2.7c-0.1-2.1-0.2-4.2-0.3-6.3c0-0.6-0.4-0.8-1-0.8c-0.7,0.1-1.2,0.5-1.2,1.3
	c0,2.6,0,5.2,0,7.8c0,1.6-0.5,2.3-2.1,2.7c-1,0.3-2,0.4-3.1,0.6C123.8,120.5,123.8,117,123.8,113.5z M149.5,121.5
	c2.2,0,2.7-1.4,3.2-3.6c0.3-1.5,0.3-3,0.4-4.5c0-0.9-0.4-1.4-1.1-1.5c-0.8-0.1-1.5,0.4-1.6,1.3c-0.1,1.1-0.1,3.9-0.2,4.7
	c0,0.8-0.9,1.1-1.1,1.1c-1.3,0-2.5,0-3.8,0c-0.1-0.6-0.2-1-0.2-1.5c0-1.4,0.1-2.8,0.1-4.2c0-1-0.7-1.7-1.7-1.7
	c-0.7,0-1.1,0.5-1.1,1.5c0,3.3,0.1,6.6,0.1,9.9c0,0.3,0,0.6,0,1.2c-1.6-0.4-3.1-0.7-4.5-1.1c-0.6-0.2-0.8-0.6-0.8-1.2
	c0-1.9,0-3.8,0-5.7c0-1.1,0.1-2.2,0-3.3c0-0.7-0.7-1.3-1.3-1.4c-0.8,0-1.2,0.3-1.2,1.1c0,0.4-0.1,0.8-0.1,1.2c0,2.3,0,4.7-0.1,7
	c0,2.1,0.9,3.6,2.6,4.6c0.8,0.4,1.6,0.8,2.5,0.9c2.3,0.4,4.6,0.7,6.9,1c1.8,0.2,3.5,0.4,5.3,0.6c0.9,0.1,1-0.4,0.9-1.2
	c0-0.5-0.3-0.9-0.8-1c-0.4-0.1-0.8-0.3-1.2-0.4c-1.6-0.2-3.2-0.5-4.9-0.6c-0.8-0.1-1-0.5-0.9-1.2c0.1-0.7,0.2-1.3,0.3-2.2
	C146.3,121.5,148.7,121.5,149.5,121.5z M152.3,131.9c0.9-0.1,0.9-0.8,0.8-1.4c-0.1-0.6-0.1-1.1-1-1.1c-2.1,0.1-4.1,0-6.2,0
	c-2.9,0-5.9,0-8.8,0c-4.7-0.1-9.3-0.2-14-0.2c-3.1,0-6.2,0.1-9.3,0.2c-0.5,0-0.7,0.2-0.8,0.7c-0.2,1.1,0.4,1.7,1.5,1.7
	c2.1,0,4.2-0.1,6.3-0.1C128.4,131.8,150.5,132,152.3,131.9z M115.8,63.3l0.4-0.2l0.5-0.7l1.9-1.3l0.9-0.6l1.2-0.5l1.4-0.3l0.6-0.2
	l1.6-1l0.8-0.1l2.3-0.4l1.6-0.6l0.7-0.1L131,57l0.7,0l0.6,0.1l0.5-0.1l0.7,0l0.9,0.2l0.8,0.2l0.8,0.2h0.6l0.4-0.1l0.9-0.2l0.5,0
	l1.2,0.6l0.7,0.1l0.6-0.1l1.1,0.3l0.5,0.1l0.1,0.2l0,0.6l0.2,0.3l0.7,0.1h0.6l0.8,0.4l0.2,0.6l-0.1,0.8l0.2,0.3l0.8,0.6
	c1.1,0.4,2,0.9,2.7,1.7l0,0l1,0.5l0.5,0.6l1,0.6l0.8,0.8l0.1,0.6l-0.1,0.6l0.3,0.5l0.4,0.9l-0.1,0.3l-0.3,0.2
	c0.5,0.8,0.9,1.7,1.3,2.5l0.7-0.3l0.6-0.1l0.6,0.1l0.3-0.1l0.3-0.3l-0.3-0.9l-0.5-0.8l-0.1-0.4l-0.9-1l0-1l-0.3-0.8l-0.6-0.5
	l-0.1-0.7l-0.4-0.7l-0.4-0.5l-1-0.3l-0.5-0.3l-0.3-0.5l-0.3-0.4l-1.4-0.9l-0.5,0.1l-0.3,0l0-0.3l0-0.5l-0.4-0.6l-1.3-0.7l-0.2-0.6
	l-0.3-0.5l-1.5-0.8l-0.6-0.2l-0.7-0.3l-3.1-1.2l-0.6-0.3l-0.6-0.3l-0.4,0l-0.6,0.1h-1.8l-0.8-0.1l-0.9-0.1l-1.5-0.3h-0.6l-1.5,0.3
	h-0.7l-1.1,0.1l-1.2,0.4l-0.9,0.1l-1.4,0.4l-0.5,0.2l-0.8,0.2l-0.9,0.3l-0.6,0.1l-0.4,0.3l-0.7,0.4l-2.4,1.2l-0.8,0.3l-1,0.9
	l-0.9,1.3L116,62l-0.4,0.4l-0.1,0.7l0.2,0.2H115.8z M107.4,138.7l0.6,0.3l1.5,0.1h0.2c-0.5-1-1.1-2-1.3-2.9
	c-0.4-1.7-0.5-3.5-0.4-5.2l-0.4-0.3l-0.3-1.2l0-2.7l-0.2-0.8v-1l-0.1-1l-0.3-4.8l0.1-1l-0.4-1.3l-0.1-0.8L106,114l0.1-0.6l0-0.8
	l-0.2-0.5l0.1-1.2l-0.3-0.7l-0.1-0.5l0.2-0.3l0.3-0.4l0-1l0.2-0.3l0.2,0c-0.1-1.3-0.2-2.6-0.3-3.8l-0.5-0.3l-0.3-0.8l0.1-0.4l0.1-1
	l0.1-0.7l-0.2-2.3l0.2-1.6l-0.3-0.7l0.6-1.3l0-0.6l0.1-0.4l0.1-1.2l0.4-0.6l0.2-1.7l0.1-0.3l0.1,0c0.2-0.9,0.4-1.8,0.7-2.7l-0.3-0.1
	l-0.3-0.4l0-1.2l0.3-1.1l0-0.8l0.1-1.2l-0.3-0.6l-0.3-0.2l-0.3,0.1l-0.4,0.5l-0.5,1.1l-0.3,0.8l-0.2,0.7l-0.3,1.7l0,0.6l-0.4,0.8
	l-0.6,0.6l-0.3,0.6l0,0.5l-0.2,0.7l0.2,1.9l-0.3,1.3l0.1,1.1l0.1,0.7l0,1.5l-0.2,1.3l0.2,2.9l-0.1,0.8l0.1,1.1l-0.1,1.8v1.7l0.2,1.1
	l0,1.5l0.1,0.7l-0.1,1.3l-0.2,0.7l0.3,2.1l0.1,0.7l0,1.1l0,1.1v1.6l0.1,1.7l-0.2,0.8l0.1,1l0,0.7l0.2,0.6l0,1.4l0.3,1.8l0.1,1.7
	l0.5,1.5v1.5l0.4,4.3l0.3,0.7l0.4,0.6l0.3,1.1L107.4,138.7z M163.4,114.1l-0.2-1v-2.5l-0.3-0.9l0.1-1.5l-0.3-1.6l0-1.1l-0.4-1.3
	l-0.1-0.4l-0.2-0.9l0.3-0.5l0-0.6l-0.1-1.5l0.1-1.6l-0.5-1l0.1-0.4l0.2-0.9l-0.1-1.4l-0.5-0.7l-0.2-0.9l0.1-0.7v-1.8l-0.4-1.2v-2.7
	l-0.5-1.2l-0.1-1.2l-0.3-1.1l-0.2-1.5l-0.6-1.3l0.2-0.9l-0.3-1l-0.2-1l-0.4-0.6l-0.5-0.9l-0.3-1.3l-0.2-0.3l-0.3-0.6l-0.9-1.1
	l-0.4-0.1l-0.6,0.1l-0.3,0.3l0,0.5l0.2,0.7l0.3,0.6l-0.3,0.7l-0.3,0.1c0.3,0.7,0.5,1.4,0.8,2.1l0.8,0.1l0.3,0.3l0.1,0.7l-0.1,0.7
	l0.3,0.7l0.8,1.1l0.1,0.6l-0.1,0.2l-0.8,0.2c0.3,1,0.5,1.9,0.7,2.9l0.4,0.2l0.1,0.3l-0.3,0.5c0.1,0.4,0.2,0.8,0.2,1.2l0.6,0.6
	l-0.2,0.5l0,0.4l0.6,1l0.1,0.8l0.1,1.4l0.2,0.6l-0.3,1l0.3,0.7l-0.2,0l-0.1-0.2c0.1,0.8,0.2,1.7,0.2,2.5l0.5,0.4l0.6,0.5l-0.6,1.2
	l0.1,0.6l0.3,0.9l0,0.9l0,0.6l0.1,0.7l0.4,0.5l-0.3,0.7l0.1,0.5l-0.3,0.8l0,1.6l-0.2,0.1c0.1,1.4,0.1,2.7,0,4.1l0.3,0.2l0.1,0.5
	l-0.1,0.9l-0.7,0.7l-0.1,1.3l0.3,0.8l0.6,1.4l-0.1,0.9l-0.5,1l-0.2,0.6l-0.1,0.9l0.2,0.4l0.5,0.5l0,0.6l-0.3,1l-0.2,0.4l0,1.6
	l0.1,0.7l-0.1,1.3l-0.2,0.9v1.4l-0.6,1.1l-0.4,0.9l-0.4,0.3c-0.1,1.1-0.3,2.2-0.5,3.3l0.5,0.1l0.6,0.3l-0.2,0.3l-0.3,0.4l-0.3,1
	l-0.3,0.7l-1,1.6v1.1l-0.3,0.3h-0.4c-0.2,1.2-0.6,2.4-1.1,3.6l0.1,0.4l-0.2,0.9l-0.4,0.9l-0.3,0.5l0.1,0.6l-0.4,1.1l-0.1,0.4l0,0.3
	l0.1,0.5l-0.2,0.4l-0.9,0.6c-0.1,2-0.9,3.6-2.3,4.9l-0.3,0.5l-0.6,0.7l-0.3,0.3l-0.7,0.3l-0.6,0.7l-0.7,0.3l-1,0.1l-0.6,0.3
	l-0.8,0.7l-0.3,0.3l-0.9,0.5l-1.6,0.2h-0.5l-1.2,0.2l-0.3,0.1l-0.6-0.1l-0.5,0.1l-0.8,0.2h-0.2l-1.3-0.2h-0.6l-0.9,0.3l-0.7,0h-1.3
	l-1.1-0.1H132l-0.6,0.1h-0.9l-1.2-0.2c-0.2,0-0.4,0-0.6,0h-0.3l-1.1,0.1l-1-0.2l-0.8-0.3c-0.2,0-0.5-0.1-0.7-0.1h-0.2l-0.6-0.1
	l-1.5-0.3l-0.6-0.2l-0.8-0.2l-1.3-0.2l-1.8-0.6l-0.4-0.3l-0.8-0.7l-0.4-1c-4.4-3.3-5.9-8.5-6.1-13.8c0,0,0-0.1,0-0.1
	c-0.1-0.2-0.1-0.4-0.2-0.7c-0.1-0.5-0.1-1,0-1.4l0-0.1l-0.4-0.6l-0.6-0.2l-0.4,0.1l-0.7,0.3l-1,0.8c0.2,1,0.2,2.1,0.2,3.1
	c0,0.3,0,0.6-0.1,0.9c0.3,0.7,0.4,1.5,0.5,2.3l0.8,1.5l0.1,1.4l2.1,3.6l0.8,0.9l1.3,1l1,1.2l1,0.8l0.3,0.3l0.6,0.4l0.6,0.5l1.2,0.5
	l0.4,0.6l2.1,1.6c4.3,0.6,8.6,1.4,13,1.5c4.2,0.1,8.6-0.4,12.9-1l0.1,0l1-0.3l0.6-0.4l1.4-0.6l0.8-0.5l0.3-0.7l1.1-1l1.2-1.2
	l0.4-0.6l0.6-0.6l0.3-0.5l0.3-0.5l0.4-0.3l0.5-0.4l0.5-0.6l0.4-0.5l0.2-0.3l0.6-1l0.2-0.7l0.4-1.1l1.1-2.6l0.4-0.7l0.2-0.5l0.2-0.6
	l0.4-1.6l0.1-0.6l0.2-0.9l0.2-0.9l0.1-0.6l0.3-0.9l1-4.1l-0.1-1.3l0.5-0.8l0.3-1.3l0-0.6l0.1-0.5l0-0.6l0.3-1.2l-0.1-0.7l0.6-1.4v-1
	l0.2-0.5v-1.8l0.2-0.6l0.1-2.2l0.3-1.6v-1.7l0-1.5l0.3-2L163.4,114.1z M107.2,81.2l-0.1-0.1l-0.2,0.1l0.1,0.1l0.2,0L107.2,81.2
	L107.2,81.2z"/>
<g>
	<defs>
		<path id="SVGID_1_" d="M226.4,116.3c-1.5,2.2-3.1,4.4-4.6,6.6c-2.6,4.1-5.2,8.2-7.6,12.4c-1,1.7-1.5,3.6-2.2,5.5
			c-0.4,1-0.6,2.1-1.1,2.9c-1.4,2.5-3.2,4.8-2.9,8c0.1,0.7-0.5,1.4-0.8,2.1c-2.4,7.3-4.9,14.6-7.2,22c-0.8,2.5-0.9,5.1-1.4,7.9
			c-2,0.5-4.8,0.5-4.9,3.8c-0.3-3.6-0.7-7.3-0.9-10.9c-0.1-2.2-0.1-4.4,1.1-6.6c0.4-0.8,2.3-1.6,1.4-3.5c-0.4-0.8,1.1-2.3,1.3-3.6
			c0.3-1.6-0.2-3.4,0.3-4.9c1.9-6,3-12.2,6.9-17.4c0.4-0.6,0.9-1.3,1.1-2c0.5-2.5,1.1-4.9,3.1-6.7c0.6-0.6,1.2-1.8,1-2.5
			c-1.7-4.9,2.1-8.1,3.9-11.7c3.3-6.8,7.2-13.4,10.8-20.2c1.8-3.4,3.7-6.9,5.8-10.9c-0.9-0.5-1.8-1.1-3-1.8c4.2-2.3,3.5-6.2,3.9-10
			c-1.2,0-2.3-0.3-3.2,0c-5.3,2.1-10.5,4.2-15.7,6.5c-0.8,0.4-1.2,1.8-1.9,3c-2.2-0.8-4.8-4-7.6-0.3c-3.5-0.2-4.5,3-6.8,5.6
			c-1.1-2.1-2.4-3.7-2.7-5.5c-1.1-5.9-2-11.8-2.8-17.8c-0.3-2.3-0.4-4.7-0.2-7c0.2-1.9-0.2-3.1-1.8-4.3c-0.9-0.7-1.5-1.9-2-2.9
			c-1.2-3.1-0.8-4.2,2.3-5.2c2.7-0.8,5.4-2.7,8.3-1c1.6,0.9,3.3,2,4.5,3.4c1.8,2.1,3.7,2.2,6.2,1.5c5-1.4,10-2.5,15-3.8
			c0.5-0.1,0.9-0.8,1.4-1.1c0.5-0.3,1.3-0.9,1.6-0.8c4,2.4,5.6-1.9,8.6-2.8c0.3-4.7,2.2-9.1,1.5-14c-0.4-2.7,1.2-5.6,1.7-8.5
			c0.5-2.8,0.6-5.8,1.3-8.6c0.6-2.5,1.5-5,2.9-7.1c0.6-0.9,2.7-1.3,4-1.2c1.6,0.2,3.1,1.1,4.3,1.6c-0.5,2.6-1.4,5.1-1.3,7.5
			c0.1,1.9,1.4,3.7,2.3,5.7c1.4-0.6,3.1-1.3,4.8-1.9c4.2-1.5,6-0.8,8,2.9c2.1,3.9,4.4,7.7,6.3,11.7c0.6,1.3,0.5,3,0.4,4.5
			c-0.2,2.4-0.2,4.6,2.6,5.4c-0.3,2.8-1.9,4.1-4.1,4.7c-3.2,0.9-5.1,2.5-4.3,6.1c0.1,0.2-0.6,0.6-1.1,1.2c-0.9-0.7-1.8-1.4-2.6-2
			c-1.4,0.4-2.7,0.8-4.1,1.2c-0.7,0.2-1.7,0.3-2.1,0.8c-2.4,3.2-6.2,3.3-9.4,4.8c-1.2,0.6-2.1,1.4-0.8,3c6.4-2,12.9-4.1,19.4-6.2
			c2.9-0.9,5.7-1,8.7,0.5c-8.7,6.4-19.6,6.9-28.6,12.3c1.1,0.2,2.2,0.4,3.4,0.5c-1,2.1-1,2.1-4.3,3.7c-0.7,6.6-1.4,13.2-2.1,19.9
			c-0.4,3.7,1.3,6,4.4,7.9c5.6,3.4,11.1,7,16.5,10.7c5.8,4,11.1,8.6,15.1,14.5c1,1.5,2.1,3,3.2,4.5c1.7,2.3,3.7,4,7,3.4
			c1.8,3.1,3.5,6.1,5.4,9.5c-2.6,1.4-2.3,3.4-0.9,5.8c0.7,1.2,0.9,2.8,1,4.3c0.1,3-0.1,5.9,1.8,8.6c0.5,0.7,0.4,2.3,0,3.2
			c-1.8,3.7-0.4,6.3,3.6,6.8c9.7,1.1,19.2-0.9,28.8-1.5c5.6-0.4,11.2-0.8,16.9-1.4c6.6-0.7,13.2-1.6,19.8-2.4
			c6.9-0.8,13.9-1.5,20.8-2.5c1.4-0.2,2.6-1.3,4.5-2.3c0.3,0.1,1.4,0.2,2.2,0.7c1.8,1,3,0.7,4.4-0.8c1.1-1.2,2.6-2.1,4.2-3.3
			c-0.3,7,1.5,13.3,3.1,19.7c0.4,1.7,2.3,3,3.5,4.6c-1.5,5.2-1.8,5.3-7,5.8c-5.6,0.6-11.1,1.6-16.7,2.4c-2.4,0.3-4.8,0.6-7.2,0.9
			c-6.8,0.7-13.5,1.2-20.3,2c-2.3,0.2-3.1-0.6-2.8-2.6c3.4-0.7,6.6-1.4,9.8-2.1c0.1,0.2,0.2,0.5,0.3,0.7c-0.8,0.7-1.6,1.3-2.4,2
			c0.1,0,0.3,0.2,0.5,0.2c4.9-0.3,10.3,1.1,13.9-3.9c0.2-0.2,0.4-0.4-0.1,0.1c5.6-1,10.3-1.8,15-2.8c0.9-0.2,1.5-1.5,2.9-2.9
			c-3.8-0.4-6.5-0.8-10-1.2c-0.8-0.8-0.6-3.7-3.5-3.7c-0.3,0-0.9-1.3-1-2.1c-0.2-1.5,0-3,0-5.1c-2.5,0.4-5,0.9-7.5,1.3
			c-2.4,0.4-4.7,0.7-7.1,1.1c0,0.9,0,1.7,0,2.7c1-0.4,1.8-0.8,3.3-1.5c-1.6,4.2-5.1,2.8-7.4,3.9c-2.3,1.1-5.4,0.3-7.9,3.1
			c1.9,0.1,3.1,0.1,4.3,0.2c0,3.2-0.3,3.9-3.1,4.3c-2.6,0.4-5,0.9-7.3,2.3c-1.4,0.8-3.7,0.7-5.5,0.3c-1.7-0.4-2.9-0.5-4.6,0.6
			c-1.2,0.8-3.2,0.2-4.8,0.3c-1.6,0.1-3.4,0.2-4.9,0.8c-1.5,0.6-3.2,1.5-2.4,4.1c1-0.6,1.9-1.1,3.1-1.8c0.1,1,0.2,1.6,0.3,2.2
			c-1.7,1.6-5.4,0.4-5.2,4.6c4.2-0.2,8.3-0.4,12.4-0.7c4-0.3,8-0.7,12.6-1c-6.6,5.5-14.1,4.3-21.2,4.9c-6.8,0.6-13.8,0.2-20.7,0.3
			c-1.5,0-2.9,0.4-5.1,0.8c-1.7-2.9-5.5-1.3-8.8-1.3c0.7-4.3-1.7-6.9-3.3-9.7c-2.7-4.8-5.6-9.4-5.1-15.2c0.1-1-0.5-2.1-0.8-3.1
			c-0.8-3.5-1.9-7-2.4-10.6c-0.3-1.8-0.7-3.8,1.5-5.3c1.1-0.7,2.3-2.7,0.1-4.2c-0.5-0.3-0.5-1.6-0.4-2.5c0.3-3.2,0.5-6.3,1-9.4
			c0.2-1,1.3-1.9,1.5-2.9c0.2-0.8-0.2-2.3-0.8-2.7c-1.3-0.8-2.3-0.1-2.6,1.5c0,0.3-0.4,0.5-0.6,0.7c-0.3,0.1-0.7,0.3-0.9,0.2
			c-6.1-3.2-13.2-4.1-18.4-9.6c-5.6-6-12-11.3-18-16.9c-0.3-0.3-0.8-0.4-1.8-0.9c-0.4,3.7-0.9,7.1-1.1,10.5
			c-0.4,8.5-0.8,17-0.8,25.6c0,6.4,0.7,12.8,0.6,19.1c0,6.1,2.2,11.6,4.1,17.2c1.1,3.2,1.6,6.5,0.8,10.3c-2.6-2.3-4.9-2.2-6.8,0.8
			c-0.5,0.8-1.7,1.2-2.7,1.8c-2.2-4-4.4-7.7-6.4-11.6c-0.9-1.9-2.7-3.9-0.3-6.1c-0.9-2-1.7-4.1-2.7-6.1c-0.3-0.6-1.2-0.8-1.9-1.2
			c0.4-1.3,0.8-2.4,1.2-4c-1.8-0.7-3.4-1.3-5-1.9c0-0.2,0.1-0.4,0.1-0.6c0.9,0.1,1.7,0.1,3,0.3c-0.2-6.2-3.7-11.1-7.2-16.6
			c1.9,0.4,3,0.6,4.7,0.9c0.6-2,1.2-4,1.9-6.4c1.4,1.3,2.8,2.6,4.4,4.2C226.8,135.6,226.6,125.9,226.4,116.3z M265.8,29.1
			c-0.3-0.9-0.7-2.3-1.2-3.7c-1-2.7-4.6-4.1-6.6-2.7c-4,2.9-5.7,9.7-3.8,14.8C259.3,36.8,264.6,36.5,265.8,29.1z M216.1,51.5
			c5.9,2.8,12,0.7,12.6-3.7C224.6,48.5,220.7,49.4,216.1,51.5z M338.8,177.4c0-0.4,0-0.8,0.1-1.1c-1-0.2-2-0.4-2.9-0.8
			c-0.9-0.4-1.8-1-3.2-1.8c-0.5,1.1-1,2.2-1.7,3.7C334.2,177.4,336.5,177.4,338.8,177.4z M348.4,168.4c-2.5,0.8-4.5-0.3-6.5,1.7
			c1.2,1.1,2.2,2.1,3.3,3.2C346.3,171.6,347,170.5,348.4,168.4z M337.7,181.8c-0.1-0.4-0.1-0.7-0.2-1.1c-2.4,0.5-5.5-2.4-7.7,2.3
			C332.8,182.6,335.2,182.2,337.7,181.8z M356.1,171.9c-1.8,0.5-3.3,0.9-5.5,1.4c2.5,0.7,4.1,1.2,6.1,1.8
			C356.5,174,356.3,173,356.1,171.9z M256.7,47.9c1.3,0.4,2.4,2.5,4.5,0.6C259.7,47.6,258.7,46,256.7,47.9z M327.6,177.4
			c0.9-0.6,1.7-1.3,2.6-1.9c-0.1-0.3-0.2-0.6-0.3-0.8c-1.4,0.2-2.7,0.3-4.5,0.6c0.6,0.8,0.8,1,1,1.2
			C326.8,176.7,327.1,177,327.6,177.4z M258.5,44.4c3.8-1.3,3.8-1.4,1.9-3.7C259.8,42,259.2,43.2,258.5,44.4z M251.2,46.3
			c-2.3-3.2-2.3-3.2-4.2-1.4C248.4,45.4,249.5,45.7,251.2,46.3z M319.2,193.2c-0.2-0.3-0.4-0.7-0.7-1c-1.3,0.3-2.8,0.2-3.3,3.1
			C316.9,194.4,318.1,193.8,319.2,193.2z M225.7,63.7c4.1-0.2,4.1-0.2,4.1-2.9C228.4,61.8,227.4,62.6,225.7,63.7z M345.6,179.6
			c-0.8-0.4-1.3-0.9-1.7-0.9c-0.4,0-0.8,0.6-1.2,1c0.5,0.4,0.9,1,1.4,1.1C344.4,180.9,344.9,180.2,345.6,179.6z M387.3,176.6
			c-2.1-2.6-3.1-1.2-5.2-0.4C384.2,176.3,385.3,176.4,387.3,176.6z M265.5,42.4c-0.1-0.3-0.1-0.5-0.2-0.8c-0.7,0.2-1.4,0.3-2.2,0.6
			c0,0,0.1,0.4,0.1,0.7C264,42.6,264.7,42.5,265.5,42.4z M229,72.1c-2.9,0.5-2.9,0.5-1.7,2.4C227.8,73.9,228.2,73.4,229,72.1z
			 M374,150.4c0.4,3.5,0.7,6.5,1,9.6c-0.3,0.2-0.6,0.3-0.9,0.5c-1.1-1.2-2.1-2.4-3.5-4c-0.7,3.5-3.3,4.1-6.2,4.3
			c-2.4,0.2-4.8,1-7.1,1.6c-6.7,1.7-13.4,1.6-20.2,1.4c-3.5-0.1-7,0.6-10.5,0.6c-3.6-0.1-7.3,0-10.7-2.2c0.8-3.6,3.6-1,5.5-2
			c-1.9-1.6-3.7-3.1-6.1-5.1c15.4-0.9,30-1.8,45-2.7c1.2-5.7-1.9-11.1-0.8-17.2c-2.9,0.7-5.5,0.9-7.8,1.9c-4.7,2.2-9.6,1-14.4,1
			c-4.7,0-9.4-0.9-14.1-1.2c-2.2-0.1-4.5,0.1-6.5,0.8c-1.2,0.4-2.6,1.8-3.1,3c-1.1,2.8-1.6,5.7-2.4,8.7c-2.2-0.3-4.5-0.7-7.3-1.1
			c1.7-2.5,1.5-4.8-0.2-7.2c-0.4-0.6-0.8-1.6-0.5-2.1c2.3-5.4-1.2-8.4-4.6-11.6c0.8-0.6,1.6-1.1,2.3-1.7c-1.2-1.2,1.3-2.9-0.7-4.1
			c-0.4-0.2-0.4-1.2-0.3-1.8c0.7-4.2-0.1-8.2-1.5-12.1c-1-2.8-0.8-5,1.7-6.7c0.6-0.4,1-1,1.6-1.6c0.3,0.5,0.6,0.9,1.4,2
			c1.2-2,2.6-3.6,3.3-5.4c2.5-7.5,4.8-15.1,7.2-22.7c0.2-0.7,0.5-1.5,0.3-2.2c-1.1-3.3,0.1-6.3,1.1-9.4c1.8-5.6,3.5-11.3,5.4-16.9
			c0.6-1.7,1.8-3.2,2.3-4.8c0.4-1.2,0-2.7-0.1-4.9c-5.5,2.1-10.4,3.6-14.9,5.8c-2.9,1.5-5.3,4-7.9,6.1c-0.6,0.5-0.9,1.2-1.4,1.8
			c-4.2,4.9-5.6,5.5-12.5,4.9c0-0.6,0.2-1.3,0-1.7c-2.4-3.3-3.8-6.6-0.5-10.3c0.4-0.5-0.4-2.3-0.8-3.4c-1.5-3.9,0.3-6.4,4.3-5.9
			c5.9,0.7,11-0.8,15.7-4.4c1.4-1.1,3.7-1.5,5.6-1.5c6.2,0,11.7-2,17.2-4.7c0.7-3-1.2-5.2-2.7-7.4c-2.3-3.3-2.1-6.2-0.1-9.7
			c2.1-3.6,5-4.2,9-3.7c2.1,0.3,4.2,0.3,6.3,0.4c1,0.1,2.1,0,3.1,0.2c1.8,0.2,3.6,0.6,5.4,0.7c2.9,0.2,4.2,1.7,3.8,4.7
			c-0.2,1.5-0.3,3-0.9,4.5c-0.2-1.2-0.3-2.4-0.5-3.6c-1,1.7-3.6,2.6-1.9,5.2c0.6,0.9-0.1,2.6-0.2,3.9c2.4,1.2,4.8,1.3,7.4,0.6
			c3.8-1,7.8-1.8,11.7-2.5c7.5-1.4,14.9-2.8,22.4-4.1c2.8-0.5,5.7-0.6,8.6-0.6c0.8,0,2,1,2.3,1.8c0.9,2.6-2.6,6.2-5,5
			c-3.4-1.7-6.5-0.8-9.8-0.2c-1.6,0.3-3.3,0.2-5,0.3c-1.7,0.1-3.4,0-5,0.4c-7.8,2-15.6,4.1-23.4,6.2c-0.2,0.1-0.3,0.4-1,1.4
			c4.1-0.5,7.5-1,11-1.4c0.1,0.2,0.1,0.5,0.2,0.7c-2,0.7-3.9,1.5-5.9,2c-5,1.3-9.7,3.3-14.8,4.4c-1.9,0.4-3.7,2.9-5,4.9
			c-1,1.5-4.1,6.4-4.7,7.5c-2.3,4.2-7.8,6.3-7.7,12.1c0.8,1.3,1.7,2.7,2.8,4.6c2.1-3.4,5.2-5.8,4.7-10.4c-0.5-4.5,2.7-6.4,7.1-4.7
			c1.4,0.6,2.8,1.2,4.2,1.6c4.5,1.4,9,2.6,13.9,1c3.2-1.1,6.6-1.4,9.8,0.4c0.3,0.1,0.6,0.4,0.8,0.3c4.1-0.7,6.2,2.3,8.4,5
			c-0.1,3.4-1,6.4-3.1,9.3c-1.3,1.8-1.7,4-1.4,6.5c0.1,0.9,2.2,3.2-1.1,3.4c0,0-0.3,1.8,0,2.6c1.6,5.1,3.3,10.2,4.9,15.4
			c0.6,2,0.8,4.1,1.1,6.2c1,7.1,2.1,14.2,2.8,21.3c0.3,3.1-0.5,6.3-0.6,9.5c-0.1,4.4-2.5,8.8,0,13.1c-2.2,2.4,0,5.9-2.3,8.5
			C376.1,149.7,375,149.8,374,150.4z M355.6,101.4c-0.8,0.6-1.7,0.9-1.9,1.4c-0.8,2.3-2.7,2.3-4.5,2.4c-4.5,0.1-9,0.1-13.5,0.1
			c-0.7,0-1.5-0.1-2.2,0.1c-5.9,1.6-11.4,0.4-16.7-2.1c-1.2,4-1.6,15.5-0.5,18.5c0.7,1.9,2.1,2.9,4,3.3c1.6,0.4,3.2,0.8,4.8,1
			c2.4,0.2,5,1.6,7-1.3c0.5-0.7,2.2-0.7,3.4-0.7c2.4-0.1,4.9-0.2,7.3-0.1c3.2,0.1,6.4,0,9.5,0.4c2.3,0.4,4.5,1.5,7,2.4
			C361.4,117.7,358.5,109.8,355.6,101.4z M352.5,92.2c0.4-1.2,1-2.1,0.8-2.6c-2.6-7-4.1-14.5-8.4-21.4c-2.1,1.2-4.4,1.9-5.9,3.4
			c-3.7,3.6-8,4.6-12.9,4.4c-0.9,0-2.5,0.5-2.7,1.1c-1.5,4.1-5,8-1,13.3c4.4,0,9.2,0.1,14.1,0C341.7,90.4,347,89,352.5,92.2z
			 M334.7,93.4c3.2,2.3,7.1,0.2,10.7,1.7c-0.8,1-1.3,1.8-2.4,3.1c3-0.1,5.2-0.2,7.6-0.3c-1.1-0.9-2.4-1.4-2.5-2.1
			c-0.2-0.7,0.7-1.7,1.4-3.1C344.4,93.2,339.6,91.2,334.7,93.4z M325.2,98.8c-2.1-1-3.6-1.7-5.2-2.5c0,1.5,0,2.6,0,4.1
			C321.7,99.9,323,99.5,325.2,98.8z M334.6,49.8c2.3-1.6,2.3-1.6,3.3-3.9C335.5,46.4,335.5,46.4,334.6,49.8z M336.5,96.1
			c-3.5-0.1-3.7,0.2-3.4,3.5C334.4,98.4,335.3,97.5,336.5,96.1z M359.6,131.3c0-0.4,0.1-0.9,0.1-1.3c-1.2-0.1-2.3-0.2-3.5-0.4
			c-0.1,0.3-0.1,0.6-0.2,0.8C357.2,130.7,358.4,131,359.6,131.3z M341.9,95.7c-0.3-0.1-0.5-0.3-0.8-0.4c-0.5,0.6-1,1.1-1.5,1.7
			c0.6,0.5,1.2,1,2.3,1.8C341.9,97.3,341.9,96.5,341.9,95.7z M374.5,175.3c-2.6-1.5-5.6-0.2-7.8,0.6c-2.2,0.8-4.2,1.2-6.4,1.5
			c-0.2,0-0.3,0.4-0.4,0.5c0.7,0.4,1.4,0.9,2.8,1.8c-2.2,0.3-3.4,0.5-4.7,0.7c0.1,0.3,0.2,0.7,0.3,1c1.9-0.5,4.4-0.5,5.5-1.8
			c1.6-1.7,3.2-2,5.1-2.2c1.9-0.2,3.7-0.5,5.6-0.8C374.5,176.2,374.5,175.8,374.5,175.3z M410,173.7c1-1.6,0.4-2.7-0.9-3.7
			c-0.6,0.4-1.1,0.6-1.3,0.9c-1,1.4-1.9,2.9-3.1,4.7C407.5,176.8,408.8,175.5,410,173.7z M335.7,192.3c-2.9-1.3-2.9-1.3-5.5,0.2
			C332.4,194.5,334.1,194.5,335.7,192.3z M338.1,191.4c4.8,2.3,4.8,2.3,6.9-1.5C342.3,190.4,340.4,190.8,338.1,191.4z M364.5,195.4
			c-2.2,0.4-4.5,0.8-6.7,1.3c0,0.3,0.1,0.5,0.1,0.8c2.3-0.3,4.6-0.5,6.9-0.8C364.7,196.3,364.6,195.9,364.5,195.4z M348.6,192.2
			c-0.1-0.7-0.2-1.5-0.3-2.2c0-0.1-0.5,0-0.8,0c0,0.8,0,1.5,0,2.3C347.8,192.2,348.2,192.2,348.6,192.2z M364.8,188.3
			c0.8-0.6,1.5-1.1,2.3-1.8C364.9,186.7,364.9,186.7,364.8,188.3z M188.8,306.9c5.2,1.5,9.3,4.2,11.1,9.6c0.3,0.9,1.3,2.5-1.1,2.5
			c-0.1,0-0.3,0.8-0.3,1.3c0.4,2.9-1.4,4-3.8,5.1c0-0.8-0.2-1.4-0.1-1.8c1.1-3.1-0.6-5.1-3-6.3c-5-2.4-9.9-5-15.1-6.8
			c-3.8-1.3-7.5-2.1-10.9-4.6c-2.4-1.8-5.9-2.1-8.9-3.1c-0.8-0.3-1.6-0.5-2.2-0.9c-6.6-4.5-14.5-6.1-21.4-9.9
			c-0.2-0.1-0.5,0.1-1.4,0.4c10,6.3,19.6,12.4,29.2,18.4c-0.1,0.2-0.2,0.4-0.2,0.6c-1-0.4-2-0.7-2.9-1.3
			c-8.4-4.7-16.7-9.4-25.1-14.1c-0.1-0.1-0.2-0.2-0.3-0.3c-3.5-0.6-6.3-2.9-9.5-4.2c-3.3-1.3-6.5-2.7-9.8-3.9
			c-1.4-0.5-3-1.7-4.5-0.1c-1.6,1.8-2.1,3.7-1.3,5.3c3.6,1.4,6.6,2.6,10.1,4c-2.2,3-0.4,4.9,1.2,6.7c3.9,4.6,7.4,9.7,12.3,13.5
			c2.9,2.2,3.6,5,3.1,8.9c-1,6.8-0.8,13.7-1.1,20.6c-0.1,1.6,0,3.2-0.5,5c-0.5-0.8-1-1.7-1.9-3.2c-0.3,3.2-0.6,5.8-0.8,8.4
			c-0.1,1.3,0,2.7-0.1,4c-0.4,4.1-1.1,4.5-5.4,4.6c-6.5,0-12.9-0.2-19.4,0.7c-5,0.7-10.1-0.4-15.2-0.9c-3.6-0.3-7.1-2.9-10.7-0.9
			c-2.3-2.2-4.4-4.2-6.4-6.3c-0.6-0.7-1.1-1.5-1.4-2.4c-2.1-7.6-4.4-15.1-5.9-22.8c-0.9-4.6-0.7-9.4-0.9-14.1c0-1.1,0.6-2.3,1.1-3.3
			c1.3-2.6,2.9-5.2,4.2-7.9c1.5-3.2,3.3-6.2,6.3-8.3c1.2,0.8,2.4,2.2,3.6,2.2c1.6,0,3.3-1,4.9-1.7c1.1-0.5,2.2-1.1,3.1-1.9
			c4.4-3.6,9.2-5.6,15.1-4.4c-0.8-2.3-1.3-4.4-2.2-6.3c-0.6-1.2-1.8-1.7-3-0.5c-0.4,0.4-0.8,1-1.2,1.6c-1.6-1.6,0.4-2.9-0.3-4.1
			c-1-2-1.9-4-3.2-6.7c-1.9-1.1-4.7-1-7.7,0c-3,1-6,1.8-9.2,2.4c-3.8,0.7-7.7,2.7-10.2,6.3c-3.2,4.7-6.2,9.5-9.7,13.9
			c-5.3,6.6-10.6,13.2-16.5,19.3c-2,2-3.4,4.5-5.5,6.3c-4.7,4.1-9.3,8.3-14.2,12c-1.9,1.4-4.6,1.8-7.5,2.8c0.2-0.7,0.3-1.2,0.5-1.5
			c6.7-7.5,13.5-15,20.2-22.5c2.4-2.7,4.7-5.4,7.2-8c2.5-2.7,5-5.2,7.4-8c2.4-2.8,4.6-5.7,6.8-8.6c0.8-1,1.4-2.2,2.4-3.8
			c-4.4,0-7.5,2.3-10.9,3.5c-3.5,1.2-7,2.3-10.1,4c-3.2,1.8-6,4.4-9.3,6.9c-1.6-1.3-3.2-2.7-4.9-4c-1.8-1.5-2.5-3.1-1.8-5.7
			c0.5-2.2,0.1-4.6,0.1-6.8c0.5-0.3,0.8-0.8,1.1-0.7c3.8,0.4,7.1-1.3,10.5-2.6c7.8-3.1,15.5-6.3,23.3-9.4c3.4-1.3,6.9-2.4,10.4-3.4
			c2.2-0.6,3.5-1.8,4.2-3.9c1.2-3.8,2.5-7.6,4.1-12.3c-4.1,1.8-7.6,2.8-10.6,4.6c-4.5,2.7-8.8,5.8-13.1,8.8
			c-2.6,1.8-5.5,2.2-7.4,0.8c-2.3-1.7-2.4-2.7-2-5.5c0.5-3,0.4-6.1,0.5-9.1c0.8-1,1.9-2.1,2.6-3.3c0.7-1.3,1-2.8,1.2-3.4
			c5.8-1.6,11-3.1,16.3-4.6c4.4-1.3,8.8-2.7,13.2-3.8c1.8-0.5,3.1-1,3.1-3.6c-1.5,0.2-3.1,0.1-4.5,0.6c-2.5,0.9-5,2-7.5,3.1
			c-0.7,0.3-1.5,0.7-2.2,0.8c-4.5,0.6-9,1.2-13.5,1.7c-2.1,0.2-4.3,0-6.3,0c-2.8-3.5,2.2-4.5,2.2-7.4c0-2.5,1.8-4.9,3.1-8.1
			c-0.9-0.4-2-1-2.9-1.4c0.5-1.1,0.6-1.5,0.9-1.7c1.9-2,4-3.8,5.9-5.9c0.8-0.9,1.4-2.2,1.7-3.5c0.8-3.1,1.3-6.4,2-9.5
			c0.7-3.1,2.8-4.5,6-3.8c2.8,0.7,5.8,0,8.4,1.8c0.5,0.4,1.8-0.2,2.9-0.4c-0.9-2.7-1.8-5.2-2.5-7.7c-0.7-2.4-0.3-3.3,2.1-3.9
			c1.4-0.3,2.2-0.9,2.7-2.1c0.7-1.7,1.8-2.5,3.7-2.4c1.9,0.1,3.7-0.4,5.7-0.6c1.7,3.5,2.2,6.8,0.5,10.2c-0.4,0.8-0.6,1.8-0.6,2.7
			c0,1.7,1.2,2.5,3,2.3c5-0.6,10-1.3,15-1.9c0.8-0.1,1.6,0.3,2.4,0.3c1.2,0.1,2.4,0.3,3.6,0.2c5-0.5,9.9-1.2,14.9-1.7
			c2.5-0.2,5.1-0.2,7.6-0.3c10.4-0.2,20.8-1.3,31,1.1c1.3,0.3,2.7,0.2,4,0.2c4.8-0.1,8.4,5.7,6.1,10.1c-0.9,1.8-2.1,3.4-3.1,5
			c1.7,1.3,3.2,2.5,5,3.9c-1,0.4-1.5,0.7-1.8,0.6c-4.4-2.1-9.3-1.1-13.8-1.9c-7.7-1.4-15.4-0.5-23,0.5c-1,0.1-2-0.1-3-0.2
			c-0.4,0-0.8-0.5-1.1-0.4c-4.9,1.8-10.1,0.9-15.1,1.9c-1.4,0.3-2.9,0.4-4.4,0.6c-3.1,0.5-6.4-0.2-9.2,2.1c-1,0.8-2.8,1-4.3,1
			c-3.7,0-6.7,2.9-10.6,2.4c-0.5-0.1-1.3,1.3-2.2,2.3c1.6,0.4,2.6,0.6,3.6,0.9c-0.1,0.4-0.1,0.8-0.2,0.8c-1.3,0.4-2.7,0.7-4.9,1.3
			c3.5,2.2,6.2,0.4,8.8,0.3c2.7-0.1,5.3-0.5,8.2-0.2c-0.5,0.4-0.9,1.1-1.4,1.2c-4.1,0.7-8.2,1.3-12.3,1.9c-2.3,0.3-2.3,0.3-3.5,3.2
			c2.2-0.4,4.2-0.6,6.1-1c4.4-1,8.8-2.4,13.3-3.1c10-1.4,20.1-2.3,30.1-3.6c3.5-0.5,6.9-0.8,10.5-0.6c5.1,0.3,8.6,2,10,7
			c0.2,0.8,0.6,1.5,1,2.7c-1.1,0.5-2.1,1.3-3.1,1.5c-3.6,0.7-7.2,1.5-10.9,0.5c-0.6-0.2-1.3-0.2-2-0.2c-11.9,0.6-23.7,2.2-35.2,5.4
			c-4.7,1.3-9.4,2.3-14.1,3.5c-1.8,0.5-3.6,1-5.4,1.7c-2.9,1.2-5.1,2.8-5.5,6.4c-0.1,1.4-1.2,2.7-1.7,4c-0.9,2.2-1.8,4.4-3.1,7.4
			c4.9-0.8,9.2-1.5,12.8-3.8c2.4-1.6,2.5-4.2,1.5-6.9c4.2-2.5,8.7-3.1,13-3.4c3.4-0.3,7.2,1,10.4,2.3c3.2,1.3,6.2,1.5,9.4,0.9
			c4.8-0.9,9.6-1.2,14.4-2.2c4.9-1,9.2-0.4,13.3,2.5c0.9,0.6,2,0.8,3.5,1.5c-1.5,0.9-2.7,1.6-3.9,2.3c-0.2,1.5-0.7,2.8,1.2,3.8
			c1.1,0.6,0.4,1.8-0.6,2c-1.2,0.3-2.4,0.1-3.6,0c-0.9-0.1-1.8-0.7-2.7-0.6c-4.3,0.7-8.7,1.6-13,2.5c-0.3,0.1-0.6,0.5-1.1,0.9
			c2.9,2.1,5.7,4.2,8.6,6.1c7.1,4.7,14.2,9.2,21.4,13.8c0.8,0.5,1.6,0.9,2.5,1.4c6.2,3.8,12.4,7.6,18.7,11.3c1.7,1,3.6,1.9,5.3,2.9
			c1.1,0.7,2.1,1.6,3.1,2.3c-1.1-0.2-2.1-0.5-3.2-0.7c0.9,1.3,2,1.9,3.2,2.4C188.1,308,188.5,307.3,188.8,306.9z M112.7,331.5
			c0.2,0.7,0.4,1,0.5,1.4c1.1,5.2-0.6,7.1-5.8,6.9c-1.3,0-2.6,0.3-4,0.4c-1.6,0.2-3.1,1.2-4.7-0.5c-0.6-0.7-2.8,0-4.2,0.2
			c-0.8,0.1-1.5,0.8-2.2,0.8c-3.3-0.4-6.4,0.7-9.3,1.8c-1.6,0.6-3,0.7-4.5-0.8c-0.6,3.8-4.1,6.2-3.3,10c0.5,0.1,0.7,0.3,1,0.3
			c0.8,0,1.6-0.1,2.4-0.1c0.4,3.1,0.6,3.6,3.5,3.9c5,0.5,10,0.8,14.9,1c9,0.3,18.1-1.2,27.1-0.1c0.3,0,0.7-0.3,1.1-0.4
			c-0.3-3.8-0.5-7.6-1.1-11.3c-1-6.2-2.3-12.5-3.6-18.7c-0.4-1.8-0.8-3.9-2-5.3c-3-3.8-6.3-7.4-9.6-11c-2.4-2.6-5.3-3-8.5-1.7
			c-4.2,1.7-8.5,3.3-13.1,5.1c-2.2-1.7-5.2-2.5-8.6-2.5c-1.1,5.9-0.3,11.5,0.7,17.3C90.9,329.4,102.1,328.6,112.7,331.5z
			 M165.5,207.9c-2.6-0.2-4.6-0.3-6.6-0.4c-4.2-0.3-8.2,0-12.2,1.3c-4.6,1.4-9.3,2.3-14.1,2.6c-2.9,0.2-5.7,0.4-8.6,0.6
			c0,0.3,0,0.6,0.1,1c6.7,0,13.4-0.3,20.1,0.1c6.6,0.4,13.1-1.4,20.2-0.9C164.7,210.7,165.1,209.1,165.5,207.9z M134.6,208.2
			c-0.4-0.6-0.5-0.8-0.6-0.8c-8.3-0.7-16.1,1.8-24.1,3.3c-1,0.2-2.3,0.8-2.2,2.8c1.8-0.1,3.5-0.2,5.1-0.2c1.1,0,2.3-0.8,2.5,1.4
			c0.1,1.2,1.5,0.9,2.1,0c0.8-1.1,1.4-2.3,1.9-3.2C124.6,210.4,129.5,209.3,134.6,208.2z M70.4,250.7c-3-2.5-3.4-2.3-7.2,2.1
			C65.4,252.1,67.6,251.5,70.4,250.7z M36.2,291.7c1.3,1.9,2.3,1,4.2,0C38.3,291.7,37.2,291.7,36.2,291.7z M95.2,215.1
			c0.1,0.3,0.2,0.5,0.4,0.8c0.9-0.3,1.8-0.7,2.6-1c-0.1-0.3-0.2-0.5-0.3-0.8C97,214.5,96.1,214.8,95.2,215.1z M141.3,323.7
			c-3,1.4-0.6,3.4-0.8,5C142,327.4,142.4,325.8,141.3,323.7z M159.9,210.2c0.9-0.1,1.8-0.1,2.7-0.2c0-0.2,0-0.4,0-0.6
			c-0.9,0-1.8-0.1-2.7-0.1C159.8,209.6,159.9,209.9,159.9,210.2z M155.4,209.8c-0.6-0.2-1.3-0.3-1.9-0.5c0,0.2-0.1,0.4-0.1,0.7
			c0.7,0,1.3,0.1,2,0.1C155.4,210,155.4,209.9,155.4,209.8z M318.6,256.6c-2.7,0.5-5.6,1-8.9,1.6c-0.4-3.7-0.8-6.7-1.2-10.5
			c2.5,0.7,4.4,1.3,6.3,1.9c-1.2-1.3-2.5-2.5-3.7-3.8c-2.3-2.4-4.5-4.8-7.3-7.7c0.2-1.8,2.7-4.5,0-7.3c-0.9,0-1.9,0-2.9-0.1
			c-2.1-0.2-3.4,1.1-3.3,2.8c0.1,1.9-0.8,3-1.7,4.3c-2.1,3.2-2,3.4,0.6,6.4c2.8,3.2,4.1,6.9,3.4,11.4c-1.2-0.1-1.9-0.1-2.6-0.1
			c-3.5-0.3-7-1-9.5,2.5c-0.2,0.3-0.8,0.6-1.1,0.6c-2-0.3-3.2,1-4.6,2.1c-1,0.7-3,1.2-3.9,0.7c-1.6-0.9-2.7-0.6-4,0.1
			c-0.6,0.3-1.5,0.5-1.9,0.2c-2.4-1.6-4.8-0.6-7.3-0.5c-2.9,0.1-5.8,0.2-9.2,0.4c-1.3-6.4-2.5-12.5-3.5-18.5
			c-0.2-1.3,0.6-2.9,1.1-4.7c-0.5-0.3-1.4-1.1-2.5-1.6c-4.3-1.9-7.7-1.1-9.6,3.1c-1.2,2.8-2.1,5.4-1.5,8.5c0.3,1.6,0.4,3.2,0.6,4.9
			c0.4,4.6-1.6,6.2-5.8,4c-4.4-2.3-8.6-3.9-13.7-3.6c-3.3,0.2-5.1,1-4.5,4.2c0.8,4,0.3,7.5-1.5,11c-0.4,0.8-1,1.5-1.1,2.3
			c-0.3,2.1-0.9,4.5-0.3,6.4c0.8,2.4,2.7,4.4,4.4,7.1c2.3-0.8,4.8-1.8,7.3-2.3c3.3-0.7,6.3-3.1,10.1-1.3c1.1,0.5,3.1-0.7,5-1.3
			c0.4,0.9,1.4,2.2,1.3,3.4c-0.2,1.9-1.2,3.7-2.1,5.4c-0.3,0.7-1.3,1.2-2,1.4c-2.4,0.6-5.5,3.1-6.3,5.3c-0.4,1.2-0.8,2.5-1.2,4
			c-2.4,0.3-4.7,0.7-7.6,1.1c3.9,4.7-0.8,8.6-0.4,13c-0.9-0.1-1.5-0.2-2.1-0.2c0.9,3.9,1.6,7.9,2.7,11.6c1.2,4,1,7.8-0.8,11.6
			c-0.8,1.7-1.7,3.3-0.8,5.4c0.3,0.8-0.2,2.1-0.6,3c-1.2,2.2-1.3,4.5-1.2,6.9c0.1,2,0,3.9-2,5.1c-0.5,0.3-0.8,1.1-0.9,1.7
			c-0.4,2.8-0.7,5.7-1,8.5c0,0.2-0.2,0.4-0.2,0.4c-0.6-0.4-1.1-0.8-2.3-1.6c-0.2,2.5-0.7,4.5-0.3,6.3c0.6,3.1-0.9,7.9-3.6,9.8
			c-0.3,0.2-0.5,0.6-1,1.2c0.7,0.3,1.3,0.6,1.9,0.8c-1,6.7-1.1,6.9-6.2,7.2c0.6,0.8,1,1.5,1.4,2c-0.6,0.6-1.2,1.2-2.2,2.2
			c1.7,0,2.7-0.1,4-0.1c-2,6.3-3.8,12.3-5.7,18.3c1.4-0.4,3-0.3,3.7-1c1.9-1.8,2.3-5,5.1-6.2c-0.1-2.9,1.7-4.8,3.1-7.1
			c1.8-2.9,2.9-6.4,5.1-8.9c2-2.2,2.4-5.9,6.1-6.7c0.3-0.1,0.7-1.6,0.4-1.9c-1.2-1.5-0.4-1.9,0.7-3.1c1-1.1,1.1-3.2,1.6-5
			c0.9,0.1,2,0.2,3.2,0.4c2.9-7,4.3-14.3,8.2-20.7c0.4,1.8,0.7,3.5,0.6,5.3c-0.6,9-1.3,18.1-1.9,27.1c-0.1,1.1,0.3,2.3,0.3,3.4
			c0,0.6,0.1,1.7-0.3,1.9c-1.5,0.9-0.9,2.1-0.6,3.1c0.7,2.6,1.5,5.1,2.4,7.6c1.6,4.9,3.3,9.8,5,14.7c0.1,0.2,0.4,0.3,0.8,0.4
			c0.3-0.6,0.6-1.3,1-1.9c0.5-0.8,0.9-1.5,1.5-2.2c0.1-0.1,0.8,0.3,1.2,0.5c0.6-2.5,1.3-5.1,1.7-7.6c0.3-2-0.2-4.2,0.4-6.2
			c1.6-5.3,1.6-10.7,0.8-16.1c-0.2-1.2-0.3-2.4-0.2-3.5c0.2-9.6,0.6-19.3,0.7-28.9c0.1-10.1-0.1-20.2-0.1-30.3
			c0-1.4,0.3-2.8,0.5-4.2c1.2,0.4,1.7,0.9,1.9,1.5c2,5.4,3.9,10.8,6.1,16.1c1.6,3.8,3.7,7.4,5.7,11.1c0.7,1.3,2,2.3,3.4,4
			c0.7-3.2,0.2-6.4,4.2-5.7c0.3,0.1,1.3-1.2,1.2-1.6c-0.8-3.2-1-6.8-2.8-9.3c-4-5.7-6.5-12.2-9.5-18.3c-0.4-0.9,0.2-2.3,0.4-4.1
			c-1.5-1.3-3.4-2.9-5.2-4.7c-1-1-2.3-2.1-2.5-3.4c-0.8-4.9-2.4-9.7-2-14.9c0.2-3.1,1.2-4.7,4.1-5.2c0.2,0,0.3-0.3,0.4-0.5
			c-0.9-0.4-1.7-0.8-3.5-1.6c1.9-0.5,2.9-0.8,3.8-0.9c6.5-0.4,13-0.2,19.1-3.1c2.4-1.1,5.2-1.2,7.7-2.2c3.3-1.4,6-0.3,8.5,1.4
			c3.4-0.8,6.4-3.3,10.1-1.4c0.2,2.9,0.4,5.7,0.6,8.5c0.2,3.3,0.2,3.4-3.1,4.1c-5.5,1.1-11.1,2.1-16.6,3.2c-3.3,0.7-6.4,0.8-9.5-0.5
			c-1.7,2.2-1.9,4.6-0.5,6.5c1.4,2,3.5,3.4,6.5,3c4-0.5,8-0.4,12-0.5c3.4-0.1,6.8-0.2,8.9,2.7c10.1-6.3,22.5-5.1,32.6-11.3
			c-1.2-0.6-2.7-0.9-3.1-1.7c-2.1-4.1-5.6-4.1-9.4-4c-3.6,0.1-7.3-0.3-10.9-0.5c0-2.1-0.1-3.6-0.1-5.1c0-1.6,0.2-3.3,0.2-4.9
			c0-6.6,0-6.4,6.3-7.5c4.5-0.8,8.9-2,13.4-3.1c0.3-0.1,0.6-0.6,0.9-1.1c-0.5-0.9-1-1.7-1.7-2.9c1.2-0.6,2.4-1.2,3.6-1.8
			c-3.1-2.5-6-3.2-9.2-3.1c-1.4,0-2.8-0.9-4.1-1.4c-1.6-0.7-3.2-1.5-4.8-2.2C316.1,252.4,319.6,253,318.6,256.6z M338.6,311.4
			c3.7-4.1,4.2-4.2,10.3-2.8c1,0.2,2.1-0.2,3.1-0.3c1.7-0.3,3.4-0.5,5.3-0.2c-1.1,0.4-2.3,1-3.5,1.2c-4.6,1.1-9.2,2.1-13.9,3
			c-0.8,0.2-1.7-0.3-2.6-0.5c0.2,0.8,0.5,1.6,0.9,2.8c17.7-3.4,35.2-7.1,53-9.2c2,6.1,3.1,11.7,1.1,17.5c-0.5,1.4-0.4,2.9-0.6,4.4
			c-0.1,1.3-0.3,2.5-0.4,3.8c0.2,0,0.3,0,0.5,0c1-2.2,2.1-4.4,3.1-6.6c-0.5,7.9-1,15.7-3.8,23.2c-0.8-0.5-1.4-0.8-2-1.2
			c-3.5,4.4-3.7,4.7,0.6,7.1c-0.8,3.1-1.6,6.3-2.6,10.6c-3.2-2.9-5.8-5.2-8.2-7.6c-1.4-1.4-2.5-3.3-4-4.7c-4-3.6-7.4-7.7-9.7-12.7
			c-0.4-1-0.8-1.7-2.2-2.1c-1.3-0.4-3.1-2.6-3-3.9c0.1-3.5-2.5-5.4-3.2-8.4c-2.8,2.6,1.4,6.5-2.4,8.4c0.4,1.7,0.7,3.3,1.2,5.7
			c-1.6-1.3-2.4-1.9-3.2-2.5c-1.6,3.3-1.8,3.8-0.8,7.7c1.2,4.8,2.5,9.5,3.8,14.2c0.2,0.5,0.4,1.4,0.8,1.5c3.9,0.6,1.5,3.4,1.6,4.5
			c3.3,1.8,6.6,3.1,9.1,5.2c2.2,1.9,4.1,3.9,6.9,5c1.1,0.5,2,1.7,2.9,2.6c0.7,0.7,1.3,1.5,2,2.1c1.6,1.2,3.2,2.3,4.9,3.5
			c-1.2,3.9-2.5,7.5-3.4,11.2c-0.8,3.6-1.8,7.1-4,10.1c0.5-6.1,2.8-11.8,3.1-17.8c-1.2-0.3-2-0.5-3.1-0.8c-2.4,7.6-4.7,15-7.1,22.5
			c-0.2,0-0.5-0.1-0.7-0.1c-0.3-0.7-0.5-1.5-1-2.9c-0.4,1.9-0.6,3.2-1,4.4c-1.3,4.2-2.4,8.4-7.3,10.2c-0.8,0.3-1.5,1.8-1.8,2.8
			c-1,2.8-1.6,5.7-2.6,8.4c-1.2,3.1-1.4,5.8,1.7,7.7c1.2-2.4,2.4-4.6,3.8-7.3c1.9,1.4,3.5,2.5,5.7,4.1c0.2-2.7,2.9-4.3,0.7-6.9
			c-0.3-0.3-0.1-1.2,0.2-1.7c1.4-2.6,2.1-5.6,4.9-7.2c0.4-0.2,0.7-0.5,1-0.9c2.2-3.8,4.4-7.6,6.7-11.4c0.5-0.8,1.1-2.3,1.7-2.3
			c2.6-0.1,2.5-2,3-3.6c0.8-2.7,1.5-5.3,2.4-7.9c0.2-0.5,1.1-0.7,1.7-1.1c0.2,0.6,0.8,1.3,0.7,1.8c-1,2.7-2.2,5.4-3.2,8.1
			c-0.6,1.8-1,3.8-1.4,5.7c3.4-4.7,4.3-10.4,6.8-15.8c1.2,0.6,2.2,1,3.4,1.6c2.3-3.1,2.9-6.6,2.1-10.1c-1-4.4-0.5-8.5,0.7-12.6
			c1.3-4.7,2.5-9.5,3.6-14.3c1.4-6.1,2.6-12.2,4-18.2c1.8-8.1,3.7-16.2,5.7-24.2c0.5-1.8,1.4-3.6,2.1-5.4c3.2,1,1.6-2.6,3.2-3.3
			c0.3-0.1,0.6-0.2,0.8-0.4c2.2-2.4,5.4-1.9,8-3.1c0.4-0.2,1.1,0.2,1.5,0.5c2.7,1.7,4.7,0.9,5-2.2c0.1-1.1-0.2-2.3-0.4-3.4
			c2.1-1.8,4.1-3.5,6-5.1c-1.8-3.9-5.7-0.4-8-2.6c0.5-1.4,1.1-2.7,1.7-4.3c-5.5-3.4-11.5-0.7-17-2c-0.1-1.5-0.6-3.1-0.3-4.4
			c1.1-5.5,0.6-11.1,0.2-16.6c-0.3-4.7,2.8-8.3,2.9-12.8c3.2-0.9,3.4-4.5,5.7-6.3c2-1.7,3-4,1.5-6.7c-0.3-0.5,0.1-1.4,0.2-2
			c4.3-0.3,4.4-0.3,5.3-3.8c-1.2,0.7-2.2,1.3-3.3,1.9c-0.3-0.3-0.5-0.6-0.8-0.8c1.2-1.5,2.4-3.1,3.6-4.6c-0.1-0.2-0.3-0.4-0.4-0.6
			c-1.9,0.6-3.8,1.3-5.9,2c-2-3-5.1-1.2-7.7-1.4c-1.4-0.1-3.2,0.7-4.2,1.8c-1.9,1.9-3.3,4.2-4.9,6.3c-1.4,2-3,2.9-5.8,2.8
			c-2.7-0.1-5.9-1.5-8.3,0.9c-0.4,4.1,1.8,8.4-1.8,11.7c3.6,5.9,3.4,5.9,4.5,12.9c0.7,4.4,1.1,8.7,0,13.1c-0.2,0.7,0.1,1.5,0,2.2
			c-0.5,2.9-0.8,5.9-1.8,8.6c-0.8,2.1-1.9,4.5-4.6,5.1c-13.4,3-26.8,6.2-40.3,9c-3.5,0.7-6.8,1.7-9.9,3.4c-1.7,0.9-3.3,1.8-5.2,2.8
			c0.1,1.2,0.2,2.6,0.3,4.1c1.8,0.2,3.4,0.4,5,0.6C337.9,311.7,338.4,311.7,338.6,311.4z M293.1,351.1c-0.9-0.5-1.9-1.1-3.2-1.8
			c-0.2,1.2-0.4,2-0.5,2.8c-0.8-0.4-1.5-0.8-2.3-1.1c-5.1-2.4-5.8-5.2-4.1-10.9c1.5-5.4,2-11,3.2-16.5c1.3-6.5,2.7-13,4.3-19.5
			c0.6-2.2,2.5-3.5,5-4.6c0,1.6-0.1,2.7-0.1,3.5c4.8-0.2,9.7-0.3,14.5-0.5c5-0.2,10-0.5,14.8-0.7c0.8,2.9,1.6,5.3,2.2,7.8
			c0.1,0.6-0.3,1.4-0.5,2.1c-1.6,4.6-2.6,9.2-2.1,14.3c0.3,3.4-1.2,6.9-1.9,10.4c-0.5,2.4-1.2,4.9-1.7,7.2c0.8,0.7,1.5,1.2,2.7,2.1
			c-0.8,0.3-1.2,0.5-1.6,0.6c-5,1.3-10,2.3-15.3,1.7c-3.6-0.4-7.3,0.6-11,1.2C294.5,349.4,293.8,350.5,293.1,351.1z M300.4,338.3
			c0.2,0.1,0.7,0.4,1.3,0.3c4-0.3,7.9-0.6,11.9-1.1c2.1-0.3,2.1-1.8,1.3-3.3c-1.7-3.5-3.3-7-5.1-10.4c-1.1-2.2-2.1-2.4-4.3-1.3
			c-2.1,1-3.7,2.5-3.8,5c-0.1,1.9-0.8,3.9-0.2,5.5C302.1,335.3,298.7,336,300.4,338.3z M302.8,320.5c2.8,0.8,3.8,0.1,3.9-1.5
			c0-0.4-0.9-1.1-1-1.1C304.8,318.6,304.1,319.3,302.8,320.5z M333.6,380c-2.4,0.9-4.7,1.9-7.1,2.7c-11.1,3.7-22.6,4.9-34.1,5.8
			c-4.6,0.4-9-1.6-13.1-3.8c-1.9-1-2.5-2.3-0.6-4.4c1.3,0.8,2.7,1.4,3.8,2.2c3.7,2.7,8.4,2.7,12-0.2c2.5-2,4.1-2.1,6.8-0.4
			c1.2,0.8,2.4,1.5,3.8,2.4c-0.4-3.8-0.7-4.1-6.2-5.2c0.5-1.1,1-2.3,1.4-3.1c-2.3-1-4.4-1.7-6.3-2.7c-1-0.5-2.5-1.9-2.4-2.4
			c1-2.9-1.5-4.4-1.9-6.5c-2.6,0.6-4.9,1.5-7.2,1.4c-2.1-0.1-4.1-1.7-6.3-2c-2.9-0.4-3.4-2.5-4-5.6c-1.2,1.6-2.1,2.4-2.5,3.4
			c-1.5,3.5,0.7,6.9,0.7,10.4c0,4.6,5.1,4.3,7.4,7.2c-4.5,2.1-3.1,6.4-3.7,9.8c-0.4,2.2,0.4,4.3,2.3,5.9c1,0.8,1.5,2.1,2.2,3.2
			c1.3,2.3,3.2,4.3,5.6,4.7c3.9,0.7,8,1.1,11.8,0.6c7.2-0.8,14.3-2.4,21.4-3.4c4.6-0.7,6.9-1.1,9.5-2.6c0.3-0.2,0.4-0.7,0.7-1.3
			c0.7,1.5,1,2.3,1.2,2.9c1.7-0.5,3.1-0.7,4.4-1.3c2.9-1.3,6.3-1.8,7.8-5.1c0.5-1.2,0.6-2.6,1.3-3.6c1.3-1.8,0.2-2.6-1-3.6
			c-1.9,2.1-4.2,2.9-7.2,2c2-1.4,4.1-2.7,6.4-4.3c-2.4-2.3,2.2-5.7-1.9-8.7C338.2,377.9,336.1,379.1,333.6,380z M310.7,359.3
			c0.7,1.4-0.3,3.7-0.4,4.9c5.4,1.9,10.3,3.2,14.8,5.4c3.1,1.5,6.4,0.7,9.3,2.2c0.4,0.2,1.3-0.1,1.7-0.4c2.1-1.7,2.6-5.1,5.9-5.4
			c0.1,0,0.1-0.3,0.2-0.4c-0.8-0.7-1.6-1.5-2.4-2.3c0.9-0.8,1.6-1.4,2.9-2.6c-5.6-2-10.5-4-15.4-5.6c-1.8-0.6-3.8-0.6-5.7-0.7
			c-3.2-0.2-6.3-0.2-10-0.3C312.1,355.6,309.5,356.9,310.7,359.3z M346.1,365.9c1.3,0.8,2.6,1.6,4.1,2.5c0.6-1.2,1.1-2.1,1.5-2.8
			c-1.7-0.2-3.4-0.4-5.1-0.6C346.5,365.3,346.3,365.6,346.1,365.9z M340.4,318.9c1.2-0.2,2.4-0.4,3.6-0.6c0-0.3-0.1-0.5-0.1-0.8
			c-1.2,0-2.4,0.1-3.6,0.1C340.3,318,340.3,318.4,340.4,318.9z"/>
	</defs>
	<clipPath id="SVGID_2_">
		<use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
	</clipPath>
	<g id="植" class="st3">
		<path class="st4 l1" d="M188.3,50.3c14.8,44.7,17.4,16,26.2,12.4c17.3-7,34.7-0.9,54.2-14"/>
		<path class="st5 l2" d="M237.4,192.8c-12.8-32.5-18.9-47.5-18.9-47.5"/>
		<path class="st6 l3" d="M236.4,66.4c-6.1,37.3-5.2,115.3,1.9,126.5"/>
		<path class="st7 l4" d="M263.1,34.6c-21.6-50.9-18-12.1-26.7,31.7"/>
		<path class="st6 l5" d="M232.8,95c-17.5,31.5-36.2,48.9-36.6,86.8"/>
		<path class="st8 l6" d="M232.7,99.6c14.2,9.6,40.6,29.5,40.6,29.5"/>
		<path class="st5 l7" d="M300.8,37.2c31.9-15.6,68.1-16.4,100-20.1"/>
		<path class="st9 l8" d="M292.8,41.4c2.6-1.5,5.3-3,8-4.3"/>
		<path class="st9 l9" d="M339.6,7.2c-10.4,13.4-27.4,43.7-26,48.3c3.6,12.2-21.8,45.5-5.9,86"/>
		<path class="st10 l10" d="M317.9,68.2c17.2-8.3,24.8-15.7,31.7-14.4c24.3,4.5,28.1,78.3,27.3,91.5"/>
		<path class="st11 l11" d="M311.4,96.3c21.7,3.2,38.7,0.7,38.7,0.7"/>
		<path class="st11 l12" d="M310.3,130.5c11.6,2.4,45.3,0.7,45.3,0.7"/>
		<path class="st5 l13" d="M313.4,158.6c29.4,2.5,45.5-2.7,53.8-4.1"/>
		<path class="st12 l14" d="M282.5,128.9c-3.2,19.9-15.9,64.1,29,58.3c44.9-5.9,90.9-16.4,90.9-16.4"/>
	</g>
	<g id="春" class="st3">
		<path class="st13 l15" d="M55.1,208.3c-23.3,40.8,7.4-4.5,113.4,0"/>
		<path class="st14 l16" d="M86.5,241.7c14.1-4.3,33.1-7.5,63.6-8"/>
		<path class="st15 l17" d="M47.4,256.8c12.1-4.6,23.6-10.4,39.1-15.1"/>
		<path class="st11 l18" d="M19.4,295.8c31.7-13.6,85.1-37.1,133.3-34"/>
		<path class="st8 l19" d="M77.4,188.3c5.2,30.5,15.8,84.3-65.2,152.2"/>
		<path class="st16 l20" d="M97.5,269.4c31.9,4.4,92.7,32,96.8,58.4"/>
		<path class="st17 l21" d="M69.6,305.6c-1.2,16.3-1,31.9-1.6,39.7"/>
		<path class="st18 l22" d="M133.6,361.5c0,0,6.8-33.7-14.6-52.7c-16.5-14.5-30.8-5.8-42.8-1.1"/>
		<line class="st11 l23" x1="72.7" y1="336.4" x2="106.7" y2="334.3"/>
		<line class="st19 l24" x1="75.6" y1="358.2" x2="121.6" y2="361.1"/>
	</g>
	<g id="樹_1_" class="st3">
		<path class="st18 l25" d="M224.9,258.8c-4.1,40.3-21-0.5,44.5,12.3"/>
		<path class="st6 l26" d="M247.3,239.7c-2.6,17.2,4,43-4.7,171.4"/>
		<line class="st20 l27" x1="244.1" y1="283.2" x2="206.2" y2="403.1"/>
		<line class="st8 l28" x1="252.4" y1="305.1" x2="267.2" y2="342.4"/>
		<path class="st21 l29" d="M276.8,268.7l54.6-13.2L301,239.4c8.3,15.1,5,37.5,5,37.5"/>
		<path class="st22 l30" d="M275.4,291.9c34.5-6.6,20.2,7.7,52.9-3.9"/>
		<path class="st19 l31" d="M298.5,302.9c-5.8,13-7.6,44.5-7.6,44.5"/>
		<path class="st18 l32" d="M298.8,315.5c0,0,17.7-7.9,20.7-4.8s0.4,22.5,0.4,22.5"/>
		<line class="st6 l33" x1="291.3" y1="344.3" x2="319.8" y2="342.5"/>
		<path class="st8 l34" d="M270.8,362.9C283,373,290,376.7,300,376.7"/>
		<path class="st22 l35" d="M315.2,360.3c10.4,2.2,30.9,7.9,30.9,7.9"/>
		<path class="st7 l36" d="M279.4,389.6c19.8,19.1,42.1,5.7,59.1-4.2"/>
		<path class="st7 l37" d="M335,311.6c0,0,74.7-19.7,92.8-17.7"/>
		<path class="st9 l38" d="M417.4,230.6c-30.1,18.1-9.5,38-11.7,84.5c-1.5,32.8-9.6,83.2-45.6,118.8"/>
		<path class="st23 l39" d="M351.7,334.3c13.1,28.2,40.9,45.8,44.4,56.3"/>
	</g>
</g>
</svg>

      <h3 class="title" data-aos="fade-top" data-aos-delay="400">
        ｜植感生活．職人建築｜
      </h3>
      <h3 class="subtitle" data-aos="fade-top" data-aos-delay="600">
        豐景 <span>2~4</span> 房
      </h3>
      <h3 class="hint" data-aos="fade-top" data-aos-delay="800">
        寓上春樹<span>NO.2</span>
      </h3>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.section1 {
  width: 100%;
  height: 100vh;
  min-height: size(900);
  max-height: size(1080);
  //overflow: hidden;
  position: relative;
  // background-image: url('./s1/01_bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  
  // margin: 0 0 size(400) 0;

  &:before {
  content: ' ';
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./s1/bg.jpg") center 0 no-repeat;
  background-size: cover;
}
}

.bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  object-fit: cover;
  margin-top: 0;
  // opacity: 0.5;
}


.grass {
  @include img_r_pc(1600, 0, -50);
  top: auto;
  bottom: 0;
  transform-origin: bottom;
  animation: grass 4s ease-in-out alternate infinite;
}

.logo {
  @include img_l_pc(430, 150, 200);
  top:calc(50% + 100vw * (150 - 540) / 1920);
  .st0{fill:#2F041B;
  animation: op 0.5s 3s linear reverse backwards;}
.st1{fill:#F4D321;
  animation: op 0.5s 3.2s linear reverse backwards;}
	.st2{fill:#000;
  animation: op 0.5s 3.5s linear reverse backwards;}
.st3{
   clip-path:url(#SVGID_2_);
  stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;fill:none;stroke:#FFFFFF}
	.st4{stroke-width:32;}
	.st5{stroke-width:14;}
	.st6{stroke-width:15;}
	.st7{stroke-width:23;}
	.st8{stroke-width:20;}
	.st9{stroke-width:29;}
	.st10{stroke-width:37;}
	.st11{stroke-width:18;}
	.st12{stroke-width:36;}
	.st13{stroke-width:35;}
	.st14{stroke-width:16;}
	.st15{stroke-width:26;}
	.st16{stroke-width:42;}
	.st17{stroke-width:21;}
	.st18{stroke-width:22;}
	.st19{stroke-width:19;}
	.st20{stroke-width:31;}
	.st21{stroke-width:20;}
	.st22{stroke-width:17;}
	.st23{stroke-width:24;}
	.l1{
		stroke-dasharray: 104;
		stroke-dashoffset: 104;
		animation: stroke1 0.13s linear forwards;
	}
	.l4{
		stroke-dasharray: 87;
		stroke-dashoffset:87;
		animation: stroke1 0.11s 0.13s linear forwards;
	}
	.l3{
		stroke-dasharray:130;
		stroke-dashoffset: 130;
		animation: stroke1 0.16s 0.24s linear forwards;
	}
	.l2{
		stroke-dasharray:67;
		stroke-dashoffset:67;
		animation: stroke1 0.065s 0.40s linear forwards;
	}
	.l5{
		stroke-dasharray: 97;
		stroke-dashoffset: 97;
		animation: stroke1 0.12s 0.465s linear forwards;
	}
	.l6{
		stroke-dasharray: 64;
		stroke-dashoffset:64;
		animation: stroke1 0.105s 0.605s linear forwards;
	}
	.l8{
		stroke-dasharray:10;
		stroke-dashoffset:10;
		animation: stroke1 0.015s 0.71s linear forwards;
	}
	.l7{
		stroke-dasharray: 106;
		stroke-dashoffset: 106;
		animation: stroke1 0.135s 0.725s linear forwards;
	}
	.l9{
		stroke-dasharray: 150;
		stroke-dashoffset: 150;
		animation: stroke1 0.19s 0.86s linear forwards;
	}
	.l10{
		stroke-dasharray: 150;
		stroke-dashoffset: 150;
		animation: stroke1 0.19s 1.05s linear forwards;
	}
	.l11{
		stroke-dasharray:60;
		stroke-dashoffset:60;
		animation: stroke1 0.075s 1.24s linear forwards;
	}
	.l12{
		stroke-dasharray:60;
		stroke-dashoffset:60;
		animation: stroke1 0.075s 1.315s linear forwards;
	}
	.l13{
		stroke-dasharray:70;
		stroke-dashoffset:70;
		animation: stroke1 0.09s 1.39s linear forwards;
	}
	.l14{
		stroke-dasharray: 190;
		stroke-dashoffset:190;
		animation: stroke1 0.235s 1.48s linear forwards;
	}
	.l15{
		stroke-dasharray: 144;
		stroke-dashoffset:144;
		animation: stroke1 0.18s 1.715s linear forwards;
	}
	.l17{
		stroke-dasharray:45;
		stroke-dashoffset:45;
		animation: stroke1 0.055s 1.895s linear forwards;
	}
	.l16{
		stroke-dasharray:67;
		stroke-dashoffset:67;
		animation: stroke1 0.085s 1.965s linear forwards;
	}
	.l18{
		stroke-dasharray:150;
		stroke-dashoffset:150;
		animation: stroke1 0.19s 2.035s linear forwards;
	}
	.l19{
		stroke-dasharray:190;
		stroke-dashoffset:190;
		animation: stroke1 0.235s 2.225s linear forwards;
	}
	.l20{
		stroke-dasharray: 132;
		stroke-dashoffset: 132;
		animation: stroke1 0.165s 2.46s linear forwards;
	}
	.l21{
		stroke-dasharray: 51;
		stroke-dashoffset: 51;
		animation: stroke1 0.065s 2.625s linear forwards;
	}
	.l22{
		stroke-dasharray:114;
		stroke-dashoffset:114;
		animation: stroke1 0.145s 2.69s linear forwards;
	}
	.l23{
		stroke-dasharray:37;
		stroke-dashoffset:37;
		animation: stroke1 0.045s 2.835s linear forwards;
	}
	.l24{
		stroke-dasharray: 51;
		stroke-dashoffset:51;
		animation: stroke1 0.13s 2.76s linear forwards;
	}
	.l25{
		stroke-dasharray:85;
		stroke-dashoffset:85;
		animation: stroke1 0.105s 2.945s linear forwards;
	}
	.l26{
		stroke-dasharray:187;
		stroke-dashoffset:187;
		animation: stroke1 0.235s 3.05s linear forwards;
	}
	.l27{
		stroke-dasharray:141;
		stroke-dashoffset:141;
		animation: stroke1 0.0655s 3.285s linear forwards;
	}
	.l28{
		stroke-dasharray: 69;
		stroke-dashoffset:69;
		animation: stroke1 0.085s 3.35s linear forwards;
	}
	.l29{
		stroke-dasharray: 130;
		stroke-dashoffset: 130;
		animation: stroke1 0.165s 3.435s linear forwards;
	}
	.l30{
		stroke-dasharray: 57;
		stroke-dashoffset: 57;
		animation: stroke1 0.065s 3.60s linear forwards;
	}
	.l31{
		stroke-dasharray: 52;
		stroke-dashoffset: 52;
		animation: stroke1 0.065s 3.665s linear forwards;
	}
	.l32{
		stroke-dasharray: 79;
		stroke-dashoffset: 79;
		animation: stroke1 0.10s 3.73s linear forwards;
	}
	.l33{
		stroke-dasharray: 36;
		stroke-dashoffset: 36;
		animation: stroke1 0.045s 3.83s linear forwards;
	}
	.l34{
		stroke-dasharray: 35;
		stroke-dashoffset: 35;
		animation: stroke1 0.045s 3.875s linear forwards;
	}
	.l35{
		stroke-dasharray: 44;
		stroke-dashoffset: 44;
		animation: stroke1 0.055s 3.93s linear forwards;
	}
	.l36{
		stroke-dasharray: 76;
		stroke-dashoffset: 76;
		animation: stroke1 0.095s 4.025s linear forwards;
	}
	.l37{
		stroke-dasharray: 97;
		stroke-dashoffset: 97;
		animation: stroke1 0.12s 4.145s linear forwards;
	}
	.l38{
		stroke-dasharray: 225;
		stroke-dashoffset: 225;
		animation: stroke1 0.28s 4.425s linear forwards;
	}
	.l39{
		stroke-dasharray:84;
		stroke-dashoffset:84;
		animation: stroke1 0.105s 4.7s linear forwards;
	}
}
@keyframes stroke1 {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes op {
  to {
    opacity: 0;
  }
}
.logo1 {
  @include img_l_pc(437, 142, 600);
}

@keyframes grass {
  to {
    transform: skewX(3deg);
  }
}

.title {
  @include img_l_pc(680, 353, 855);
  top:calc(50% + 100vw * (353 - 540) / 1920);
  font-size: size(54);
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: size(8.64);
  text-align: left;
  color: #ffffff;
  white-space: nowrap;
}

.subtitle {
  @include img_l_pc(866, 417, 866);
  top:calc(50% + 100vw * (417 - 540) / 1920);
  font-size: size(123.9);
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #231815;
  white-space: nowrap;
  span {
    display:inline-block;
    position: relative;
    top: 0.06em;
    margin: 0 -0.06em;
    padding: 0.2em 0 0 0;
    font-size: 1.3em;
    font-weight: 500;
    font-family: TTNorms;
    letter-spacing: -0.05em;
  }
}

.hint {
  @include img_l_pc(244, 833, 470);
  top:calc(50% + 100vw * (830 - 540) / 1920);
  font-size: size(34.3);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: size(0.69);
  text-align: left;
  color: #00101d;
  span {
    font-weight: 500;
  }
}
/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .section1 {
    width: 100vw;
    min-height: sizem(604);
    max-height: sizem(750);
    height: calc(100vh - 63px);
    // margin: 0 0 -12vw 0;
    // background-size: auto size-m(750);
    z-index: initial;
  &:before {
  background-image: url("./s1/bg_m.jpg");
}
  }

  .grass {
    @include img_r_m(375, 0, -40);
    top: auto;
    bottom: 0;
    transform-origin: bottom;
    animation: grass 4s ease-in-out alternate infinite;
  }

  .logo {
    @include img_l_m(195, 50, 88);
  }
  .logo1 {
    @include img_l_m(195, 50, 88);
  }

  @keyframes grass {
    to {
      transform: skewX(3deg);
    }
  }

  .title {
    @include img_r_m(290, 409, 0);
	width: 100%;
    font-size: sizem(22);
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing:0.16em;
    text-align: center;
    color: #ffffff;
    white-space: nowrap;
  }

  .subtitle {
    @include img_l_m(290, 437,0);
	width: 100%;
    font-size: sizem(52);
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    color: #231815;
    white-space: nowrap;
    span {
    font-size: 1.28em;
		margin: 0 -0.15em;
    }
  }

  .hint {
    @include img_r_m(113, 363, 52);
	width:auto;
    font-size: sizem(16);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: sizem(0.32);
    text-align: left;
    color: #00101d;
	//white-space: nowrap;
    span {
      font-weight: 500;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import { isPC, isMobile, isTablet } from '@/utils'
import info from '@/info'

export default {
  name: 'section1',

  data() {
    return {
      info,
      isPC,
      isMobile,
      isTablet,
      // blockIndex: 0,
    }
  },

  methods: {},

  mounted() {
    // setTimeout(() => {
    //   this.blockIndex = 1
    // }, 6000)
  },

  created() {},

  computed: {},
}
</script>
