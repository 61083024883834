import { render, staticRenderFns } from "./Section2.vue?vue&type=template&id=718dce2c&scoped=true&"
import script from "./Section2.vue?vue&type=script&lang=js&"
export * from "./Section2.vue?vue&type=script&lang=js&"
import style0 from "./Section2.vue?vue&type=style&index=0&id=718dce2c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "718dce2c",
  null
  
)

export default component.exports